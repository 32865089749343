import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainService } from '../../../services/main.service';
// import {Article} from '../../../../../_classes/Article';
// import {FrontendService} from '../../../../services/frontend.service';
// import {ActivatedRoute, Router} from '@angular/router';
// import {MainService} from '../../../../services/main.service';
// import {Prices} from '../../../../../_classes/Prices';
// import {CartService} from '../../../../services/cart.service';
// import {Ingredient} from '../../../../../_classes/Ingredient';
// import {IngredientList} from '../../../../../_classes/IngredientList';
// import {ArticleGroup} from '../../../../../_classes/ArticleGroup';

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

    // check if form is submitted
    formSent = false;

    storeData: any;

    // HeaderBilder
    // default für die Fenstergröße max-width: 576px
    headerbild_Daves = "/assets/img/header/Daves_Headerbild_Obergruppen_304x170px_drei/Daves_Headerbild_Obergruppen_304x170px_Stellenangebot.jpg";
    headerbild_Daves_Desktop = "/assets/img/header/Daves_Headerbild_Obergruppen_2184x656px_burg_stellen_mittags/Daves_Headerbild_Obergruppen_2184x656px_Stellenangebot.jpg";
    sizeSmall = true;
    showRecaptchaAlert = false;


    // create new reactive formgroup for form
    // https://angular.io/guide/reactive-forms
    jobsFormGroup = new FormGroup({
        filiale: new FormControl(null, [Validators.nullValidator]),
        email: new FormControl(null, [Validators.required, Validators.email]),
        salutation: new FormControl(null, [Validators.required]),
        firstName: new FormControl(null, [Validators.required]),
        lastName: new FormControl(null, [Validators.required]),
        street: new FormControl(null, [Validators.required]),
        zip: new FormControl(null, [Validators.required, Validators.pattern('[0-9 ]*')]),
        city: new FormControl(null, [Validators.required]),
        phone: new FormControl(null, [Validators.required]),
        birthday: new FormControl(null, [Validators.required]),
        other_jobs: new FormControl(null, Validators.nullValidator),
        status: new FormControl(null, Validators.nullValidator),
        dataProtect: new FormControl(false, Validators.requiredTrue),
        type_full: new FormControl(null, Validators.nullValidator),
        type_half: new FormControl(null, Validators.nullValidator),
        type_450: new FormControl(null, Validators.nullValidator),
        days_1: new FormControl(null, Validators.nullValidator),
        days_2: new FormControl(null, Validators.nullValidator),
        days_3: new FormControl(null, Validators.nullValidator),
        days_4: new FormControl(null, Validators.nullValidator),
        days_5: new FormControl(null, Validators.nullValidator),
        days_6: new FormControl(null, Validators.nullValidator),
        days_7: new FormControl(null, Validators.nullValidator),
        health: new FormControl(null, Validators.nullValidator),
        own_car: new FormControl(null, Validators.nullValidator),
        job_kitchen: new FormControl(null, Validators.nullValidator),
        job_kitchen2: new FormControl(null, Validators.nullValidator),
        job_kitchen3: new FormControl(null, Validators.nullValidator),
        job_driver: new FormControl(null, Validators.nullValidator),
        driversLicense: new FormControl(null, Validators.nullValidator)
    });

    constructor(
        private http: HttpClient,
        private main_service: MainService,
    ) { }

    ngOnInit() {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        console.log('STOREDATA', this.storeData.master);
        this.setHeaderbild();
        let grcaptcha = document.createElement("script");
        grcaptcha.type = "text/javascript";
        grcaptcha.async = true;
        grcaptcha.src = "https://www.google.com/recaptcha/api.js";
        document.body.appendChild(grcaptcha);
    }

    // getter for form elements
    get email() {
        return this.jobsFormGroup.get('email');
    }

    get zip() {
        return this.jobsFormGroup.get('zip');
    }

    get firstName() {
        return this.jobsFormGroup.get('firstName');
    }

    get lastName() {
        return this.jobsFormGroup.get('lastName');
    }

    get street() {
        return this.jobsFormGroup.get('street');
    }

    get city() {
        return this.jobsFormGroup.get('city');
    }

    get phone() {
        return this.jobsFormGroup.get('phone');
    }

    get status() {
        return this.jobsFormGroup.get('status');
    }

    get otherJobs() {
        return this.jobsFormGroup.get('other_jobs');
    }

    // submit the newsletter form
    onSubmit() {

        if (!this.checkRecaptcha()) {
            this.showRecaptchaAlert = true;
            return;
        } else {
            this.showRecaptchaAlert = false;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin:': '*'
            }),
        };


        console.log(this.jobsFormGroup.value);

        const url = this.main_service.getFrontendUrl() + '/job/send';
        const data = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email
        };

        this.http.post(url, JSON.stringify(this.jobsFormGroup.value), httpOptions)
            .subscribe(
                (res: any) => {
                    this.formSent = true;
                    window.scroll(0, 0);
                },
                err => {
                    console.log('Error occured');
                }
            );
    }


    // Headerbild setzen
    setHeaderbild() {
        let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall) return;
        this.sizeSmall = small;
        if (small === false) {
            this.headerbild_Daves = this.headerbild_Daves_Desktop
        }
        else {
        }
    }

    checkRecaptcha() {
        let gr = (<HTMLInputElement>document.getElementById('g-recaptcha-response')).value;
        if (!gr) {
            return false;
        } else {
            return true;
        }
    }


}
