<ng-container *ngIf="!ready">
    <div class="text-center color-primary">
        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>


<ng-container *ngIf="ready">

    <ng-container *ngIf="couponRemoved">
        <div class="bg-shop-closed">
            <div class="dialog-shop-closed">
                <div class="title-dialog-shop-closed">
                    Gutschein entfernt
                </div>
                <div class="cont-dialog-shop-closed">
                    Der Gutschein <strong>{{couponRemoved.name}}</strong> wurde aus Ihrem Warenkorb entfernt. Bitte
                    beachten Sie die Einlösebedingungen des Gutscheins.
                    <div class="btn-dialog-shop-closed">
                        <button class="mp-btn mp-btn-yellow-on-white" (click)="ClearCouponRemoved()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div id="plshld" *ngIf="mollieinprocess">
        <h2 class="zahlung-bearbeitet">Ihre Zahlung wird bearbeitet</h2>
        <div class="mt-5 text-center">
            <svg-icon src="assets/icons/icon-loading-small.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
        </div>
        <div class="mt-5">
            <p class="text-center">
                Bitte haben sie etwas Geduld, es kann bis 2 Minuten dauern.
            </p>

            <h2 class="zahlung-bearbeitet mt-5">
                <b>Bitte aktualisieren sie die Seite nicht</b>
            </h2>
        </div>
    </div>
    <!-- Cart Dialog Order Success -->
    <div *ngIf="show_dialog" id="order-finished" class="static-page-layout row">

        <div class="col-md-12 mb-7">
            <div class="header-background-img header-wrapper" [style.backgroundImage]="'url(' + headerbild_MP + ')'">
            </div>
        </div>
        <br><br>


        <div class="col-md-12" *ngIf="payment_error">
            <div class="center-content">
                <h2 class="mp-font-large-yellow text-center">Ups, leider ist ein Fehler aufgetreten!</h2>
                <br>
                <div class="go-cart-content">
                    <p>
                        Deine Zahlung konnte nicht korrekt abgewickelt werden.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-12" *ngIf="!payment_error">
            <div class="center-content">
                <h2 *ngIf="winorderTrackingStatus !== 2 && winorderTrackingStatus !== 7  && winorderTrackingStatus !== -1"
                    class="mp-font-large-yellow text-center">Vielen Dank für deine Bestellung!</h2>
                <h2 *ngIf="winorderTrackingStatus == 7" class="mp-font-large-yellow text-center">Deine Bestellung ist
                    noch nicht bezahlt.</h2>
                <br>
                <div class="go-cart-content">
                    <h3 class="mp-font-medium-yellow">Status deiner Bestellung</h3>

                    <!-- Bestellung gespeichert und wartet auf Annahme durch Winorder - dh check status === 1 -->
                    <ng-container *ngIf="!orderResponse.status">
                        <p>
                            <svg-icon src="assets/icons/icon-loading-small.svg" [svgStyle]="{ 'fill':'currentColor' }">
                            </svg-icon>
                            Bestellung wird verarbeitet
                        </p>
                    </ng-container>


                    <!-- ALLES IN ORDNUNG UND BESTLLUNG ANGENOMMEN: dh if status === 1 -->
                    <ng-container *ngIf="orderResponse.status === 'Saving successful' || orderResponse.status">
                        <ng-container *ngIf="winorderTrackingStatus == -1">
                            <svg-icon src="assets/icons/icon-loading-small.svg"
                                      [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
                            ...
                        </ng-container>

                        <ng-container *ngIf="winorderTrackingStatus == 7">
                            <div class="col-12">
                                <p>
                                    <svg-icon src="assets/icons/icon-loading-small.svg"
                                              [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
                                    Wartet auf Zahlung.
                                </p>
                                <p>
                                    Für Bezahlung klicke bitte auf den folgenden Button:&nbsp;
                                </p>

                                <p class="text-center">
                                    <a class="mp-btn zahlung-fortfahren my-3 " href="{{mollieurl}}"
                                       target="_blank">Mit Zahlung
                                        fortfahren</a>
                                </p>
                            </div>
                        </ng-container>

                        <ng-container
                                *ngIf="winorderTrackingStatus !== 2 && winorderTrackingStatus !== 7  && winorderTrackingStatus !== -1">

                            <div class="col-12">
                                <p>
                                    <img src="assets/icons/icon_small_check_pizzablitz.svg" alt="Preloader">

                                    Bestellung erhalten
                                </p>

                                <p>
                                    <svg-icon src="assets/icons/icon-loading-small.svg"
                                              [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
                                    Bestellung gebucht (Dies kann bis zu 10 Minuten dauern)
                                </p>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="winorderTrackingStatus === 2">
                            <div class="col-12">
                                <p>
                                    <img src="assets/icons/icon_small_check_pizzablitz.svg"
                                         alt="Preloader">
                                    Bestellung erhalten
                                </p>
                                <br>
                                <p>
                                    <img src="assets/icons/icon_small_check_pizzablitz.svg" alt="Preloader">

                                    Bestellung gebucht
                                </p>
                            </div>
                        </ng-container>


                    </ng-container>

                    <!-- BESTELLUNG NICHT ABGESPEICHERT, SERVER ERROR -->
                    <ng-container *ngIf="orderResponse.status !== 'Saving successful' && !orderResponse.status">
                        Bei der Bestellung ist ein Fehler aufgetreten! <br>
                        Bitte ruf uns umgehend an, damit wir deine Bestellung neu aufnehmen
                        <br><br>
                        <!-- Telefon 0 37 31 - 77 50 30 -->
                        Telefon {{storeData.phone}}
                    </ng-container>

                    <p>Sobald deine Bestellung von unserem Mitarbeiter gebucht wurde, erhältst du eine
                        Bestellbestätigung per E-Mail.</p>
                    <h3 class="mp-font-medium-yellow">Lieferzeit</h3>
                    <p><strong>Montag bis Donnerstag:</strong> ca. 30 bis 60 Minuten </p>
                    <p><strong>Freitag bis Sonntag:</strong> ca. 45 bis 90 Minuten </p>
                    <p><strong>Selbstabholer:</strong> je nach Auftragslage ca. 20-30 Minuten</p>
                    <p>
                        Wir versuchen immer, die Lieferzeiten so kurz wie möglich zu halten. <br><br> Falls du eine
                        genauere Auskunft
                        der Lieferzeit wünschst, bitten wir dich, uns unter dieser Nummer zu kontaktieren:
                        <strong>{{storeData.phone}}.</strong> Bitte beachte die Öffnungszeiten. <br><br> Dein Team von
                        {{storeData.name}}
                    </p>
                </div>

            </div>
        </div>

    </div>

    <!-- Cart Page Stepper -->
    <div id="cart" *ngIf="!show_dialog">
        <ng-container *ngIf="cart_items.length === 0">
            <div class="mt-7 pt-7 mt-xl-0 text-center">
                <h5 class="mt-3">Du hast noch keine Artikel ausgesucht</h5>
                Entdecke jetzt unser umfangreiches Sortiment!
                <br>
                <button routerLink="/shop" class="btn-mp btn-yellow mt-3">Zur Startseite</button>
                <hr class="mx-3">
                <h2>Sparcoupons / Gutscheine</h2>
                <p>Wenn du einen Sparcoupon oder Gutscheincode hast, kannst du ihn hier eingeben und einlösen!</p>

                <!-- <ng-container *ngIf="!singleCoupon"> -->
                <form class="mp-form" [formGroup]="couponForm" (ngSubmit)="onSubmitCouponForm()">
                    <p class="error-message" *ngIf="couponErrorStr">
                        {{ couponErrorStr }}
                    </p>
                    <p class="error-message" *ngIf="errorCodeNotValid">Der eingegebene Code ist nicht korrekt oder
                        nicht mehr gültig!</p>
                    <p class="error-message" *ngIf="errorCodeIsEmpty">Bitte einen Code eingeben!</p>
                    <p class="error-message" *ngIf="errorCouponNoItemsInCart">Für den Gutschein müssen Artikel im
                        Warenkorb sein!</p>
                    <p class="error-message" *ngIf="errorCouponsAlreadyInCart">Dieser Gutschein gilt nur
                        einzeln!
                    </p>
                    <p class="error-message" *ngIf="errorMinorderValue">Der Mindestbestellwert von
                        <strong>{{couponData.min_order_value_on_pickup | number:'1.2-5'}}€</strong> wurde für diesen
                        Gutschein noch nicht erreicht!
                    </p>
                    <label>
                        <input autocomplete="off" class="mp-form-input" type="text" formControlName="couponCode"
                               (input)="resetErrors()" placeholder="Hier Code eingeben">
                    </label>
                    <br>
                    <input class="btn-mp btn-yellow mt-3" type="submit" value="hier einlösen">

                </form>
                <!-- </ng-container> -->
                <!-- <p class="error-message" *ngIf="singleCoupon">Es wurde bereits ein Coupon eingelöst!</p> -->
                <hr class="mx-3">
            </div>

            <!-- delivery-wrapper -->
            <div class="delivery-wrapper">
                <form #delivery_form="ngForm" class="delivery-form mp-form">

                    <!-- begin: delivery column -->
                    <div class="delivery-col mt-0">
                        <h2>Deine Bestelldetails</h2>
                        <div class="form-group-wrapper text-center pb-5">
                            <div class="delivery-details-list-item">
                                <strong>Lieferart:</strong>
                                <div *ngIf="!on_pickup">Liefern lassen</div>
                                <div *ngIf="on_pickup">Abholen</div>
                                <a routerLink="/willkommen/start">Ändern</a>
                            </div>
                            <div class="delivery-details-list-item">
                                <strong>Zeitpunkt:</strong>
                                <div *ngIf="!send_later">Sofort</div>
                                <div *ngIf="send_later">{{ deliveryDate | date:'fullDate':'':'de' }} um {{ deliveryTime
                                    }}
                                </div>
                                <a *ngIf="shopClosed" [routerLink]="['/willkommen/start/edit']"
                                   fragment="datum">Ändern</a>
                                <a *ngIf="!shopClosed" [routerLink]="['/willkommen/start/edit']"
                                   fragment="datum">Ändern</a>
                            </div>
                            <div class="delivery-details-list-item" *ngIf="!on_pickup">
                                <strong>Deine Adresse:</strong>
                                <p>{{ user.adress.street }} {{ user.adress.street_number }}, {{ user.adress.plz }}
                                    {{
                                    user.adress.district }} OT von {{ user.adress.city }}</p>
                                <a routerLink="/willkommen/start">Ändern</a>
                            </div>
                        </div>
                    </div>
                    <!-- end: delivery column -->

                </form>

            </div>
            <!-- /delivery-wrapper -->
        </ng-container>

        <ng-container *ngIf="cart_items.length > 0">
            <!-- begin:: STEPPER COMPONENT -->
            <mat-horizontal-stepper linear #stepper (selectionChange)="scrollTop()">
                <!-- STEP 1: CART OVERVIEW -->
                <ng-template matStepperIcon="edit" let-index="index">
                    {{index + 1}}
                </ng-template>

                <!-- changed start -->

                <mat-step label="Adresseingabe & Warenkorb" [completed]="Step2IsValid()">
                    <!-- changed end -->

                    <div class="container-step-1 row mt-3 mx-0">
                        <!-- WARENKOB-->
                        <div class="px-4 mx-0 col-lg-5 ml-lg-7 mt-lg-0 pr-lg-0">
                            <h2 class="">Warenkorb</h2>

                            <div class="">
                                <form #delivery_form="ngForm" class="delivery-form mp-form">

                                    <!-- Sparcoupons / Gutscheine -->
                                    <details open class="my-3">
                                        <summary>Sparcoupons / Gutscheine
                                        </summary>
                                        <p class="text-center">Wenn du einen Sparcoupon oder Gutscheincode hast,
                                            kannst du
                                            ihn hier eingeben und einlösen!</p>
                                        <div class="form-group-wrapper">
                                            <ng-container>
                                                <form class="mp-form" [formGroup]="couponForm"
                                                      (ngSubmit)="onSubmitCouponForm()">
                                                    <p class="success-message" *ngIf="couponSuccessStr">
                                                        {{ couponSuccessStr }}
                                                    </p>
                                                    <p class="error-message" *ngIf="couponErrorStr">
                                                        {{ couponErrorStr }}
                                                    </p>
                                                    <label>Sparcoupon / Gutscheincode</label><br>
                                                    <input autocomplete="off" class="input-coupon mp-form-input mb-2"
                                                           type="text" formControlName="couponCode"
                                                           [ngModel]="couponCodeNum" (input)="resetErrors()"
                                                           placeholder="Hier Code eingeben">
                                                    <input class=" mp-btn button-step1 button-black ml-sm-3 ml-lg-0 mb-1"
                                                           type="submit" value="Hier einlösen">
                                                </form>
                                            </ng-container>
                                            <!-- <p class="error-message" *ngIf="singleCoupon && couponsInCart">Es wurde
                                                bereits ein Coupon eingelöst!</p> -->
                                        </div>
                                    </details>


                                    <!-- Deine Bestelldetails -->
                                    <details open class="my-3">
                                        <summary>Deine Bestelldetails
                                        </summary>

                                        <div class="delivery-details-list-item">
                                            <strong>Lieferart:</strong>
                                            <div class="d-flex ">
                                                <div *ngIf="!on_pickup" class="art">liefern lassen</div>
                                                <div *ngIf="on_pickup" class="art">abholen</div>
                                                <a routerLink="/willkommen/start"
                                                   class="art-aendern text-right">Ändern</a>
                                            </div>
                                        </div>

                                        <div class="delivery-details-list-item">
                                            <strong>Zeitpunkt:</strong>
                                            <div class="d-flex ">
                                                <div *ngIf="!send_later" class="art">sofort</div>
                                                <div *ngIf="send_later" class="art">{{ deliveryDate |
                                                date:'fullDate':'':'de' }}
                                                    um {{ deliveryTime }} Uhr
                                                    <span
                                                            *ngIf="shopClosed && !shopClosedUntilTomorrow && storeData.master == 3 && currDateDeliv">
                                                        (oder Schnellmöglichst)</span>
                                                </div>
                                                <a *ngIf="shopClosed" [routerLink]="['/willkommen/start/edit']"
                                                   fragment="datum" class="art-aendern text-right">Ändern</a>
                                                <a *ngIf="!shopClosed" [routerLink]="['/willkommen/start/edit']"
                                                   fragment="datum" class="art-aendern text-right">Ändern</a>
                                            </div>
                                        </div>
                                        <div class="delivery-details-list-item" *ngIf="!on_pickup">
                                            <strong>Deine Adresse:</strong>
                                            <div class="d-flex ">
                                                <div class="art">
                                                    {{ user.adress.street }} {{ user.adress.street_number }},
                                                    {{ user.adress.plz }} {{ user.adress.district }} OT von
                                                    {{ user.adress.city }}
                                                </div>
                                                <a routerLink="/willkommen/start"
                                                   class="art-aendern text-right">Ändern</a>
                                            </div>
                                        </div>
                                        <hr>
                                        <!--Kommentarfeld-->
                                        <label>Anmerkung zur Bestellung</label>
                                        <mat-form-field appearance="outline">
                                            <textarea matInput rows="4" name="comment" [(ngModel)]="comment"></textarea>
                                        </mat-form-field>
                                    </details>
                                </form>
                            </div>
                            <!-- /delivery-wrapper -->


                            <!-- Trinkgeld -->
                            <details open>
                                <summary>Trinkgeld
                                </summary>
                                <div class="form-group-wrapper form-group-tip" [formGroup]="tipFormGroup">
                                    <!-- Trinkgeld -->
                                    <div class="tip-row">
                                        <label for="tip">Trinkgeld</label>
                                        <div class="d-flex">
                                            <mat-radio-group formControlName="tipFixedPercentage"
                                                             aria-label="Select an option"
                                                             (change)="changeTipPercentage($event.value)"
                                                             class="radio-buttons">
                                                <mat-radio-button value="0.03" class="m-1">3%</mat-radio-button>
                                                <mat-radio-button value="0.05" class="m-1">5%</mat-radio-button>
                                                <mat-radio-button value="0.1" class="m-1">10%</mat-radio-button>
                                                <mat-radio-button value="0" class="m-1">Eigener Betrag
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <mat-form-field class="tip-input text-right mb-0 " appearance="outline">
                                                <input autocomplete="off" placeholder="" type="text" min="0" matInput
                                                       #number formControlName="tipValue" name="tip"
                                                       value="{{ tip | number:'1.2-2':undefined:'de-DE' }}"
                                                       (change)="onChangeTip($event)" (blur)="onBlurTip($event)"
                                                       class="wk-trink-input"><span class="wk-trink-eur">€</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </details>

                            <!-- Deine Bestellung alt-->
                            <!--   <details open class="my-3">
                                   <summary>Deine Bestellung</summary>
                                   <div class="bestellung-list">
                                       <div class="row ml-0" *ngFor="let article of cart_items, let i = index">
                                           <div class="col-12 row m-0 p-1">
                                               <div class=" name col-8 col-lg-9 p-1">
                                                   &lt;!&ndash; article name &ndash;&gt;
                                                   <p><strong>{{article.amount}}x <span
                                                               [innerHTML]="article.article.name"></span>
                                                           <ng-container *ngIf="article.article.coupon_data">Code:
                                                               {{ article.article.coupon_data.coupon_content }}
                                                           </ng-container>
                                                       </strong>
                                                   </p>
                                                   &lt;!&ndash; size &ndash;&gt;
                                                   <p class="pl-2">Größe:
                                                       <br>{{article.article.price[article.size_index].size}}
                                                       ({{article.article.price[article.size_index].price |
                                                       number:'1.2-5'}}€)
                                                   </p>
                                                   &lt;!&ndash; ingredient list &ndash;&gt;
                                                   <p class="pl-2">
                                                       <ng-container *ngFor="let added_ing of article.added_ingredients">
                                                           {{added_ing.amount}}x {{added_ing.ingredients.name}}
                                                           ({{ added_ing.ingredients.price[article.size_index].price *
                                                           added_ing.amount |
                                                           number:'1.2-5'
                                                           }}€)<br>
                                                       </ng-container>
                                                   </p>
                                               </div>

                                               <div class="price col-4 col-lg-3 p-1 text-right">
                                                   <ng-container>
                                                       <div class="mp-font-semibold pr-2">
                                                           {{ (article.article.price[article.size_index].price *
                                                           article.amount) | number:'1.2-5'
                                                           }} €
                                                       </div>
                                                   </ng-container>
                                               </div>
                                           </div>
                                           <hr class="col-11 mr-7 mr-sm-5 my-2 p-0">
                                           <div class="col-12 row m-0 p-0 ">
                                               <div class="aendern col-8 col-sm-9 col-md-10 col-lg-9 text-right">
                                                   <button *ngIf="article.article.expandable == true" (click)="onEdit(i)"
                                                       class="mp-btn mp-btn-small">Ändern
                                                   </button>
                                               </div>
                                               <div class="plus-minus col-4 col-sm-3 col-md-2 col-lg-3 m-0 p-0">
                                                   <ng-container *ngIf="!article.article.is_coupon_product">
                                                       <button (click)="onSub(i)" type="button"
                                                           class="ml-2 mr-1 ml-sm-6 ml-md-3 mr-sm-3 ml-lg-1 mr-xl-2  mp-btn-minus">
                                                           <svg-icon src="assets/icons/icon_minus.svg"
                                                               [svgStyle]="{ 'width.px':25,'fill':'currentColor' }">
                                                           </svg-icon>
                                                       </button>
                                                       <button (click)="onAdd(i)" type="button"
                                                           class="mr-1 mr-sm-2 mr-lg-1 mp-btn-plus">
                                                           <svg-icon src="assets/icons/icon_plus.svg"
                                                               [svgStyle]="{ 'width.px':25,'fill':'currentColor' }">
                                                           </svg-icon>
                                                       </button>
                                                   </ng-container>
                                                   <ng-container *ngIf="article.article.is_coupon_product">
                                                       <button (click)="onSub(i)" type="button" class="mr-3 mp-btn-minus">
                                                           <svg-icon src="assets/icons/icon_minus.svg"
                                                               [svgStyle]="{ 'width.px':25,'fill':'currentColor' }">
                                                           </svg-icon>
                                                       </button>
                                                   </ng-container>
                                               </div>

                                           </div>
                                           <hr class="col-11 mr-7 mr-sm-5 my-2 p-0 ">
                                       </div>
                                   </div>
                               </details>-->

                            <!--Deine Bestellung copie MP-->
                            <details open class="my-3">
                                <summary>Deine Bestellung</summary>
                                <div class="bestellung-list">
                                    <div class="row ml-0" *ngFor="let article of cart_items, let i = index">
                                        <!-- for Article-->
                                        <div class="col-12 row m-0 p-1">
                                            <div class=" name col-8 col-lg-9 p-1">
                                                <!-- article name -->
                                                <p><strong>{{article.amount}}x <span
                                                        [innerHTML]="article.article.name"></span>
                                                    <ng-container *ngIf="article.article.coupon_data">Code:
                                                        {{ article.article.coupon_data.coupon_content }}
                                                    </ng-container>
                                                </strong>
                                                </p>
                                                <!-- size -->
                                                <p class="pl-2">Größe:
                                                    <br>{{article.article.price[article.size_index].size}}
                                                    ({{article.article.price[article.size_index].price |
                                                    number:'1.2-5'}}€)
                                                </p>
                                            </div>
                                            <div class="price col-4 col-lg-3 p-1 text-right">
                                                <ng-container>
                                                    <div class="mp-font-semibold pr-2">
                                                        {{ (article.article.price[article.size_index].price *
                                                            article.amount) | number:'1.2-5'
                                                        }} €
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!-- /for Article-->

                                        <!--for Ingredient-->
                                        <div class="col-12 row m-0 p-1">
                                            <div class=" name col-8 col-lg-9 p-1">
                                                <!-- ingredient list -->
                                                <div class="pl-2">
                                                    <ng-container *ngFor="let added_ing of article.added_ingredients">
                                                        <div>{{added_ing.amount}}x {{added_ing.ingredients.name}}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <!-- ingredient price -->
                                            <div class="price col-4 col-lg-3 p-1 text-right">
                                                <ng-container *ngFor="let added_ing of article.added_ingredients">
                                                    <div class="pr-2">
                                                        {{ added_ing.ingredients.price[article.size_index].price *
                                                    added_ing.amount |
                                                    number:'1.2-5'
                                                        }}€
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                        <!--/for Ingredient-->

                                        <hr class="col-11 mr-7 mr-sm-5 my-2 p-0">
                                        <div class="col-12 row m-0 p-0 ">
                                            <div class="aendern col-8 col-sm-9 col-md-10 col-lg-9 text-right">
                                                <button *ngIf="article.article.expandable == true" (click)="onEdit(i)"
                                                        class="mp-btn mp-btn-small">Ändern
                                                </button>
                                            </div>
                                            <div class="plus-minus col-4 col-sm-3 col-md-2 col-lg-3 m-0 p-0">
                                                <ng-container *ngIf="!article.article.is_coupon_product">
                                                    <button (click)="onSub(i)" type="button"
                                                            [disabled]="!storageLoaded"
                                                            class="ml-2 mr-1 ml-sm-6 ml-md-3 mr-sm-3 ml-lg-1 mr-xl-2  mp-btn-minus">
                                                        <svg-icon src="assets/icons/icon_minus.svg"
                                                                  [svgStyle]="{ 'width.px':25,'fill':'currentColor' }">
                                                        </svg-icon>
                                                    </button>
                                                    <button (click)="onAdd(i)" type="button"
                                                            [disabled]="!storageLoaded"
                                                            class="mr-1 mr-sm-2 mr-lg-1 mp-btn-plus">
                                                        <svg-icon src="assets/icons/icon_plus.svg"
                                                                  [svgStyle]="{ 'width.px':25,'fill':'currentColor' }">
                                                        </svg-icon>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngIf="article.article.is_coupon_product">
                                                    <button (click)="onSub(i)" type="button" class="mr-3 mp-btn-minus">
                                                        <svg-icon src="assets/icons/icon_minus.svg"
                                                                  [svgStyle]="{ 'width.px':25,'fill':'currentColor' }">
                                                        </svg-icon>
                                                    </button>
                                                </ng-container>
                                            </div>

                                        </div>
                                        <hr class="col-11 mr-7 mr-sm-5 my-2 p-0 ">
                                    </div>
                                </div>
                            </details>

                            <!-- Cart Subtotal -->
                            <div class="gesamtbetrag mx-0">

                                <div class="my-5 pt-2 pr-2">
                                    <div class="cart-total-price-tip-black">Betrag Warenkorb:
                                        <strong>{{priceCart | number:'1.2-5'}}€ </strong>
                                    </div>

                                    <ng-container *ngFor="let mod of priceModifications">
                                        <div class="cart-total-price-trinkgeld">
                                            {{mod.name}}: <strong>{{mod.value | number:'1.2-5'}}€ </strong>
                                        </div>
                                    </ng-container>

                                    <div class="cart-total-price font-weight-bold border-top py-2">Gesamtbetrag:
                                        <strong>{{priceTotal - onlineBonus | number:'1.2-5'}}€ </strong>
                                    </div>

                                </div>

                            </div>

                            <!--Block 2  "Weiter zur Kasse  -->
                            <div class="wk-btns-arrow d-flex justify-content-center flex-column align-items-center mt-2 mx-auto">
                                <div class="delivery-wrapper text-center" *ngIf="min_val > priceCart">
                                    <p style="font-size: 15px; font-weight: bold;" class="mb-4 mt-4">
                                        Dein Mindestbestellwert für <br> {{ user.adress.street }}
                                        {{ user.adress.street_number }} <br> in {{ user.adress.plz}}
                                        {{ user.adress.district }} OT von {{ user.adress.city}} beträgt {{ min_val }} €
                                    </p>
                                    <ng-container *ngIf="addMinQuantitySurcharge == false">
                                        <p class="warning-big">Du hast den Mindestbestellwert von
                                            {{min_val | number:'1.2-5'}}€ noch nicht erreicht. Dir fehlen noch
                                            {{ min_quan_sur | number:'1.2-5'}}€!</p>
                                        <p>Keine Sorge, wir liefern trotzdem. Wenn du keinen weiteren Artikel bestellen
                                            möchtest, zahlst du automatisch den Mindestbestellwert.</p>
                                    </ng-container>

                                    <ng-container *ngIf="addMinQuantitySurcharge == true">
                                        <p class="positive-big">Der Mindermengenaufschlag von
                                            {{ min_quan_sur |
                                            number:'1.2-5'}}€ wurde zum Warenkorb hinzugefügt.</p>
                                    </ng-container>

                                    <button class="mp-btn mb-2 button-step1" (click)="onAddMinQuantitySurcharge()"
                                            *ngIf="addMinQuantitySurcharge == true">{{ min_quan_sur | number:'1.2-5'}} €
                                        entfernen
                                    </button>
                                    <button class="mp-btn mb-2" (click)="onAddMinQuantitySurcharge()"
                                            *ngIf="addMinQuantitySurcharge == false">{{ min_quan_sur | number:'1.2-5'}}
                                        € hinzufügen
                                    </button>
                                    <button class="mp-btn ml-2 mb-2" *ngIf="addMinQuantitySurcharge == false"
                                            routerLink="/shop">Weitere Artikel hinzufügen
                                    </button>
                                </div>

                                <div>
                                    <div
                                            *ngIf="!adress_form.form.valid  ||  ( on_pickup && (  !searchPlzFormControl.valid || !searchStreetFormControl.valid))">
                                        <p class="text-danger text-center"> Die von dir angegebene Adresse ist nicht
                                            vollständig, bitte überprüfe sie nochmal.</p>
                                    </div>

                                    <button type="button" class="mp-btn mp-btn-yellow-on-black button-step1 mt-2"
                                            disabeled="" (click)="updateUserData()"
                                            [disabled]="!adress_form.form.valid  ||  ( on_pickup && (  !searchPlzFormControl.valid || !searchStreetFormControl.valid)) || ((addMinQuantitySurcharge == false && !on_pickup) && (min_val > priceCart))">
                                        Weiter zur Kasse
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Seite 2: Adresseingabe - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - --->

                        <!--  ADRESSE-->
                        <div class="mat-step-address ml-lg-2 ml-xl-0 col-lg-6 text-left order-first">
                            <!--<mat-step  completed="false">-->
                            <h2 class="">Adresseingabe</h2>
                            <form #adress_form="ngForm" id="checkoutAddressForm">

                                <div class="p-3">

                                    <label style="margin-bottom: 10px;">Anrede *</label>
                                    <mat-radio-group name="title" [(ngModel)]="user.title" [required] required>
                                        <mat-radio-button value="Herr">Herr</mat-radio-button>
                                        <mat-radio-button value="Frau" style="margin-left:20px;">Frau</mat-radio-button>
                                    </mat-radio-group>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Vorname *</label>
                                            <mat-form-field class="example-full-width address-form-input"
                                                            appearance="outline">
                                                <input matInput [(ngModel)]="user.fir_name" required name="fir_name"
                                                       #fir_name="ngModel">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Nachname *</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input matInput [(ngModel)]="user.fam_name" required name="fam_name"
                                                       #fam_name="ngModel">
                                            </mat-form-field>
                                        </div>
                                    </div>


                                    <div class="checkbox">
                                        <mat-checkbox [(ngModel)]="b_deliver_to_firma" name="deliver_to_forma">
                                            Firmenadresse
                                        </mat-checkbox>
                                    </div>

                                    <ng-container *ngIf="b_deliver_to_firma">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Firma</label>
                                                <mat-form-field class="example-full-width" *ngIf="b_deliver_to_firma"
                                                                appearance="outline">
                                                    <input matInput [(ngModel)]="user.adress.firma" name="firma">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Abteilung</label>
                                                <mat-form-field class="example-full-width" *ngIf="b_deliver_to_firma"
                                                                appearance="outline">
                                                    <input matInput [(ngModel)]="user.adress.department"
                                                           name="department">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="row">
                                        <div class="col">

                                            <label>Postleitzahl *</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input autocomplete="off" maxlength="5"
                                                       [matAutocomplete]="plzAutocomplete" (change)="changePlz()"
                                                       [formControl]="searchPlzFormControl" (blur)="plzBlur()" matInput
                                                       required autocomplete="off">
                                                <mat-autocomplete [panelWidth]="300" #plzAutocomplete="matAutocomplete"
                                                                  [displayWith]="displayFnPlz"
                                                                  (optionSelected)="plzClick($event)">
                                                    <mat-option *ngIf="isLoadingPlz" class="is-loading">Suche...
                                                    </mat-option>
                                                    <ng-container *ngIf="!isLoadingPlz">
                                                        <mat-option *ngFor="let address of filteredPlz"
                                                                    [value]="address">
                                                            <span><b>{{address.plz}}</b> {{address.city}}
                                                                ({{address.district}})</span>
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Stadt *</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input autocomplete="off" matInput disabled
                                                       [(ngModel)]="user.adress.city" required name="city"
                                                       #city="ngModel">
                                            </mat-form-field>

                                        </div>
                                        <div class="col-md-6">
                                            <label>Ortsteil *</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input autocomplete="off" matInput disabled
                                                       [(ngModel)]="user.adress.district" required name="district"
                                                       #district="ngModel">
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Straße *</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input autocomplete="off" matInput [disabled]="!on_pickup"
                                                       [formControl]="searchStreetFormControl"
                                                       [matAutocomplete]="streetAutocomplete" required name="street"
                                                       autocomplete="off">
                                                <mat-autocomplete #streetAutocomplete="matAutocomplete"
                                                                  [displayWith]="displayFnStreet"
                                                                  (optionSelected)="streetClick($event)">
                                                    <mat-option *ngIf="isLoadingStreet" class="is-loading">Suche...
                                                    </mat-option>
                                                    <ng-container *ngIf="!isLoadingStreet">
                                                        <mat-option *ngFor="let address of filteredStreet"
                                                                    [value]="address">
                                                            <span><b>{{address.street}}</b> {{address.plz}}
                                                                {{address.city}} ({{address.district}})</span>
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Hausnummer *</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input autocomplete="off" maxlength="6" matInput [disabled]="!on_pickup"
                                                       [(ngModel)]="user.adress.street_number" required
                                                       name="street_number" #street_number="ngModel">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <!--Etage und Kommentar-->
                                            <label>Etage</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <input autocomplete="off" matInput [(ngModel)]="user.adress.etage"
                                                       name="etage">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Wegbeschreibung</label>
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <textarea autocomplete="off" matInput [(ngModel)]="user.adress.comment"
                                                          name="comment" rows="4"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>


                                </div>

                                <div class="wk-forms pb-3 px-3">
                                    <div class="row">
                                        <!--Mail -->
                                        <div class="col-md-6">
                                            <label>E-Mail *</label>
                                            <mat-form-field class="example-full-width" appearance="fill"
                                                            (input)="changeEmail()">
                                                <input autocomplete="off" matInput email [(ngModel)]="user.mail"
                                                       required name="mail" #email="ngModel">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Telefonnummer *</label>
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <input autocomplete="off" matInput pattern="^[0-9]*$"
                                                       [(ngModel)]="user.phone" required minlength="8" name="phone"
                                                       #phone="ngModel">
                                            </mat-form-field>
                                            <p class="mt-2 mb-0" *ngIf="phone.invalid">Bitte gib deine
                                                Telefonnummer an! Bitte NUR Zahlen!</p>
                                        </div>
                                    </div>
                                </div>

                                <p class="px-3 show-desktop">Alle Felder mit * sind Pflichtfelder. Das Formular kann
                                    erst abgeschickt werden,
                                    wenn
                                    alle Daten korrekt eingegeben wurden.</p>
                            </form>

                            <div class="wk-btns-arrow mt-2 mx-auto show-desktop">
                                <div class="delivery-wrapper text-center" *ngIf="min_val > priceCart">
                                    <p style="font-size: 15px; font-weight: bold;" class="mb-4 mt-4">
                                        Dein Mindestbestellwert für <br> {{ user.adress.street }}
                                        {{ user.adress.street_number }} <br> in {{ user.adress.plz}}
                                        {{ user.adress.district }} OT von {{ user.adress.city}} beträgt {{ min_val }} €
                                    </p>
                                    <ng-container *ngIf="addMinQuantitySurcharge == false">
                                        <p class="warning-big">Du hast den Mindestbestellwert von
                                            {{min_val | number:'1.2-5'}}€ noch nicht erreicht. Dir fehlen noch
                                            {{ min_quan_sur | number:'1.2-5'}}€!</p>
                                        <p>Keine Sorge, wir liefern trotzdem. Wenn du keinen weiteren Artikel bestellen
                                            möchtest, zahlst du automatisch den Mindestbestellwert.</p>
                                    </ng-container>

                                    <ng-container *ngIf="addMinQuantitySurcharge == true">
                                        <p class="positive-big">Der Mindermengenaufschlag von
                                            {{ min_quan_sur |
                                            number:'1.2-5'}}€ wurde zum Warenkorb hinzugefügt.</p>
                                    </ng-container>

                                    <button class="mp-btn mb-2 button-step1" (click)="onAddMinQuantitySurcharge()"
                                            *ngIf="addMinQuantitySurcharge == true">{{ min_quan_sur | number:'1.2-5'}} €
                                        entfernen
                                    </button>
                                    <button class="mp-btn mb-2" (click)="onAddMinQuantitySurcharge()"
                                            *ngIf="addMinQuantitySurcharge == false">{{ min_quan_sur | number:'1.2-5'}}
                                        € hinzufügen
                                    </button>
                                    <button class="mp-btn ml-2 mb-2" *ngIf="addMinQuantitySurcharge == false"
                                            routerLink="/shop">Weitere Artikel hinzufügen
                                    </button>
                                </div>

                                <div>
                                    <div
                                            *ngIf="!adress_form.form.valid  ||  ( on_pickup && (  !searchPlzFormControl.valid || !searchStreetFormControl.valid))">
                                        <p class="text-danger text-center"> Die von dir angegebene Adresse ist nicht
                                            vollständig, bitte überprüfe sie nochmal.</p>
                                    </div>

                                    <button type="button" class="mp-btn mp-btn-yellow-on-black button-step1 mt-2"
                                            disabeled="" (click)="updateUserData()"
                                            [disabled]="!adress_form.form.valid  ||  ( on_pickup && (  !searchPlzFormControl.valid || !searchStreetFormControl.valid)) || ((addMinQuantitySurcharge == false && !on_pickup) && (min_val > priceCart))">
                                        Weiter zur Kasse
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <p class="px-3 show-mobile">Alle Felder mit * sind Pflichtfelder. Das Formular kann erst abgeschickt
                        werden,
                        wenn
                        alle Daten korrekt eingegeben wurden.</p>
                </mat-step>


                <!-- STEP-BEZAHLUNG -->
                <!-- Seite 3: Abschließende Übersicht - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - --->
                <mat-step label="Zahlart & Übersicht">

                    <div class="container-step-1 row mt-3 mx-xxxl-7 ml-xl-1">
                        <!-- begin: Zahlenarten -->

                        <div class="mat-step-address col-lg-6 ml-4 ml-xl-0 text-left order-lg-first">
                            <h2>Zahlungsart wählen</h2>
                            <div class="form-group-wrapper mt-3">
                                <form #pay_form="ngForm">

                                    <div class="payment" *ngIf="payments && payments.mollie">
                                        <div class="payment-type text-left mb-2">
                                            <strong class="summary">Online bezahlen</strong>
                                        </div>
                                        <mat-radio-group *ngIf="payments" (change)="changePayment()"
                                                         [(ngModel)]="payment_type" name="payment_type" required
                                                         class="payment-group ">
                                            <p class="text-left" *ngIf="paymentAvailable('mollie')">
                                                <mat-radio-button value="{{payments.mollie.id}}" class="payment-bar">
                                                    <ng-container *ngIf="payments.mollie.logo">
                                                        <img src="{{image_url}}/admin{{logo}}"
                                                             *ngFor="let logo of payments.mollie.logo"
                                                             class="zahlung-icon">
                                                    </ng-container>
                                                </mat-radio-button>
                                            </p>

                                        </mat-radio-group>
                                    </div>
                                    <div class="payment">
                                        <div class="payment-type text-left mb-2">
                                            <strong class="summary">Bezahlung bei
                                                <span *ngIf="!on_pickup">Lieferung</span>
                                                <span *ngIf="on_pickup">Abholung</span></strong>
                                        </div>
                                        <mat-radio-group (change)="changePayment()" [(ngModel)]="payment_type"
                                                         name="payment_type" required class="payment-group">
                                            <p class="text-left" *ngIf="paymentAvailable('cash')">
                                                <mat-radio-button value="{{payments.cash.id}}" class="payment-bar">
                                                    <ng-container *ngIf="payments.cash.logo">
                                                        <img src="{{image_url}}/admin{{logo}}"
                                                             *ngFor="let logo of payments.cash.logo"
                                                             class="zahlung-icon">
                                                    </ng-container>
                                                </mat-radio-button>
                                            </p>
                                            <p class="text-left"
                                               *ngIf="!on_pickup && paymentAvailable('creditcard_onsite')">
                                                <mat-radio-button value="{{payments.creditcard_onsite.id}}"
                                                                  class="payment-bar">
                                                    <ng-container *ngIf="payments.creditcard_onsite.logo">
                                                        <img src="{{image_url}}/admin{{logo}}"
                                                             *ngFor="let logo of payments.creditcard_onsite.logo"
                                                             class="zahlung-icon">
                                                    </ng-container>
                                                </mat-radio-button>
                                            </p>
                                            <p class="text-left"
                                               *ngIf="on_pickup && paymentAvailable('creditcard_onsite_pickup')">
                                                <mat-radio-button value="{{payments.creditcard_onsite_pickup.id}}"
                                                                  class="payment-bar">
                                                    <ng-container *ngIf="payments.creditcard_onsite_pickup.logo">
                                                        <img src="{{image_url}}/admin{{logo}}"
                                                             *ngFor="let logo of payments.creditcard_onsite_pickup.logo"
                                                             class="zahlung-icon">
                                                    </ng-container>
                                                </mat-radio-button>
                                            </p>
                                        </mat-radio-group>
                                    </div>
                                </form>

                                <form class="mt-5 mx-2">

                                    <p class="text-left mt-3"><strong class="summary">Bitte bestätige, um
                                        fortzufahren:</strong>
                                    <div>
                                        <mat-checkbox required (change)="checkValidation()" [(ngModel)]="read_agb"
                                                      name="read_agb">Ich habe die <a class="mp-link-underline"
                                                                                      routerLink="/shop/agb">AGB</a>
                                            gelesen und akzeptiert. *
                                        </mat-checkbox>
                                    </div>
                                    <div>
                                        <mat-checkbox required (change)="checkValidation()"
                                                      [(ngModel)]="read_datenschutz" name="read_datenschutz">
                                            Ich habe die <a class="mp-link-underline"
                                                            routerLink="/shop/datenschutz">Datenschutzbestimmungen</a>
                                            gelesen
                                            und akzeptiert. *
                                        </mat-checkbox>
                                    </div>
                                </form>
                            </div>

                            <!--                   Änderungen 9 November 2022-->
                            <!-- <ng-container *ngIf="cart_items.length > 6">-->
                            <div class="col text-center mt-4">
                                <div *ngIf="!payment_type">
                                    <p class="text-danger">Bitte wähle die Zahlungsart aus.</p>
                                </div>
                                <div *ngIf="!read_agb || !read_datenschutz">
                                    <p class="text-danger">Bitte stimme den AGB und Datenschutz zu.</p>
                                </div>
                            </div>


                            <div class="wk-btns-arrow wk-overview-btns-arrow wk-btns-arrow-ab6">
                                <!-- BUTTON ZURÜCK -->
                                <!-- show Desktop-->
                                <div class="d-flex align-items-end justify-content-center show-desktop">
                                    <ng-container *ngIf="payment_type!=1">

                                        <button mat-button matStepperPrevious type="button"
                                                class="m-0 button-link mp-btn-prev">
                                            <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">

                                        <button mat-button matStepperPrevious type="button"
                                                class="m-0 button-link mp-btn-prev">
                                            <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">

                                        <button class="m-0 button-link mp-btn-prev" disabled>
                                            <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                        </button>
                                    </ng-container>

                                </div>
                                <!-- show Mobile-->
                                <div
                                        class="mb-sm-0 mt-sm-auto p-0 d-flex align-items-end justify-content-center show-mobile b-z">
                                    <ng-container *ngIf="payment_type!=1">

                                        <button mat-button matStepperPrevious type="button"
                                                class="m-0 button-link mp-btn-prev">
                                            <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">

                                        <button mat-button matStepperPrevious type="button"
                                                class="m-0 button-link mp-btn-prev">
                                            <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">

                                        <button type="button" class="m-0 button-link mp-btn-prev" disabled>
                                            <svg-icon src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                        </button>
                                    </ng-container>

                                </div>

                                <!-- BUTTON BEZAHLEN -->
                                <div class="p-0  zahlungspflichtig">

                                    <button id="btn-submit-order" type="button" disabled=""
                                            class="mp-btn mp-btn-yellow-on-black  button" *ngIf="!payment_type">
                                <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                number:'1.2-5'}}€)</span>
                                    </button>

                                    <ng-container>
                                        <ng-container
                                                *ngIf="(payment_type==0 || payment_type==7 || payment_type==8) && !disableButtonBuy">
                                            <button id="btn-submit-order" type="button" class="mp-btn mp-btn-red button"
                                                    [disabled]="!read_agb || !read_datenschutz" (click)="onSubmit()">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="payment_type==2 && showSuccess">
                                            <button id="btn-submit-order" type="button" class="mp-btn mp-btn-red button"
                                                    [disabled]="!read_agb || !read_datenschutz" (click)="onSubmit()">
                                                <span>Bestellung abschließen</span>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="payment_type==2 ">
                                            <div [ngClass]="{'ppInactve': agbDsValid === false}">
                                                <div class="mx-4 my-3" id="paypal-button-container1"></div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="disableButtonBuy">
                                            <div class="text-center" style="margin: 50px 0;">
                                                <img src="assets/img/preloader_onwhite.gif" alt="Preloader">
                                            </div>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="payments && payments.mollie && payment_type==payments.mollie.id && !disableButtonBuy">
                                            <button id="btn-submit-order" type="button" class="mp-btn mp-btn-red button"
                                                    [disabled]="!read_agb || !read_datenschutz"
                                                    (click)="getmolliehref()">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                            </button>
                                        </ng-container>

                                        <ng-container
                                                *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">
                                            <button id="btn-submit-order" type="button" class="mp-btn mp-btn-red button"
                                                    disabled>
                                        <span>Bitte per Amazon Pay bezahlen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                            </button>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">
                                            <button id="btn-submit-order" type="button" class="mp-btn mp-btn-red button"
                                                    [disabled]="!read_agb || !read_datenschutz" (click)="onSubmit()">
                                                <span>Bestellung abschließen</span>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="payment_type==4 && !showSuccess">
                                            <button id="btn-submit-order" (click)="InitConcardisPayment()" type="button"
                                                    class="mp-btn mp-btn-red button"
                                                    [disabled]="!read_agb || !read_datenschutz">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </div>

                            </div>
                        </div>

                        <hr class="col-11 mx-auto my-2 p-0 show-mobile">
                        <!-- begin: Übersicht -->
                        <div class=" mx-4 mt-sm-5 mx-lg-1 col-lg-5 ml-lg-5 mt-lg-0 pr-lg-0">

                            <h2 class="mt-3 mt-lg-0">Bestellübersicht</h2>
                            <details open class="mt-2 mb-4" id="det" >

                                <summary> Deine Bestellung</summary>
                                <p>Bitte prüfe abschließend deine Bestellung und sende diese
                                    ab.</p>
                                <hr class="col-11 mr-7 mr-sm-5 my-2 p-0 ">
                                <div class="bestellung-list">
                                    <div class="row ml-0 " *ngFor="let article of cart_items, let i = index">
                                        <div class="col-12 row m-0 p-1">
                                            <div class=" name col-8 col-lg-9 p-1">
                                                <!-- article name -->
                                                <p><strong>{{article.amount}}x <span
                                                        [innerHTML]="article.article.name"></span>
                                                    <ng-container *ngIf="article.article.coupon_data">Code:
                                                        {{
                                                        article.article.coupon_data.coupon_content }}
                                                    </ng-container>
                                                </strong>
                                                </p>
                                                <!-- size -->
                                                <p class="pl-2">Größe:
                                                    {{article.article.price[article.size_index].size}}
                                                    ({{article.article.price[article.size_index].price |
                                                    number:'1.2-5'}}
                                                    €)</p>
                                                <!-- ingredient list -->
                                                <p class="pl-2">
                                                    <ng-container *ngFor="let added_ing of article.added_ingredients">
                                                        {{added_ing.amount}}x {{added_ing.ingredients.name}}
                                                        ({{ added_ing.ingredients.price[article.size_index].price *
                                                    added_ing.amount |
                                                    number:'1.2-5'
                                                        }}€)
                                                        <br>
                                                    </ng-container>
                                                </p>
                                            </div>

                                            <div class="price col-4 col-lg-3 p-1 text-right">
                                                <ng-container>
                                                    <div class="mp-font-semibold pr-2">
                                                        {{ (article.article.price[article.size_index].price *
                                                            article.amount) | number:'1.2-5'
                                                        }} €
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <hr class="col-11 mr-7 mr-sm-5 my-2 p-0 ">
                                        </div>
                                    </div>

                                    <!-- /Cart Articles-->

                                    <!-- Cart Subtotal -->
                                    <div class="p-0 subtotal">
                                        <div class="cart-total-price-tip-black">Betrag Warenkorb:&nbsp;
                                            <strong>{{priceCart | number:'1.2-5'}}€ </strong>
                                        </div>

                                        <ng-container *ngFor="let mod of priceModifications">
                                            <div class="cart-total-price-trinkgeld">
                                                {{mod.name}}: <strong>{{mod.value | number:'1.2-5'}}€ </strong>
                                            </div>
                                        </ng-container>

                                        <div class="cart-total-price font-weight-bold">Gesamtbetrag:&nbsp;
                                            <strong>{{priceTotal - onlineBonus |
                                            number:'1.2-5'}}€
                                            </strong>
                                        </div>
                                    </div>
                                    <!-- /Cart Subtotal -->

                                    <hr>

                                    <div class="delivery-form last-step">
                                        <!-- begin: delivery column -->
                                        <div class="text-left mt-3">
                                            <h5>Bestelldetails</h5>
                                            <div class="form-group-wrapper row mt-3">
                                                <div class="delivery-details-list-item col-6">
                                                    <strong>Lieferart:</strong>
                                                    <div *ngIf="!on_pickup">liefern lassen</div>
                                                    <div *ngIf="on_pickup">abholen</div>
                                                </div>
                                                <div class="delivery-details-list-item col-6">
                                                    <strong>Zeitpunkt:</strong>
                                                    <div *ngIf="!send_later">sofort</div>
                                                    <div *ngIf="send_later">{{ deliveryDate | date:'fullDate':'':'de' }}
                                                        um
                                                        {{
                                                        deliveryTime }}
                                                    </div>
                                                    <a *ngIf="shopClosed" [routerLink]="['/willkommen/start/edit']"
                                                       fragment="datum">Ändern</a>
                                                    <a *ngIf="!shopClosed" [routerLink]="['/willkommen/start/edit']"
                                                       fragment="datum">Ändern</a>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- end: delivery column -->

                                        <!-- begin: delivery column -->
                                        <div class="text-left mt-5" *ngIf="!on_pickup">
                                            <h5>Deine Lieferanschrift</h5>
                                            <div class="form-group-wrapper mt-3 row">
                                                <div class="col-6">
                                                    <div class="delivery-details-list-item">{{ user.fir_name }} {{
                                                        user.fam_name
                                                        }}
                                                    </div>
                                                    <div class="delivery-details-list-item">{{ user.adress.street}}
                                                        {{ user.adress.street_number }}
                                                    </div>
                                                    <div class="delivery-details-list-item">{{ user.adress.plz }}
                                                        {{ user.adress.district}} OT von {{ user.adress.city }}
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="delivery-details-list-item"><strong>Telefon:</strong>
                                                        <br>
                                                        {{user.phone}}
                                                    </div>
                                                    <div class="delivery-details-list-item"><strong>Mail:</strong>
                                                        <br>
                                                        {{user.mail}}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end: delivery column -->
                                    </div>
                                </div>
                            </details>
                            <!-- Änderungen 9 November 2022-->
                            <!-- show Desktop-->
                            <div class="show-desktop" *ngIf="cart_items.length > 6">
                                <div class="col text-center mt-4">
                                    <div *ngIf="!payment_type">
                                        <p class="text-danger">Bitte wähle die Zahlungsart aus.</p>
                                    </div>
                                    <div *ngIf="!read_agb || !read_datenschutz">
                                        <p class="text-danger">Bitte stimme den AGB und Datenschutz zu.</p>
                                    </div>
                                </div>

                                <div class="wk-btns-arrow wk-overview-btns-arrow wk-btns-arrow-ab6">
                                    <!-- BUTTON ZURÜCK -->

                                    <div class="d-flex align-items-end justify-content-center ">
                                        <ng-container *ngIf="payment_type!=1">

                                            <button mat-button matStepperPrevious type="button"
                                                    class="m-0 button-link mp-btn-prev">
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">

                                            <button mat-button matStepperPrevious type="button"
                                                    class="m-0 button-link mp-btn-prev">
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">

                                            <button class="m-0 button-link mp-btn-prev" disabled>
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </ng-container>

                                    </div>

                                    <!-- BUTTON BEZAHLEN -->
                                    <div class="p-0  zahlungspflichtig">

                                        <button id="btn-submit-order" type="button" disabled=""
                                                class="mp-btn mp-btn-yellow-on-black  button" *ngIf="!payment_type">
                                <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                number:'1.2-5'}}€)</span>
                                        </button>

                                        <ng-container>
                                            <ng-container
                                                    *ngIf="(payment_type==0 || payment_type==7 || payment_type==8) && !disableButtonBuy">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="onSubmit()">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="payment_type==2 && showSuccess">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="onSubmit()">
                                                    <span>Bestellung abschließen</span>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="payment_type==2 ">
                                                <div [ngClass]="{'ppInactve': agbDsValid === false}">
                                                    <div class="mx-4 my-3" id="paypal-button-container1"></div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="disableButtonBuy">
                                                <div class="text-center" style="margin: 50px 0;">
                                                    <img src="assets/img/preloader_onwhite.gif" alt="Preloader">
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="payments && payments.mollie && payment_type==payments.mollie.id && !disableButtonBuy">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="getmolliehref()">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>

                                            <ng-container
                                                    *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        disabled>
                                        <span>Bitte per Amazon Pay bezahlen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="onSubmit()">
                                                    <span>Bestellung abschließen</span>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="payment_type==4 && !showSuccess">
                                                <button id="btn-submit-order" (click)="InitConcardisPayment()"
                                                        type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </div>

                                    <!--Help Container-->
                                    <div class="wk-btns-arrow wk-overview-btns-arrow wk-btns-arrow-ab6"
                                         style="visibility: hidden ">
                                        <div class="mb-sm-0 mt-sm-auto p-0 d-flex align-items-end justify-content-center show-mobile b-z">
                                            <button type="button" class="m-0 button-link mp-btn-prev" disabled>
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- show Mobile-->
                            <div class="show-mobile mb-4">
                                <div class="col text-center mt-4">
                                    <div *ngIf="!payment_type">
                                        <p class="text-danger">Bitte wähle die Zahlungsart aus.</p>
                                    </div>
                                    <div *ngIf="!read_agb || !read_datenschutz">
                                        <p class="text-danger">Bitte stimme den AGB und Datenschutz zu.</p>
                                    </div>
                                </div>
                                <div class="wk-btns-arrow wk-overview-btns-arrow">
                                    <div
                                            class="mb-sm-0 mt-sm-auto p-0 d-flex align-items-end justify-content-center">
                                        <ng-container *ngIf="payment_type!=1">

                                            <button mat-button matStepperPrevious type="button"
                                                    class="m-0 button-link mp-btn-prev">
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">

                                            <button mat-button matStepperPrevious type="button"
                                                    class="m-0 button-link mp-btn-prev">
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">

                                            <button type="button" class="m-0 button-link mp-btn-prev" disabled>
                                                <svg-icon
                                                        src="assets/icons/icon_arrow_prev_transperent_s.svg"></svg-icon>
                                            </button>
                                        </ng-container>

                                    </div>
                                    <!-- BUTTON BEZAHLEN -->
                                    <div class="p-0  zahlungspflichtig">

                                        <button id="btn-submit-order" type="button" disabled=""
                                                class="mp-btn mp-btn-yellow-on-black  button" *ngIf="!payment_type">
                                <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                number:'1.2-5'}}€)</span>
                                        </button>

                                        <ng-container>
                                            <ng-container
                                                    *ngIf="(payment_type==0 || payment_type==7 || payment_type==8) && !disableButtonBuy">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="onSubmit()">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="payment_type==2 && showSuccess">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="onSubmit()">
                                                    <span>Bestellung abschließen</span>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="payment_type==2 ">
                                                <div [ngClass]="{'ppInactve': agbDsValid === false}">
                                                    <div class="mx-4 my-3" id="paypal-button-container1"></div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="disableButtonBuy">
                                                <div class="text-center" style="margin: 50px 0;">
                                                    <img src="assets/img/preloader_onwhite.gif" alt="Preloader">
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="payments && payments.mollie && payment_type==payments.mollie.id && !disableButtonBuy">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="getmolliehref()">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>

                                            <ng-container
                                                    *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus !== '200'">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        disabled>
                                        <span>Bitte per Amazon Pay bezahlen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="payment_type==1 && amazonPayConfirmOrderResponse.authorize.ResponseStatus === '200'">
                                                <button id="btn-submit-order" type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz"
                                                        (click)="onSubmit()">
                                                    <span>Bestellung abschließen</span>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="payment_type==4 && !showSuccess">
                                                <button id="btn-submit-order" (click)="InitConcardisPayment()"
                                                        type="button"
                                                        class="mp-btn mp-btn-red button"
                                                        [disabled]="!read_agb || !read_datenschutz">
                                        <span>Zahlungspflichtig bestellen ({{priceTotal - onlineBonus |
                                        number:'1.2-5'}}€)</span>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </mat-step>
            </mat-horizontal-stepper>
            <!-- end:: STEPPER COMPONENT -->
        </ng-container>

    </div>

    <!-- VISA Info Box -->
    <div *ngIf="show_visa_info" class="dialog-payment">
        <div class="dialog-pay-info">
            <h3>Info zu Kreditkarte und SEPA-Lastschrift</h3>
            <p>
                Dies ist ein PayPal-Service. Sie benötigen hier <b>kein</b> Paypal-Konto!
            </p>
            <div class="btn-pay-info">
                <button (click)="show_visa_info = false" class="btn button">OK</button>
            </div>
        </div>
    </div>
    <!-- /VISA Info Box -->

</ng-container>


<!--/*Alter Prise POP-UP*/-->
<ng-container *ngIf="showPriceDiffPopup">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <div class="title-dialog-shop-closed">
                Preis hat sich geändert
            </div>
            <div class="cont-dialog-shop-closed pb-0 mt-2">
                <p style="font-size: 18px;"><strong>Alter Preis: {{ priceCartTemp | number:'1.2-2' }}</strong></p>
                <p style="font-size: 22px;"><strong>Neuer Preis: {{ priceCart | number:'1.2-2' }}</strong></p>
                <div class="btn-dialog-shop-closed">
                    <button (click)="hidePriceDiff()" class="mp-btn mp-btn-yellow-on-white">weiter</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
