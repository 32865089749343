import { EventEmitter } from '@angular/core';
import { FrontendService } from './frontend.service';
import { Router } from '@angular/router';
import { CartService } from './cart.service';
import * as i0 from "@angular/core";
import * as i1 from "./frontend.service";
import * as i2 from "@angular/router";
import * as i3 from "./cart.service";
var CouponsService = /** @class */ (function () {
    /**
     * Component constructor
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    function CouponsService(frontendService, router, cart_service) {
        var _this = this;
        this.frontendService = frontendService;
        this.router = router;
        this.cart_service = cart_service;
        // Definiert, ob Kunde Ware abholt -> true: holt ab
        this.coupons = [];
        this.cart_items = [];
        this.price_total = 0;
        this.price_cart = 0;
        this.couponRemoved = new EventEmitter();
        this.cart_service.article_group_list_observable.subscribe(function (data) {
            _this.cart_items = data;
        });
        this.cart_service.priceTotalObservable.subscribe(function (price) {
            _this.price_total = price;
        });
        this.cart_service.priceCartObservable.subscribe(function (price) {
            _this.price_cart = price;
        });
        this.coupons = JSON.parse(sessionStorage.getItem('coupons')) || [];
    }
    CouponsService.prototype.getCoupons = function () {
        return this.coupons;
    };
    /**
     *
     * @param coupon_data
     */
    CouponsService.prototype.setCoupon = function (coupon_data) {
        return;
        this.coupons.push(coupon_data);
        sessionStorage.setItem('coupons', JSON.stringify(this.coupons));
    };
    /**
     *
     * @param couponIndex
     */
    CouponsService.prototype.removeCoupon = function (couponIndex) {
        return;
        var newCoupons = [];
        for (var couponItem in this.getCoupons()) {
            if (couponIndex !== couponItem) {
                newCoupons.push(this.getCoupons()[couponItem]);
            }
        }
        this.coupons = newCoupons;
        sessionStorage.setItem('coupons', JSON.stringify(this.coupons));
    };
    /**
     *
     */
    CouponsService.prototype.checkCouponValidity = function () {
        return;
        // const valid_coupons = [];
        // for (const cartItemIndex in this.cart_items){
        //     /**
        //      * Coupon-Produkt
        //      */
        //     if ( this.cart_items[cartItemIndex].article.is_coupon_product === 1 ) {
        //         const productCoupon = this.findCouponByArticleId(this.cart_items[cartItemIndex].article.id);
        //         if ( productCoupon ) {
        //             if ( ( this.price_total - this.cart_items[cartItemIndex].prices.real_price_total) >= productCoupon.coupon.min_order_value_on_pickup ) {
        //                 valid_coupons.push(productCoupon.coupon);
        //             } else {
        //                 console.warn('REMOVE COUPON');
        //                 // tslint:disable-next-line: radix
        //                 this.cart_service.decreseAmount( parseInt(cartItemIndex) );
        //                 this.removeCoupon(productCoupon.index);
        //                 this.couponRemoved.emit(productCoupon.coupon);
        //             }
        //         }
        //     }
        // }
        // this.coupons = valid_coupons;
        // sessionStorage.setItem('coupons', JSON.stringify(valid_coupons));
    };
    /**
     *
     *
     * @param article_id
     */
    CouponsService.prototype.findCouponByArticleId = function (article_id) {
        for (var couponItem in this.getCoupons()) {
            if (this.getCoupons()[couponItem].coupon_product_id === article_id) {
                return { index: couponItem, coupon: this.getCoupons()[couponItem] };
            }
        }
        return false;
    };
    CouponsService.prototype.checkRedeemCount = function (couponData) {
        var max_count = couponData.redeem_count;
        var coupons = this.getCoupons();
        var coupon_counter = [];
        for (var couponItem in coupons) {
            if (coupon_counter[coupons[couponItem].coupon_product_id] == undefined) {
                coupon_counter[coupons[couponItem].coupon_product_id] = 1;
            }
            else {
                coupon_counter[coupons[couponItem].coupon_product_id]++;
            }
        }
        if (coupon_counter[couponData.coupon_product_id] == undefined ||
            ((coupon_counter[couponData.coupon_product_id] < max_count) && max_count !== 0) || couponData.redeem_count === 0) {
            return true;
        }
        else {
            return false;
        }
    };
    CouponsService.ngInjectableDef = i0.defineInjectable({ factory: function CouponsService_Factory() { return new CouponsService(i0.inject(i1.FrontendService), i0.inject(i2.Router), i0.inject(i3.CartService)); }, token: CouponsService, providedIn: "root" });
    return CouponsService;
}());
export { CouponsService };
