<!--<button class="log-button" (click)="onLog()">Log Wrapper Component</button>-->
<div class="to-top-button show-mobile" (click)="toTop()" *ngIf="showUpButton">
    <button class="mp-btn-up"></button>
</div>

<div *ngIf="shop_close_countdown_show" class="shop_close_countdown">
    <svg-icon src="assets/icons/icon_welcome_clock.svg" [svgStyle]="{ 'width.px':40,'fill':'currentColor' }"></svg-icon>
    <div *ngIf="shop_close_countdown>1">
        <p *ngIf="pickup">
            <small><strong> Shop schließt sich in</strong></small><br/>
            <small>{{shop_close_countdown}} Minuten!</small>
            <br/>
            <small>Jetzt noch schnell bestellen!</small>
        </p>
        <p *ngIf="!pickup">
            <small><strong> Shop schließt sich in</strong></small><br/>
            <small>{{shop_close_countdown}} Minuten für Lieferung,</small>
            <br/> <small><strong>du kannst aber abholen!</strong></small>
        </p>
    </div>
    <p *ngIf="shop_close_countdown<=1">
        <small><strong> Shop schließt sich in</strong></small><br/> einer Minute für Lieferung,
        <br/> du kannst aber abholen!
    </p>
</div>
<ng-container *ngIf="ready">
    <div id="wrapper" [class.toggled]="is_open" class="wrapper-desk">
        <!--<button (click)="onLog()">log</button>-->

       <div class="bg-shop-closed" *ngIf="!isStaticSite() && storeData.is_closed">
            <div class="dialog-shop-closed">
                <div class="title-dialog-shop-closed">
                    Shop geschlossen
                </div>
                <div class="cont-dialog-shop-closed">
                    <p>
                        {{ storeData.is_closed_message }}
                    </p>
                </div>
            </div>
        </div>

        <!--Navigation:-->
        <nav class="navbar-inverse navbar-fixed-top mobile-nav" id="sidebar-wrapper" role="navigation">
            <div class="app-navigation">
                <app-navigation [is_open]="is_open" (toggle_is_open_emitter)="onToggle()"></app-navigation>
            </div>


           <!-- <div *ngIf="!isJobs" class="nav-info-wrapper mb-5">-->
                <div class="nav-info-wrapper mb-5">
                <div  class="nav-info ">
                    <p class="nav-hotline">
                        <span>Dave`s-Hotline</span><br>

                        <strong><a href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a></strong>
                    </p>
                    <p>
                        {{ storeData.street }}<br>
                        {{ storeData.zip }} {{ storeData.city }}
                    </p>
                </div>

                <div class="nav-social">

                    <!--Freiberg Facebook-->
                  <a *ngIf="storeData?.id === 2" href="https://www.facebook.com/meisterpizza" target="_blank">

                        <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                             viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                        </svg>
                    </a>

                    <!--Dresden Facebook-->
                    <a *ngIf="storeData?.id === 7" href="https://www.facebook.com/meisterpizzadresden" target="_blank">

                        <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                             viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                        </svg>
                    </a>

                    <!--Freiberg Instagram-->
                    <a *ngIf="storeData?.id === 2" href="https://www.instagram.com/meisterpizzafreiberg/"
                       target="_blank">

                        <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                             viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                        </svg>
                    </a>

                    <!--Dresden Instagram-->
                    <a *ngIf="storeData?.id === 7" href="https://www.instagram.com/meisterpizzadresden/"
                       target="_blank">

                        <svg aria-hidden="true" focusable="false" class="icon icon&#45;&#45;md" role="img"
                             viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                        </svg>
                    </a>

                </div>
            </div>

        </nav>

        <!--Content:-->
        <div class="container-fluid" id="page-content-wrapper"
             [class.article-list-drinks]="master_cat_name =='Getränke'">


            <div class="overlay" [class.overlay_show]="is_open"></div>

            <!--Header:-->
            <app-header [is_open]="is_open" (toggle_is_open_emitter)="onToggle()"></app-header>

            <router-outlet></router-outlet>

            <!--PopUp Fenster:-->
            <div *ngIf="shop_schliesst_bald" class="bg-shop-closed">
                <div class="dialog-shop-closed close-soon">

                    <div *ngIf="open_end>0" class="title-dialog-shop-closed">
                        Shop schließt in {{open_end}} min!
                    </div>
                    <div *ngIf="open_end<=0" class="title-dialog-shop-closed">
                        Shop schließt jetzt!
                    </div>
                    <div *ngIf="open_end>0" class="cont-dialog-shop-closed">
                        <i class="far fa-clock"></i><br>
                        Jetzt schnell doch, bestell noch.
                    </div>
                    <div class="btn-dialog-shop-closed">
                        <button (click)="shop_schliesst_bald = false" class="btn">Ja ich beeil mich</button>
                    </div>
                </div>
            </div>

            <!-- <ng-container *ngIf="shop_closed">
                <div class="bg-shop-closed">
                    <div class="dialog-shop-closed">
                        <div class="title-dialog-shop-closed">
                            Jetzt vorbestellen
                        </div>
                        <div class="cont-dialog-shop-closed">
                            <p>
                                Leider befinden Sie sich außerhalb der Öffnungszeiten.
                            </p>
                            <p>
                                Sie können aber eine Vorbestellung tätigen - diese wird nach Öffnung der Filiale geliefert.
                            </p>
                            <div class="open-hours">
                                <table>
                                    <tr *ngFor="let day of opening_hours_array, let index = index">
                                        <td *ngIf="index == 0"><b>Montag</b></td>
                                        <td *ngIf="index == 1"><b>Dienstag</b></td>
                                        <td *ngIf="index == 2"><b>Mittwoch</b></td>
                                        <td *ngIf="index == 3"><b>Donnerstag</b></td>
                                        <td *ngIf="index == 4"><b>Freitag</b></td>
                                        <td *ngIf="index == 5"><b>Samstag</b></td>
                                        <td *ngIf="index == 6"><b>Sonntag</b></td>
                                        <td *ngFor="let item of day">
                                            {{item}}
                                        </td>
                                        <br>
                                    </tr>
                                </table>
                            </div>
                            <div class="btn-dialog-shop-closed">
                                <button (click)="shop_closed = false" class="btn-mp btn-yellow">Jetzt vorbestellen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container> -->


                <app-footer></app-footer>


        </div>
    </div>


</ng-container>

<ng-container *ngIf="relocateDeliveryTimePopup">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed" *ngIf="!pickup && delivery_later">
            <div class="title-dialog-shop-closed">
                Deine gewählte Lieferzeit wurde geändert
            </div>
            <div class="cont-dialog-shop-closed">
                <p>
                    Wir benötigen für deine Bestellung immer ein wenig Vorlaufzeit.
                </p>
                <p>
                    Neue Lieferzeit: <strong>{{delivery_time}} Uhr</strong>
                </p>

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="closeRelocateDeliveryTimePopup()">OK</button>
                </div>
            </div>
        </div>
        <div class="dialog-shop-closed text-center" *ngIf="!pickup && !delivery_later">
            <div class="title-dialog-shop-closed">
                Deine gewählte Lieferzeit wurde geändert
            </div>
            <div class="cont-dialog-shop-closed">
                <p>
                    Wir benötigen für deine Bestellung immer ein wenig Vorlaufzeit.
                </p>
                <p>
                    <strong>Sofort bestellen</strong>
                </p>

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="closeRelocateDeliveryTimePopup()">OK</button>
                </div>
            </div>
        </div>
        <div class="dialog-shop-closed text-center" *ngIf="pickup && delivery_later">
            <div class="title-dialog-shop-closed">
                Deine gewählte Abholzeit wurde geändert
            </div>
            <div class="cont-dialog-shop-closed">
                <p>
                    Wir benötigen für deine Bestellung immer ein wenig Vorlaufzeit.
                </p>
                <p>
                    Neue Abholzeit: <strong>{{delivery_time}} Uhr</strong>
                </p>

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="closeRelocateDeliveryTimePopup()">OK</button>
                </div>
            </div>
        </div>
        <div class="dialog-shop-closed" *ngIf="pickup && !delivery_later">
            <div class="title-dialog-shop-closed">
                Deine gewählte Abholzeit wurde geändert
            </div>
            <div class="cont-dialog-shop-closed">
                <p>
                    Wir benötigen für deine Bestellung immer ein wenig Vorlaufzeit.
                </p>
                <p>
                    <strong>Sofort abholen</strong>
                </p>

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="closeRelocateDeliveryTimePopup()">OK</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="shop_closed">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <div class="title-dialog-shop-closed">
                Der Shop hat jetzt geschlossen
            </div>
            <div class="cont-dialog-shop-closed">
                Du kannst allerdings eine Vorbestellung tätigen. Dein Warenkorb bleibt bestehen.

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white" routerLink="/willkommen/start">Jetzt vorbestellen
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- begin: popup shop currently closed -->
<ng-container *ngIf="showSessionExpiredPopup">
    <div class="bg-shop-closed">
        <div class="dialog-shop-closed">
            <div class="title-dialog-shop-closed">
                Sitzung abgelaufen
            </div>
            <div class="cont-dialog-shop-closed">
                Deine Sitzung ist abgelaufen! Bitte starte den Bestellvorgang erneut.

                <div class="btn-dialog-shop-closed">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="resetPage()">weiter</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- end: popup shop currently closed -->
