<div class="static-page-layout">
    <ng-container>
        <!--<div class="row">-->
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Newsletter</h1>
                    </div>
                </div>
            </div>
        </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <h2 class="mp-font-medium-yellow text-center">Vielen Dank für Ihre Anmeldung! </h2>

                    <p class="text-center spacing font-wight-bold">Sie haben sich erfolgreich angemeldet.
                        <!--<a routerLink="/willkommen/start"><strong class="font-italic"> Zur Startseite</strong></a> </p>-->

                    <a  href="https://{{ storeData?.domain}}" target="_blank"><strong class="font-italic"> Zur Startseite</strong></a> </p>
                </div>
            </div>
      <!--  </div>-->
    </ng-container>


</div>
