<!--<button class="log-button" (click)="onLog()">Log</button>-->

<ng-container *ngIf="ready">
    <div class="holder margin">
        <app-element-header element="offerHeader"></app-element-header>
        <div class="article-list-row row p-2">
            <!--Auflistung der Aktionsartikel-->
            <ng-container *ngFor="let article of article_list, let i_art = index">
                <ng-container *ngIf="article.is_active">
                    <!--article box-->
                    <div class="col-12 col-md-6 col-xl-4 article-box-drinks article-box mb-5 article-box-{{article.category_id}}">
                        <div class="d-flex flex-column">
                            <div class="article-box-content">
                                <picture class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="container-flag-info">
                                        <div class="is-new-flag" *ngIf="article.is_new">
                                            <svg width="70" height="25.496" viewBox="0 0 70 25.496">
                                                <g transform="translate(-200 -332)">
                                                    <path fill="#b70e0c"
                                                          d="M261.463,332.045V332H200v25.5h61.463L270,344.771Z"
                                                          transform="translate(0 0)"/>
                                                    <g transform="translate(212.687 337.616)">
                                                        <path fill="#fff"
                                                              d="M265.461,369.575h-3.838l-6.2-10.774h-.088q.185,2.854.185,4.072v6.7h-2.7V355.333h3.809L262.821,366h.068q-.146-2.777-.146-3.926v-6.741h2.718Z"
                                                              transform="translate(-252.827 -355.333)"/>
                                                        <path fill="#fff"
                                                              d="M335.232,369.575h-8.2V355.333h8.2v2.474H330.05v3.127h4.822v2.474H330.05v3.672h5.182Z"
                                                              transform="translate(-310.811 -355.333)"/>
                                                        <path fill="#fff"
                                                              d="M389.392,355.333v9.215a5.317,5.317,0,0,1-.706,2.767,4.6,4.6,0,0,1-2.041,1.821,7.3,7.3,0,0,1-3.156.633,6.042,6.042,0,0,1-4.266-1.408,4.992,4.992,0,0,1-1.52-3.853v-9.176h3.01v8.719a3.649,3.649,0,0,0,.662,2.415,2.752,2.752,0,0,0,2.192.77,2.693,2.693,0,0,0,2.148-.775,3.671,3.671,0,0,0,.667-2.431v-8.7Z"
                                                              transform="translate(-350.408 -355.333)"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>
                                            <span class="infobox-link" (click)="onOpenInfoBox(article.id)">
                                                <svg-icon src="assets/icons/icon_info-circle-solid-orange.svg"
                                                          [svgStyle]="{ 'width.px':25.496,'fill':'currentColor' }"></svg-icon>
                                            </span>
                                        </div>
                                    </div>

                                    <source media="(min-width: 120.5em)"
                                            srcset="{{image_url}}{{article.image.webp[6].image}}" type="image/webp">
                                    <source media="(min-width: 120.5em)"
                                            srcset="{{image_url}}{{article.image.png[6].image}}">
                                    <source media="(min-width: 80em)"
                                            srcset="{{image_url}}{{article.image.webp[2].image}}" type="image/webp">
                                    <source media="(min-width: 80em)"
                                            srcset="{{image_url}}{{article.image.png[2].image}}">
                                    <source media="(min-width: 60em)"
                                            srcset="{{image_url}}{{article.image.webp[4].image}}" type="image/webp">
                                    <source media="(min-width: 60em)"
                                            srcset="{{image_url}}{{article.image.png[4].image}}">
                                    <source media="(min-width: 48em)"
                                            srcset="{{image_url}}{{article.image.webp[1].image}}" type="image/webp">
                                    <source media="(min-width: 48em)"
                                            srcset="{{image_url}}{{article.image.png[1].image}}">
                                    <source media="(min-width: 36em)"
                                            srcset="{{image_url}}{{article.image.webp[5].image}}" type="image/webp">
                                    <source media="(min-width: 36em)"
                                            srcset="{{image_url}}{{article.image.png[5].image}}">
                                    <source media="(min-width: 30em)"
                                            srcset="{{image_url}}{{article.image.webp[3].image}}" type="image/webp">
                                    <source media="(min-width: 30em)"
                                            srcset="{{image_url}}{{article.image.png[3].image}}">
                                    <source media="(min-width: 22.5em)"
                                            srcset="{{image_url}}{{article.image.webp[1].image}}" type="image/webp">
                                    <source media="(min-width: 22.5em)"
                                            srcset="{{image_url}}{{article.image.png[1].image}}">
                                    <source srcset="{{image_url}}{{article.image.webp[0].image}}" type="image/webp">
                                    <source srcset="{{image_url}}{{article.image.png[0].image}}">
                                    <img class="img-fluid mx-auto" src="{{image_url}}{{article.image.png[2].image}}"
                                         alt="">
                                </picture>
                                <div class="container-details-product d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                    <!--HEADING & DESCRIPTION-->
                                    <div class="article-box-heading heading d-flex flex-column justify-content-center align-items-center my-0">
                                        <h3 class="mp-font-large-yellow product-title px-2 mt-2">{{article.name}}</h3>
                                        <p *ngIf="article.description" class="text-center px-2 product-description">
                                            <span [innerHTML]="article.description"></span>
                                        </p>
                                        <p *ngIf="!article.description" class="text-center px-2 product-description">
                                            <ng-container *ngFor="let ing of article.ingredients, let last = last">
                                                {{ing.name}}
                                                <ng-container *ngFor="let marking of ing.markings, let last = last">
                                                    <sup>{{marking.abbreviation}}</sup>
                                                    <ng-container *ngIf="!last"><sup>,</sup></ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="!last">,</ng-container>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <!--PRICE-->
                                    <div class="col-12 d-flex flex-row justify-content-around align-items-center product-details article-box-cta article-box-btns-{{article.price.length}}">
                                        <ng-container *ngFor="let price of article.price, let i_size = index">
                                            <!--Preis bei abholung-->
                                            <!-- <a class="details-price d-flex flex-column justify-content-around align-items-center" (click)="onLinkToDetail(i_art, i_size)"
                                               *ngIf="(on_pickup && !on_happy_hour && !onLunch) || (on_pickup && on_happy_hour && !price.happy_hour_price) || (on_pickup && onLunch && !price.lunch_price)">
                                                <span class="span-size">{{price.size}} </span><br>
                                                <span class="spanPrice"> {{price.price - price.pickup_saving | number: '1.2-5'}}&nbsp;€</span>
                                            </a> -->
                                            <!--regulärer preis-->
                                            <!-- <a class="details-price d-flex flex-column justify-content-around align-items-center" (click)="onLinkToDetail(i_art, i_size)"
                                               *ngIf="(!on_happy_hour && !on_pickup && !onLunch) || (!on_pickup && on_happy_hour && !price.happy_hour_price) || (!on_pickup && onLunch && !price.lunch_price)">
                                                <span class="span-size">{{price.size}} </span><br>
                                                <span class="spanPrice"> {{price.price | number: '1.2-5'}}&nbsp;€</span>
                                            </a> -->
                                            <a class="details-price d-flex flex-column justify-content-around align-items-center" (click)="onLinkToDetail(i_art, i_size)">
                                                <span class="span-size">{{price.size}} </span><br>
                                                <span class="spanPrice"> {{article.real_price[i_size].price | number: '1.2-5'}}&nbsp;€</span>
                                            </a>
                                            <!-- happy hour preis-->
                                            <!-- <a class="details-price d-flex flex-column justify-content-around align-items-center" (click)="onLinkToDetail(i_art, i_size)"
                                               *ngIf="on_happy_hour && price.happy_hour_price">
                                                <span class="span-size">{{price.size}} </span><br>
                                                <span class="spanPrice">{{price.happy_hour_price | number: '1.2-5'}}&nbsp;€</span>
                                            </a> -->
                                            <!-- lunch price -->
                                            <!-- <a class="details-price d-flex flex-column justify-content-around align-items-center" (click)="onLinkToDetail(i_art, i_size)"
                                               *ngIf="onLunch && price.lunch_price">
                                                <span class="span-size">{{price.size}}</span> <br>
                                                <span class="spanPrice"> {{price.lunch_price | number: '1.2-5'}}&nbsp;€</span>
                                            </a> -->
                                        </ng-container>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- /article box -->
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>

<!-- begin: popup -->
<ng-container *ngIf="showInfoBox">
    <div class="bg-mp-dialog">
        <div class="mp-dialog">
            <div class="title-mp-dialog">
                Allergene & Zusatzstoffe
            </div>
            <div class="cont-mp-dialog">
                <h3 class="mp-font-large-yellow">{{ infoBoxProduct.name }}</h3>
                <span [innerHTML]="infoBoxProduct.description"></span>
                <ng-container *ngIf="infoBoxProduct.description_addition">
                    <span class="description-addition" [innerHTML]="infoBoxProduct.description_addition"></span>
                </ng-container>
                <br><br>
                <h4>Zusatzstoffe:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxProduct.markings">
                        <li *ngIf="marking.type === 'Zusatzstoff'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>Allergene:</h4>
                <ul>
                    <ng-container *ngFor="let marking of infoBoxProduct.markings">
                        <li *ngIf="marking.type === 'Allergen'">{{ marking.abbreviation}} - {{ marking.name }}</li>
                    </ng-container>
                </ul>
                <br>
                <h4>BITTE BEACHTE:</h4>
                <p>Die Zusatzstoffe und Allergene können sich beim Hinzufügen oder Wechseln von Zutaten ändern.</p>
                <br>
                <div class="btn-mp-dialog text-center">
                    <button class="mp-btn mp-btn-yellow-on-white" (click)="showInfoBox = false">Ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- end: popup -->
