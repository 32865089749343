import {Component, ElementRef, QueryList, OnInit, ViewChildren, HostBinding, AfterViewChecked, Input} from '@angular/core';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../../services/main.service';
declare var jQuery: any;

@Component({
  selector: 'app-element-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderElementComponent implements OnInit  {

    data: any =[];
    ready: boolean = false;
    image_url;
    @Input('element') element: string;

  constructor(
      private http: HttpClient,
      private main_service: MainService,

  ) {


  }


  ngOnInit() {

      this.image_url = this.main_service.image_url;
      const url = this.main_service.getFrontendUrl() + '/elementByName/' + this.element;
      this.http.get(url).subscribe(data => {

          this.data = data;
          this.ready = true;

      });

  }



}


