import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MainService} from '../../../services/main.service';
import {FrontendService} from '../../../services/frontend.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

    // public stuff  - - - - - - - - - - - - - - - -
    current_page_id = 10;
    opening_hours_array: any[] = [];
    special_opening_hours_array: any[] = [];
    opening_hours_array_lif: any[] = [];
    deliveryAreas: any[] = [];
    storeData: any[] = [];
    ready = false;
    newsletterSubscribed = false;

    // Icons für Liefergebiete
    linkPfeilSvg = "assets/icons/arrowUmod.svg";
    linkPfeilSvgPB = "assets/icons/arrowUwhitemod.svg";

    // Links für soziale Medien
    link_facebook = '';
    link_instagram = '';

    // private stuff - - - - - - - - - - - - - - - -

    private subscriptions: Subscription[] = [];

    newsletterFormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        dataProtect: new FormControl(false, Validators.requiredTrue)
    });


    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private main_service: MainService,
        private frontend_service: FrontendService,
        private http: HttpClient,
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {
        registerLocaleData(localeDe, 'de');

        // Observable: überwacht page ID
        this.subscriptions.push(this.main_service.current_page_id_observable.subscribe(
            (data: number) => {
                this.current_page_id = data;

            }
        ));

        // get opening hours for opening type "standard week day"
        this.subscriptions.push(this.frontend_service.getOpeningHours(1).subscribe(
            (data: any[]) => {
                this.opening_hours_array = data;
            }));

        // get opening hours for special opening dates
        this.subscriptions.push(this.frontend_service.getOpeningHours(2).subscribe(
            (data: any[]) => {
                this.special_opening_hours_array = data;
            }));
        
        this.subscriptions.push(this.frontend_service.getOpeningHours(3).subscribe(
            (data: any[]) => {
                this.opening_hours_array_lif = data;
                /*console.log("THIS.SPECIAL-OPENING-HOURS-3", this.opening_hours_array_lif);*/
            }));

        this.subscriptions.push(this.frontend_service.getDeliveryAreas().subscribe(
            (data: any[]) => {
                this.deliveryAreas = data;
            }));

        this.subscriptions.push(this.frontend_service.getStoreData().subscribe(
            (data: any[]) => {
                this.storeData = data;
                this.ready = true;
            }));
    }

    onSubmit() {


        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            // responseType: 'text' as 'json'
            // responseType: 'json'
        };

        const url = this.main_service.getFrontendUrl() + '/newsletter/subscribe';

        this.http.post(url, JSON.stringify(this.newsletterFormGroup.value), httpOptions)
            .subscribe(
                (res: any) => {
                    this.newsletterSubscribed = true;
                },
                err => {


                }
            );

    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onLog() {
        // console.log(this, "THIS");
    }

    onClick(event: any) {
        var sichtbar = document.getElementById("collapse-button").getAttribute("aria-expanded");
        if (sichtbar == "false") {
            this.linkPfeilSvg = "assets/icons/arrowO.svg";
            /*this.linkPfeilSvgPB = "assets/icons/arrowOred.svg";*/
            this.linkPfeilSvgPB = "assets/icons/arrowOwhite.svg";
        } else {
            this.linkPfeilSvg = "assets/icons/arrowU.svg";
           /* this.linkPfeilSvgPB = "assets/icons/arrowUred.svg";*/
            this.linkPfeilSvgPB = "assets/icons/arrowUwhite.svg";
        }
    }

    setPageID(id: number) {
        this.main_service.setCurrentPageID(id);
    }


    // setSocLink() {
    //     if (this.storeData.id == 2) {
    //         this.link_facebook = 'https://www.facebook.com/meisterpizza';
    //         this.link_instagram = 'https://www.instagram.com/meisterpizzafreiberg/';
    //     } else if (this.storeData.id == 4) {
    //         this.link_facebook = 'https://www.facebook.com/meisterpizza';
    //         this.link_instagram = 'https://www.instagram.com/meisterpizzafreiberg/';
    //     }
    // }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
}
