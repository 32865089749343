import { of } from 'rxjs';
import * as i0 from "@angular/core";
var SessionService = /** @class */ (function () {
    function SessionService() {
        this.anmeldung = {
            edit: false,
            showBtnBestellen: false,
            showBtnWarenkorb: false,
            disableBestellen: true,
            disableWarenkorb: true,
            valueLiefern: "null",
            valueHolen: "null",
            isLiefern: false,
            isHolen: false,
            isSofort: true
        };
        // console.log("Session.service wurde erstellt...");
        this.loadAnmeldung();
    }
    SessionService.prototype.getAnmeldung = function () {
        var anmeldung = of(this.anmeldung);
        return anmeldung;
    };
    SessionService.prototype.showBtnBestellen = function () {
        this.anmeldung.showBtnBestellen = true;
        this.anmeldung.showBtnWarenkorb = false;
        /*this.saveAnmeldung();*/
    };
    SessionService.prototype.showBtnWarenkorb = function () {
        this.anmeldung.showBtnBestellen = false;
        this.anmeldung.showBtnWarenkorb = true;
        /* this.saveAnmeldung();*/
    };
    SessionService.prototype.setLiefern = function () {
        this.anmeldung.isLiefern = true;
        this.anmeldung.isHolen = false;
        this.anmeldung.valueLiefern = "aktiv";
        this.anmeldung.valueHolen = "null";
        /*this.saveAnmeldung();*/
    };
    SessionService.prototype.setHolen = function () {
        this.anmeldung.isLiefern = false;
        this.anmeldung.isHolen = true;
        this.anmeldung.valueLiefern = "null";
        this.anmeldung.valueHolen = "aktiv";
        /*this.saveAnmeldung();*/
    };
    SessionService.prototype.setDisableButtons = function (value) {
        this.anmeldung.disableBestellen = value;
        this.anmeldung.disableWarenkorb = value;
        /* this.saveAnmeldung();*/
    };
    SessionService.prototype.setEdit = function () {
        this.anmeldung.edit = true;
        /* this.saveAnmeldung();*/
    };
    SessionService.prototype.saveAnmeldung = function () {
        var tmpData = JSON.stringify(this.anmeldung);
        sessionStorage.setItem("Anmeldung", tmpData);
    };
    SessionService.prototype.loadAnmeldung = function () {
        var tmpString = sessionStorage.getItem("Anmeldung");
        if (tmpString == null) {
            tmpString = '{"edit": false,"showBtnBestellen": false,"showBtnWarenkorb": false,"disableBestellen": true,"disableWarenkorb": true,"valueLiefern": "null","valueHolen": "null","isLiefern": false,"isHolen": false}';
        }
        this.anmeldung = JSON.parse(tmpString);
    };
    SessionService.ngInjectableDef = i0.defineInjectable({ factory: function SessionService_Factory() { return new SessionService(); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
