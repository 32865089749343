import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MainService} from '../../../services/main.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss']
})

export class CouponComponent implements OnInit {
    storeData: any;

    // headerBilder
    headerbild_MP = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Spar-Coupon.jpg";
    headerbild_MP_Desktop = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Spar-Coupon.jpg";
    sizeSmall = true;
    constructor(
        private http: HttpClient,
        private main_service: MainService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        this.setHeaderbild();
    }

    // Headerbild setzen
    setHeaderbild() {
        let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall) return;
        this.sizeSmall = small;
        /*console.log("Kleines Bild = ",small);*/
        if (small === false) {
            this.headerbild_MP = this.headerbild_MP_Desktop;
            /*this.headerbild_Mittags_PB = this.headerbild_Mittags_PB_Desktop;
            this.headerbild_Mittags_MD = this.headerbild_Mittags_MD_Desktop;*/
            // console.log(this.headerbild_Mittags_PB, "MP-DESKTOP");
            // console.log(this.headerbild_Mittags_MP, "PB-DESKTOP");
        }
        else {
            // console.log(this.headerbild_Mittags_MP, "MP-MOBIL");
            // console.log(this.headerbild_Mittags_PB, "PB-MOBIL");
        }
    }

    getHeaderBild() {
        var tmpUrl;
        if (this.storeData.id== 2){
            tmpUrl= this.headerbild_MP;
        } else if(this.storeData.id== 7) {
            tmpUrl= this.headerbild_MP;
        }
      /*  else if(this.storeData.master== 3) {
            tmpUrl= this.headerbild_Mittags_PB;
        }*/

        return  tmpUrl;
    }
}
