<!--<button class="log-button" (click)="onLog()">log</button>-->
<div style="background-color:  white; color: black; padding: 5%">
    <mat-tab-group>
        <!--anmelden - - - - - - - - - - - - - - - - - - - - - - - - - - - -->
        <mat-tab label="Anmelden">
            <br>
            <h2>Anmelden</h2>
            <hr>
            <form (ngSubmit)="onLogin(f_login)" #f_login="ngForm">
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="email" id="email_login" placeholder="email" name="email" ngModel email
                           required> <br>
                </mat-form-field>
                <br>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="password" id="password_login" placeholder="password" name="password" ngModel
                           required> <br>
                </mat-form-field>
                <hr>
                <button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button
                        [disabled]="!f_login.valid" type="submit">anmelden
                </button>

            </form>
            <!--error msg: login lief schief-->
            <div *ngIf="!valid_login ">{{error_message_login_failed}}</div>

            <!--<button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button  (click)="onDummyLog()">DummyLogin</button>-->
            <br><br>


            <!--passwort vergessen-->
            <!--sollte vllt ein dialog öffnen, wo dann der templatetext drin steht-->
            <button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button
                    (click)="show_reset_pw_form = !show_reset_pw_form"> Passwort vergessen
            </button>
            <br> <br>

            <ng-container *ngIf="show_reset_pw_form">
                <form (ngSubmit)="onResetPW(f_reset_pw)" #f_reset_pw="ngForm">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput type="email" id="email" placeholder="email_new_pw" name="email_new_pw"
                               ngModel email
                               required> <br>
                    </mat-form-field>
                    <br>
                    <button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button
                            [disabled]="!f_reset_pw.valid" type="submit">neues PW an Mail schicken
                    </button>
                </form>
            </ng-container>
            <!--<button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button  (click)="onReloadUser()">reload user</button>-->

        </mat-tab>

        <!--regestrieren - - - - - - - - - - - - - - - - - - - - - - - - - -->
        <mat-tab label="Registrieren">
            <br>
            <h2>Regestrieren</h2>
            <hr>
            <form (ngSubmit)="onSignup(f_signup)" #f_signup="ngForm">

                <!--email + control mail-->
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="email" id="email_signup" placeholder="email_signup" name="email_signup"
                           ngModel email
                           required #mail="ngModel"> <br>
                </mat-form-field>
                <p class="space"></p>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="email" id="email_signup_control" placeholder="email_signup_control"
                           name="email_signup_control" ngModel
                           email required #mail_control="ngModel"> <br>
                </mat-form-field>
                <br>
                <!--error msg: mailadressen stimmen nicht überein-->
                <p *ngIf="mail.value != mail_control.value && mail.touched && mail_control.touched"> Mailadressen
                    stimmen nicht überein :(</p>
                <br>

                <!--passwort + control password-->
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="password" id="password_signup" placeholder="password_signup"
                           name="password_signup" ngModel required #pw="ngModel" minlength="6">
                </mat-form-field>
                <p class="space"></p>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="password" id="password_signup_control" placeholder="password" name="password"
                           ngModel required #pw_control="ngModel">
                </mat-form-field>
                <br>
                <!--error msg: passwort stimmen nicht überein + pw zu krz-->
                <p *ngIf="pw.value != pw_control.value && pw.touched && pw_control.touched "> Passwörter stimmen nicht
                    überein :(</p>
                <p *ngIf="!pw.valid && pw.touched"> Min 6 Zeichen bitte!!</p>
                <br>
                <hr>

                <!--titel-->
                <mat-radio-group name="titel_reg" required id="titel_reg" ngModel>
                    <mat-radio-button value="Herr">Herr</mat-radio-button>
                    <mat-radio-button value="Frau">Frau</mat-radio-button>
                </mat-radio-group>
                <br>

                <!--name-->
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="text" id="first_name" placeholder="first_name" name="first_name" ngModel
                           required> <br>
                </mat-form-field>
                <p class="space"></p>

                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="text" id="last_name" placeholder="last_name" name="last_name" ngModel
                           required>
                </mat-form-field>
                <br>

                <!--adresse-->
                <!-- <app-adress-input></app-adress-input> -->

                <!--etage-->

                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="text" id="etage" placeholder="etage" name="etage" ngModel> <br>
                </mat-form-field>

                <!--handynummer-->

                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput type="text" id="phone_reg" placeholder="tel" name="phone" ngModel required> <br>
                </mat-form-field>

                <hr>
                <!--buttonser-->
                <button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button
                        [disabled]="!f_signup.valid" type="submit">regestrieren
                </button>
                <!--<button class="btn btn-warning btn-round" style="font-size: 15px; width: 100%" mat-button-->
                        <!--(click)="onReloadUser()">reload user-->
                <!--</button>-->

            </form>
            <!--error msg: regestrierung fehlgeschlagen-->
            <div *ngIf="!valid_signup ">{{error_message_signup_failed}}</div>

        </mat-tab>
    </mat-tab-group>

</div>

