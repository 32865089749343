<div class="row header-slider mb-6" *ngIf="ready">
    <div class="col d-flex justify-content-center align-items-center">
        <div id="carouselElement" class="carousel slide margin d-flex justify-content-center align-items-center "  #carouselElement>
            <div class="carousel-inner">
                <ng-container *ngFor="let slider of  data.slider, let slideIndex = index">
                    <div class="carousel-item" [class.active]="slideIndex=='0'">
                        <a routerLink="{{slider.url}}" title="{{slider.title}}">
                            <picture *ngIf="slider.image_desktop.jpg && slider.image_mobile.jpg">
                                <source media="(min-width: 120.5em)" srcset="{{image_url}}{{slider.image_desktop.webp[0].image}}" type="image/webp">
                                <source media="(min-width: 120.5em)" srcset="{{image_url}}{{slider.image_desktop.jpg[0].image}}">
                                <source media="(min-width: 80em)" srcset="{{image_url}}{{slider.image_desktop.webp[1].image}}" type="image/webp">
                                <source media="(min-width: 80em)" srcset="{{image_url}}{{slider.image_desktop.jpg[1].image}}">
                                <source media="(min-width: 60em)" srcset="{{image_url}}{{slider.image_desktop.webp[2].image}}" type="image/webp">
                                <source media="(min-width: 60em)" srcset="{{image_url}}{{slider.image_desktop.jpg[2].image}}">
                                <source media="(min-width: 48em)" srcset="{{image_url}}{{slider.image_desktop.webp[3].image}}" type="image/webp">
                                <source media="(min-width: 48em)" srcset="{{image_url}}{{slider.image_desktop.jpg[3].image}}">
                                <source media="(min-width: 36em)" srcset="{{image_url}}{{slider.image_mobile.webp[4].image}}" type="image/webp">
                                <source media="(min-width: 36em)" srcset="{{image_url}}{{slider.image_mobile.jpg[4].image}}">
                                <source media="(min-width: 30em)" srcset="{{image_url}}{{slider.image_mobile.webp[5].image}}" type="image/webp">
                                <source media="(min-width: 30em)" srcset="{{image_url}}{{slider.image_mobile.jpg[5].image}}">
                                <source media="(min-width: 22.5em)" srcset="{{image_url}}{{slider.image_mobile.webp[6].image}}" type="image/webp">
                                <source media="(min-width: 22.5em)" srcset="{{image_url}}{{slider.image_mobile.jpg[6].image}}">
                                <source srcset="{{image_url}}{{slider.image_mobile.webp[7].image}}" type="image/webp">
                                <source srcset="{{image_url}}{{slider.image_mobile.jpg[7].image}}">
                                <img class="img-fluid" src="{{image_url}}{{slider.image_desktop.jpg[1].image}}" alt="" />
                            </picture>
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselElement" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselElement" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
