<div style="background-color: white; color: black; padding: 7%">
    <h1>Hallo {{user.fir_name}}!</h1>

    <hr>

    <h2>Dein Kundenkonto:</h2>

    <!--Name + Adresse - Kurzanzeige-->
    <ng-container *ngIf="!on_edit">
        <p>{{user.fir_name}} {{user.fam_name}}</p>
        <p>{{user.adress.street}} {{user.adress.street_number}} </p>
        <p>{{user.adress.plz}} {{user.adress.city}}
            <ng-container *ngIf="user.adress.district != ''">OT {{user.adress.district}}</ng-container>
        </p>
        <p>E-Mail: {{user.mail}}</p>
        <p>Telefon: {{user.phone}}</p>


    </ng-container>

    <!--Formular um Nutzerdaten zu Bearbeiten-->
    <ng-container *ngIf="on_edit">
        <form>
            <!--Anrede-->
            <mat-radio-group [(ngModel)]="user.title" name="title">
                <mat-radio-button value="Herr">Herr</mat-radio-button>
                <mat-radio-button value="Frau">Frau</mat-radio-button>
            </mat-radio-group>
            <br>

            <!--Name-->
            <label>Nachname</label>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Nachname" [(ngModel)]="user.fam_name" name="fam_name">
            </mat-form-field>
            <br>
            <label>Vorname</label>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Vorname" [(ngModel)]="user.fir_name" name="fir_name">
            </mat-form-field>
            <br>

            <!--Adresse-->
            <!-- <app-adress-input></app-adress-input> -->

            <!--Etage-->
            <label>Etage</label>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Etage" [(ngModel)]="user.adress.etage" name="etage">
            </mat-form-field>
            <br>

            <!--Telefonnummer-->
            <label>Telefonnummer</label>
            <mat-form-field appearance="outline">
                <input matInput placeholder="phone" [(ngModel)]="user.phone" name="etage">
            </mat-form-field>
            <br>

            <!--Newsletter-->
            <mat-checkbox [(ngModel)]="!user.no_marketing" name="no_marketing">Newsletter abonniert</mat-checkbox>

            <button type="submit" (click)="onSaveUserData()" class="btn btn-warning btn-round"
                    style="font-size: 15px; width: 100%" mat-button>speichern
            </button>
        </form>
    </ng-container>

    <br><br>

    <hr>
    <h2>Aktionen</h2>
    <!--Button Daten Bearbeiten-->
    <button (click)="on_edit = true" class="btn btn-warning btn-round"
            style="font-size: 15px; width: 100%" mat-button>
        Meine Daten bearbeiten
    </button>
    <br>
    <br>

    <!--Button Bestellhistorie-->
    <button class="btn btn-warning btn-round"
            style="font-size: 15px; width: 100%" mat-button>
        Bestellhistorie anzeigen
    </button>
    <br>
    <br>

    <!--Button Passwort ändern-->
    <button (click)="on_change_pw = !on_change_pw" class="btn btn-warning btn-round"
            style="font-size: 15px; width: 100%" mat-button>
        Passwort ändern
    </button>
    <br>

    <!--Formular Passwort ändern-->
    <ng-container *ngIf="on_change_pw">
        <form style="margin: 7% 10%" (submit)="onChangePassword(f_change_pw)" #f_change_pw="ngForm">
            <label>altes Passwort</label>
            <mat-form-field appearance="outline">
                <input type="password" matInput name="old_pw" ngModel required #field_pw_org="ngModel">
            </mat-form-field>
            <br>
            <label>neues Passwort</label>
            <mat-form-field appearance="outline">
                <input type="password" matInput name="new_pw" ngModel required #field_pw="ngModel" minlength="6">
            </mat-form-field>
            <br>
            <label>neues Passwort wiederholen</label>
            <mat-form-field appearance="outline">
                <input type="password" matInput name="new_pw_corr" ngModel required #field_pw_corr="ngModel">
            </mat-form-field>

            <!--error msg: neue pws stimmen nicht überein-->
            <p *ngIf="field_pw.value != field_pw_corr.value && field_pw.touched && field_pw_corr.touched "> Passwörter
                stimmen nicht
                überein :(</p>
            <!--error msg: neues pw muss anders sein-->
            <p *ngIf="field_pw_org.value == field_pw.value && field_pw_org.touched && field_pw.touched "> neues PW muss
                schon neu sein
                und nich wieder das selbe wie vorher</p>
            <br>
            <!--error msg: neues pw ist zu kurz-->
            <p *ngIf="!field_pw.valid && field_pw.touched "> pw zu kurz - min 6 zeichen!</p>

            <button type="submit" class="btn btn-warning btn-round"
                    [disabled]="!f_change_pw.valid || (field_pw.value != field_pw_corr.value) || (field_pw_org == field_pw)"
                    style="font-size: 15px; width: 80%; margin-left: 10%" mat-button>
                absenden
            </button>

        </form>
    </ng-container>
    <br>

    <!--Button Mail bearbeiten-->
    <button (click)="on_change_mail = !on_change_mail" class="btn btn-warning btn-round"
            style="font-size: 15px; width: 100%" mat-button>
        E-Mail ändern
    </button>
    <br>

    <!--formular Mail bearbeiten-->
    <ng-container *ngIf="on_change_mail">
        <form style="margin: 7% 10%" (submit)="onChangeMail(f_change_mail)" #f_change_mail="ngForm">
            <label>current password</label>
            <mat-form-field appearance="outline">
                <input type="password" matInput name="pass" ngModel required #field_pw="ngModel">
            </mat-form-field>


            <label>neue E-Mail Adresse</label>
            <mat-form-field appearance="outline">
                <input type="text" email matInput name="mail" ngModel required #field_mail="ngModel">
            </mat-form-field>

            <label>neues E-Mail Adresse wiederholen</label>
            <mat-form-field appearance="outline">
                <input type="text" email matInput name="mail_corr" ngModel required #field_mail_corr="ngModel">
            </mat-form-field>
            <br>
            <!--error msg: mails stimmen nicht überein-->
            <p *ngIf="field_mail.value != field_mail_corr.value && field_mail.touched && field_mail_corr.touched ">
                Mails stimmen nicht
                überein :(</p>

            <button type="submit" class="btn btn-warning btn-round"
                    [disabled]="!f_change_mail.valid || (field_mail.value != field_mail_corr.value) "
                    style="font-size: 15px; width: 80%; margin-left: 10%" mat-button>
                absenden
            </button>
        </form>
    </ng-container>
    <br>

    <!--Button Ausloggen-->
    <button (click)="onLogOut()" class="btn btn-warning btn-round"
            style="font-size: 15px; width: 100%" mat-button>
        ausloggen
    </button>
    <br>
    <br>

    <!--Button Account löschen-->
    <button (click)="on_delete= !on_delete" class="btn btn-warning btn-round"
            style="font-size: 15px; width: 100%" mat-button>
        Nutzerkonto löschen
    </button>
    <ng-container *ngIf="on_delete">

        <!--Nachfrage wirklich löschen-->
        <div style="margin: 7% 10%">

            <form (submit)="onDeleteAccount(f_del_acc)" #f_del_acc="ngForm">
                Jetz in echt? Bist sicher und so? Also so wirklich? So richtig? - Es gibt kein zurück!
                <br><br>

                <label>current password</label>
                <mat-form-field appearance="outline">
                    <input type="password" matInput name="pass" ngModel required #field_pw="ngModel">
                </mat-form-field>

                <br><br>

                <button type="submit" [disabled]="!f_del_acc.valid" style="font-size: 15px; width: 80%; margin-left: 10%" mat-button
                        class="btn btn-warning btn-round">
                    jupp
                </button>
                <br><br>
                <button (click)="on_delete = false" style="font-size: 15px; width: 80%; margin-left: 10%" mat-button
                        class="btn btn-warning btn-round">
                    nope - doch nich
                </button>

            </form>

        </div>

    </ng-container>
    <br>
</div>

