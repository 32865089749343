<div class="static-page-layout">

    <ng-container *ngIf="!formSent">
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Jobs</h1>
                    </div>
                </div>
            </div>

            <div class="inhalt-container px-4">
                <div class="col-md-12 mt-5">
                    <div class="center-content-narrow">
                        <p class="text-color-white text-center spacing">Fährst du auf Pizza
                            und
                            Burger ab? Dann bist du bei uns genau richtig!
                            Werde unser neues Teammitglied in ein paar Klicks und fülle das untenstehende Jobformular
                            aus: </p>
                    </div>

                </div>

                <div class="col-md-12 mt-5">
                    <div class="center-content-narrow">
                        <div>
                            <form class="mp-form" [formGroup]="jobsFormGroup" (ngSubmit)="onSubmit()">

                           <!--     <div class="form-group">
                                    <mat-form-field>
                                        <label class="full-width">Filiale wählen *</label>
                                       <select required matNativeControl formControlName="filiale">
                                            <option value="Freiberg">Freiberg</option>
                                            <option value="Dresden">Dresden</option>
                                        </select>
                                    </mat-form-field>
                                </div>-->

                                <h2>Persönliche Daten</h2>

                                <div class="form-group">
                                    <label style="margin-bottom: 10px;">Anrede *</label>
                                    <mat-radio-group name="title" formControlName="salutation">
                                        <mat-radio-button value="Herr">Herr</mat-radio-button>
                                        <mat-radio-button value="Frau" class="ml-3">Frau</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (firstName.dirty || firstName.touched) && firstName.invalid }">
                                    <label>Vorname *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput id="firstName" name="firstName" formControlName="firstName">
                                    </mat-form-field>

                                    <span class="error-message"
                                          *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                                     Bitte füllen Sie dieses Feld aus
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (lastName.dirty || lastName.touched) && lastName.invalid }">
                                    <label>Nachname *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="lastName" formControlName="lastName">
                                    </mat-form-field>

                                    <span class="error-message"
                                          *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                     Bitte füllen Sie dieses Feld aus
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (street.dirty || street.touched) && street.invalid }">
                                    <label>Straße und Hausnummer *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="street" formControlName="street">
                                    </mat-form-field>

                                    <span class="error-message"
                                          *ngIf="(street.dirty || street.touched) && street.invalid">
                                     Bitte füllen Sie dieses Feld aus
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (zip.dirty || zip.touched) && zip.invalid }">
                                    <label>PLZ *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="zip" formControlName="zip">
                                    </mat-form-field>

                                    <span class="error-message" *ngIf="(zip.dirty || zip.touched) && zip.invalid">
                                     Dies ist keine gültige Postleitzahl!
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (city.dirty || city.touched) && city.invalid }">
                                    <label>Ort *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="city" formControlName="city">
                                    </mat-form-field>

                                    <span class="error-message" *ngIf="(city.dirty || city.touched) && city.invalid">
                                     Bitte füllen Sie dieses Feld aus
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (city.dirty || city.touched) && city.invalid }">
                                    <label>Geburtstag *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="birthday" formControlName="birthday">
                                    </mat-form-field>

                                    <span class="error-message" *ngIf="(city.dirty || city.touched) && city.invalid">
                                     Bitte füllen Sie dieses Feld aus
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (phone.dirty || phone.touched) && phone.invalid }">
                                    <label>Telefon *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="phone" formControlName="phone">
                                    </mat-form-field>

                                    <span class="error-message" *ngIf="(phone.dirty || phone.touched) && phone.invalid">
                                     Bitte füllen Sie dieses Feld aus
                                 </span>
                                </div>

                                <div class="form-group"
                                     [ngClass]="{ 'error-message' : (email.dirty || email.touched) && email.invalid }">
                                    <label>E-Mail *</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput type="text" formControlName="email">
                                    </mat-form-field>

                                    <span class="error-message" *ngIf="(email.dirty || email.touched) && email.invalid">
                                     Dies ist keine gültige E-Mail Adresse!
                                 </span>
                                </div>

                                <h2>Berufsbezogene Daten</h2>

                                <div class="form-group">
                                    <mat-form-field>
                                        <label class="full-width">Status</label>
                                        <select matNativeControl formControlName="status">
                                            <option value="Arbeitslos">arbeitslos</option>
                                            <option value="Berufstätig">berufstätig</option>
                                            <option value="Schüler">Schüler/in</option>
                                            <option value="Student">Student/in</option>
                                        </select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <label class="full-width">Andere Beschäftigungsverhältnisse</label>
                                    <mat-radio-group name="other_jobs" formControlName="other_jobs">
                                        <mat-radio-button value="Ja">Ja</mat-radio-button>
                                        <mat-radio-button value="Nein" style="margin-left:20px;">Nein</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="form-group">
                                    <label class="full-width">Bewerbung als</label>
                                    <div class="row m-0">

                                        <mat-checkbox formControlName="job_kitchen" value="ja"
                                                      (change)="kitchenAssist = !kitchenAssist"
                                                      class="beruf">Pizzabeleger (m, w, d)
                                        </mat-checkbox>

                                        <mat-checkbox formControlName="job_driver" value="ja"
                                                      (change)="pizzaDriver = !pizzaDriver"
                                                      class="beruf">Pizzafahrer (m, w, d)
                                        </mat-checkbox>

                                        <mat-checkbox formControlName="job_kitchen3" value="ja"
                                                      (change)="kitchenAssist3 = !kitchenAssist3"
                                                      class="beruf">Servicekraft (m, w, d)
                                        </mat-checkbox>

                                        <mat-checkbox formControlName="job_kitchen2" value="ja"
                                                      (change)="kitchenAssist2 = !kitchenAssist2"
                                                      class="beruf">Schichtleiter (m, w, d)
                                        </mat-checkbox>
                                    </div>

                                </div>

                                <div class="form-group" *ngIf="kitchenAssist||kitchenAssist2||kitchenAssist3">
                                    <label class="full-width">Gesundheitszeugnis</label>
                                    <mat-radio-group name="health_cert" formControlName="health">
                                        <mat-radio-button value="Ja">Ja</mat-radio-button>
                                        <mat-radio-button value="Nein" style="margin-left:20px;">Nein</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="form-group" *ngIf="pizzaDriver">
                                    <label>Führerschein seit</label>
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput name="driversLicense" formControlName="driversLicense">
                                    </mat-form-field>
                                </div>

                                <div class="form-group" *ngIf="pizzaDriver">
                                    <label class="full-width">Eigener Wagen</label>
                                    <mat-radio-group name="own_car" formControlName="own_car">
                                        <mat-radio-button value="Ja">Ja</mat-radio-button>
                                        <mat-radio-button value="Nein" style="margin-left:20px;">Nein</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="form-group">
                                    <label class="full-width">Art der Beschäftigung</label>

                                    <mat-checkbox value="3" formControlName="type_full">Vollzeit</mat-checkbox>
                                    <mat-checkbox value="5" formControlName="type_half">Teilzeit</mat-checkbox>
                                    <mat-checkbox value="10" formControlName="type_450">450 €</mat-checkbox>

                                </div>

                                <div class="form-group">
                                    <label class="full-width">Arbeitstage</label>
                                    <mat-checkbox formControlName="days_1">Mo</mat-checkbox>
                                    <mat-checkbox formControlName="days_2">Di</mat-checkbox>
                                    <mat-checkbox formControlName="days_3">Mi</mat-checkbox>
                                    <mat-checkbox formControlName="days_4">Do</mat-checkbox>
                                    <mat-checkbox formControlName="days_5">Fr</mat-checkbox>
                                    <mat-checkbox formControlName="days_6">Sa</mat-checkbox>
                                    <mat-checkbox formControlName="days_7">So</mat-checkbox>
                                </div>

                                <div class="form-group checkbox-top">

                                    <mat-checkbox formControlName="dataProtect">Ich habe die
                                        Datenschutzerklärung zur
                                        Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten aus dem Formular
                                        zur
                                        Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Mehr
                                        Informationen zum Umgang mit Nutzerdaten findest du in unserer
                                        Datenschutzerklärung.
                                        Du kannst deine Einwilligung jederzeit per E-Mail an {{ storeData?.email }}
                                        widerrufen.
                                    </mat-checkbox>
                                </div>

                                <p>
                                <span class="error-message" *ngIf="showRecaptchaAlert">
                                    Bitte bestätige, dass du kein Roboter bist.
                                </span>
                                </p>

                                <div class="g-recaptcha" data-sitekey="6LejNzseAAAAAPizak3eire_Ah0JydXzz3Bp29Zd"></div>

                                <div class="text-center submit-row">
                                    <button class="margin-center mb-7 mt-7 mp-btn mp-btn-black" type="submit"
                                            [disabled]="!jobsFormGroup.valid">Bewerbung absenden
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="formSent">
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Jobs</h1>
                    </div>
                </div>
            </div>

            <div class="inhalt-container px-4">
                <div class="col-md-12">
                    <div class="center-content-narrow">
                        <div class="text-center mt-7">
                            <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                        </div>
                        <h2 class="mp-font-medium-yellow text-center">Deine Bewerbung wurde erfolgreich gesendet. Wir
                            werden
                            deine Unterlagen prüfen und uns dann bei dir melden.</h2>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
</div>
