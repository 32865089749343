<div class="static-page-layout lunch-page">
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="header-wrapper d-flex justify-content-center align-items-center"
                 [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                    <h1 class="category-headline static-page-title">Mittags-Angebot</h1>
                </div>
            </div>
        </div>
    </div>


    <div class="inhalt-container">
        <div class="col-md-12 mb-2">
            <div class="center-content-narrow">
                <h2 class="text-center">Das Mittags-Angebot ist bestellbar von: <br>
                    <strong>Montag
                        - Freitag, 11:00 - 14:00 Uhr (außer an Feiertagen)</strong>
                </h2>
            </div>

        </div>

        <div class="article-list-row row">
            <div class="col-12 col-md-6 col-xl-4 col-xxxl-3 col-centered article-list-wrap article-box ">

                <div class="article-box-inner-wrap pizza">
                    <div class="article-list-content">
                        <a routerLink="/shop/bestellen/130/Pizza">
                            <strong>ab 10,90 €</strong></a>
                    </div>
                </div>

                <div class="article-list-sub-content">
                    <h3 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Pizza</span>
                        26er</h3>
                    <p class="sub mp-font-regular-white">- Aus unserem Pizzasortiment -</p>
                </div>
                <a routerLink="/shop/bestellen/130/Pizza"
                   class="mp-btn  bestellen-btn">lecker bestellen</a>
            </div>

            <div class="col-12 col-md-6 col-xl-4 col-xxxl-3 col-centered article-list-wrap article-box">

                <div class="article-box-inner-wrap burger">
                    <div class="article-list-content">
                        <a routerLink="/shop/bestellen/132/Burger">
                            <strong>ab 10,90 €</strong></a>
                    </div>

                </div>
                <div class="article-list-sub-content">
                    <h3 class="mp-font-large-yellow text-center article-name"><span
                            class="text-uppercase">BURGER</span> + Beilage nach Wahl & Dip nach Wahl</h3>

                    <p class="sub mp-font-regular-white">- Aus unserem Burgersortiment -</p>
                </div>
                <a routerLink="/shop/bestellen/132/Burger"
                   class="mp-btn  bestellen-btn">lecker bestellen</a>

            </div>

            <div class="col-12 col-md-6 col-xl-4 col-xxxl-3 col-centered article-list-wrap article-box">
                <div class="article-box-inner-wrap salad">
                    <div class="article-list-content">
                        <a routerLink="/shop/bestellen/133/Salat">
                            <strong><span>ab </span>9,90
                            €</strong></a>
                    </div>
                </div>

                <div class="article-list-sub-content">
                    <h3 class="mp-font-large-yellow text-center"><span class="text-uppercase">SALAT</span> & Dip nach Wahl</h3>

                    <p class="sub mp-font-regular-white">- Aus unserem Salatsortiment -</p>
                </div>
                <a routerLink="/shop/bestellen/133/Salat" class="mp-btn bestellen-btn">lecker bestellen</a>
            </div>

            <div class="col-12 col-md-6 col-xl-4 col-xxxl-3 col-centered article-list-wrap article-box">
                <div class="article-box-inner-wrap baguette">
                    <div class="article-list-content">
                        <a routerLink="/shop/bestellen/141/Baguettes">
                            <strong><span>ab </span>9,90 €</strong></a>
                    </div>
                </div>

                <div class="article-list-sub-content">
                    <h3 class="mp-font-large-yellow text-center"><span class="text-uppercase">BAGUETTE & Dip nach Wahl</span>
                        <!--+ Brotstange & Dip nach Wahl-->
                    </h3>
                    <p class="sub mp-font-regular-white">- Aus unserem Baguettesortiment -</p>
                </div>
                <a routerLink="/shop/bestellen/141/Baguettes" class="mp-btn bestellen-btn">lecker bestellen</a>
            </div>
        </div>
    </div>

</div>
