<div class="row mb-7" *ngIf="ready">
    <div class="col-12 p-0">
        <div class="category-img">
            <picture>
                <source media="(min-width: 62em)" srcset="/assets/img/startseite/Daves_Produktrange/Daves_Produktrange_1950x400px.jpg">
                <source media="(min-width: 36em)" srcset="/assets/img/startseite/Daves_Produktrange/Daves_Produktrange_1100x850px.jpg">
                <img src="/assets/img/startseite/Daves_Produktrange/Daves_Produktrange_330x300px.jpg" alt="Daves" class="img-fluid col-12 px-0">
            </picture>

            <div class="category-title">
                <p class="category-title-inner">
                    <!-- Aktion -->
                </p>
            </div>
        </div>
    </div>
</div>
