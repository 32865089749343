<!--<button>show logout</button>-->
<div class="content">
    <div>
        <div class="box">
            Hauptkategorien für Navigation <br>
            <button (click)="onLogMastercats()">log master_cats</button>
        </div>

        <div class="box">
            Artikelliste, geordnet nach sub_cat für gegebene master_cat<br>
            <button (click)="onLogArricleList(3, 'pickup', '2022.07.01')">log article_list</button>
        </div>

        <div class="box">
            Artikeldetail, für gegebene ID, Größe<br>
            <button (click)="onLogArticleDetail(2)">log article detail</button>
        </div>

        <div class="box">
            Zutaten für eine bestimmte aub_cat<br>
            <button (click)="onLogIngList(1)">log ing list</button>
        </div>

        <div class="box">
            Menüartikel liste anzeigen<br>
            <button (click)="onLogMenArtList()">log article for menue</button>
        </div>


        <div class="box">
            Kontrolliere ob/wie lange Shop offen ist + ob Mittagsangebot<br>
            <button (click)="onLogOH()">log current opening_hours</button>
        </div>

        <div class="box">
            Öffnungszeit aktuell ausgeben<br>
            <button (click)="onLogOpeningHours()">log OH</button>
        </div>

        <div class="box">
            Aktionsartikel anzeigen<br>
            <button (click)="onLogOfferArticle()">log spec offer articles</button>
        </div>

        <div class="box">
            Slider Informationen auslesen<br>
            <button (click)="onLogSliderStuff()">log slider stuff</button>
        </div>
    </div>

    <hr>

    Bootstrap test button:  <br>
    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-html="true" title="<em>Tooltip</em> <u>with</u> <b>HTML</b>">
        Tooltip with HTML
    </button>
<br><br>

    <button #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltip="hier ist der tooltip man!" matTooltipPosition="above" > Tooltip </button>

    <br>
    snackbar
    <button mat-button (click)="openSnackBar('Coca Cola', 'in den Warenkorb gelegt')">Show snack-bar</button>

    <br>

    <hr> <!-- - - - - - - - -->
    <!--<input [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">-->
    <!--<owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>-->

    <!--<input [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2">-->
    <!--<owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>-->
    <hr> <!-- - - - - - - - -->
    <div style="padding: 5%">
        <h2>Adresseingabe</h2>
        <!-- <app-adress-input></app-adress-input> -->
    </div>

    <div style="padding: 5%">
        <h2>Login</h2>
        <app-login></app-login>
    </div>

</div>



