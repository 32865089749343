import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs/internal/Subscription";
import { FrontendService } from "../../../services/frontend.service";
import { Article } from "../../../../_classes/Article";
import { MainService } from "../../../services/main.service";
import { Router } from "@angular/router";
import { CartService } from 'src/app/services/cart.service';

@Component({
    selector: 'app-special-offer',
    templateUrl: './special-offer.component.html',
    styleUrls: ['./special-offer.component.scss']
})
export class SpecialOfferComponent implements OnInit, OnDestroy {
    // public stuff  - - - - - - - - - - - - - - - -
    article_list: Article[] = [];
    image_url: string;
    offer_image_url: string;
    offer_heading: string;
    ready = false;
    on_happy_hour: boolean;
    onLunch: boolean;
    on_pickup: boolean;
    // articleListCount: number;
    // rCeady = false;
    /* show the info box for the product */
    showInfoBox = false;
    /* holds the current infobox product information */
    infoBoxProduct: any;


    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];

    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private main_service: MainService,
        private cart_service: CartService,
        private router: Router,
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // init functions
    ngOnInit() {

        this.cart_service.checkInstore();

        this.subscriptions.push(this.frontend_service.getSpOfferArticle().subscribe(
            (data: any[]) => {
                this.article_list = data;
            }));

        this.ready = true;

        // abholung oder lieferung
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            data => {
                // console.log('this.on_pickup: ' + data);
                this.on_pickup = data;
            }));

        // happy our?
        this.subscriptions.push(this.cart_service.on_happy_hour_observable.subscribe(
            data => {
                // console.log('this.on_happy_hour: ' + data);
                this.on_happy_hour = data;
            }));

        // lunch time?
        this.subscriptions.push(this.cart_service.onLunchObservable.subscribe(
            data => {
                // console.log('this.onLunch: ' + data);
                this.onLunch = data;
            }));

        this.image_url = this.main_service.image_url;
    }


    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions

    onLog() {

    }

    onLinkToDetail(i_art, i_size) {
        let article = this.article_list[i_art];

        // this.router.navigate(['Shop/4/Salat/34/Chef%20Salat/gro%C3%9Fe%20Portion']);
        this.router.navigate(['shop/bestellen', 'aktion',
            article.id, article.name.replace(/ /g, '-'), article.price[i_size].size.replace(/ /g, '_')]);
    }

    onOpenInfoBox(id: number) {
        /* request current article form api */
        this.frontend_service.getProductByID(id).then((data) => {
            /* fill in variables */
            this.infoBoxProduct = data;
            // CODE HERE...

            /* open popup with info */
            this.showInfoBox = true;
        });
    }


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

}
