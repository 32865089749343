<!--<button class="log-button" (click)="onLog()">log address input component</button>-->

<div class="row m-0 flex-column align-items-center address-input-form">
    <ng-container *ngIf="!onPickup">
        <ng-container *ngIf="!onShowAddressFound">
        <!--    <div class="col-12">
                <ng-container *ngIf="userLoadedFromCache && anmeldung.edit">
                    <p>
                        Deine bisherige Adresse: <strong>{{ tempUser.adress.street }} {{ tempUser.adress.street_number }}, {{ tempUser.adress.plz }} {{ tempUser.adress.district}}, OT von {{ tempUser.adress.city}}</strong> <br>
                        &lt;!&ndash; Dein Mindestbestellwert: <strong>{{ minOrderValue }} €</strong> &ndash;&gt;

                    </p>
                </ng-container>
              &lt;!&ndash;  <p>Bitte gib zuerst deine PLZ ein!</p>&ndash;&gt;
            </div>-->

            <div class="col-12 p-0 mt-3">
                <label class="text-left pt-lg-3">Postleitzahl *</label>
                <mat-form-field appearance="outline">
                    <input autocomplete="off" matInput placeholder="Postleitzahl eingeben" maxlength="5" (change)="changePlz()" aria-label="State" [matAutocomplete]="plzAutocomplete" [formControl]="searchPlzFormControl" (blur)="checkPlz()" (input)="checkDeleteZip($event)" autofocus autocomplete="off">
                    <mat-autocomplete #plzAutocomplete="matAutocomplete" [displayWith]="displayFnPlz" (optionSelected)="plzClick($event)" class="">
                        <mat-option *ngIf="isLoadingPlz" class="is-loading">Suche...</mat-option>
                        <ng-container *ngIf="!isLoadingPlz">
                            <mat-option *ngFor="let address of filteredPlz" [value]="address">
                                <span><b>{{address.plz}}</b> {{address.city}} ({{address.district}})</span>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
                <br>
                <!-- <p>Selected Value: {{ plz }}</p> -->
                <!-- <p class="error-msg"> -->
                    <!-- {{ errorMessagePlz.message }}<br /> -->
                    <!-- {{ errorMessagePlz.noResultsMessage }}</p> -->
            </div>

            <div class="col-12 p-0 mt-3">
                <label class="text-left pt-lg-3">Straße *</label>
                <mat-form-field appearance="outline">
                    <input autocomplete="off" matInput placeholder="Straße eingeben" (change)="changeStreet()"  aria-label="State" [matAutocomplete]="streetAutocomplete" [formControl]="searchStreetFormControl" (blur)="checkStreet()" (input)="checkDeleteStreet($event)" autofocus autocomplete="off" #plzInput>
                    <mat-autocomplete #streetAutocomplete="matAutocomplete" [displayWith]="displayFnStreet" (optionSelected)="streetClick($event)" class="">
                        <mat-option *ngIf="isLoadingStreet" class="is-loading">Suche...</mat-option>
                        <ng-container *ngIf="!isLoadingStreet">
                            <mat-option *ngFor="let address of filteredStreet" [value]="address">
                                <span><b>{{address.street}}</b> {{address.plz}} {{address.city}} ({{address.district}})</span>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>

                <br>

                <!-- <p>Selected Value: {{ street }}</p> -->
                <!--<p class="error-msg">
                    &lt;!&ndash; {{ errorMessageStreet.message }}<br /> &ndash;&gt;
                    {{ errorMessageStreet.noResultsMessage }}</p>-->
            </div>

            <div class="col-12 p-0 mt-3">
                <label class="text-left pt-lg-3">Hausnummer *</label>
                <mat-form-field appearance="outline">
                    <input autocomplete="off" maxlength="5" [formControl]="streetNumberFormControl" (input)="checkStreetNumber($event)" (blur)="checkStreetNumber($event)" matInput placeholder="Hausnummer eingeben" name="street_number">
                </mat-form-field>
                <br>
                <p class="error-msg">
                    {{ errorMessageStreetNumber.message }}
                </p>
            </div>

            <div *ngIf="city !== ''">
                <p><strong>Stadt:</strong> {{ city }}</p>
            </div>

            <div *ngIf="district !== ''">
                <p><strong>Ortsteil:</strong> {{ district }}</p>
            </div>

            <p class="error-msg">
                <!-- {{ errorMessageStreet.message }}<br /> -->
                {{ errorMessageStreet.noResultsMessage }}</p>
        </ng-container>

        <!-- <ng-container *ngIf="onShowAddressFound">
            <div class="address-result">

                <h1 class="small-yanone">Mindestbestellwert berechnen</h1>
                <br>
                <p>Bei Abholung ist keine Berechnung des Mindestbestellwerts nötig.</p>
                <p>Keine Sorge, wir liefern auch wenn du den Mindestbestellwert nicht erreichst. Wenn du keine weiteren Artikel bestellen möchtest, zahlst du automatisch den Mindestbestellwert.</p>
                <br>
                <p>Sollte deine Adresse nicht im Liefergebiet liegen, nicht gefunden werden oder es Probleme bei der Adresseingabe geben, dann rufe uns gern unter 03731/ 77 55 30 an.</p>

                <ng-container *ngIf="minOrderValue == 0">
                    <div class="text-center">
                        <img src="assets/img/preloader_onwhite.gif" alt="Preloader">
                    </div>
                </ng-container>

                <ng-container *ngIf="minOrderValue > 0">
                    <br><br>
                    <img class="icon-check" src="assets/img/icons/check.svg" alt="">
                    <br><br>
                    <p>Danke, deine Adresse wurde gefunden!</p>
                    <p>{{user.adress.street}} {{user.adress.street_number}}, <br> {{user.adress.plz}} {{user.adress.district}} <br>OT von {{user.adress.city}}</p>
                    <p>Dein Mindestbestellwert: <strong>{{minOrderValue | number:'1.2-5'}} €</strong></p>

                    <button mat-button type="button" class="mp-btn float-left mobile-no-float mobile-margin" (click)="onGoBack()">Zurück</button>
                    <button mat-button type="button" class="mp-btn float-right mobile-no-float mobile-margin" (click)="onResetForm('reset')">neu berechnen</button>

                    <br><br><br>
                </ng-container>
            </div>
        </ng-container> -->
    </ng-container>


    <ng-container *ngIf="onPickup">
        <h1 class="small-yanone">Mindestbestellwert berechnen</h1>
        <br>
        <p class="text-center">Bei Abholung ist keine Berechnung des Mindestbestellwerts nötig.</p>

        <!-- <div class="button-wrap">
            <button mat-button type="button" class="mp-btn" (click)="onGoBack()">
                Zurück
            </button>
        </div> -->
    </ng-container>

</div>
