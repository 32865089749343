import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainService } from '../../../services/main.service';
// import {Article} from '../../../../../_classes/Article';
// import {FrontendService} from '../../../../services/frontend.service';
// import {ActivatedRoute, Router} from '@angular/router';
// import {MainService} from '../../../../services/main.service';
// import {Prices} from '../../../../../_classes/Prices';
// import {CartService} from '../../../../services/cart.service';
// import {Ingredient} from '../../../../../_classes/Ingredient';
// import {IngredientList} from '../../../../../_classes/IngredientList';
// import {ArticleGroup} from '../../../../../_classes/ArticleGroup';
var JobsComponent = /** @class */ (function () {
    function JobsComponent(http, main_service) {
        this.http = http;
        this.main_service = main_service;
        // check if form is submitted
        this.formSent = false;
        // HeaderBilder
        // default für die Fenstergröße max-width: 576px
        this.headerbild_Daves = "/assets/img/header/Daves_Headerbild_Obergruppen_304x170px_drei/Daves_Headerbild_Obergruppen_304x170px_Stellenangebot.jpg";
        this.headerbild_Daves_Desktop = "/assets/img/header/Daves_Headerbild_Obergruppen_2184x656px_burg_stellen_mittags/Daves_Headerbild_Obergruppen_2184x656px_Stellenangebot.jpg";
        this.sizeSmall = true;
        this.showRecaptchaAlert = false;
        // create new reactive formgroup for form
        // https://angular.io/guide/reactive-forms
        this.jobsFormGroup = new FormGroup({
            filiale: new FormControl(null, [Validators.nullValidator]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            salutation: new FormControl(null, [Validators.required]),
            firstName: new FormControl(null, [Validators.required]),
            lastName: new FormControl(null, [Validators.required]),
            street: new FormControl(null, [Validators.required]),
            zip: new FormControl(null, [Validators.required, Validators.pattern('[0-9 ]*')]),
            city: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            birthday: new FormControl(null, [Validators.required]),
            other_jobs: new FormControl(null, Validators.nullValidator),
            status: new FormControl(null, Validators.nullValidator),
            dataProtect: new FormControl(false, Validators.requiredTrue),
            type_full: new FormControl(null, Validators.nullValidator),
            type_half: new FormControl(null, Validators.nullValidator),
            type_450: new FormControl(null, Validators.nullValidator),
            days_1: new FormControl(null, Validators.nullValidator),
            days_2: new FormControl(null, Validators.nullValidator),
            days_3: new FormControl(null, Validators.nullValidator),
            days_4: new FormControl(null, Validators.nullValidator),
            days_5: new FormControl(null, Validators.nullValidator),
            days_6: new FormControl(null, Validators.nullValidator),
            days_7: new FormControl(null, Validators.nullValidator),
            health: new FormControl(null, Validators.nullValidator),
            own_car: new FormControl(null, Validators.nullValidator),
            job_kitchen: new FormControl(null, Validators.nullValidator),
            job_kitchen2: new FormControl(null, Validators.nullValidator),
            job_kitchen3: new FormControl(null, Validators.nullValidator),
            job_driver: new FormControl(null, Validators.nullValidator),
            driversLicense: new FormControl(null, Validators.nullValidator)
        });
    }
    JobsComponent.prototype.ngOnInit = function () {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        console.log('STOREDATA', this.storeData.master);
        this.setHeaderbild();
        var grcaptcha = document.createElement("script");
        grcaptcha.type = "text/javascript";
        grcaptcha.async = true;
        grcaptcha.src = "https://www.google.com/recaptcha/api.js";
        document.body.appendChild(grcaptcha);
    };
    Object.defineProperty(JobsComponent.prototype, "email", {
        // getter for form elements
        get: function () {
            return this.jobsFormGroup.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "zip", {
        get: function () {
            return this.jobsFormGroup.get('zip');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "firstName", {
        get: function () {
            return this.jobsFormGroup.get('firstName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "lastName", {
        get: function () {
            return this.jobsFormGroup.get('lastName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "street", {
        get: function () {
            return this.jobsFormGroup.get('street');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "city", {
        get: function () {
            return this.jobsFormGroup.get('city');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "phone", {
        get: function () {
            return this.jobsFormGroup.get('phone');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "status", {
        get: function () {
            return this.jobsFormGroup.get('status');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobsComponent.prototype, "otherJobs", {
        get: function () {
            return this.jobsFormGroup.get('other_jobs');
        },
        enumerable: true,
        configurable: true
    });
    // submit the newsletter form
    JobsComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.checkRecaptcha()) {
            this.showRecaptchaAlert = true;
            return;
        }
        else {
            this.showRecaptchaAlert = false;
        }
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        console.log(this.jobsFormGroup.value);
        var url = this.main_service.getFrontendUrl() + '/job/send';
        var data = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email
        };
        this.http.post(url, JSON.stringify(this.jobsFormGroup.value), httpOptions)
            .subscribe(function (res) {
            _this.formSent = true;
            window.scroll(0, 0);
        }, function (err) {
            console.log('Error occured');
        });
    };
    // Headerbild setzen
    JobsComponent.prototype.setHeaderbild = function () {
        var small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall)
            return;
        this.sizeSmall = small;
        if (small === false) {
            this.headerbild_Daves = this.headerbild_Daves_Desktop;
        }
        else {
        }
    };
    JobsComponent.prototype.checkRecaptcha = function () {
        var gr = document.getElementById('g-recaptcha-response').value;
        if (!gr) {
            return false;
        }
        else {
            return true;
        }
    };
    return JobsComponent;
}());
export { JobsComponent };
