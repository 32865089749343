<div class="static-page-layout">

    <ng-container *ngIf="optinSuccess">

        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Newsletter</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="inhalt-container p-4">
            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Deine Anmeldung ist abgeschlossen</h2>
                    <p class="text-center spacing">Du erhältst zukünftig tolle Aktionen und leckere Gutscheine
                        direkt in
                        Dein Postfach.</p>
                </div>
            </div>
        </div>


    </ng-container>
    <ng-container *ngIf="optoutSuccess">

        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Newsletter</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="inhalt-container px-4">
            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Schade, dass du gehst!</h2>
                    <p class="text-center spacing">

                    <p>
                        Du hast dich erfolgreich vom Daves-Pizza Newsletter abgemeldet.
                    </p>
                    <p>
                        Wenn du deinen Entschluss doch bereust, dann kannst du dich auf WWW..... jederzeit
                        wieder anmelden. Uns würde es freuen.
                    </p>
                    <p>

                        Dein Daves-Pizza-Team </p>
                </div>
            </div>
        </div>

    </ng-container>
    <ng-container *ngIf="!formSent && !optinSuccess && !optoutSuccess">
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Newsletter</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="inhalt-container px-0">
            <div class="col-md-12 px-0">
                <div class="center-content-narrow">
                    <p class="text-center spacing">Nach Absenden des Formulars bekommst du eine E-Mail mit einem
                        Bestätigungslink.
                        Nach Anklicken des Links ist die Anmeldung bestätigt. </p>
                </div>
            </div>

            <div class="col-md-12 px-0">
                <div class="center-content-narrow">
                    <iframe width="540" height="900" src="https://49095320.sibforms.com/serve/MUIEAEz1qYZ1yxREDo50xbRj_fpfaYj11uDkNTaSKxNjWYiiN1C-8ScA5GIciniog6ePxy3GWdi8vCu0_H0E_dEfGhQOwgQSwt6dSz6RspeebON0ZTI5cWHZ3BtBGtriOegMQN3jEUdnTscep4MpB1IeCwzrExs-mqvBZTsvLCqBaEd6soIhxvAC4I-pG5BLQYKDosqjV1mYXJN9" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="formSent">
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Newsletter</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="inhalt-container px-4">
            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Deine Anmeldung wurde erfolgreich abgesendet.</h2>
                    <p class="text-center spacing">Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt.
                        Nach
                        Anklicken ist die Newsletteranmeldung bestätigt.</p>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <h2 class="mp-font-medium-white text-center mt-7 mb-7">Und jetzt...Hunger?</h2>
            </div>
        </div>

        <div class="row sortiment center-content">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/114/pizza">
                    <div>
                        <img src="/assets/img/header/Daves_Headerbild_Obergruppen_304x170px/Daves_Headerbild_Obergruppen_304x170px_Pizza.jpg"
                             class="img-fluid">
                        <h2>Pizza-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/115/burger">
                    <div>
                        <img src="/assets/img/header/Daves_Headerbild_Obergruppen_304x170px/Daves_Headerbild_Obergruppen_304x170px_Burger.jpg"
                             class="img-fluid">
                        <h2>Burger-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/118/salat">
                    <div>
                        <img src="/assets/img/header/Daves_Headerbild_Obergruppen_304x170px/Daves_Headerbild_Obergruppen_304x170px_Salat.jpg"
                             class="img-fluid">
                        <h2>Salat-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/6/fingerfood">
                    <div>
                        <img src="assets/img/startseite/fingerfood-sortiment_kategorie-startseite.jpg"
                             class="img-fluid">
                        <h2>Fingerfood-<br>Sortiment</h2>
                    </div>
                </a>
            </div>
            <button class="margin-center mb-7 mt-7 mp-btn mp-btn-yanone-on-black" routerLink="/shop">lecker bestellen
            </button>
        </div>
    </ng-container>

</div>
