import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {
  storeData: any;
  // headerBilder
  headerbild_MP = "/assets/img/header/Meisterpizza_Headerbild_Obergruppen_304x170px_Restaurant.jpg";
  headerbild_MP_Desktop = "/assets/img/header/Meisterpizza_Headerbild_Obergruppen_2184x656px_Restaurant.jpg";
  sizeSmall = true;
  constructor() { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
    // console.log("STOREDATE", this.storeData);
    this.setHeaderbild();
  }

  // Headerbild setzen
 setHeaderbild() {
  let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
  if (small == this.sizeSmall) return;
  this.sizeSmall = small;
  if (small === false) {
      this.headerbild_MP = this.headerbild_MP_Desktop;
  }
  else {
      // console.log(this.headerbild_MP, "MP-MOBIL");
      // console.log(this.headerbild_PB, "PB-MOBIL");
  }
}
}
