import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {FrontendService} from '../services/frontend.service';
import {MainService} from '../services/main.service';
import {MasterCat} from '../../_classes/MasterCat';
import {Article} from '../../_classes/Article';
import {User} from '../../_classes/User';
import {DOCUMENT} from '@angular/common';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit, OnDestroy {

    data: any[] = [];
    name: string = 'pizza nach Wunsch';
    i = 0;
    cookie: string;


    //public stuff  - - - - - - - - - - - - - - - -

    //private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];


    //constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private main_service: MainService,
        public snackBar: MatSnackBar,
        @Inject(DOCUMENT) private document: any,
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //init functions
    ngOnInit() {

        this.testerlingerfunctionsnuffnuff();
        this.createCookie('first_name', 'Horst');
        this.createCookie('last_name', 'Schlemmer');
        this.createCookie('street', 'Dingsstraße');
        this.createCookie('city', 'Dingsstadt');
        this.cookie = this.document.cookie;
        this.readCookie();
    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions

    onLogMastercats() {
        this.subscriptions.push(this.frontend_service.getCategories().subscribe(
            (data: MasterCat[]) => {
                this.data = data;
            }));
    }

    onLogArricleList(sub_cat_id: number, delivType: string, delivDate: string) {
        this.subscriptions.push(this.frontend_service.getArticleListByCategory(sub_cat_id, delivType, delivDate).subscribe(
            (data: any[]) => {
            }));
    }

    onLogArticleDetail(article_id: number) {
        this.subscriptions.push(this.frontend_service.getArticleByID(article_id).subscribe(
            (data: any[]) => {
            }));
    }

    onLogIngList(sub_cat_id) {
        this.subscriptions.push(this.frontend_service.getIngListBySubCat(sub_cat_id).subscribe(
            (data: any[]) => {

            }));
    }

    onLogOfferArticle() {
        this.subscriptions.push(this.frontend_service.getSpOfferArticle().subscribe(
            (data: any[]) => {
                console.log(data);
            }));
    }

    onLogOpeningHours() {
        this.subscriptions.push(this.frontend_service.getOpeningHours(1).subscribe(
            (data: any[]) => {
                console.log(data);
            }));
    }

    onLogMenArtList() {
        this.subscriptions.push(this.frontend_service.getArticleForMenu().subscribe(
            (data: any[]) => {
                console.log(data);
            }));
    }

    onLogOH() {
        this.subscriptions.push(this.frontend_service.getOpeningHoursNow().subscribe(
            (data: any[]) => {
                console.log(data);
            }));
    }

    onLogSliderStuff() {
        this.subscriptions.push(this.frontend_service.getSliderInfo().subscribe(
            (data: any[]) => {
                console.log(data);
            }));
    }

    onTest() {
        this.i++;
        console.log('i = ' + this.i);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    private testerlingerfunctionsnuffnuff() {
        let user = new User();
        user.phone = '01213';

        let copysuser = Object.assign({}, user);
        copysuser.phone = 'abc';
    }

    private createCookie(name: string, val: string) {

        let date = new Date();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));

        let delete_cookie = 'expires=' + date.toUTCString();
        this.document.cookie = name + '=' + val + ';' + delete_cookie;
    }

    private readCookie() {
        let cookie_array = this.document.cookie.split(';');

        //iterate through the cookie-fields
        for (let i = 0; i < cookie_array.length; i++) {
            let cookie_elem = cookie_array[i];

            //delete unneccessary spaces
            while (cookie_elem.charAt(0) === ' ') {
                cookie_elem = cookie_elem.substring(1);
            }

            //get data substring
            // cookie_elem.substring()
        }
    }
}
