import {Injectable} from '@angular/core';
import {FrontendService} from './frontend.service';
import {Router} from '@angular/router';
import {CartService} from './cart.service';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';
import {PaymentsService} from './payments.service';



@Injectable({
    providedIn: 'root'
})

export class PaypalService {

    public libIsLoaded = false;

    constructor(
        private paymentsService: PaymentsService
    ) {


    }

    loadLib(){

        return new Promise((resolve, reject) => {

            if( this.libIsLoaded === false ) {

                this.paymentsService.load().then(payments => {
                    this.libIsLoaded = true;
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = 'https://www.paypal.com/sdk/js?client-id=' + payments.paypal.settings.clientId + '&commit=true&currency=EUR&locale=de_DE';
                    script.onload = () => {
                        console.warn('PayPal Lib Loaded');
                        resolve();
                    };
                    document.getElementsByTagName('head')[0].appendChild(script);
                });


            } else {
                resolve();
            }

        });

    }


}
