<!--<button class="log-button" (click)="onLog()">Log Dashboard Component</button>-->

<ng-container *ngIf="!ready">
    <div class="text-center color-primary">
        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>

<ng-container *ngIf="ready">
    <div class="container-full home-content-wrapper" id="home-content">
        <app-element-slider element="sliderDashboard"></app-element-slider>

        <app-element-categories element="categoriesDashboard"></app-element-categories>


        <!-- Kacheln-->
        <div class="row">
            <div class="col-md-6 px-0 pt-0 pb-2 pt-md-1 pb-md-0">
                <a class="mp-box-link d-block position-relative text-center"
                   routerLink="/shop/bestellen/136/burgermenu">
                    <picture>
                        <source media="(min-width: 62em)"
                                srcset=" /assets/img/startseite/Daves_Startseite_BurgerMenue_960x520px/Startseite_BurgerMenue_960x520px.jpg">
                        <source media="(min-width: 36em)"
                                srcset=" /assets/img/startseite/Daves_Startseite_BurgerMenue_960x520px/Startseite_BurgerMenue_960x520px.jpg">
                        <img src=" /assets/img/startseite/Daves_Startseite_BurgerMenue_960x520px/Startseite_BurgerMenue_960x520px.jpg"
                             alt="Daves" class="img-fluid col-12 px-0">

                    </picture>
                </a>
            </div>
            <div class="col-md-6 px-0 pt-1 pb-2 pt-md-1 pb-md-0">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/mittags-angebot">
                    <picture>
                        <source media="(min-width: 62em)"
                                srcset="/assets/img/startseite/Daves_Startseite_Mittagsangebot_960x520px/Startseite_Mittagsangebot_960x520px.jpg">
                        <source media="(min-width: 36em)"
                                srcset="/assets/img/startseite/Daves_Startseite_Mittagsangebot_960x520px/Startseite_Mittagsangebot_960x520px.jpg">
                        <img src="/assets/img/startseite/Daves_Startseite_Mittagsangebot_960x520px/Startseite_Mittagsangebot_960x520px.jpg"
                             alt="Daves" class="img-fluid col-12 px-0">
                    </picture>
                </a>
            </div>
            <div class="col-md-6 px-0 pt-1 pb-2 pt-md-0 pb-md-2">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/pizza-party-mega-deal">
                    <picture>
                        <source media="(min-width: 62em)"
                                srcset="/assets/img/startseite/Daves_Startseite_MegaDeal_UeberUns_960x520px/Startseite_MegaDeal_960x520px.jpg">
                        <source media="(min-width: 36em)"
                                srcset="/assets/img/startseite/Daves_Startseite_MegaDeal_UeberUns_960x520px/Startseite_MegaDeal_960x520px.jpg">
                        <!--                       <img src="/assets/img/startseite/Daves_Headerbild_Obergruppen_304x170px_Ueber_Uns_Mega Deal/Daves_Headerbild_Obergruppen_304x170px_MegaDeal.jpg" alt="Daves" class="img-fluid col-12 px-0">-->
                        <img src="/assets/img/startseite/Daves_Startseite_MegaDeal_UeberUns_960x520px/Startseite_MegaDeal_960x520px.jpg"
                             alt="Daves" class="img-fluid col-12 px-0">
                    </picture>
                </a>
            </div>
            <div class="col-md-6 px-0 pt-1 pb-2 pt-md-0 pb-md-2">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/ueberuns">
                    <picture>
                        <source media="(min-width: 62em)"
                                srcset="/assets/img/startseite/Daves_Startseite_MegaDeal_UeberUns_960x520px/Startseite_UeberUns_960x520px.jpg">
                        <source media="(min-width: 36em)"
                                srcset="/assets/img/startseite/Daves_Startseite_MegaDeal_UeberUns_960x520px/Startseite_UeberUns_960x520px.jpg">
                        <!--<img src="/assets/img/startseite/Daves_Headerbild_Obergruppen_304x170px_Ueber_Uns_Mega Deal/Daves_Headerbild_Obergruppen_304x170px_UeberUns.jpg" alt="Daves" class="img-fluid col-12 px-0">-->
                        <img src="/assets/img/startseite/Daves_Startseite_MegaDeal_UeberUns_960x520px/Startseite_UeberUns_960x520px.jpg"
                             alt="Daves" class="img-fluid col-12 px-0">
                    </picture>
                </a>
            </div>
            <div class="col-md-12 pt-3 pb-2 px-0">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/jobs">
                    <picture>
                        <source media="(min-width: 62em)"
                                srcset="/assets/img/startseite/Daves_Startseite_Stellenangebot_1840x440px/Startseite_Stellenangebot_1840x440px.jpg">
                        <source media="(min-width: 36em)"
                                srcset="/assets/img/startseite/Daves_Startseite_Mittagsangebot_960x520px/Startseite_Stellenangebot_960x520px_c.jpg">
                        <img src="/assets/img/startseite/Daves_Startseite_Mittagsangebot_960x520px/Startseite_Stellenangebot_960x520px_c.jpg"
                             alt="Daves" class="img-fluid col-12 p-0">
                    </picture>
                </a>
            </div>
        </div>
    </div>

</ng-container>


<!--/*Meisterpizza*/-->
<!--<ng-container *ngIf="ready">
    <div class="container-full home-content-wrapper" id="home-content">
        <app-element-slider element="sliderDashboard"></app-element-slider>

        <app-element-categories element="categoriesDashboard"></app-element-categories>


        <div class="row">
            <div class="col-md-4 mb-6">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/pizza-party-mega-deal">
                    <picture>
                        <img src="/assets/img/MegaDeal/MP-DD-FG_Kategoriebilder_Startseite_1500x1500px_MegaDeal.jpg"
                             class="img-fluid" alt="">

                    </picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 mb-6">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/mittags-angebot">
                    <picture>
                        <img
                             src="assets/img/startseite/Kategoriebilder_Startseite_1500x1500px_Mittagsangebot_newDD.jpg"
                             class="img-fluid" alt="">
                    </picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 mb-6">
                <picture>
                    <img
                            src="assets/img/startseite/MP-DD_Kategoriebilder_Startseite_1500x1500px_Sparen.jpg"
                            class="img-fluid" alt="">
                </picture>
            </div>
        </div>


        <div class="row boxes-home">
            <div class="col-md-4 box-news mb-6">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/newsletter">
                    <figure class="mb-5 mb-xl-0">
                        <img src="assets/img/icons/newsletter-icon_homepage.png" class="">
                    </figure>
                    <h4>Newsletter</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 box-jobs mb-6">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/jobs">
                    <figure class="mb-5 mb-xl-0">
                        <img src="assets/img/icons/jobs-icon_homepage.png" class="">
                    </figure>
                    <h4>Stellenangebote</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
            <div class="col-md-4 box-partner mb-6">
                <a *ngIf="storeData?.id === 7" class="mp-box-link d-block position-relative text-center"
                   routerLink="/shop/restaurant">
                    <figure class="mb-5 mb-xl-0">
                        <img src="assets/img/icons/Button_Restaurant.png" class="">
                    </figure>
                    <h4>Restaurant</h4>

                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
                <a *ngIf="storeData?.id === 2" href="https://dresden-meisterpizza.de/#/shop/restaurant" class="mp-box-link d-block position-relative text-center">
                    <figure class="mb-5 mb-xl-0">
                        <img src="assets/img/icons/Button_Restaurant.png" class="">
                    </figure>
                    <h4 id="restaurant">Restaurant in Dresden</h4>

                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
        </div>
    </div>

</ng-container>-->
