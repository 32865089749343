import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";
import {User} from "../../../../_classes/User";
import {UserService} from "../../../services/user.service";
import {FrontendService} from "../../../services/frontend.service";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
    //public stuff  - - - - - - - - - - - - - - - -
    user: User;
    on_edit: boolean = false;
    on_change_pw: boolean = false;
    on_change_mail: boolean = false;
    on_delete:boolean = false;


    //private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];

    //constructor   - - - - - - - - - - - - - - - -
    constructor(
        private user_service: UserService,
        private frontend_service: FrontendService,
        private auth_service: AuthService,
        private router: Router,
    ) {

    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //init functions
    ngOnInit() {
        this.subscriptions.push(this.user_service.user_observable.subscribe(data => {
            this.user = data;
        }))

    }


    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onSaveUserData() {
        this.on_edit = false;
        let new_data = {
            'first_name': this.user.fir_name,
            'last_name': this.user.fam_name,
            'title': this.user.title,
            'plz': this.user.adress.plz,
            'city': this.user.adress.city,
            'district': this.user.adress.district,
            'street': this.user.adress.street,
            'hnr': this.user.adress.street_number,
            'floor': this.user.adress.etage,
            'token': this.auth_service.getToken(),

        };
        this.frontend_service.onEditUser(JSON.stringify(new_data));
    }

    onChangePassword(form: NgForm) {

        this.auth_service.updatePassword(form.value.old_pw, form.value.new_pw );
        this.on_change_pw = false;
    }

    onLogOut() {
        this.auth_service.logoutUser();
        this.router.navigate(['login']);
    }

    onChangeMail(form: NgForm) {

        this.auth_service.updateEMail(form.value.mail, form.value.pass);
        this.on_change_mail = false;
    }

    onDeleteAccount(form: NgForm) {

        this.auth_service.deleteUser(form.value.pass)

    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

}
