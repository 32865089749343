import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";
import {NgForm} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {User} from "../../../../_classes/User";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    //public stuff  - - - - - - - - - - - - - - - -
    valid_login: boolean;
    valid_signup: boolean;
    error_message_login_failed: string;
    error_message_signup_failed: string;
    user: User;

    show_reset_pw_form: boolean = false;

    //private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];

    //constructor   - - - - - - - - - - - - - - - -
    constructor(
        private auth_service: AuthService,
        private user_service: UserService,
        private router:Router,
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //init functions
    ngOnInit() {
        //check if login/signup is valid
        this.subscriptions.push(this.auth_service.valid_login_observable.subscribe(data => {
            this.valid_login = data;
        }));
        this.subscriptions.push(this.auth_service.valid_sign_up_observable.subscribe(data => {
            this.valid_signup = data;
        }));

        // get error messages
        this.subscriptions.push(this.auth_service.error_message_login_failed_observable.subscribe(data => {
            this.error_message_login_failed = data;
        }));
        this.subscriptions.push(this.auth_service.error_message_signup_failed_observable.subscribe(data => {
            this.error_message_signup_failed = data;
        }));

        this.subscriptions.push(this.user_service.user_observable.subscribe(data => {
            this.user = data;
        }))
    }


    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onSignup(form: NgForm) {
        // console.log(form);
        // console.log(this.user_service);
        const email = form.value.email_signup;
        const passw = form.value.password_signup;
        const titel = form.value.titel_reg;
        const first_name = form.value.first_name;
        const last_name = form.value.last_name;
        const floor = form.value.etage;
        const phone = form.value.phone;
        const plz = this.user.adress.plz;
        const city = this.user.adress.city;
        const district = this.user.adress.district;
        const street = this.user.adress.street;
        const hnr = this.user.adress.street_number;

        this.auth_service.signupUser(email, passw,  titel, first_name, last_name, plz,city,district,street, hnr,floor,phone)

        // this.router.navigate(['']);
    }

    onLogin(form: NgForm) {
        // console.log(form);
        const email = form.value.email;
        const passw = form.value.password;
        this.auth_service.loginUser(email, passw);


    }

    onResetPW(form: NgForm){
        this.auth_service.resetPassword(form.value.email_new_pw);
    }

    onDummyLog(){
        this.auth_service.loginUser('a@b.de', '123456');
    }

    onLog() {
        // console.log(this);
    }

    onReloadUser(){
        this.user_service.onReInitUserData();
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

}