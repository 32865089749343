import {CartService} from "../../../services/cart.service";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

//public stuff  - - - - - - - - - - - - - - - -
    on_pickup: boolean;
    total_amount: number;
    show_cart: boolean;
   /* logo2 = "/assets/icons/Logo_Daves/Logo_Daves_ohne_text.svg";*/
    logo2 = "/assets/icons/Logo_Daves/Daves_logo.png";
    // total_price: number;
    @Input() is_open: boolean;
    @Output() toggle_is_open_emitter = new EventEmitter();
    
    storeData: any;

//private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];

//constructor   - - - - - - - - - - - - - - - -
    constructor(
        private cart_service: CartService,
        public  auth_service: AuthService,
        private  user_service: UserService,
    ) {
    }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// init functions
    ngOnInit() {
        this.show_cart = !!(this.cart_service.isPickup() || !!(sessionStorage.getItem('tempUser')));
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            (data: boolean) => {
                this.on_pickup = data;
            }
        ));
        this.subscriptions.push(this.cart_service.article_amount_observable.subscribe(data => {
            this.total_amount = data;
        }));
        // this.subscriptions.push(this.cart_service.price_total_observable.subscribe(data => {
        //     this.total_price = data
        // }));

        this.storeData = JSON.parse(localStorage.getItem('storedata'));
    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// public functions
    // onTogglePickup() {
    //     this.cart_service.toggleOnPickup();
    //     if (sessionStorage.getItem('tipFixedPercentage') && parseFloat(sessionStorage.getItem('tipFixedPercentage')) > 0) {
    //         this.cart_service.calcTipPercentage(sessionStorage.getItem('tipFixedPercentage'));
    //     }
    // }

    onToggle() {
        this.toggle_is_open_emitter.emit();
    }

    onLog() {
        console.log(this);
    }

    onLogUser(){
        // console.log(this.user_service)
    }
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// private functions
