// import {Component, OnDestroy, OnInit} from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FrontendService } from 'src/app/services/frontend.service';
import { Subscription, forkJoin, of, interval } from 'rxjs';
import { Location } from '@angular/common';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { SettingsService } from '../../../services/settings.service';


@Component({
    selector: 'app-oeffnungszeiten',
    templateUrl: './oeffnungszeiten.component.html',
    styleUrls: ['./oeffnungszeiten.component.scss']
})
export class OeffnungszeitenComponent implements OnInit {

    /* Collect component subscriptions */
    private subscriptions: Subscription[] = [];
    /* Set if shop is closed at the moment the users loads page  */
    shopClosed = false;
    /* Show popup info that shop is closed */
    showClosedPopup = false;
    /* Show popup when the time change rsults in different total price */
    showPriceDifferencesPopup = false;
    showSessionExpiredPopup: boolean;
    /* Check if service has loaded api call from service and render page on success */
    ready = false;
    /* Collect store data from api backend */
    storeData: any;
    /* Get the opening hour of current time */
    openingHoursNow: any;
    /* Get the store opening hours for display */
    openingHours: any;
    days: object = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    /* Set true if the customer would like to postpone delivery to certain time or date */
    // deliveryLater: boolean;
    /* Set true if the customer would like to pickup order */
    // pickup: boolean;
    /* total price of order */
    // priceTotal: number;
    /* store shop setting array */
    /* store global image url */
    // imageUrl: string;
    // logo: any;
    // welcomeText: any;

    subscription: Subscription;

    special_opening_hours: any[] = [];

    // headerBilder
    headerbild_MP = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_MP.jpg";
    headerbild_PB = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_PB.jpg";
    headerbild_MP_Desktop = "/assets/img/header/Headerbild_Obergruppen_Newsletter_MP.jpg";
    headerbild_PB_Desktop = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Newsletter.jpg";
    sizeSmall = true;
    /**
     * Component constructor which provides service classes
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    constructor(
        private frontendService: FrontendService,
        private cartService: CartService,
        private router: Router,
        private location: Location,
        private mainService: MainService
    ) {
        /* Make api and service calls and get data - fork join is used to check the completion of alle calls at once */
        forkJoin([
            /* make api calls */
            // this.frontendService.getSettingsFromBackend(),
            this.frontendService.getStoreData(),
            this.frontendService.getOpeningHoursNow(),
            this.frontendService.getOpeningHours(1),
            this.frontendService.getOpeningHours(2),
            /* provide number of observables from the related service */
            of(this.cartService.shopClosedObservable),
            of(this.cartService.showSessionExpiredPopupObservable)

        ])
            .subscribe(
                data => {
                    this.distributeServiceDataOnSuccess(data);
                },
                err => console.error(err)
            );

    }

    /**
     * Call angular lifecycle hook on init
     */
    ngOnInit() {
        // this.storeData = JSON.parse(localStorage.getItem('storedata'));
        // this.imageUrl = this.mainService.image_url;
        const source = interval(60000);
        this.subscription = source.subscribe(val => this.cartService.checkSession());
        this.setHeaderbild();
    }


    /**
     * Distribute the service data to the corresponding attributes
     */
    distributeServiceDataOnSuccess(data: any) {
        /* distribute the api call response to the corresponding vars */
        this.storeData = data[0];
        this.openingHoursNow = data[1];
        this.openingHours = [8];
        for (var i = 0; i < 7; i++) {
            var text01 = data[2][i][0];
            var text02 = data[2][i][1];
            if (text02.length > 0) {
                text01 = text01 + " Uhr / " + text02 + " Uhr";
            } else {
                text01 = text01 + " Uhr";
            }
            this.openingHours[i] = [this.days[i], text01];
        }
        var z = ["Feiertags", "10:30 - 21:00 Uhr"];
        this.openingHours[7] = z;

        // get opening hours for special opening dates
        this.subscriptions.push(this.frontendService.getOpeningHours(2).subscribe(
            (data: any[]) => {
                this.special_opening_hours = data;
            }));

        /* set same value for popup */
        if (!sessionStorage.getItem('showClosedPopup')) {
            this.showClosedPopup = this.shopClosed;
        }

        /** set page ready when finished */
        this.ready = true;
    }


    // Headerbild setzen
    setHeaderbild() {
        let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall) return;
        this.sizeSmall = small;
        if (small === false) {
            this.headerbild_MP = this.headerbild_MP_Desktop;
            this.headerbild_PB = this.headerbild_PB_Desktop;
        }
        else {
        }
    }

}
