import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Anmeldung } from '../wrapper-components/welcome/step-5/welcomenDaten';

@Injectable({
  providedIn: 'root'
})

export class SessionService {

  private anmeldung: Anmeldung = {
    edit: false,
    showBtnBestellen: false,
    showBtnWarenkorb: false,
    disableBestellen: true,
    disableWarenkorb: true,
    valueLiefern: "null",
    valueHolen: "null",
    isLiefern: false,
    isHolen: false,
    isSofort: true
  };

  constructor() {
    // console.log("Session.service wurde erstellt...");
    this.loadAnmeldung();
  }

  getAnmeldung(): Observable<Anmeldung> {
    const anmeldung = of(this.anmeldung);
    return anmeldung;
  }

  showBtnBestellen() {
    this.anmeldung.showBtnBestellen = true;
    this.anmeldung.showBtnWarenkorb = false;
    /*this.saveAnmeldung();*/
  }

  showBtnWarenkorb() {
    this.anmeldung.showBtnBestellen = false;
    this.anmeldung.showBtnWarenkorb = true;
   /* this.saveAnmeldung();*/
  }

  setLiefern() {
    this.anmeldung.isLiefern = true;
    this.anmeldung.isHolen = false;
    this.anmeldung.valueLiefern = "aktiv";
    this.anmeldung.valueHolen = "null";
    /*this.saveAnmeldung();*/
  }

  setHolen() {
    this.anmeldung.isLiefern = false;
    this.anmeldung.isHolen = true;
    this.anmeldung.valueLiefern = "null";
    this.anmeldung.valueHolen = "aktiv";
    /*this.saveAnmeldung();*/
  }

  setDisableButtons(value: boolean) {
    this.anmeldung.disableBestellen = value;
    this.anmeldung.disableWarenkorb = value;
   /* this.saveAnmeldung();*/
  }

  setEdit() {
    this.anmeldung.edit = true;
   /* this.saveAnmeldung();*/
  }

  saveAnmeldung(): void {
    var tmpData = JSON.stringify(this.anmeldung);
    sessionStorage.setItem("Anmeldung", tmpData);
  }

  loadAnmeldung(): void {
    var tmpString: any = sessionStorage.getItem("Anmeldung");
    if (tmpString == null) {
      tmpString = '{"edit": false,"showBtnBestellen": false,"showBtnWarenkorb": false,"disableBestellen": true,"disableWarenkorb": true,"valueLiefern": "null","valueHolen": "null","isLiefern": false,"isHolen": false}';
    }
    this.anmeldung = JSON.parse(tmpString);
  }
}
