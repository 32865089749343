/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oeffnungszeiten.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./oeffnungszeiten.component";
import * as i4 from "../../../services/frontend.service";
import * as i5 from "../../../services/cart.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../services/main.service";
var styles_OeffnungszeitenComponent = [i0.styles];
var RenderType_OeffnungszeitenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OeffnungszeitenComponent, data: {} });
export { RenderType_OeffnungszeitenComponent as RenderType_OeffnungszeitenComponent };
function View_OeffnungszeitenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-12 p-0 m-0 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_OeffnungszeitenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [["class", "row p-0 m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [["class", "col-12 col-md-8 col-lg-8 m-0 mx-md-auto p-0 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "p-0 m-0 row col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OeffnungszeitenComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_OeffnungszeitenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "static-page-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-12 p-0 m-0 header-background-img header-wrapper headerBild"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D6ffnungszeiten"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "mp-font-large-yellow text-center my-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D6ffnungszeiten"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "table", [["class", "col-12 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OeffnungszeitenComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openingHours; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.headerbild_MP) + ")"); _ck(_v, 2, 0, currVal_0); }); }
export function View_OeffnungszeitenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oeffnungszeiten", [], null, null, null, View_OeffnungszeitenComponent_0, RenderType_OeffnungszeitenComponent)), i1.ɵdid(1, 114688, null, 0, i3.OeffnungszeitenComponent, [i4.FrontendService, i5.CartService, i6.Router, i2.Location, i7.MainService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OeffnungszeitenComponentNgFactory = i1.ɵccf("app-oeffnungszeiten", i3.OeffnungszeitenComponent, View_OeffnungszeitenComponent_Host_0, {}, {}, []);
export { OeffnungszeitenComponentNgFactory as OeffnungszeitenComponentNgFactory };
