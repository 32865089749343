import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { MainService } from 'src/app/services/main.service';
import { FrontendService } from 'src/app/services/frontend.service';
import { UserService } from '../../../services/user.service';
import { CartService } from '../../../services/cart.service';
import { User } from '../../../../_classes/User';
import { AddressItem } from '../adress-page/adress-page.component';

import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

/**
 * Decorators
 */
@Component({
    selector: 'app-min-order-value',
    templateUrl: './min-order-value.component.html',
    styleUrls: ['./min-order-value.component.scss']
})

/**
 * Class for min order value calculation
 */
export class MinOrderValueComponent implements OnInit, OnDestroy {
    // Zip
    searchPlzFormControl = new FormControl();
    filteredPlz: any;
    isLoadingPlz = false;
    errorMessagePlz = {
        response: Object,
        message: '',
        noResultsMessage: ''
    };
    plz = '';

    // Street
    searchStreetFormControl = new FormControl();
    filteredStreet: any;
    isLoadingStreet = false;
    errorMessageStreet = {
        response: Object,
        message: '',
        noResultsMessage: ''
    };
    street = '';

    // other address values
    streetNumberFormControl = new FormControl();
    streetNumber = '';
    errorMessageStreetNumber = {
        response: Object,
        message: ''
    };

    city = '';
    district = '';

    // Other
    private subscriptions: Subscription[] = [];

    // template vars
    onShowAddressFound = false;
    resettedStreet = false;
    resettedPlz = false;
    onPickup: boolean;
    minOrderValue: number;

    // User initialization
    user: User;
    tempUser: User;

    constructor(
        private http: HttpClient,
        private main_service: MainService,
        private frontend_service: FrontendService,
        private user_service: UserService,
        private cart_service: CartService,
        private location: Location
    ) { }

    ngOnInit() {

        this.cart_service.checkInstore();
        // get user data from service
        this.subscriptions.push(this.user_service.user_observable.subscribe(
            data => {
                this.user = data;
                this.tempUser = data;
            }));

        // get min order value from cart service
        this.subscriptions.push(this.cart_service.min_val_observable.subscribe(
            data => {
                this.minOrderValue = data;
            }
        ));

        // get min order value from cart service
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            data => {
                this.onPickup = data;
            }
        ));

        this.autocompletePlz();
        this.autocompleteStreet();

    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    /**
     * Zip calculation
     */
    autocompletePlz() {
        this.searchPlzFormControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.filteredPlz = [];
                    this.isLoadingPlz = true;
                }),
                switchMap(value => this.http.get(this.main_service.adress_url + '/get-address-data?field=plz&plz=' + value + '&street=' + this.street + '&district=')
                    .pipe(
                        finalize(() => {
                            this.isLoadingPlz = false;
                        }),
                    )
                )
            )
            .subscribe(data => {
                if (data === undefined) {
                    this.filteredPlz = [];
                    this.errorMessagePlz.message = 'Data undefined';
                } else {
                    this.filteredPlz = data;
                    if (this.filteredPlz.length === 0 && this.plz === '' && !this.resettedPlz) {
                        this.errorMessagePlz.noResultsMessage = 'Postleitzahl nicht gefunden';
                    }
                    this.resettedPlz = false;
                }

            });
    }

    displayFnPlz(address: AddressItem): string {
        return (address && address.plz && (address.plz !== '')) ? address.plz : '';
    }

    // on select autocomplete value
    plzClick(event: any) {
        this.plz = event.option.value.plz;
        this.city = event.option.value.city;
        this.district = event.option.value.district;

        this.errorMessagePlz.message = '';
        this.errorMessagePlz.noResultsMessage = '';
    }

    // validate on blur
    checkPlz() {
        if (!this.plz || this.plz !== this.searchPlzFormControl.value.plz) {
            this.searchPlzFormControl.setValue(null);
            this.plz = '';
            this.errorMessagePlz.message = 'Bitte gültige Postleitzahl eingeben';
        }
    }

    /**
     * Street calculation
     */
    autocompleteStreet() {
        this.searchStreetFormControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.filteredStreet = [];
                    this.isLoadingStreet = true;
                }),
                switchMap(value => this.http.get(this.main_service.adress_url + '/get-address-data?field=street&plz=' + this.plz + '&street=' + value + '&district=' + this.district)
                    .pipe(
                        finalize(() => {
                            this.isLoadingStreet = false;
                        }),
                    )
                )
            )
            .subscribe(data => {
                if (data === undefined) {
                    this.filteredStreet = [];
                    this.errorMessageStreet.message = 'Data undefined for Street';
                } else {
                    this.filteredStreet = data;
                    if (this.filteredStreet.length === 0 && this.street === '' && !this.resettedStreet) {
                        this.errorMessageStreet.noResultsMessage = 'Straße nicht gefunden';
                    }
                    this.resettedStreet = false;
                }

            });
    }

    displayFnStreet(address: AddressItem): string {
        return (address && address.street && (address.street !== '')) ? address.street : '';
    }

    // on select autocomplete value
    streetClick(event: any) {
        this.street = event.option.value.street;
        this.plz = event.option.value.plz;
        this.city = event.option.value.city;
        this.district = event.option.value.district;
        // preselect value for zip code
        this.searchPlzFormControl.setValue(event.option.value);

        this.errorMessagePlz.message = '';
        this.errorMessagePlz.noResultsMessage = '';
        this.errorMessageStreet.message = '';
        this.errorMessageStreet.noResultsMessage = '';
    }

    // validate on blur
    checkStreet() {
        if (!this.street || this.street !== this.searchStreetFormControl.value.street) {
            this.searchStreetFormControl.setValue(null);
            this.street = '';
            this.errorMessageStreet.message = 'Bitte gültige Straße eingeben';
        }
    }

    // validate on blur
    checkStreetNumber() {
        if (this.streetNumber === '') {
            this.errorMessageStreetNumber.message = 'Bitte Hausnummer eingeben';
        } else {
            this.errorMessageStreetNumber.message = '';
        }
    }

    /**
     * Other min order value functions
     */

    // go back to previous page
    onGoBack() {
        this.location.back();
    }

    // Adresse zur Validierug an BE senden, response: Adresse io / Alternativen
    onSubmit() {
        // save data to global user object
        this.saveUserData();

        this.cart_service.calcMinVal(this.plz, this.district);
        this.onShowAddressFound = true;

        // save to temp data to show previous address while input new values
        this.tempUser = this.user;
        this.saveAddress(this.user.adress.plz, this.user.adress.city, this.user.adress.district, this.user.adress.street, this.user.adress.street_number);

        // save to storage
        sessionStorage.setItem('tempUser', JSON.stringify(this.user));
    }

    /**
     * Resets the whole form and all given data
     */
    onResetForm() {
        this.onShowAddressFound = false;
        this.user = new User();

        this.plz = '';
        this.street = '';
        this.city = '';
        this.district = '';
        this.streetNumber = '';

        this.searchPlzFormControl.setValue(null);
        this.searchStreetFormControl.setValue(null);

        this.resettedPlz = true;
        this.resettedStreet = true;

        // this.errorMessageStreetNumber.message = '';
    }

    // save form data to user object
    saveUserData() {
        this.user.adress.plz = this.plz;
        this.user.adress.street = this.street;
        this.user.adress.city = this.city;
        this.user.adress.district = this.district;
        this.user.adress.street_number = this.streetNumber;
    }

    // set user data over service to set usser globally
    saveAddress(plz: string, city: string, district: string, street: string, number: string) {
        this.user_service.setUserAdressData(plz, city, district, street, number);

        // get price for min order value
        this.subscriptions.push(this.frontend_service.getMinVal(plz, district).subscribe(
            (data: string) => {
                this.cart_service.setMinVal(data);
                // verzögerung bei min value berechnung
                // thisNewMinValueSet = true;
            }
        ));
    }

    // Misc
    onLog() {
        // console.log('LOG COMPONENT: %o', this);
        // console.log();
    }
}
