import {Injectable} from '@angular/core';
import {FrontendService} from './frontend.service';
import {Router} from '@angular/router';
import {CartService} from './cart.service';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MainService} from './main.service';
import DateTimeFormat = Intl.DateTimeFormat;



@Injectable({
    providedIn: 'root'
})

export class LeadtimeService {

    private rules = 0;

    constructor(
        private http: HttpClient,
        private main_service: MainService
    ) {




    }

    loadRules(date: Date, type: string) {

        // console.log('deliv-date-before: ' + date);
        

        const post_data = {
            date: date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'T'+date.getHours()+':'+date.getMinutes()+':00',
            type: type
        };

        // console.log('deliv-date-after: ' + JSON.stringify(post_data));

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            // responseType: 'text' as 'json'
            // responseType: 'json'
        };

        return this.http.post(this.main_service.getFrontendUrl() + '/LeadTimeRules', JSON.stringify(post_data), httpOptions)
            .toPromise()
            .then(
                (data: any) => {
                this.rules = data.rules;
            },
                (data: any) => {
                    this.rules = 0;
                }
            );

    }

    getByDate() {

        return (this.rules === 0) ? 0  : (( this.rules * 60 ) * 1000);

    }


}
