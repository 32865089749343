import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainService } from './main.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./main.service";
var LeadtimeService = /** @class */ (function () {
    function LeadtimeService(http, main_service) {
        this.http = http;
        this.main_service = main_service;
        this.rules = 0;
    }
    LeadtimeService.prototype.loadRules = function (date, type) {
        // console.log('deliv-date-before: ' + date);
        var _this = this;
        var post_data = {
            date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes() + ':00',
            type: type
        };
        // console.log('deliv-date-after: ' + JSON.stringify(post_data));
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        return this.http.post(this.main_service.getFrontendUrl() + '/LeadTimeRules', JSON.stringify(post_data), httpOptions)
            .toPromise()
            .then(function (data) {
            _this.rules = data.rules;
        }, function (data) {
            _this.rules = 0;
        });
    };
    LeadtimeService.prototype.getByDate = function () {
        return (this.rules === 0) ? 0 : ((this.rules * 60) * 1000);
    };
    LeadtimeService.ngInjectableDef = i0.defineInjectable({ factory: function LeadtimeService_Factory() { return new LeadtimeService(i0.inject(i1.HttpClient), i0.inject(i2.MainService)); }, token: LeadtimeService, providedIn: "root" });
    return LeadtimeService;
}());
export { LeadtimeService };
