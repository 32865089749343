<div class="static-page-layout">
    <ng-container>
      <!--  <div class="row">-->
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="header-wrapper d-flex justify-content-center align-items-center"
                     [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                    <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                        <h1 class="static-page-title">Newsletter</h1>
                    </div>
                </div>
            </div>
        </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <h2 class="mp-font-medium-yellow text-center mb-1">Bestätige deine Anmeldung </h2>
                    <p class="text-left font-wight-bold mb-0">Wir müssen deine E-Mail-Adresse bestätigen, um den Registrierungsprozess abzuschließen.
                        <br>
                        Klicke bitte auf den Link in der E-Mail, die wir dir gerade gesendet haben.

                        <br>Du wirst nicht registriert, wenn du nicht auf den Bestätigungslink klickst.
                    </p>
                </div>
            </div>
       <!-- </div>-->
    </ng-container>


</div>
