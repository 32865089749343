<div class="static-page-layout">
  <ng-container>
    <div class="col-12 p-0 m-0 header-background-img header-wrapper headerBild"
      [style.backgroundImage]="'url(' + headerbild_MP + ')'">
      <h1 class="static-page-title">Öffnungszeiten</h1>
    </div>
    <div>
      <h3 class="mp-font-large-yellow text-center my-5">Öffnungszeiten</h3>

      <table class="col-12 text-left">
        <tr class="row p-0 m-0" *ngFor="let day of openingHours, let index = index">
          <td class="col-12 col-md-8 col-lg-8 m-0 mx-md-auto p-0 ">
            <div class="p-0 m-0 row col-12 text-center">
              <div *ngFor="let item of day" class="col-12 p-0 m-0 mb-2">
                {{item}}
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>


    <!-- <div class="">-->
      <!-- <div class="special-opening-hours" *ngIf="special_opening_hours_array.length >0"> -->
     <!--  <div class="special-opening-hours">
        <h3 class="mp-font-large-yellow text-center my-5">Gesonderte Öffnungszeiten</h3>
        <div *ngFor="let day of special_opening_hours_array, let index = index" class="open-hours">
          <table *ngIf="!day.closed">
            <td class="d-block mr-2">{{ day.special_opening_date | date : 'dd.MM.yyyy':'':'de'
              }}
            </td>
            <td>{{ day.open_from | slice:0:5 }} bis {{ day.open_to | slice:0:5 }}</td>
          </table>
          <table *ngIf="day.closed">
            <td class="d-block mr-2">{{ day.special_opening_date | date : 'dd.MM.yyyy':'':'de'
              }}
            </td>
            <td>geschlossen!</td>
          </table>
        </div>
      </div>
    </div> -->

  </ng-container>

</div>