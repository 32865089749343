<div class="static-page-layout">

    <div class="row">

        <div class="col-md-12 text-center">
            <div class="header-wrapper-small">
                <h1 class="static-page-title">Zusatzstoffe & Allergene</h1>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">

            <div class="center-content">

                <p class="text-center mt-2 mx-auto">Wir haben die Kennzeichnung aller verwendeter Zutaten mit den Zusatzstoffen und
                    enthaltenen
                    Allergenen nach bestem Wissen und Gewissen vorgenommen. Es ist aber nie auszuschließen, dass winzige
                    Mengen von
                    Zusatzstoffen / Allergenen auch bei Nichtkennzeichnung enthalten sein könnten. Solltest du Probleme
                    mit
                    Allergien oder Lebensmittelunverträglichkeiten haben, so besprich dies am besten bei der Bestellung
                    mit unserem
                    Personal ab.</p>

                <div class="row justify-content-center">

                    <div class="col-10 box box-grey">
                        <h2 class="big-yanone text-uppercase text-white">Zusatzstoffe</h2>
                        <div class="row justify-content-between allergen">
                            <ul class="col-lg-6">
                                <li>1 mit Farbstoff</li>
                                <li>2 mit Konservierungsstoff</li>
                                <li>3 mit Antioxydationsmittel</li>
                                <li>4 mit Geschmacksverstärker</li>
                                <li>5 geschwefelt</li>
                                <li>6 geschwärzt</li>
                            </ul>
                            <ul class="col-lg-6">
                                <li>7 gewachst</li>
                                <li>8 mit Phosphat</li>
                                <li>9 mit Süßungsmittel</li>
                                <li>10 Milch- / Sojaeiweiß, Eiklar, Stärke</li>
                                <li>11 koffeinhaltig</li>
                                <li>12 chininhaltig</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-10 box box-grey">
                        <h2 class="big-yanone text-uppercase text-white">Allergene</h2>
                        <div class="row justify-content-between allergen">
                            <ul class="col-lg-5">
                                <li>A Glutenhaltiges Getreide</li>
                                <li>A1 Weizen</li>
                                <li>A2 Roggen</li>
                                <li>A3 Gerste</li>
                                <li>A4 Hafer</li>
                                <li>A5 Dinkel</li>
                                <li>A6 Kamut,</li>
                                <li>B Krebstiere und K.-erzeugnisse</li>
                                <li>C Eier und Eierzeugnisse</li>
                                <li>D Fisch und Fischerzeugnisse</li>
                                <li>E Erdnüsse und Erdnusserzeugnisse</li>
                                <li>F Sojabohnen und Sojaerzeugnisse</li>
                                <li>G Milch und Milcherzeugnisse</li>
                                <li>H Schalenfrüchte und S.-erzeugnisse</li>
                                <li>H1 Mandeln</li>
                            </ul>

                            <ul class="col-lg-5">
                                <li>H2 Haselnüsse</li>
                                <li>H3 Walnüsse</li>
                                <li>H4 Kaschu-/Cashewnüsse</li>
                                <li>H5 Pecannüsse</li>
                                <li>H6 Paranüsse</li>
                                <li>H7 Pistazien,</li>
                                <li>H8 Macademiannüsse</li>
                                <li>H9 Queenslandnüsse</li>
                                <li>I Sellerie und Sellerieerzeugnisse</li>
                                <li>J Senf und Senferzeugnisse</li>
                                <li>K Sesamsamen und S.-erzeugnisse</li>
                                <li>L Schwefeldioxid/Sulfite</li>
                                <li>M Lupinen und L.-erzeugnisse</li>
                                <li>N Weichtiere und W.-erzeugnisse</li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

</div>
