import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { WrapperComponent } from './wrapper-components/wrapper/wrapper.component';
import { WelcomeComponent } from './wrapper-components/welcome/welcome.component';
import { HeaderComponent } from './wrapper-components/1-head/header/header.component';
import { NavigationComponent } from './wrapper-components/1-head/navigation/navigation.component';
import { DashboardComponent } from './wrapper-components/2-content/dashboard/dashboard.component';
import { ArticleListComponent } from './wrapper-components/2-content/list-view/article-list/article-list.component';
import { ArticleDetailComponent } from './wrapper-components/2-content/detail-view/article-detail/article-detail.component';
import { AddIngredientsComponent } from './wrapper-components/2-content/detail-view/add-ingredients/add-ingredients.component';
import { AddFreeIngredientsComponent } from './wrapper-components/2-content/detail-view/add-free-ingredients/add-free-ingredients.component';
import { AddArticleComponent } from './wrapper-components/2-content/detail-view/add-article/add-article.component';
import { TestComponent } from './test/test.component';
import { ServerDownComponent } from './wrapper-components/3-other/server-down/server-down.component';
import { RoutingModule } from './/routing.module';
import { HttpClientModule } from "@angular/common/http";
import { CartPageComponent } from "./wrapper-components/2-content/cart-page/cart-page.component";
import { SpecialOfferComponent } from './wrapper-components/2-content/special-offer/special-offer.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { AdressInputComponent } from './wrapper-components/3-other/adress-input/adress-input.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoginComponent } from './wrapper-components/3-other/login/login.component';

import { AdressPageComponent } from './wrapper-components/2-content/adress-page/adress-page.component';
import { AuthService } from "./services/auth.service";
import { UserComponent } from './wrapper-components/3-other/user/user.component';
import { AuthGuard } from "./services/auth-guard.service";

import { LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material';
import { UserService } from "./services/user.service";
import { FooterComponent } from './wrapper-components/4-footer/footer/footer.component';
import { AgbComponent } from './wrapper-components/4-footer/agb/agb.component';
import { ImpressumComponent } from './wrapper-components/4-footer/impressum/impressum.component';
import { DatenschutzComponent } from './wrapper-components/4-footer/datenschutz/datenschutz.component';
import { AllergeneComponent } from './wrapper-components/4-footer/allergene/allergene.component';
import { UeberunsComponent } from './wrapper-components/4-footer/ueberuns/ueberuns.component';
import { NewsletterComponent } from './wrapper-components/4-footer/newsletter/newsletter.component';
import { JobsComponent } from './wrapper-components/4-footer/jobs/jobs.component';
import { CouponComponent } from './wrapper-components/4-footer/coupon/coupon.component';
import { SystempartnerComponent } from './wrapper-components/4-footer/systempartner/systempartner.component';
import { FeedbackComponent } from './wrapper-components/4-footer/feedback/feedback.component';
import { PizzaPartyMegaDealComponent } from './wrapper-components/2-content/pizza-party-mega-deal/pizza-party-mega-deal.component';
import { MittagsAngebotComponent } from './wrapper-components/2-content/mittags-angebot/mittags-angebot.component';
import { MinOrderValueComponent } from './wrapper-components/2-content/min-order-value/min-order-value.component';
import { AddressInputComponent } from './wrapper-components/2-content/address-input/address-input.component';
import { AmazonPayComponent } from './wrapper-components/2-content/amazon-pay/amazon-pay.component';
import { PageNotFoundComponent } from './wrapper-components/3-other/page-not-found/page-not-found.component';
import { Step1Component } from './wrapper-components/welcome/step-1/step-1.component';
import { Step2Component } from './wrapper-components/welcome/step-2/step-2.component';
import { Step3Component } from './wrapper-components/welcome/step-3/step-3.component';
import { Step4Component } from './wrapper-components/welcome/step-4/step-4.component';
import { Step5Component } from './wrapper-components/welcome/step-5/step-5.component';
import { APP_INITIALIZER } from '@angular/core';

// Import angular-paypal component
import { NgxPayPalModule } from 'ngx-paypal';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MyDateAdapter } from './adapters/DateAdapter';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';

// fix 404 on page reload
// https://stackoverflow.com/questions/35284988/angular-2-404-error-occur-when-i-refresh-through-the-browser
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {DateService} from './services/date.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpStoreInterceptor } from './http.store.interceptor';
import {SliderComponent} from './wrapper-components/elements/slider/slider.component';
import {HeaderElementComponent} from './wrapper-components/elements/header/header.component';
import {CategoriesComponent} from './wrapper-components/elements/categories/categories.component';
import {WelcomeElementComponent} from './wrapper-components/elements/welcome/welcome.component';
import {SafeHtmlPipe} from './saveHtmlPipe';
import {PaymentsService} from './services/payments.service';
import { NewsletterAnmeldungComponent } from './wrapper-components/4-footer/newsletter-anmeldung/newsletter-anmeldung.component';
import { NewsletterErfolgComponent } from './wrapper-components/4-footer/newsletter-erfolg/newsletter-erfolg.component';

import { RestaurantComponent } from './wrapper-components/4-footer/restaurant/restaurant.component';
import { FooterFDComponent } from './wrapper-components/4-footer/footer-fd/footer-fd.component';
import { LiefergebieteComponent } from './wrapper-components/4-footer/liefergebiete/liefergebiete.component';
import { OeffnungszeitenComponent } from './wrapper-components/4-footer/oeffnungszeiten/oeffnungszeiten.component';
import { ZahlungsartenComponent } from './wrapper-components/4-footer/zahlungsarten/zahlungsarten.component';
import { ShopinfosComponent } from './wrapper-components/4-footer/shopinfos/shopinfos.component';
export function appInit(dateService: DateService) {
    console.warn('INIT!');
    return () => dateService.load();
}

@NgModule({
    declarations: [
        AppComponent,
        WrapperComponent,
        WelcomeComponent,
        HeaderComponent,
        NavigationComponent,
        DashboardComponent,
        ArticleListComponent,
        ArticleDetailComponent,
        AddFreeIngredientsComponent,
        AddIngredientsComponent,
        AddArticleComponent,
        TestComponent,
        ServerDownComponent,
        CartPageComponent,
        SpecialOfferComponent,
        // AdressInputComponent,
        LoginComponent,
        AdressPageComponent,
        UserComponent,
        FooterComponent,
        AgbComponent,
        ImpressumComponent,
        DatenschutzComponent,
        AllergeneComponent,
        UeberunsComponent,
        NewsletterComponent,
        JobsComponent,
        SystempartnerComponent,
        FeedbackComponent,
        PizzaPartyMegaDealComponent,
        MittagsAngebotComponent,
        MinOrderValueComponent,
        AddressInputComponent,
        AmazonPayComponent,
        PageNotFoundComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        Step4Component,
        Step5Component,
        SliderComponent,
        HeaderElementComponent,
        CategoriesComponent,
        WelcomeElementComponent,
        SafeHtmlPipe,
        CouponComponent,
        NewsletterAnmeldungComponent,
        NewsletterErfolgComponent,
        RestaurantComponent,
        FooterFDComponent,
        LiefergebieteComponent,
        OeffnungszeitenComponent,
        ZahlungsartenComponent,
        ShopinfosComponent
    ],
    imports: [
        BrowserModule,
        RoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        NgxPayPalModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxPageScrollCoreModule,
        NgxPageScrollModule
    ],
    providers: [
        DateService,
        AuthService,
        UserService,
        PaymentsService,
        AuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            multi: true,
            deps: [DateService]
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpStoreInterceptor,
            multi: true
        }

    ],
    bootstrap: [AppComponent]
})


export class AppModule {
}
