import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allergene',
  templateUrl: './allergene.component.html',
  styleUrls: ['./allergene.component.scss']
})
export class AllergeneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
