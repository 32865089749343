import {Injectable} from '@angular/core';
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {FrontendService} from "./frontend.service";
import {UserService} from "./user.service";
import {Router} from "@angular/router";
import {CartService} from "./cart.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
//intern variable stuff  - - - - - - - - - - -
    private token: string;

    //valid_login
    private valid_login: boolean;
    private valid_login_subject = new ReplaySubject<boolean>(1);
    valid_login_observable = this.valid_login_subject.asObservable();

    private error_message_login_failed: string;
    private error_message_login_failed_subject = new ReplaySubject<string>(1);
    error_message_login_failed_observable = this.error_message_login_failed_subject.asObservable();

    //valid_signup
    private valid_sign_up: boolean;
    private valid_sign_up_subject = new ReplaySubject<boolean>(1);
    valid_sign_up_observable = this.valid_login_subject.asObservable();

    private error_message_signup_failed: string;
    private error_message_signup_failed_subject = new ReplaySubject<string>(1);
    error_message_signup_failed_observable = this.error_message_signup_failed_subject.asObservable();


//constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private user_service: UserService,
        private cart_service: CartService,
        private router: Router,
    ) {

        this.user_service.token_observable.subscribe(
            token => {
                this.token = token;
            }
        )
    }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// public functions
    //creates a new account with mail and pw on firebase
    signupUser(email: string, password: string, titel: string, first_name: string, last_name: string, plz: string, city: string,
               district: string, street: string, hnr: string, floor: string, phone: string) {

    }

    updateEMail(new_mail: string, password: string) {


    }

    updatePassword(old_pw: string, new_pw) {

    }

    //logging the user in
    loginUser(email: string, password: string) {

        this.user_service.clearCurrentUser();


    }

    getToken() {

    }

    //returns true, if user is loged in
    isAuthenticated() {
        return this.token.length > 5;
    }

    logoutUser() {

        this.cart_service.setMinVal('0');
        this.user_service.deleteToken();
        this.user_service.clearCurrentUser();
    }


    resetPassword(email: string) {

    }

    private fetchAdressData() {
        this.frontend_service.fetchAdressData(this.token).subscribe(data => {
            if (data['result'] == null) {
                console.error('invalid token');
                return;
            }
            this.user_service.setUserAdressData(data['zip'], data['city'], data['district'], data['street'], data['house_no']);
            this.user_service.setUserData(data['title'], data['last_name'], data['first_name'], data['no_marketing'] == 1, data['phone_number'], data['e-mail'])
        });
    }

    deleteUser(password: string) {
        // delete user in direct us
        this.frontend_service.deleteUser(this.token).subscribe(
            result => {
                //if response is positive
                if (result == 1) {
                    // delete firebase acc

                    this.user_service.deleteToken();
                    this.user_service.clearCurrentUser();
                    delete this.token;
                }

            })
    }


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// private functions

}
