import { Injectable, OnInit, HostListener, EventEmitter } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { ArticleGroup } from '../../_classes/ArticleGroup';
import { MainService } from './main.service';
import { Article } from '../../_classes/Article';
import { FrontendService } from './frontend.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { forkJoin } from 'rxjs';
import { IngredientList } from 'src/_classes/IngredientList';
import { Router} from '@angular/router';
import { CartService } from './cart.service';

@Injectable({
    providedIn: 'root'
})
export class CouponsService {

    // Definiert, ob Kunde Ware abholt -> true: holt ab
    private coupons = [];
    private cart_items = [];
    private price_total: number = 0;
    private price_cart: number = 0;

    couponRemoved = new EventEmitter<Object>();
    /**
     * Component constructor
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    constructor(
        private frontendService: FrontendService,
        private router: Router,
        private cart_service: CartService
    ) {

        this.cart_service.article_group_list_observable.subscribe(
            (data: any[]) => {
                this.cart_items = data;
            }
        );

        this.cart_service.priceTotalObservable.subscribe(
            (price: number) => {
                this.price_total = price;
            }
        );

        this.cart_service.priceCartObservable.subscribe(
            (price: number) => {
                this.price_cart = price;
            }
        );

        this.coupons = JSON.parse(sessionStorage.getItem('coupons')) || [];

    }

    getCoupons(){

      return this.coupons;

    }

    /**
     *
     * @param coupon_data
     */
    setCoupon(coupon_data) {
        return;
        this.coupons.push(coupon_data);
        sessionStorage.setItem('coupons', JSON.stringify(this.coupons));

    }

    /**
     *
     * @param couponIndex
     */

    removeCoupon(couponIndex){
        return;
        const newCoupons = [];

        for ( const couponItem in this.getCoupons() ){
            if ( couponIndex !==  couponItem) {
                newCoupons.push(this.getCoupons()[couponItem]);
            }
        }

        this.coupons = newCoupons;
        sessionStorage.setItem('coupons', JSON.stringify(this.coupons));
    }

    /**
     *
     */
    checkCouponValidity() {

        return;
        // const valid_coupons = [];

        // for (const cartItemIndex in this.cart_items){

        //     /**
        //      * Coupon-Produkt
        //      */

        //     if ( this.cart_items[cartItemIndex].article.is_coupon_product === 1 ) {

        //         const productCoupon = this.findCouponByArticleId(this.cart_items[cartItemIndex].article.id);
        //         if ( productCoupon ) {

        //             if ( ( this.price_total - this.cart_items[cartItemIndex].prices.real_price_total) >= productCoupon.coupon.min_order_value_on_pickup ) {

        //                 valid_coupons.push(productCoupon.coupon);

        //             } else {

        //                 console.warn('REMOVE COUPON');
        //                 // tslint:disable-next-line: radix
        //                 this.cart_service.decreseAmount( parseInt(cartItemIndex) );
        //                 this.removeCoupon(productCoupon.index);
        //                 this.couponRemoved.emit(productCoupon.coupon);
        //             }
        //         }
        //     }

        // }

        // this.coupons = valid_coupons;
        // sessionStorage.setItem('coupons', JSON.stringify(valid_coupons));

    }

    /**
     *
     *
     * @param article_id
     */
    findCouponByArticleId(article_id){

        for ( const couponItem in this.getCoupons() ){
            if ( this.getCoupons()[couponItem].coupon_product_id === article_id ) {
                return { index: couponItem, coupon: this.getCoupons()[couponItem]};
            }
        }

        return false;

    }

    checkRedeemCount(couponData){

        const max_count = couponData.redeem_count;
        const coupons = this.getCoupons();
        const coupon_counter = [];

        for ( const couponItem in coupons ){
            if (coupon_counter[coupons[couponItem].coupon_product_id] == undefined) {
                coupon_counter[coupons[couponItem].coupon_product_id] = 1;
            } else {
                coupon_counter[coupons[couponItem].coupon_product_id]++;
            }
        }

        if (coupon_counter[couponData.coupon_product_id] == undefined ||
            (
                (coupon_counter[ couponData.coupon_product_id] < max_count) && max_count !== 0) || couponData.redeem_count === 0){
            return true;
        } else {
            return false;
        }

    }


}
