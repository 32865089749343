<div class="static-page-layout">
  <div class="col-md-12">

    <div class="header-background-img header-wrapper"
      style="background: url('assets/img/header/header-about.png'); background-size: cover">
      <h1 class="static-page-title">Liefergebiete</h1>
    </div>
  </div>
  <ng-container>

    <h3 class="mp-font-large-yellow text-center my-5">Liefergebiete</h3>


    <div class="card card-body liefergebite">
      <div class="col-xxl-12">
        <div>
          <ul class="d-flex flex-wrap justify-content-center justify-content-xxl-start list-unstyled mb-0">
            <li *ngFor="let deliveryArea of deliveryAreas">{{ deliveryArea.district }}</li>
          </ul>
        </div>
      </div>
    </div>


  </ng-container>
</div>