<!--<div class="row sortiment" *ngIf="ready">
    <ng-container *ngFor="let category of  data.section, let slideIndex = index">
    <div class="col-sm-6  mb-6" [ngClass]="data.section.length >= 8 ? 'col-xl-3' : 'col-xl-4'">
        <a class="mp-box-link d-block position-relative text-center" routerLink="{{category.url}}">
            <picture><img src="{{image_url}}{{category.image.jpg[0].image}}" class="img-fluid" alt=""></picture>
            <h2>{{category.title}}</h2>
            <button class="mp-btn mp-btn-icon"><svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon></button>
        </a>
    </div>
    </ng-container>
</div>-->


<div class="row sortiment" *ngIf="ready">
    <ng-container *ngFor="let category of  data.section, let slideIndex = index">
        <div class="categorie-element col-12 col-s-6 col-lg-3" >
            <a class="mp-box-link d-block position-relative text-center" routerLink="{{category.url}}">
                <figure>
                    <img src="{{image_url}}{{category.image.jpg[0].image}}" class="img-fluid" alt="">
                    <figcaption class=""><h2 class="categorie-title">{{category.title}}</h2></figcaption>
                </figure>
            </a>
        </div>
    </ng-container>
</div>

