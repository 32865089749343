/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./server-down.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./server-down.component";
var styles_ServerDownComponent = [i0.styles];
var RenderType_ServerDownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServerDownComponent, data: {} });
export { RenderType_ServerDownComponent as RenderType_ServerDownComponent };
export function View_ServerDownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", ""], ["style", "padding: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-center mp-font-large-yellow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ups..."])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h2", [["class", " text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Du scheinst gerade keine Internetverbindung zu haben."])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bitte versuch es doch sp\u00E4ter noch einmal."])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", " text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "mp-btn mp-btn-yellow-on-black"], ["href", "/#/willkommen/start"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erneut verbinden"]))], null, null); }
export function View_ServerDownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-server-down", [], null, null, null, View_ServerDownComponent_0, RenderType_ServerDownComponent)), i1.ɵdid(1, 114688, null, 0, i2.ServerDownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServerDownComponentNgFactory = i1.ɵccf("app-server-down", i2.ServerDownComponent, View_ServerDownComponent_Host_0, {}, {}, []);
export { ServerDownComponentNgFactory as ServerDownComponentNgFactory };
