import {Component, ElementRef, QueryList, OnInit, ViewChildren, HostBinding, AfterViewChecked, Input} from '@angular/core';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../../services/main.service';
declare var jQuery: any;

@Component({
  selector: 'app-element-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewChecked {

    data: any =[];
    image_url;
    carousel_init: boolean = false;
    ready: boolean = false;
    @Input('element') element: string;
    @ViewChildren('carouselElement', {read: ElementRef}) carousel: QueryList<ElementRef>;
  constructor(
      private http: HttpClient,
      private main_service: MainService,

  ) {



  }


  ngOnInit() {


      this.image_url = this.main_service.image_url;
      const url = this.main_service.getFrontendUrl() + '/elementByName/' + this.element;
      this.http.get(url).subscribe(data => {

          this.ready = true;
          this.data = data;

      });

  }

    initCarousel (element) {

        const corousel_interval = 4000;


             jQuery(element).carousel('dispose');
             jQuery(element).carousel({ride: 'carousel', interval: corousel_interval, touch: true});
            this.carousel_init = true;


    }

    ngAfterViewChecked(){


        this.carousel.changes.subscribe((next: QueryList<ElementRef>) => {


         this.initCarousel(next.first.nativeElement);

        });




    }

}


