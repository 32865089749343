<div class="static-page-layout">
    <ng-container>
        <div>
            <div class="col-12 p-0 m-0 header-background-img header-wrapper headerBild"
                 [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                <h1 class="static-page-title">Dein Restaurant</h1>
            </div>
            <div id="restaurant" class="col-md-11 mb-7 mt-5 mb-xxl-0 text-center mx-auto p-0">
                <h1 class="mp-font-large-white text-center">Meisterpizza - Restaurant</h1>
                <p class="mb-3 mt-0">
                    <a href="https://goo.gl/maps/ovZhdFhQnpDX8ndB7" target="blank">

                        Karlsruher Str. 1 &bull; in der Coschützer Höhe &bull; 01189 &bull; Dresden
                    </a>
                </p>
                <!-- <p>
                   <a href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a>
                 </p>-->
                <div class="row my-7 p-0">
                    <img class="img-fluid col-12 col-md-6"
                         src="/assets/img/restaurant/MP-DD_Kategoriebilder_Restaurant_610x380px_03.jpg"
                         alt="restaurant" srcset="">
                    <img class="img-fluid col-12 col-md-6 mt-3 mt-md-0"
                         src="/assets/img/restaurant/MP-DD_Kategoriebilder_Restaurant_610x380px_01.jpg"
                         alt="restaurant" srcset="">
                </div>
                <div class="my-3">
                    <h4 class="mp-font-large-white text-center">
                        Herzlich Willkommen <br> in deinem Meisterpizza-Restaurant
                    </h4>
                    <p class="text">Besuche uns mit deiner Familie oder Freunden und erlebe eine köstliche Zeit mit
                        ihnen. Ob im Restaurant oder auf unserer Sommerterrasse – laden wir dich ein, als unseren Gast
                        zur Feier oder einem genussvollen Abend zu begrüßen.
                        <br> Hast du dich schon heute begeistert? Lass dir freudige und kraftvolle Momente mit unseren
                        Burger, Pizza, Fingerfood bis zum Cocktail oder Bier aus der Flasche und frisch gezapftes
                        Budweiser vom Fass schenken.</p>
                    <div class="row my-3">
                        <img class="img-fluid col-12 col-md-6"
                             src="/assets/img/restaurant/MP-DD_Kategoriebilder_Restaurant_610x380px_04.jpg"
                             alt="restaurant" srcset="">
                        <img class="img-fluid col-12 col-md-6 mt-3 mt-md-0"
                             src="/assets/img/restaurant/MP-DD_Kategoriebilder_Restaurant_610x380px_05.jpg"
                             alt="restaurant" srcset="">
                    </div>
                </div>
                <div class="my-3">
                    <h4 class="mp-font-large-white text-center">
                        Freu dich auf den Genuss <br> in deinem Meisterpizza-Restaurant
                    </h4>
                    <p class=" text">Wir wissen, dass gutes Essen glücklich macht!<br>
                        Deshalb steht für uns die Qualität an erster Stelle. Ob es die ofenfrische Pizza oder saftige
                        Bürger oder das knusprige Fingerfood ist, alles wird frisch und mit größter Sorgfalt für dich
                        zubereitet.</p>
                    <div class="row my-3">
                        <img class="img-fluid col-12 col-md-6"
                             src="/assets/img/restaurant/MP-DD_Kategoriebilder_Restaurant_610x380px_02.jpg"
                             alt="restaurant" srcset="">
                        <img class="img-fluid col-12 col-md-6 mt-3 mt-md-0"
                             src="/assets/img/restaurant/MP-DD_Kategoriebilder_Restaurant_610x380px_06.jpg"
                             alt="restaurant" srcset="">
                    </div>
                </div>

            </div>
        </div>
    </ng-container>
</div>
