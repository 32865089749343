import { Injectable, OnInit, HostListener } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { ArticleGroup } from '../../_classes/ArticleGroup';
import { MainService } from './main.service';
import { Article } from '../../_classes/Article';
import { FrontendService } from './frontend.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { forkJoin } from 'rxjs';
import { IngredientList } from 'src/_classes/IngredientList';
import { Router} from '@angular/router';
import {DateService} from './date.service';
import {LeadtimeService} from './leadtime.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    // Definiert, ob Kunde Ware abholt -> true: holt ab
    private on_pickup = false;
    private on_pickup_subject = new ReplaySubject<boolean>(1);
    on_pickup_observable = this.on_pickup_subject.asObservable();

    /* sets the value (true, false) for delivery now or later */
    private deliveryLater = false;
    private deliveryLaterSubject = new ReplaySubject<boolean>(1);
    deliveryLaterObservable = this.deliveryLaterSubject.asObservable();

    // Definiert, ob Kunde Ware abholt -> true: holt ab
    private on_happy_hour = false;
    private on_happy_hour_subject = new ReplaySubject<boolean>(1);
    on_happy_hour_observable = this.on_happy_hour_subject.asObservable();

    // defines if it is lunch time
    private onLunch = false;
    private onLunchSubject = new ReplaySubject<boolean>(1);
    onLunchObservable = this.onLunchSubject.asObservable();

    /* Sets the shop closed or open */
    private shopClosed = false;
    private shopClosedSubject = new ReplaySubject<boolean>(1);
    shopClosedObservable = this.shopClosedSubject.asObservable();

    private isDelivDateNow = false;
    private isDelivDateNowSubject = new ReplaySubject<boolean>(1);
    isDelivDateNowObservable = this.isDelivDateNowSubject.asObservable();

    /* Sets the shop closed or open if closed until tomorrow */
    private shopClosedUntilTomorrow = false;
    private shopClosedUntilTomorrowSubject = new ReplaySubject<boolean>(1);
    shopClosedUntilTomorrowObservable = this.shopClosedUntilTomorrowSubject.asObservable();

    next_delivery_later_time: any = false;

    /* Set if the order is delivered during lunch time */
    private deliveryOnLunchTime = false;
    private deliveryOnLunchTimeSubject = new ReplaySubject<boolean>(1);
    deliveryOnLunchTimeObservable = this.deliveryOnLunchTimeSubject.asObservable();

    /* Sets the shop closed or open */
    private deliveryDate = this.dateService.newDate();
    private deliveryDateSubject = new ReplaySubject<any>(1);
    deliveryDateObservable = this.deliveryDateSubject.asObservable();

    /* Sets the opening hours for a selected future delivery date */
    private openingHoursDeliveryLater: any;
    private openingHoursDeliveryLaterSubject = new ReplaySubject<any>(1);
    openingHoursDeliveryLaterObservable = this.openingHoursDeliveryLaterSubject.asObservable();

    /* Sets the time values for selection of delviery time */
    private deliveryLaterTimeValues: any;
    private deliveryLaterTimeValuesSubject = new ReplaySubject<any>(1);
    deliveryLaterTimeValuesObservable = this.deliveryLaterTimeValuesSubject.asObservable();

    private noMoreDelivery: any;
    private noMoreDeliverySubject = new ReplaySubject<any>(1);
    noMoreDeliveryObservable = this.noMoreDeliverySubject.asObservable();

    private noMoreDeliveryLunch: any;
    private noMoreDeliveryLunchSubject = new ReplaySubject<any>(1);
    noMoreDeliveryLunchObservable = this.noMoreDeliveryLunchSubject.asObservable();

    private deliveryTime = '';
    private deliveryTimeSubject = new ReplaySubject<string>(1);
    deliveryTimeObservable = this.deliveryTimeSubject.asObservable();

    private sessionStart = this.dateService.newDate();
    private sessionStartSubject = new ReplaySubject<Date>(1);
    sessionStartObservable = this.sessionStartSubject.asObservable();

    private addMinQuantitySurcharge = false;
    private addMinQuantitySurchargeSubject = new ReplaySubject<boolean>(1);
    addMinQuantitySurchargeObservable = this.addMinQuantitySurchargeSubject.asObservable();

    private showPriceDifferencesPopup = false;
    private showPriceDifferencesPopupSubject = new ReplaySubject<boolean>(1);
    showPriceDifferencesPopupObservable = this.showPriceDifferencesPopupSubject.asObservable();

    private showSessionExpiredPopup = false;
    private showSessionExpiredPopupSubject = new ReplaySubject<boolean>(1);
    showSessionExpiredPopupObservable = this.showSessionExpiredPopupSubject.asObservable();

    private relocateDeliveryTimePopup = false;
    private relocateDeliveryTimePopupSubject = new ReplaySubject<boolean>(1);
    relocateDeliveryTimePopupObservable = this.relocateDeliveryTimePopupSubject.asObservable();



    // Warenkorb - beinhaltet alle hinzugefügten Artikel
    private article_group_list: ArticleGroup[] = [];
    private article_group_list_subject = new ReplaySubject<ArticleGroup[]>(1);
    article_group_list_observable = this.article_group_list_subject.asObservable();

    // Anzahl der Artikel im Warenkorm
    private article_amount: number = 0;
    private article_amount_subject = new ReplaySubject<number>(1);
    article_amount_observable = this.article_amount_subject.asObservable();

    /* the overall price of the cart items */
    private priceCart = 0;
    private priceCartSubject = new ReplaySubject<number>(1);
    priceCartObservable = this.priceCartSubject.asObservable();

    private priceCartTemp = 0;
    private priceCartTempSubject = new ReplaySubject<number>(1);
    priceCartTempObservable = this.priceCartTempSubject.asObservable();

    /* the total price of the order */
    private priceTotal = 0;
    private priceTotalSubject = new ReplaySubject<number>(1);
    priceTotalObservable = this.priceTotalSubject.asObservable();

    // Mindermengenaufschlag
    private min_quan_sur = 0;
    private min_quan_sur_subject = new ReplaySubject<number>(1);
    min_quan_sur_observable = this.min_quan_sur_subject.asObservable();

    // Mindestbestellwert
    private min_val = 0;
    private min_val_subject = new ReplaySubject<number>(1);
    min_val_observable = this.min_val_subject.asObservable();

    private tip = 0;
    private tipSubject = new ReplaySubject<number>(1);
    tipObservable = this.tipSubject.asObservable();

    private tipFixedPercentage = -1;
    private tipFixedPercentageSubject = new ReplaySubject<number>(1);
    tipFixedPercentageObservable = this.tipFixedPercentageSubject.asObservable();

    private minValueTemp = 0;

    // index: which article should be edited
    private edit_index: number;

    // bool defines state
    on_edit: boolean = false;

    private shop_closed_countdown = false;
    private marker = true;
    private closedInThisDate = 0;
    private attempt = 0;



    // private tip: number = 0;

    // init coutner for 5 possible sizes
    // TODO: make this dynamic and get actual available sizes from backend
    private megaDealCounter = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        'total': 0,
        'active': false
    };
    private megaDealCounterSubject = new ReplaySubject<Object>(1);
    megaDealCounterObservable = this.megaDealCounterSubject.asObservable();

    private priceModifications = [];
    private priceModificationsSubject = new ReplaySubject<any>(1);
    priceModificationsObservable = this.priceModificationsSubject.asObservable();

    private couponsArr = [];
    private couponsArrSubject = new ReplaySubject<Object>(1);
    couponsArrObservable = this.couponsArrSubject.asObservable();

    mail_control: string;
    deliver_date: any;
    // deliver_time: any;
    // deliveryLater = false;

    private subscriptions: Subscription[] = [];

    /* Get the opening hour of current time */
    openingHoursNow: any;

    storageLoaded = false;
    private storageLoadedSubject = new ReplaySubject<any>(1);
    storageLoadedObservable = this.storageLoadedSubject.asObservable();

    timeValuesCreated = false;
    private timeValuesCreatedSubject = new ReplaySubject<any>(1);
    timeValuesCreatedObservable = this.timeValuesCreatedSubject.asObservable();

    /**
     * Component constructor
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    constructor(
        private frontendService: FrontendService,
        private router: Router,
        private dateService: DateService,
        private leadtimeService: LeadtimeService
    ) {
        this.getDateAndTimes();
        this.priceCartSubject.next(this.priceCart);
        this.priceCartTempSubject.next(this.priceCartTemp);
        this.priceTotalSubject.next(this.priceTotal);
        this.on_pickup_subject.next(this.on_pickup);
        this.deliveryLaterSubject.next(this.deliveryLater);
        this.shopClosedSubject.next(this.shopClosed);
        this.isDelivDateNowSubject.next(this.isDelivDateNow);
        this.shopClosedUntilTomorrowSubject.next(this.shopClosedUntilTomorrow);
        this.deliveryTimeSubject.next(this.deliveryTime);
        this.deliveryDateSubject.next(this.deliveryDate);
        this.deliveryLaterTimeValuesSubject.next(this.deliveryLaterTimeValues);
        // this.deliveryOnLunchTimeSubject.next(this.deliveryOnLunchTime);
        this.on_happy_hour_subject.next(this.on_happy_hour);
        this.onLunchSubject.next(this.onLunch);
        this.article_amount_subject.next(this.article_amount);
        this.min_val_subject.next(this.min_val);
        // this.priceTotalRegularSubject.next(this.priceTotalRegular);
        // this.priceTotalPickupSubject.next(this.priceTotalPickup);
        this.megaDealCounterSubject.next(this.megaDealCounter);
        this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
        this.showSessionExpiredPopupSubject.next(this.showSessionExpiredPopup);
        this.addMinQuantitySurchargeSubject.next(this.addMinQuantitySurcharge);
        this.tipSubject.next(this.tip);

        this.priceModificationsSubject.next(this.priceModifications);


        if (sessionStorage.getItem('cartItems') != 'undefined' && sessionStorage.getItem('cartItems') != null && sessionStorage.getItem('cartItems') != 'null') {
            this.article_group_list = JSON.parse(sessionStorage.getItem('cartItems'));
        }
        this.article_group_list_subject.next(this.article_group_list);

        if (sessionStorage.getItem('coupons') != 'undefined') {
            this.couponsArr =  JSON.parse(sessionStorage.getItem('coupons'));
        }
        this.couponsArrSubject.next(this.couponsArr);

        this.tipFixedPercentageSubject.next(this.tipFixedPercentage);
        this.min_quan_sur_subject.next(this.min_quan_sur);
        this.sessionStartSubject.next(this.sessionStart);
        this.storageLoadedSubject.next(this.storageLoaded);


        window.addEventListener('focus', event => {
            this.preCheck();
        });

        /* save session start time value to storage once */
        if (!sessionStorage.getItem('sessionStart')) {
            sessionStorage.setItem('sessionStart', this.sessionStart.toString());
        }
        /* Make api and service calls and get data - fork join is used to check the completion of alle calls at once */
        forkJoin([
            /* make api calls */
            this.frontendService.getOpeningHoursNow()

        ])
            .subscribe(
                data => {
                    this.distributeServiceDataOnSuccess(data);
                },
                err => console.error(err)
            );

        // this.recalculatePrices();
    }

    getDateAndTimes() {
        if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
            this.deliveryDate = this.dateService.newDate();
            sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
        }
    
        if (!this.deliveryDate || this.deliveryDate.toString() == 'Invalid Date') {
            this.deliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        }

        if (!sessionStorage.getItem('deliveryTime')) {
            if (this.deliveryLaterTimeValues && this.deliveryLaterTimeValues.length > 0) {
                this.deliveryTime = this.deliveryLaterTimeValues[0].time;
            } else {
                this.deliveryTime = this.deliveryDate.toLocaleTimeString().substring(0, 5);
            }
            sessionStorage.setItem('deliveryTime', this.deliveryTime);
            this.deliveryTimeSubject.next(this.deliveryTime);
        }

        if (!this.deliveryTime) {
            this.deliveryTime = sessionStorage.getItem('deliveryTime');
            this.deliveryTimeSubject.next(this.deliveryTime);
        }

        this.deliveryDate.setHours(parseInt(this.deliveryTime.substring(0, 2)));
        this.deliveryDate.setMinutes(parseInt(this.deliveryTime.substring(3, 5)));
        this.deliveryDateSubject.next(this.deliveryDate);
        this.setDeliveryDateAndTime(this.deliveryDate, this.deliveryTime);
    }

    preCheck( ) {
        this.checkSession();
    }

    checkInstore() {
        // const isGoogleBot = navigator.userAgent.includes('Googlebot');
        const check = !!(this.isPickup() || !!(sessionStorage.getItem('tempUser')));
        if ( check === false ) {
            this.router.navigate(['/willkommen/start']);
        } else {

        }
    }

    checkRelocateDeliveryTime() {

        // this.deliveryTime = sessionStorage.getItem('deliveryTime');
        // this.deliveryTimeSubject.next(this.deliveryTime);


        if( this.deliveryLater ) {

            this.createTimeValues(false, true).then(() => {

                /* code moved to createTimeValues() */

            });


        } else {

        }

    }

    closeRelocateDeliveryTimePopup(){

        this.relocateDeliveryTimePopup =  false;
        this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup );

    }



    /**
     * Distribute the service data to the corresponding attributes
     */
    distributeServiceDataOnSuccess(data: any) {

        /* distribute the api call response to the corresponding vars */
        this.openingHoursNow = data[0];

        /* check if shop is open or closed */
        this.checkShopOpenOrClosed().then(() => {
            this.loadCartSessionStorage().then(() => {
                this.loadMinOrderValueSessionStorage();
            });
            this.loadTipSessionStorage()
            /* execute to load all available values from session storage */
            this.loadDeliveryDateAndTimeSessionStorage().then(() => {
                this.loadTipSessionStorage();
                this.loadPickupSessionStorage().then(() => {
                    this.loadDeliveryLaterSessionStorage().then(() => {
                        this.checkLunchTime()/* .then(() => { */;
                            this.recalculatePrices();
                        /* }); */
                    });
                });

            });
        });
    }

    /**
     * Check if the shop is currently open
     */
    checkShopOpenOrClosed() {
        return new Promise<void>((resolve) => {

            const currentDate = this.dateService.newDate();
            const currentTime = currentDate.getHours().toString().padStart(2, '0') + ':' + currentDate.getMinutes().toString().padStart(2, '0') + ':' + currentDate.getSeconds().toString().padStart(2, '0');
            const currentD = this.dateService.newDate();
            currentD.setHours(0,0,0,0);
            const delivD = new Date(this.deliveryDate);
            delivD.setHours(0,0,0,0);

            if (currentD.getTime() == delivD.getTime() && this.openingHoursNow.length > 0) {
                for (const oh of this.openingHoursNow) {
                    if (currentTime >= oh.time_from && currentTime < oh.time_to) {
                        this.shopClosed = false;
                        break;
                    } else {
                        this.shopClosed = true;
                    }
                }
            }

            if (currentD.getTime() == delivD.getTime() && this.openingHoursNow.length > 0) {
                this.isDelivDateNow = !this.shopClosed;
            } else {
                this.isDelivDateNow = false;
            }
            this.isDelivDateNowSubject.next(this.isDelivDateNow);
            /* set true if all time values are lower than current time */
            if (this.shopClosed && currentD.getTime() == delivD.getTime()) {
                for (const oh of this.openingHoursNow) {
                    if (currentTime >= oh.time_to) {
                        this.shopClosedUntilTomorrow = true;
                    } else {
                        this.shopClosedUntilTomorrow = false;
                        break;
                    }
                }
            }

            this.shopClosedUntilTomorrowSubject.next(this.shopClosedUntilTomorrow);
            this.shopClosedSubject.next(this.shopClosed);
            resolve();
        });
    }

    /* check session time and if time has changes lunch time */
    checkSession() {

        if (sessionStorage.getItem('sessionStart')) {
            const sessionStart = new Date(sessionStorage.getItem('sessionStart')).getTime();
            const now = this.dateService.newDate().getTime();
            /* wait 2 hour to reload session window, if the user did not order anything */
            if ((now - sessionStart) > 7200000 ) {
                this.showSessionExpiredPopup = true;
                this.showSessionExpiredPopupSubject.next(this.showSessionExpiredPopup);
            }
        }
    }

    /**
     * Load the cart items which are stored in session storage (eg. when user reloads the page)
     */
    loadCartSessionStorage(coupons = false, checkPriceDiff = false) {
        return new Promise<void>((resolve) => {
            const priceCartTemp = this.priceTotal;
            this.showPriceDifferencesPopup = false;
            this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
            this.storageLoaded = false;
            this.storageLoadedSubject.next(this.storageLoaded);
            if (sessionStorage.getItem('cartItems')) {
                let loadedCartItems :any = [];
                if (sessionStorage.getItem('cartItems') != 'undefined' && sessionStorage.getItem('cartItems') != null && sessionStorage.getItem('cartItems') != 'null') {
                    loadedCartItems = JSON.parse(sessionStorage.getItem('cartItems'));
                }
                let resp;
                let couponsInSession: any;
                couponsInSession = [];
                if (sessionStorage.getItem('coupons') != 'undefined' && sessionStorage.getItem('coupons') != null && sessionStorage.getItem('coupons') != 'null') {
                    couponsInSession = JSON.parse(sessionStorage.getItem('coupons'));
                }
                const tp = sessionStorage.getItem('tip');
                const tpr = sessionStorage.getItem('tipFixedPercentage');
                // this.getDelivDateTime();





                if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
                    this.deliveryDate = this.dateService.newDate();
                    sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
                }
            
                if (!this.deliveryDate || this.deliveryDate.toString() == 'Invalid Date') {
                    this.deliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
                }
        
                if (!sessionStorage.getItem('deliveryTime')) {
                    if (this.deliveryLaterTimeValues.length > 0) {
                        this.deliveryTime = this.deliveryLaterTimeValues[0].time;
                    } else {
                        this.deliveryTime = this.deliveryDate.toLocaleTimeString().substring(0, 5);
                    }
                    sessionStorage.setItem('deliveryTime', this.deliveryTime);
                    this.deliveryTimeSubject.next(this.deliveryTime);
                }
        
                if (!this.deliveryTime) {
                    this.deliveryTime = sessionStorage.getItem('deliveryTime');
                }

                let userData = sessionStorage.getItem('tempUser');

                let userAddressId: any = '';
                if (sessionStorage.getItem('user_address_id')) {
                    userAddressId = sessionStorage.getItem('user_address_id');
                }
        
                this.deliveryDate.setHours(parseInt(this.deliveryTime.substring(0, 2)));
                this.deliveryDate.setMinutes(parseInt(this.deliveryTime.substring(3, 5)));
                this.deliveryDateSubject.next(this.deliveryDate);
                this.frontendService.getCartItems({
                    'article':loadedCartItems, 
                    'deliveryType': this.on_pickup ? 'pickup' : 'delivery', 
                    'deliveryDate': this.deliveryDate.toString(), 
                    'tip': (tp == 'NaN' || tp == 'null' || tp == 'undefined') ? '0' : tp, 
                    'tipPercentage': (tpr == 'NaN' || tpr == 'null' || tpr == 'undefined') ? '0' : tpr, 
                    'minValAdd': this.min_quan_sur, 
                    'minVal': this.min_val, 
                    'minValIsAdded': this.addMinQuantitySurcharge,
                    'couponCode': couponsInSession,
                    'user': userData,
                    'user_address_id': userAddressId})
                .then((data: any) => 
                {
                    resp = data;
                    sessionStorage.setItem('cartItems', JSON.stringify(resp.article));

                    sessionStorage.setItem('tip', resp.tip);
                    this.tip = resp.tip;
                    this.tipSubject.next(this.tip);

                    sessionStorage.setItem('tipFixedPercentage', resp.tipPercentage);
                    this.tipFixedPercentage = resp.tipPercentage;
                    this.tipFixedPercentageSubject.next(this.tipFixedPercentage);

                        if (coupons) {
                            sessionStorage.setItem('coupons', JSON.stringify(resp.couponCode));
                            this.couponsArr = resp.couponCode;
                            this.couponsArrSubject.next(this.couponsArr);
                        } else {
                            if (resp.couponCode.coupon_content == couponsInSession.coupon_content 
                                && resp.couponCode.errorString && resp.couponCode.errorString != '') {
                                    sessionStorage.setItem('coupons', 'null');
                                    this.couponsArr = [];
                                    this.couponsArrSubject.next(this.couponsArr);
                            }
                        }

                    this.article_amount = resp.total_amount;
                    this.article_amount_subject.next(this.article_amount);

                    this.priceTotal = resp.total_sum;
                    this.priceTotalSubject.next(this.priceTotal);

                    this.priceModifications = resp.modifications;
                    this.priceModificationsSubject.next(this.priceModifications);
                    
                    loadedCartItems = resp.article;
                    this.article_group_list = [];
                    for (const cartItem of loadedCartItems) {
                        let product_coupon = '';
                        if (cartItem.product_coupon) {
                            product_coupon = cartItem.product_coupon;
                        }
                        let onlyCoupon = 0;
                        if (cartItem.onlyCoupon) {
                            onlyCoupon = cartItem.onlyCoupon;
                        }
                        const article_group: ArticleGroup = new ArticleGroup(cartItem.article, cartItem.prices, cartItem.size_index, cartItem.article.category_id, cartItem.added_ingredients, product_coupon, onlyCoupon);
                        for (let i = 0; i < cartItem.amount; i++) {
                            this.addNewSingleArticleGroup(article_group);
                        }
                    }
                    this.article_group_list_subject.next(this.article_group_list);



                    this.min_quan_sur = resp.minValAdd <= 0 ? 0 : resp.minValAdd;
                    this.min_quan_sur_subject.next(this.min_quan_sur);

                    this.priceCart = resp.base_sum;
                    this.priceCartSubject.next(this.priceCart);

                    if( this.showPriceDifferencesPopup === false && checkPriceDiff) {
                        if (priceCartTemp !== 0) {
        
                            if (priceCartTemp !== this.priceTotal) {
                                this.priceCartTemp = priceCartTemp;
                                this.priceCartTempSubject.next(this.priceCartTemp);
                                this.showPriceDifferencesPopup = true;
                                this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
                            }
                        }
                    }

                    this.storageLoaded = true;
                    this.storageLoadedSubject.next(this.storageLoaded);

                    resolve();
                });
            } else {
                this.storageLoaded = true;
                this.storageLoadedSubject.next(this.storageLoaded);
                resolve();
            }
        });
    }

    /**
     * Load the minimum order value which is stored in session storage (eg. when user reloads the page)
     */
    loadMinOrderValueSessionStorage() {
        return new Promise((resolve) => {
            if (sessionStorage.getItem('minOrderValue')) {
                const loadedMinOrderValue = JSON.parse(sessionStorage.getItem('minOrderValue'));
                this.setMinVal(loadedMinOrderValue);
            }
            resolve();
        });
    }

    /**
     * Load the values for pickup or delivery from session storage
     */
    loadPickupSessionStorage() {
        return new Promise((resolve) => {
            if (sessionStorage.getItem('pickup')) {
                const loadedPickup = JSON.parse(sessionStorage.getItem('pickup'));
                this.setPickup(loadedPickup);
            }
            resolve();
        });
    }

    /**
     * Load the value for delivery later - determines if the user wishes to postpone the order
     */
    loadDeliveryLaterSessionStorage() {
        return new Promise((resolve) => {
            if (sessionStorage.getItem('deliveryLater')) {
                const loadedDeliveryLater = JSON.parse(sessionStorage.getItem('deliveryLater')) == 1 ? true : false;
                this.setDeliveryLater(loadedDeliveryLater);
            }
            resolve();
        });
    }

    /**
     * Load the given delivery date
     */
    loadDeliveryDateAndTimeSessionStorage() {
        return new Promise((resolve) => {
            if (sessionStorage.getItem('deliveryDate') && sessionStorage.getItem('deliveryTime') && sessionStorage.getItem('deliveryDate') != 'Invalid Date') {
                const loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
                const loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
                this.setDeliveryDateAndTime(loadedDeliveryDate, loadedDeliveryTime).then(() => {
                    resolve();
                });
                // this.recalculatePrices();
            } else if (!sessionStorage.getItem('deliveryDate') || (sessionStorage.getItem('deliveryDate') && sessionStorage.getItem('deliveryDate') == 'Invalid Date')) {
                const loadedDeliveryDate = this.dateService.newDate();
                var loadedDeliveryTime;
                if (!sessionStorage.getItem('deliveryTime')) {
                    loadedDeliveryTime = loadedDeliveryDate.toLocaleTimeString().substring(0, 5);
                } else {
                    loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
                }
                this.setDeliveryDateAndTime(loadedDeliveryDate, loadedDeliveryTime).then(() => {
                    resolve();
                });
            }
                resolve();
        });
    }

    /**
     * Load the given tip
     */
    loadTipSessionStorage() {
        return new Promise((resolve) => {

            if (sessionStorage.getItem('tip')) {

                const tipFixedPercentage = parseFloat(sessionStorage.getItem('tipFixedPercentage'));
                if (parseFloat(sessionStorage.getItem('tipFixedPercentage')) > 0) {
                    this.setTipFixedPercentage(tipFixedPercentage);
                    this.calcTipPercentage(tipFixedPercentage);
                } else {
                    this.setTipFixedPercentage(0);
                    this.setTip(parseFloat(sessionStorage.getItem('tip')));
                }
            }
            resolve();
        });
    }

    loadIngredientList(productSubCategoryId: number) {
        const ingredientList = this.frontendService.getIngListBySubCat(productSubCategoryId);
    }

    checkCouponProductInCart(couponData){

        if(sessionStorage.getItem('cartItems')){
            const cart_item =  JSON.parse(sessionStorage.getItem('cartItems'));
            var tovs = [];
            if( typeof cart_item === 'object' && cart_item.length > 0){
                for(const item of cart_item){
                    tovs[item.article.id] = tovs[item.article.id] > 0 ? tovs[item.article.id] + item.amount : item.amount;
                    if (item.onlyCoupon && item.onlyCoupon === '0') {
                        item.onlyCoupon = 0;
                    }
                    if((item.article.is_coupon_product && couponData.only_coupon_in_cart) || item.onlyCoupon/*  && item.article.id === couponData.product.id */) {
                        return true;
                    }
                }
                if (couponData.product && tovs[couponData.product.id] && tovs[couponData.product.id] >= couponData.redeem_count) {
                    return true;
                }
            }
        }


        return false;
    }

    /**
     * Calculate the party pizza mega deal - if user has 10 or more pizzas on cart the prices changes accordingly
     */
    calcMegaDeal() {
        if (!this.onLunch) {
            if (this.megaDealCounter.total > 9) {
                this.megaDealCounter.active = true;
            } else {
                this.megaDealCounter.active = false;
            }
        } else {
            /* FEATURE: check if size "32er" has 10 or more items when lunch time */
            if (this.megaDealCounter[1] > 9) {
                this.megaDealCounter.active = true;
            } else {
                this.megaDealCounter.active = false;
            }
        }

    }

    /**
     * Calculate the tip value by percentage
     * @param percentage The given tip percentage
     */
    calcTipPercentage(percentage: any = false) {
        if( percentage === false  && sessionStorage.getItem('tipFixedPercentage')){

            this.tip = parseFloat((this.priceCart * parseFloat(sessionStorage.getItem('tipFixedPercentage'))).toFixed(2));
            this.setTip(this.tip);
        }else {

            this.tip = parseFloat((this.priceCart * percentage).toFixed(2));
            this.setTip(this.tip);
        }
    }

    // calcTipPercentage(eventValue: string) {
    //     let tip = parseFloat((this.price_sum * parseFloat(eventValue)).toFixed(2));
    //     this.tipFormGroup.get('tipValue').setValue(tip);
    //     this.cart_service.setTip(tip);
    //     sessionStorage.setItem('tipFixedPercentage', this.tipFormGroup.get('tipFixedPercentage').value);
    // }

    /**
     * Calculate the current minimum order value for the given zip and district
     * @param plz The given zip code
     * @param district The given district
     */
    calcMinVal(plz, district) {
        this.frontendService.getMinVal(plz, district).subscribe(
            (data: string) => {
                this.setMinVal(data);
            }
        );
    }

    /**
     * Set true or false - shop is open or not
     */
    setShopClosed(shopClosed: boolean) {
        this.shopClosed = shopClosed;
        this.shopClosedSubject.next(this.shopClosed);
    }

    /**
     * Set shop is currently having happy hour
     * @param state Define true or false
     */
    setHappyHour(state: boolean) {
        this.on_happy_hour = state;
        this.on_happy_hour_subject.next(this.on_happy_hour);
    }

    /**
     * Set if the shop currently has lunch time
     * @param state Define true or false
     */
    setLunch(state: boolean) {
        this.onLunch = state;
        this.onLunchSubject.next(this.onLunch);
    }

    /**
     * Sets the global value for if the user would have items delivered or wishes to pick them up
     * @param value Set value for delivery or pickup
     */
    setPickup(value: boolean) {
        this.on_pickup = value;
        this.on_pickup_subject.next(this.on_pickup);
        if (value) {
            sessionStorage.removeItem('minOrderValue');
            this.setMinVal('0');
        }
    }

    isPickup() {
        return this.on_pickup;
    }

    /**
     * Set new cart items array after altering in some way
     */
    setCartItems(cartItems: any) {
        return new Promise((resolve) => {
            this.article_group_list = cartItems;
            this.article_group_list_subject.next(this.article_group_list);
            sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
            resolve();
        });
    }

    /**
     * Sets the global value for if the user would have items delivered or wishes to pick them up
     * @param value Set value for delivery or pickup
     */
    setDeliveryLater(value: boolean) {
        this.deliveryLater = value;
        this.deliveryLaterSubject.next(this.deliveryLater);
    }

    /**
     * Set the current delivery date
     * @param value The date object
     */
    setDeliveryDateAndTime(date: Date, time: string) {
        return new Promise<void>((resolve) => {
            /* if shop has closed until end of day, overwrite date to tomorrow */
            const today: Date = this.dateService.newDate();
            today.setHours(0,0,0,0);

            const selDate: Date = new Date(date.getTime());
            selDate.setHours(0,0,0,0);
            
            if (this.shopClosedUntilTomorrow && today.getTime() == selDate.getTime()) {
                const tomorrow = this.dateService.newDate();
                tomorrow.setDate(this.dateService.newDate().getDate() + 1);
                date = tomorrow;
            }
            this.deliveryTime = time;
            this.deliveryDate = date;
            this.deliveryDate.setHours(parseInt(time.toString().substring(0, 2)));
            this.deliveryDate.setMinutes(parseInt(time.toString().substring(3, 5)));
            this.deliveryDateSubject.next(this.deliveryDate);
            this.deliveryTimeSubject.next(this.deliveryTime);


            sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
            sessionStorage.setItem('deliveryTime', this.deliveryTime.toString());
            /* temp values for the date request */
            const send_date = { 'year': date.getFullYear(), 'month': date.getMonth() + 1, 'day': date.getDate() };
            const query = send_date.year + '-' + send_date.month + '-' + send_date.day;
            /* request the opening hours for the given date, and check if its lunch time, then create the array for the time vlues to choose from */
            this.frontendService.onGetOpeningHoursByDate(query).then((data) => {
                this.openingHoursNow = data;
                this.closedInThisDate = data[0].closedInThisDate;
                if (data[0].closedInThisDate == 1) {
                    this.openingHoursDeliveryLater = [];
                } else {
                    this.openingHoursDeliveryLater = data;
                }
                this.checkShopOpenOrClosed();
                this.createTimeValues(true).then(() => {
                    /* code moved to createTimeValues() */
                });
                
            });
            resolve();
        });
    }

    resetDeliveryDateTime() {

        this.deliveryTime = '';
        this.deliveryTimeSubject.next(this.deliveryTime);
        sessionStorage.setItem('deliveryTime', this.deliveryTime);

    }

    /**
     * Predefine object for available time values according to current or selected delivery date
     */
    createTimeValues(extraCalc = false, extraCalcReloc = false) {
        return new Promise((resolve) => {
            if (!this.deliveryLaterTimeValues) {
                this.deliveryLaterTimeValues = [];
            }
            const typeDeliv = (sessionStorage.getItem('pickup') === '1' ) ? 'pickup' : 'delivery';
            const dateArr = { 'year': this.deliveryDate.getFullYear(), 'month': this.deliveryDate.getMonth() + 1, 'day': this.deliveryDate.getDate() };
            const delivDate = dateArr.day + '-' + dateArr.month + '-' + dateArr.year;

            this.frontendService.getOpeningHoursArr(typeDeliv, delivDate).then((data) => {
                if (data['times']) {
                    this.deliveryLaterTimeValues = data['times'];
                    for (let i=0; i < this.deliveryLaterTimeValues.length; i++) {
                        if (this.deliveryLaterTimeValues[i].time == this.deliveryTime) {
                            if (this.deliveryLaterTimeValues[i].label.includes('chnellstmöglich')) {
                                sessionStorage.setItem('Schnell', '1');
                                sessionStorage.setItem('Schnell_text', this.deliveryLaterTimeValues[i].label);
                            } else {
                                sessionStorage.setItem('Schnell', '0');
                                sessionStorage.setItem('Schnell_text', '');
                            }
            
                        }
                    }
                } else {
                    this.deliveryLaterTimeValues = [];
                }
                this.deliveryLaterTimeValuesSubject.next(this.deliveryLaterTimeValues);
                
                this.noMoreDelivery = data['noMoreDelivery'] ? data['noMoreDelivery'] : false;
                this.noMoreDeliverySubject.next(this.noMoreDelivery);

                this.noMoreDeliveryLunch = data['noMoreDeliveryLunch'] ? data['noMoreDeliveryLunch'] : false;
                this.noMoreDeliveryLunchSubject.next(this.noMoreDeliveryLunch);

                if (extraCalc) {
                    if ( this.deliveryLaterTimeValues.length > 0) {
                        if (!sessionStorage.getItem('deliveryTime')) {
                            this.deliveryTime = this.deliveryLaterTimeValues[0].time;
                            sessionStorage.setItem('deliveryTime', this.deliveryTime);
                            this.deliveryTimeSubject.next(this.deliveryTime);
                        }

                        /* save to session storage */
                        sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
                        let available_time: any = null;
                        this.deliveryLaterTimeValues.forEach( time => {
                            if ( time.time === this.deliveryTime ) {
                                available_time = this.deliveryTime;
                            }
                        });
                        if (!sessionStorage.getItem('sofortAfterPopapClick')) {
                            this.deliveryTime = (available_time) ? available_time : this.deliveryLaterTimeValues[0].time;
                            this.deliveryTimeSubject.next(this.deliveryTime);
                            sessionStorage.setItem('deliveryTime', this.deliveryTime);
                        }
                        this.checkShopOpenOrClosed();
                        this.checkLunchTime()/* .then(() => { */;
                            this.alterCartItemFreeAddables().then(() => {
                                this.recalculatePrices().then(() => {
                                    resolve();
                                });
                            });
                        /* }); */
                    } else {
                        var loadedDeliveryDate = new Date();
                        this.deliveryTime = loadedDeliveryDate.getHours().toString().padStart(2, '0') + ':' + loadedDeliveryDate.getMinutes().toString().padStart(2, '0');
                        sessionStorage.setItem('deliveryTime', this.deliveryTime);
                        this.deliveryTimeSubject.next(this.deliveryTime);
                    }
                }

                if (extraCalcReloc) {
                    let available_time_reloc:any = null;
                    if( this.deliveryLaterTimeValues.length > 0) {

                        this.deliveryLaterTimeValues.forEach(time => {
                            if (time.time === this.deliveryTime) {
                                available_time_reloc = this.deliveryTime;
                            }
                        });

                        if (null === available_time_reloc) {

                            const prevDeliveryTimeObject = this.dateService.newDate();
                            // tslint:disable-next-line: radix
                            prevDeliveryTimeObject.setHours(parseInt(this.deliveryTime.toString().substring(0, 2)));
                            // tslint:disable-next-line: radix
                            prevDeliveryTimeObject.setMinutes(parseInt(this.deliveryTime.toString().substring(3, 5)));

                            const DeliveryTimeObject = this.dateService.newDate();
                            DeliveryTimeObject.setHours(this.deliveryLaterTimeValues[0].time.substring(0, 2));
                            DeliveryTimeObject.setMinutes(this.deliveryLaterTimeValues[0].time.substring(3, 5));

                            let openHourIndex: any = null;
                            let prevOpenHourIndex: any = null;

                            for (let index = 0; index < this.openingHoursDeliveryLater.length; index++) {

                                const open_from = this.dateService.newDate();
                                // tslint:disable-next-line: radix
                                open_from.setHours(parseInt(this.openingHoursDeliveryLater[index].time_from.substring(0, 2)));
                                // tslint:disable-next-line: radix
                                open_from.setMinutes(parseInt(this.openingHoursDeliveryLater[index].time_from.substring(3, 5)));

                                const open_to = this.dateService.newDate();
                                // tslint:disable-next-line: radix
                                open_to.setHours(parseInt(this.openingHoursDeliveryLater[index].time_to.substring(0, 2)));
                                // tslint:disable-next-line: radix
                                open_to.setMinutes(parseInt(this.openingHoursDeliveryLater[index].time_to.substring(3, 5)));

                                if (prevDeliveryTimeObject >= open_from && prevDeliveryTimeObject <= open_to) {
                                    prevOpenHourIndex = index;
                                }

                                if (DeliveryTimeObject >= open_from && DeliveryTimeObject <= open_to) {
                                    openHourIndex = index;
                                }

                            }
                            const andOfDay = this.dateService.newDate();
                            andOfDay.setHours(23, 59, 59, 59);

                            if (andOfDay > this.deliveryDate) {
                                if( prevOpenHourIndex !== openHourIndex ){
                                    this.setDeliveryLater(false);
                                    sessionStorage.setItem('deliveryLater', '0');
                                    this.relocateDeliveryTimePopup = true;
                                    this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup);
                                }else{
                                    this.deliveryTime = this.deliveryLaterTimeValues[0].time;
                                    this.deliveryTimeSubject.next(this.deliveryTime);
                                    sessionStorage.setItem('deliveryTime', this.deliveryTime);
                                    this.relocateDeliveryTimePopup = true;
                                    this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup);

                                }
                            }

                        } else {

                        }
                    } else {
                        const andOfDay = this.dateService.newDate();
                        andOfDay.setHours(23, 59, 59, 59);
                        if (andOfDay > this.deliveryDate) {
                            this.setDeliveryLater(false);
                            sessionStorage.setItem('deliveryLater', '0');
                            this.relocateDeliveryTimePopup = true;
                            this.relocateDeliveryTimePopupSubject.next(this.relocateDeliveryTimePopup);
                        }
                    }
                }
                this.timeValuesCreated = true;
                this.timeValuesCreatedSubject.next(this.timeValuesCreated);
                resolve();
            });
            resolve();
        });
    }

    /**
     * Helper function: Sort array of objects by given key
     */
    compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    /**
      * Check if the selected time and date interects with lunch time
      */
    checkLunchTime() {

        // return new Promise((resolve) => {
            /* preset the lunch time to false, for component and global service */
            this.deliveryOnLunchTime = false;
            this.setLunch(false);

            const now = this.dateService.newDate();
            let delivery_time: any = null;
            if ( this.deliveryLater === false ) {
                delivery_time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
            } else {
                delivery_time = this.deliveryTime+':00';
            }

            /* check if the chosen time is at lunch time (mainly for price benefits) */
            if (this.openingHoursDeliveryLater && this.openingHoursDeliveryLater[0] && this.openingHoursDeliveryLater[0].lunch) {
                if (this.openingHoursDeliveryLater[0].lunch.time_from && this.openingHoursDeliveryLater[0].lunch.time_to) {

                    if ( delivery_time >= this.openingHoursDeliveryLater[0].lunch.time_from && delivery_time <= this.openingHoursDeliveryLater[0].lunch.time_to) {
                        // this.deliveryOnLunchTime = true;
                        // this.deliveryOnLunchTimeSubject.next(this.deliveryOnLunchTime);
                        this.setLunch(true);
                    }
                }
            }

            if (this.openingHoursDeliveryLater && this.openingHoursDeliveryLater[1] && this.openingHoursDeliveryLater[1].lunch) {
                if (this.openingHoursDeliveryLater[1].lunch.time_from && this.openingHoursDeliveryLater[1].lunch.time_to) {
                    if (delivery_time >= this.openingHoursDeliveryLater[1].lunch.time_from && delivery_time<= this.openingHoursDeliveryLater[1].lunch.time_to) {
                        // this.deliveryOnLunchTime = true;
                        // this.deliveryOnLunchTimeSubject.next(this.deliveryOnLunchTime);
                        this.setLunch(true);
                    }
                }
            }
            /* Resolve the promise at the end */
        //     resolve();
        // });
    }

    alterCartItemFreeAddables() {
        return new Promise((resolve) => {
            /* correct the amount fo free addables regarding to the new delivery time (at lunch or not at lunch) */
            if (this.onLunch) {
                /* add free addables until max addables reached */
                for (let itemIndex = 0; itemIndex < this.article_group_list.length; itemIndex++) {
                    const item = this.article_group_list[itemIndex];
                    if (item.added_ingredients.length > 0) {
                        for (let ingIndex = 0; ingIndex < item.added_ingredients.length; ingIndex++) {
                            const ingredient = item.added_ingredients[ingIndex];
                            const priceNode = ingredient.ingredients.price[item.size_index];
                            if (priceNode.only_at_lunch && priceNode.is_free_addable) {
                                for (let amountIndex = 0; amountIndex < ingredient.amount; amountIndex++) {
                                    if (ingredient.free_amount < ingredient.max_free_addable) {

                                        this.article_group_list[itemIndex].added_ingredients[ingIndex].free_amount++;
                                    }
                                }
                            }
                        }
                    } else {
                        if (!item.article.price[item.size_index].free_addables_reached) {
                            // let max_free_addable = 0;
                            // if (this.ingredient_list[ingredientGroupIndex].sizes_free_addables[this.current_size]) {
                            //     max_free_addable = this.ingredient_list[ingredientGroupIndex].sizes_free_addables[this.current_size].free_addable_amount;
                            // }
                            this.frontendService.getIngredientsByProductSubCategory(item.sub_cat).then((data) => {
                                const ingredientList = data;
                                for (const key in ingredientList) {
                                    if (ingredientList.hasOwnProperty(key)) {
                                        const ingCatIndex = parseInt(key, 10);
                                        const element = ingredientList[key];
                                        let max_addable = 1;
                                        if (element.max_addable) {
                                            max_addable = element.max_addable;
                                        }
                                        for (let ingIndex = 0; ingIndex < element.ingredients.length; ingIndex++) {
                                            const ingredient = element.ingredients[ingIndex];
                                            // TODO: fix the amount of max_free_addable when creating IngredientList (currently 1, but should depend on actual setting)
                                            if (ingredient.id === item.article.price[item.size_index].default_free_addable_id) {
                                                item.added_ingredients.push(new IngredientList(ingredient, ingCatIndex, ingIndex, true, 1, max_addable));
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            } else {
                /* remove free addables until amount is 0 */
                for (let itemIndex = 0; itemIndex < this.article_group_list.length; itemIndex++) {
                    const item = this.article_group_list[itemIndex];
                    for (let ingIndex = 0; ingIndex < item.added_ingredients.length; ingIndex++) {
                        const ingredient = item.added_ingredients[ingIndex];
                        const priceNode = ingredient.ingredients.price[item.size_index];
                        if (priceNode.only_at_lunch && priceNode.is_free_addable) {
                            for (let amountIndex = 0; amountIndex < ingredient.amount; amountIndex++) {
                                if (ingredient.free_amount > 0) {

                                    this.article_group_list[itemIndex].added_ingredients[ingIndex].free_amount--;
                                }
                            }
                        }
                    }
                }
            }

            this.setCartItems(this.article_group_list).then(() => {
                // this.recalculatePrices();
                resolve();
            });
        });
    }

    /**
     * Set the value for addMinQuantitySurcharge from cart page (if the user added the surcharge or not)
     * @param value Value for "addMinQuantitySurcharge"
     */
    setAddMinQuantitySurcharge(value: boolean) {
        this.addMinQuantitySurcharge = value;
        this.addMinQuantitySurchargeSubject.next(this.addMinQuantitySurcharge);
    }
    /**
     * Set the current minimum order value
     * @param min_val The minimum order value
     */
    setMinVal(min_val: string) {
        if (this.on_pickup) {
            sessionStorage.removeItem('minOrderValue');
            this.min_val = 0;
            this.min_val_subject.next(this.min_val);
        } else {
            this.min_val = +min_val;
            // this.min_quan_sur = (this.min_val - this.priceCart) < 0 ? 0 : (this.min_val - this.priceCart);
            this.min_val_subject.next(this.min_val);
            // this.min_quan_sur_subject.next(this.min_quan_sur);

            // set temp min value for togglePickup switch
            this.minValueTemp = this.min_val;

            sessionStorage.setItem('minOrderValue', JSON.stringify(this.min_val));
        }
        this.recalculatePrices();
    }

    /**
     * Set the index of the current product which will be edited after already in cart
     * @param index The index in the cart items array
     */
    setEditIndex(index: number) {
        this.edit_index = index;
        this.on_edit = true;
    }

    /**
     * Add a new product to the current items in cart
     */
    addNewSingleArticleGroup(new_article_group: ArticleGroup) {

        let create_new: boolean = true;
        // for (let i = 0; i < this.article_group_list.length; i++) {
        //     if (this.article_group_list[i].article.id == new_article_group.article.id
        //         && this.article_group_list[i].size_index == new_article_group.size_index
        //         && JSON.stringify(this.article_group_list[i].added_ingredients) === JSON.stringify(new_article_group.added_ingredients)) {

        //         create_new = false;
        //         this.article_group_list[i].increaseAmount();
        //         break;
        //     }
        // }
        if (create_new) {

            this.article_group_list.push(new_article_group);
        }

        this.article_group_list_subject.next(this.article_group_list);

        // update pizza mega deal
        /**
         * @hardcoded
         */
        if (new_article_group.article.category_id === 39 || new_article_group.article.category_id === 196) {
            this.megaDealCounter[new_article_group.size_index]++;
            this.megaDealCounter.total++;
            this.megaDealCounterSubject.next(this.megaDealCounter);

        }

        /* put items in session storage */
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        /* recalculate order */
        // this.recalculatePrices();
    }

    /**
     * Increment the amount of products in cart
     * @param index The index of the cart items array
     */
    increaseAmount(index: number) {
        // update pizza mega deal
        /**
         * @hardcoded
         */
        // if (this.article_group_list[index] && (this.article_group_list[index].article.category_id === 39 || this.article_group_list[index].article.category_id === 196)) {
        //     this.megaDealCounter[this.article_group_list[index].size_index]++;
        //     this.megaDealCounter.total++;
        //     this.megaDealCounterSubject.next(this.megaDealCounter);

        // }

        this.article_group_list[index].increaseAmount();
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        // this.loadCartSessionStorage();

        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);
        // update session storage
    }

    increaseAmountDoubled(index: number) {
        let tempGroup = JSON.parse(JSON.stringify( this.article_group_list[index]));
        this.addNewSingleArticleGroup(tempGroup);
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        // this.loadCartSessionStorage();

        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);
    }
    
    /**
     * Decrement the amount of products in cart
     * @param index The index of the cart items array
     */
    decreseAmount(index: number) {
        // update pizza mega deal
        /**
         * @hardcoded
         */
        // if (this.article_group_list[index] && (this.article_group_list[index].article.category_id === 39 || this.article_group_list[index].article.category_id === 196)) {
        //     this.megaDealCounter[this.article_group_list[index].size_index]--;
        //     this.megaDealCounter.total--;
        //     this.megaDealCounterSubject.next(this.megaDealCounter);

        // }

        if (this.article_group_list[index].amount <= 1) {
            delete this.article_group_list[index];
            this.article_group_list = this.resetIndexes(this.article_group_list);
        } else {
            this.article_group_list[index].decreaseAmount();
        }

        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        // this.loadCartSessionStorage();

        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);

    }

    getEditArticle() {
        return JSON.parse(JSON.stringify(this.article_group_list[this.edit_index]));
    }

    submitEditArticle(price, current_size, added_ing, amountArt) {
        this.article_group_list[this.edit_index].prices = price;
        this.article_group_list[this.edit_index].size_index = current_size;
        this.article_group_list[this.edit_index].added_ingredients = added_ing;
        this.article_group_list[this.edit_index].amount = amountArt;

        // this.recalculatePrices();
        this.resetEditStat();

        // update session storage
        sessionStorage.setItem('cartItems', JSON.stringify(this.article_group_list));
        this.recalculatePrices();
    }

    resetEditStat() {
        this.on_edit = false;
    }

    /**
     * The the current tip value
     * @param new_tip The tip value
     */
    setTip(new_tip: number) {
        this.tip = new_tip;
        this.tipSubject.next(this.tip);
        sessionStorage.setItem('tip', this.tip.toString());
        this.recalculatePrices();
        this.article_group_list_subject.next(this.article_group_list);

    }

    /**
     * The the current tip value
     * @param new_tip The tip value
     */
    setTipFixedPercentage(percentage: number) {
        this.tipFixedPercentage = percentage;
        this.tipFixedPercentageSubject.next(this.tipFixedPercentage);
    }

    /**
     * Get the current tip value
     */
    getTip() {
        return this.tip;
    }

    resetIndexes(array: any[]) {
        const temp: any[] = [];
        for (const item of array) {
            if (item != undefined) {
                temp.push(item);
            }
        }
        return temp;
    }

    /**
     * Recalculate prices of the current order
     */
    recalculatePrices(checkPriceDiff = false) {


        return new Promise((resolve) => {
            // const priceCartTemp = this.priceCart;
            // this.showPriceDifferencesPopup = false;
            // this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
            /* calculate if mega deal is active */
            // this.calcMegaDeal();

            let amount = 0;
            let price = 0;
            let price_item = 0;

            this.loadCartSessionStorage(false, checkPriceDiff);



            // for (let i = 0; i < this.article_group_list.length; i++) {
            //     const temp_group = this.article_group_list[i];
            //     let ing_price = 0;
            //     let ing_price_for_free_addables = 0;

            //     amount = amount + temp_group.amount;

            //     /* calculate free addabales and ingredients */
            //     for (let i = 0; i < temp_group.added_ingredients.length; i++) {
            //         temp_group.added_ingredients[i].ingredients['real_price'] = temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].price;
            //         const ing_price_single = (temp_group.added_ingredients[i].amount - temp_group.added_ingredients[i].free_amount) * temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].price;
            //         temp_group.added_ingredients[i].ingredients['real_price_total'] = ing_price_single;
            //         ing_price += ing_price_single;
            //     }
            //     for (let i = 0; i < temp_group.added_ingredients.length; i++) {
            //         if (this.onLunch && temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].is_free_addable) {
            //             ing_price_for_free_addables += 0;
            //         } else {
            //             ing_price_for_free_addables += temp_group.added_ingredients[i].free_amount * temp_group.added_ingredients[i].ingredients['price'][temp_group.size_index].price;
            //         }
            //     }
            //     temp_group.prices.price_for_added_ingredients = ing_price;
            //     temp_group.prices.price_for_added_free_addables = ing_price_for_free_addables;

            //     if (
            //         (
            //         this.megaDealCounter.active &&
            //         temp_group.prices.mega_deal_price > 0 &&
            //         (temp_group.article.category_id === 39 || temp_group.article.category_id === 196) &&
            //         !this.onLunch
            //         )
            //         ||
            //             (
            //                 this.megaDealCounter.active &&
            //                 temp_group.prices.mega_deal_price > 0 &&
            //                 (temp_group.article.category_id === 39 || temp_group.article.category_id === 196) &&
            //                 this.onLunch &&
            //                 temp_group.size_index === 1
            //             )
            //     ) {
            //         if (temp_group.prices.mega_deal_price > temp_group.prices.regular_price && !this.on_pickup) {
            //             temp_group.prices.real_price = temp_group.prices.regular_price;
            //             price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         } else if (temp_group.prices.mega_deal_price > temp_group.prices.pickup_price && this.on_pickup) {
            //             temp_group.prices.real_price = temp_group.prices.pickup_price;
            //             price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         } else {
            //             temp_group.prices.real_price = temp_group.prices.mega_deal_price;
            //             price_item = (temp_group.amount * (temp_group.prices.mega_deal_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         }

            //     } else {
            //         /* calculate the regular price for cart item */
            //         if ((!this.on_pickup && !this.on_happy_hour && !this.onLunch) || (!this.on_pickup && this.on_happy_hour && !temp_group.prices.happy_hour_price) || (!this.on_pickup && this.onLunch && !temp_group.prices.lunch_price)) {
            //             /* recalculate price if it is not lunch time and the article has enabled "only on lunch" flag */
            //             if (temp_group.prices.price_for_added_free_addables > 0) {
            //                 if (!temp_group.article.price[temp_group.size_index].always_show_free_addables) {
            //                     temp_group.prices.real_price = temp_group.prices.regular_price ;
            //                     price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1 + temp_group.prices.price_for_added_free_addables * 1));
            //                     price = price + price_item;

            //                 } else {
            //                     temp_group.prices.real_price = temp_group.prices.regular_price ;
            //                     price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                     price = price + price_item;
            //                 }
            //             } else {
            //                 temp_group.prices.real_price = temp_group.prices.regular_price ;
            //                 price_item = (temp_group.amount * (temp_group.prices.regular_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             }
            //         }
            //         /* calculate the pickup price for cart item */
            //         if ((this.on_pickup && !this.on_happy_hour && !this.onLunch) || (this.on_pickup && this.on_happy_hour && !temp_group.prices.happy_hour_price) || (this.on_pickup && this.onLunch && !temp_group.prices.lunch_price)) {
            //             /* recalculate price if it is not lunch time and the article has enabled "only on lunch" flag */
            //             if (temp_group.prices.price_for_added_free_addables > 0) {
            //                 if (!temp_group.article.price[temp_group.size_index].always_show_free_addables) {
            //                     temp_group.prices.real_price = temp_group.prices.pickup_price ;
            //                     price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1 + temp_group.prices.price_for_added_free_addables * 1));
            //                     price = price + price_item;
            //                 } else {
            //                     temp_group.prices.real_price = temp_group.prices.pickup_price ;
            //                      price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                     price = price + price_item;
            //                 }
            //             } else {
            //                 temp_group.prices.real_price = temp_group.prices.pickup_price ;
            //                 price_item = (temp_group.amount * (temp_group.prices.pickup_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             }
            //         }
            //         if (this.on_happy_hour && temp_group.prices.happy_hour_price) {

            //             temp_group.prices.real_price = temp_group.prices.happy_hour_price ;
            //             price_item = (temp_group.amount * (temp_group.prices.happy_hour_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //             price = price + price_item;
            //         }

            //         //Lunch logic
            //         if (this.onLunch && temp_group.prices.lunch_price) {

            //             if (!sessionStorage.getItem('masterstore_id')) {
            //                 sessionStorage.setItem('masterstore_id', this.frontendService.storeData.master);
            //             }
            //             if( this.on_pickup && temp_group.prices.pickup_price < temp_group.prices.lunch_price && temp_group.article.category_id !== 42 ) {
            //                 temp_group.prices.real_price = temp_group.prices.pickup_price;
            //                 price_item = (temp_group.amount * (temp_group.prices.real_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             } else if (!this.on_pickup && temp_group.prices.real_price >= temp_group.prices.lunch_price && sessionStorage.getItem('masterstore_id') == '1') {
            //                 var pls = 0;
            //                 temp_group.prices.real_price =  temp_group.prices.lunch_price + pls;
            //                 price_item = (temp_group.amount * (temp_group.prices.real_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             } else {
            //                 //IF NOT PICKUP AND PICKUP_PRICE > LUNCH_PRICE
            //                 temp_group.prices.real_price =  temp_group.prices.lunch_price;
            //                 price_item = (temp_group.amount * (temp_group.prices.real_price * 1 + temp_group.prices.price_for_added_ingredients * 1));
            //                 price = price + price_item;
            //             }

            //         }
            //     }

            //     temp_group.prices.real_price_total = price_item;


            // }

            // price = Math.round(price * 100) / 100;
            // this.article_amount = amount;


            // this.priceCart = price;


            /* no min value on pickup */
            // if (this.on_pickup) {
            //     this.min_val = 0;
            //     this.min_val_subject.next(this.min_val);
            // } else {
            //     this.min_val = this.minValueTemp;
            //     this.min_val_subject.next(this.min_val);
            // }

            // /* New additions moved from cart page - calculate the total price of the curret order */
            // if (this.addMinQuantitySurcharge === true) {
            //     this.priceTotal = this.priceCart + this.min_quan_sur/*  + this.tip */;
            // } else {
            //     this.priceTotal = this.priceCart/*  + this.tip */;
            // }

            // /* Add the local values to observables */
            // this.min_quan_sur = (this.min_val - this.priceCart) < 0 ? 0 : (this.min_val - this.priceCart);
            // this.min_quan_sur_subject.next(this.min_quan_sur);
            // this.priceCartSubject.next(this.priceCart);
            // this.priceTotalSubject.next(this.priceTotal);
            // this.article_amount_subject.next(this.article_amount);

            /* check differences */
            // if( this.showPriceDifferencesPopup === false ) {
            //     if (priceCartTemp !== 0) {

            //         if (priceCartTemp !== this.priceCart) {
            //             this.priceCartTemp = priceCartTemp;
            //             this.priceCartTempSubject.next(this.priceCartTemp);
            //             this.showPriceDifferencesPopup = true;
            //             this.showPriceDifferencesPopupSubject.next(this.showPriceDifferencesPopup);
            //         }
            //     }
            // }
            /* Resolve the promise at the end */
            resolve();
        });
    }

    /**
     * Reset all cart data to default
     */
    resetCartData() {
        this.article_group_list = [];
        this.min_quan_sur = 0;
        this.priceTotal = 0;
        this.priceCart = 0;
        this.article_amount = 0;
        // this.priceTotalRegular = 0;
        // this.priceTotalPickup = 0;
        //this.min_val = 0;
        //this.min_val_subject.next(this.min_val);

        this.priceTotalSubject.next(this.priceTotal);
        this.priceCartSubject.next(this.priceCart);
        this.article_amount_subject.next(this.article_amount);

        this.addMinQuantitySurcharge =  false;
        this.addMinQuantitySurchargeSubject.next(this.addMinQuantitySurcharge);

        // this.priceTotalRegularSubject.next(this.priceTotalRegular);
        // this.priceTotalPickupSubject.next(this.priceTotalPickup);

        this.min_quan_sur_subject.next(this.min_val);

        this.article_group_list_subject.next(this.article_group_list);
        sessionStorage.setItem('cartItems', '[]');
    }

    getDelivDateTime () {
        if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
            this.deliveryDate = this.dateService.newDate();
            sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
        }
    
        if (!this.deliveryDate || this.deliveryDate.toString() == 'Invalid Date') {
            this.deliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        }

        if (!sessionStorage.getItem('deliveryTime')) {
            if (this.deliveryLaterTimeValues.length > 0) {
                this.deliveryTime = this.deliveryLaterTimeValues[0].time;
            } else {
                this.deliveryTime = this.deliveryDate.toLocaleTimeString().substring(0, 5);
            }
            sessionStorage.setItem('deliveryTime', this.deliveryTime);
            this.deliveryTimeSubject.next(this.deliveryTime);
        }

        if (!this.deliveryTime) {
            this.deliveryTime = sessionStorage.getItem('deliveryTime');
        }

        this.deliveryDate.setHours(parseInt(this.deliveryTime.substring(0, 2)));
        this.deliveryDate.setMinutes(parseInt(this.deliveryTime.substring(3, 5)));
        this.deliveryDateSubject.next(this.deliveryDate);
    }

    getPickup() {
        return this.on_pickup;
    }
    
    getLunch() {
        return this.onLunch;
    }

    getdeliveryDate() {
        return this.deliveryDate;
    }

    getdeliveryTime() {
        return this.deliveryTime;
    }
}
