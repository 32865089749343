<div class="header" id="header">
    <div class="menu d-flex justify-content-center align-items-center col-12 p-0">
        <div class="menu-left">
            <div class="hamburger-menu">
                <button type="button" class="hamburger animated fadeInLeft is-closed" [class.is-open]="is_open"
                        [class.is-closed]="!is_open" (click)="onToggle()" data-toggle="offcanvas">
                    <span class="hamb-top"></span>
                    <span class="hamb-middle"></span>
                    <span class="hamb-bottom"></span>
                </button>
            </div>
        </div>

        <div class="menu-center">
            <div class="logo-daves">
                <a routerLink="/shop">
                   <!-- <svg-icon src="{{logo2}}"></svg-icon>-->
                    <img src="{{logo2}}"
                         alt="Daves" class="img-fluid">
                </a>
            </div>
        </div>

        <div class="menu-right" *ngIf="show_cart">

            <div class="menu-user">
                <button class="btn-cart  d-flex justify-content-center align-items-center" routerLink="/shop/bestellen/warenkorb">
                    <svg-icon src="assets/icons/cart.svg" [svgStyle]="{ 'width.px': 30 }" ></svg-icon>
                    <span class="cart-count-mobile  d-flex justify-content-center align-items-center">{{total_amount}}</span>
                </button>
            </div>
        </div>

    </div>

    <!-- <hr class="menu-trenner">-->
</div>
