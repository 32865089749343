import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ueberuns',
  templateUrl: './ueberuns.component.html',
  styleUrls: ['./ueberuns.component.scss']
})
export class UeberunsComponent implements OnInit {
  storeData: any;
  // headerBilder
  headerbild_Daves = "/assets/img/header/Daves_Headerbild_Obergruppen_304x170pxUeberUns_MegaDeal/Daves_Headerbild_Obergruppen_304x170px_UeberUns.jpg";
  headerbild_Daves_Desktop = "/assets/img/header/Daves_Headerbild_Obergruppen_2184x656px_Ueber_Uns_MegaDeal_k/Daves_Headerbild_Obergruppen_2184x656px_UeberUns.jpg";

  sizeSmall = true;
  constructor() { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
    console.log("STOREDATE", this.storeData);
    this.setHeaderbild();
  }

  // Headerbild setzen
  setHeaderbild() {
    let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
    if (small == this.sizeSmall) return;
    this.sizeSmall = small;
    if (small === false) {
      this.headerbild_Daves = this.headerbild_Daves_Desktop;
    }
    else {
    }
  }
}

