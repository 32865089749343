<ng-container *ngIf="ready">
  <footer>
      <div id="footer-info">
          <ul class="list-unstyled d-flex flex-wrap justify-content-center align-items-start">
              <li class="col-12 col-lg-4">
                  <div id="contact">
                      <h3 class="mb-3 mp-font-medium-yellow ">Kontakt</h3>
                      <ul class="list-unstyled mb-7">
                          <!-- <li class="d-flex flex-row justify-content-center my-1 mx-0 p-0">
                               <span class="py-1">Noch Fragen? </span>
                           </li>-->
                          <div *ngIf="storeData?.master === 1">
                              <li class="row d-flex flex-row justify-content-between">
                                  <span id="phone1" class="col-6"><a
                                      href="tel:{{phoneMP}}">{{phoneMP}}</a></span>
                                  <span class="col-6"><a href="tel:{{phoneMD}}">{{phoneMD}}</a></span>
                              </li>
                              <li class="row d-flex flex-row justify-content-between">
                                  <span id="street1" class="col-6">{{streetMP}}</span>
                                  <span class="col-6">{{streetMD}}</span>
                              </li>
                              <li class="row d-flex flex-row justify-content-between">
                                  <span id="zip1" class="col-6">{{zipMP}}</span>
                                  <span class="col-6">{{zipMD}}</span>

                              </li>
                              <li class="row d-flex flex-row justify-content-between">
                                  <span id="city1" class="col-6">{{cityMP}}</span>
                                  <span class="col-6">{{cityMD}}</span>

                              </li>
                              <li class="row d-flex flex-row justify-content-center">
                                  <span id="media1" class="col-6">
                                      <a href="https://www.facebook.com/meisterpizza" target="_blank">
                                           <svg aria-hidden="true" focusable="false" class="icon icon--sm m-0"
                                                role="img" viewBox="0 0 448 512">
                                  <path fill="currentColor"
                                        d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                              </svg>
                              </a>&nbsp;&nbsp;&nbsp;
                                  <a href="https://www.instagram.com/meisterpizzafreiberg/" target="_blank">
                                  <svg aria-hidden="true" focusable="false" class="icon icon--sm m-0" role="img"
                                       viewBox="0 0 448 512">
                                      <path fill="currentColor"
                                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                  </svg>
                                  </a>
                          </span>

                                  <span class="col-6">
                                      <a href="https://www.facebook.com/meisterpizza"
                                         target="_blank">
                                               <svg aria-hidden="true" focusable="false" class="icon icon--sm m-0"
                                                    role="img"
                                                    viewBox="0 0 448 512">
                                                  <path fill="currentColor"
                                                        d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                                           </svg>
                                          </a>&nbsp;&nbsp;&nbsp;
                                              <a href="https://www.instagram.com/meisterpizzafreiberg/"
                                                 target="_blank">
                          <svg aria-hidden="true" focusable="false" class="icon icon--sm m-0" role="img"
                               viewBox="0 0 448 512">
                              <path fill="currentColor"
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                          </svg>
                      </a>    </span>


                              </li>
                          </div>

                          <div *ngIf="storeData?.master === 3">
                              <li class="row d-flex flex-row justify-content-between">
                                  <span class="p-3"><a
                                      href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a></span>
                              </li>
                          <li class="row d-flex flex-row justify-content-between">
                                  <span class="p-3">{{ storeData.street }}</span>
                              </li>
                          <li class="row d-flex flex-row justify-content-between">
                                  <span class="p-3">{{ storeData.zip }} {{ storeData.city }}</span>
                              </li>
                              <li class="d-flex flex-row justify-content-center">
                    <span class="p-3">  <a href="https://www.facebook.com/meisterpizza" target="_blank">
                          <svg aria-hidden="true" focusable="false" class="icon icon--md" role="img"
                               viewBox="0 0 448 512">
                              <path fill="currentColor"
                                    d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                          </svg>
                    </a>&nbsp;&nbsp;&nbsp;</span>
                                  <span class="p-3"><a href="https://www.instagram.com/meisterpizzafreiberg/"
                                                       target="_blank">
                          <svg aria-hidden="true" focusable="false" class="icon icon--md" role="img"
                               viewBox="0 0 448 512">
                              <path fill="currentColor"
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                          </svg>
                      </a>    </span>
                              </li>

                          </div>


                      </ul>
                  </div>
              </li>

              <li class="col-12 col-md-6 col-lg-4">
                  <div id="shopInfo">
                      <h3 class="mb-3 mp-font-medium-yellow">Shop-Infos</h3>
                      <ul class="list-unstyled mb-7">
                          <div>
                          <li class="m-3">
                              <span><a routerLink="/shop/oeffnungszeiten">Öffnungszeiten</a></span>
                          </li>
                          <li class="m-3">
                              <span><a routerLink="/shop/oeffnungszeiten">Gesonderte Öffnungszeiten</a></span>
                          </li>
                          <li class="m-3">
                              <span><a routerLink="/shop/zahlungsarten">Zahlungsarten</a></span>
                          </li>
                          <li class="m-3">
                              <span><a routerLink="/shop/liefergebiete">Liefergebiete</a></span>
                          </li>
                          </div>
                      </ul>
                  </div>
              </li>
              <li class="col-12 col-md-6 col-lg-4">
                  <div id="newsletter">
                      <h3 class="mp-font-medium-yellow">Newsletter</h3>
                      <ul class="list-unstyled">
                          <li class="m-3">
                      <a routerLink="/shop/newsletter" class="mp-btn mp-btn-newsletter">Jetzt kostenlos
                          abonnieren</a>
                          </li>
                      </ul>
                  </div>
              </li>
          </ul>
      </div>


      <div class="row">
          <div id="service" class="mt-5 col-xxl-12 text-center">
              <ul class="list-unstyled mb-0">
                  <!-- <li [class.menu-active-0]="current_page_id == 13">
                       <a routerLink="/shop/ueberuns" (click)="setPageID(13)">Über uns</a>
                   </li>-->
                  <li [class.menu-active-0]="current_page_id == 14" class="col-12 col-sm-auto">
                      <a routerLink="/shop/newsletter" (click)="setPageID(14)">Newsletter</a>
                  </li>
                  <li [class.menu-active-0]="current_page_id == 16" class="col-12 col-sm-auto">
                      <a routerLink="/shop/jobs" (click)="setPageID(16)">Jobs</a>
                  </li>
                 
                  <li [class.menu-active-0]="current_page_id == 20" class="col-12 col-sm-auto">
                      <a routerLink="/shop/impressum" (click)="setPageID(20)">Impressum</a>
                  </li>
                  <li [class.menu-active-0]="current_page_id == 21" class="col-12 col-sm-auto">
                      <a routerLink="/shop/datenschutz" (click)="setPageID(21)">Datenschutz</a>
                  </li>

                  <li [class.menu-active-0]="current_page_id == 19" class="col-12 col-sm-auto">
                    <a routerLink="/shop/agb" (click)="setPageID(19)">AGB</a>
                </li>
                  <li [class.menu-active-0]="current_page_id == 18" class="col-12 col-sm-auto">
                    <a routerLink="/shop/allergene" (click)="setPageID(18)">Allergene & Zusatzstoffe</a>
                </li>
                  <li [class.menu-active-0]="current_page_id == 21" class="col-12 col-sm-auto">
                      <a href="javascript:CookieConsent.renew();CookieDeclaration.SetUserStatusLabel();">
                          Cookie-Einstellungen </a>
                  </li>
              </ul>
          </div>
      </div>
  </footer>


</ng-container>
<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
      integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
      crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js"
      integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns"
      crossorigin="anonymous"></script>

<script>
  let collapse = $('#collapse-button');
  collapse.on('shown.bs.collapse', function () {
      console.log("Opened")
  });

  collapse.on('hidden.bs.collapse', function () {
      console.log("Closed")
  });
</script>

