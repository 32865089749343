<!-- <div class="to-top-button show-mobile" (click)="toTop()" *ngIf="showUpButton"> -->
<ul class="nav sidebar-nav" *ngIf="show_navigation">
    <!--   <div class="to-down-button " (click)="toBottom()" *ngIf="showDownButton">
           <button class="mp-btn-down"></button>
       </div>
       <div class="to-up-button " (click)="toTop()" *ngIf="showUpDownButton">
           <button class="mp-btn-up"></button>
       </div>-->

    <li class="nav-item logo-mobile-menu py-4 " [class.menu-active]="current_page_id == -1"
        (click)="onToggleOpen(-1)">
        <a routerLink="/shop">
            <!--<svg-icon src="{{logo2}}" class="logo-mobil"></svg-icon>-->
            <!-- <svg-icon src="{{logo2}}" class="logo-desk"></svg-icon>-->
            <img src="{{logo2}}" alt="Daves" class="img-fluid logo-mobil">
            <img src="{{logo2}}" alt="Daves" class="img-fluid logo-desk">
        </a>
    </li>

    <li class="nav-item menu-horizontal-items ">
        <ul class="container-menu-horizontal">

            <li class="nav-item warenkorb-container py-1">
                <a class="nav-link warenkorb" routerLink="/shop/bestellen/warenkorb-index">
                    <div class="svg-container">
                        <svg-icon src="assets/icons/cart.svg"></svg-icon>
                    </div>
                    <p class="menu-categorie">Warenkorb</p>
                    <span class="cart-count cart-count-mobile">{{total_amount}}
            </span>
                </a>
            </li>

            <li *ngIf="showOffer" class="nav-item icon-aktion" [class.menu-active]="current_page_id == 0"
                (click)="onToggleOpen(0)">
                <a class="nav-link" routerLink="/shop/bestellen/aktion">
                    <div class="svg-container">
                        <svg-icon src="/assets/icons/Daves_icons_menu_button/Menue-Button_35x35px_Aktion.svg"
                                  [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
                    </div>
                    <p class="menu-categorie">Aktion</p>
                </a>
            </li>
            <!--<li *ngIf="showLunch" class="nav-item icon-lunch" [class.menu-active]="current_page_id == 10"
                 (click)="onToggleOpen(10)">-->
            <li class="nav-item icon-lunch" [class.menu-active]="current_page_id == 10" (click)="onToggleOpen(10)">
                <a class="nav-link" routerLink="/shop/mittags-angebot">
                    <div class="svg-container">
                        <svg-icon src="/assets/icons/Daves_icons_menu_button/Menue-Button_35x35px_Mittagstisch.svg"
                                  [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
                    </div>
                    <p class="menu-categorie">Mittagstisch</p>
                </a>
            </li>

            <li class="nav-item icon-lunch mobile-anzeigen" [class.menu-active]="current_page_id == 10"
                (click)="onToggleOpen(10)">
                <a class="nav-link" routerLink="/shop/pizza-party-mega-deal">
                    <div class="svg-container">
                        <svg-icon src="/assets/icons/Daves_icons_menu_button/Menue-Button_35x35px_Mittagstisch.svg"
                                  [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
                    </div>
                    <p class="menu-categorie">Mega Deal</p>
                </a>
            </li>

            <ng-container *ngFor="let masterCategory of masterCategories">
                <li *ngIf="masterCategory.show_in_navigation" class="nav-item menu-icon"
                    [class.menu-active]="current_page_id == masterCategory.id"
                    (click)="onToggleOpen(masterCategory.id)">
                    <a class="nav-link"
                       routerLink="/shop/bestellen/{{masterCategory.id}}/{{masterCategory.master_cat_name}}">
                        <div class="svg-container">
                            <span class="svg-icon" [innerHTML]="masterCategory.icon2|safeHtml"></span>
                        </div>
                        <p class="menu-categorie">{{masterCategory.master_cat_name}}</p>
                    </a>
                </li>
            </ng-container>
            <!--    <li class="nav-item icon-lunch">
                    <a class="nav-link" routerLink="/shop/bestellen/warenkorb">
                        <svg-icon src="assets/icons/Icon_Sparcoupon_weiss.svg"
                                  [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
                        <span>Coupons</span>

                    </a>
                </li>-->
            <!--Warenkorb-->
            <li class="nav-item warenkorb-container-desktop">
                <a class="nav-link warenkorb" routerLink="/shop/bestellen/warenkorb-index">
                    <div class="svg-container">
                        <svg-icon src="assets/icons/cart.svg"></svg-icon>
                    </div>
                    <span class="cart-count">{{total_amount}}</span>
                    <p class="menu-categorie">Warenkorb</p>
                </a>
            </li>

        </ul>
    </li>
</ul>


<!--für Statische Seiten-->
<div *ngIf="!show_navigation">
    <ul class="nav sidebar-nav">
        <li class="nav-item logo-mobile-menu" [class.menu-active-0]="current_page_id == -1" (click)="onToggleOpen(-1)">
            <a routerLink="/shop">
                <svg-icon src="{{logo2}}" class="logo-mobil"></svg-icon>
                <svg-icon src="{{logo2}}" class="logo-desk"></svg-icon>
            </a>
        </li>
        <!-- <li class="nav-item mt-7" style="margin-top:3rem ">-->
        <!-- <li class="nav-item my-7 d-flex justify-content-xl-end">
             <button class="btn-mp btn-yellow btn-block mx-auto col-10 col-xl-3" routerLink="/">Jetzt bestellen</button>
         </li>-->
    </ul>
</div>


