<div class="static-page-layout">

    <div class="row">

        <div class="col-md-12">
            <div class="header-wrapper-small">
                <h1 class="static-page-title">AGB</h1>
            </div>
        </div>

    </div>

    <ng-container>
        <div class="row">

            <div class="col-md-12">

                <div class="center-content">
                    <h2 class="text-center">Allgemeine Geschäftsbedingungen mit Kundeninformationen </h2>
                    <h3>Inhaltsverzeichnis</h3>
                    <p>1. Geltungsbereich<br>
                        2. Vertragsschluss<br>
                        3. Widerrufsrecht<br>
                        4. Preise und Zahlungsbedingungen<br>
                        5. Liefer- und Versandbedingungen<br>
                        6. Eigentumsvorbehalt<br>
                        7. Mängelhaftung (Gewährleistung)<br>
                        8. Einlösung von Aktionsgutscheinen<br>
                        9. Einlösung von Geschenkgutscheinen<br>
                        10. Anwendbares Recht<br>
                        <11. Alternative Streitbeilegung</p>
                    

                    <ul>
                        <li>
                            <h3>§ 1 Geltungsbereich</h3>
                            <ol>
                                <li>1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") des David Krieger,
                                    handelnd unter "Dave´s Pizza meets Burger" (nachfolgend "Verkäufer"), gelten für
                                    alle Verträge zur Lieferung von Waren, die ein Verbraucher oder Unternehmer
                                    (nachfolgend „Kunde“) mit dem Verkäufer hinsichtlich der vom Verkäufer in seinem
                                    Online-Shop dargestellten Waren abschließt. Hiermit wird der Einbeziehung von
                                    eigenen Bedingungen des Kunden widersprochen, es sei denn, es ist etwas anderes
                                    vereinbart.
                                </li>
                                <li>1.2 Für Verträge zur Lieferung von Gutscheinen gelten diese AGB entsprechend, sofern
                                    insoweit nicht etwas Abweichendes geregelt ist.
                                </li>
                                <li>1.3 Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein
                                    Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
                                    ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.
                                </li>
                                <li>1.4 Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische Person oder
                                    eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in
                                    Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 2 Vertragsschluss</h3>
                            <ol>
                                <li>
                                    <!--<a  href="https://{{ storeData?.domain}}" target="_blank">{{ storeData?.domain}}</a>-->
                                    2.1 Die im Online-Shop des Verkäufers enthaltenen Produktbeschreibungen stellen
                                    keine verbindlichen Angebote seitens des Verkäufers dar, sondern dienen zur Abgabe
                                    eines verbindlichen Angebots durch den Kunden.
                                </li>
                                <li>2.2 Der Kunde kann das Angebot über das in den Online-Shop des Verkäufers
                                    integrierte Online-Bestellformular abgeben. Dabei gibt der Kunde, nachdem er die
                                    ausgewählten Waren in den virtuellen Warenkorb gelegt und den elektronischen
                                    Bestellprozess durchlaufen hat, durch Klicken des den Bestellvorgang abschließenden
                                    Buttons ein rechtlich verbindliches Vertragsangebot in Bezug auf die im Warenkorb
                                    enthaltenen Waren ab. Ferner kann der Kunde das Angebot auch telefonisch oder per
                                    Online-Kontaktformular gegenüber dem Verkäufer abgeben.
                                </li>
                                <li>2.3 Der Verkäufer kann das Angebot des Kunden innerhalb von fünf Tagen annehmen,<br>
                                    - indem er dem Kunden eine schriftliche Auftragsbestätigung oder eine
                                    Auftragsbestätigung in Textform (Fax oder E-Mail) übermittelt, wobei insoweit der
                                    Zugang der Auftragsbestätigung beim Kunden maßgeblich ist, oder<br>
                                    - indem er dem Kunden die bestellte Ware liefert, wobei insoweit der Zugang der Ware
                                    beim Kunden maßgeblich ist, oder<br>
                                    - indem er den Kunden nach Abgabe von dessen Bestellung zur Zahlung auffordert.<br>
                                    Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in dem Zeitpunkt
                                    zustande, in dem eine der vorgenannten Alternativen zuerst eintritt. Die Frist zur
                                    Annahme des Angebots beginnt am Tag nach der Absendung des Angebots durch den Kunden
                                    zu laufen und endet mit dem Ablauf des fünften Tages, welcher auf die Absendung des
                                    Angebots folgt. Nimmt der Verkäufer das Angebot des Kunden innerhalb vorgenannter
                                    Frist nicht an, so gilt dies als Ablehnung des Angebots mit der Folge, dass der
                                    Kunde nicht mehr an seine Willenserklärung gebunden ist.
                                </li>
                                <li>2.4 Bei Auswahl einer von PayPal angebotenen Zahlungsart erfolgt die
                                    Zahlungsabwicklung über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie,
                                    S.C.A., 22-24 Boulevard Royal, L-2449 Luxemburg (im Folgenden: „PayPal“), unter
                                    Geltung der PayPal-Nutzungsbedingungen, einsehbar unter
                                    https://www.paypal.com/de/webapps/mpp/ua/useragreement-full oder - falls der Kunde
                                    nicht über ein PayPal-Konto verfügt – unter Geltung der Bedingungen für Zahlungen
                                    ohne PayPal-Konto, einsehbar unter
                                    https://www.paypal.com/de/webapps/mpp/ua/privacywax-full. Zahlt der Kunde mittels
                                    einer im Online-Bestellvorgang auswählbaren von PayPal angebotenen Zahlungsart,
                                    erklärt der Verkäufer schon jetzt die Annahme des Angebots des Kunden in dem
                                    Zeitpunkt, in dem der Kunde den Button anklickt, welcher den Bestellvorgang
                                    abschließt.
                                </li>
                                <li>2.5 Bei Auswahl der Zahlungsart "Amazon Payments" erfolgt die Zahlungsabwicklung
                                    über den Zahlungsdienstleister Amazon Payments Europe s.c.a., 38 avenue John F.
                                    Kennedy, L-1855 Luxemburg (im Folgenden: „Amazon“), unter Geltung der Amazon
                                    Payments Europe Nutzungsvereinbarung, einsehbar unter
                                    https://payments.amazon.de/help/201751590. Wählt der Kunde im Rahmen des
                                    Online-Bestellvorgangs „Amazon Payments“ als Zahlungsart aus, erteilt er durch
                                    Klicken des den Bestellvorgang abschließenden Buttons zugleich auch einen
                                    Zahlungsauftrag an Amazon. Für diesen Fall erklärt der Verkäufer schon jetzt die
                                    Annahme des Angebots des Kunden in dem Zeitpunkt, in dem der Kunde durch Klicken des
                                    den Bestellvorgang abschließenden Buttons den Zahlungsvorgang auslöst.
                                </li>
                                <li>2.6 Bei der Abgabe eines Angebots über das Online-Bestellformular des Verkäufers
                                    wird der Vertragstext nach dem Vertragsschluss vom Verkäufer gespeichert und dem
                                    Kunden nach Absendung von dessen Bestellung in Textform (z. B. E-Mail, Fax oder
                                    Brief) übermittelt. Eine darüber hinausgehende Zugänglichmachung des Vertragstextes
                                    durch den Verkäufer erfolgt nicht.
                                </li>
                                <li>2.7 Vor verbindlicher Abgabe der Bestellung über das Online-Bestellformular des
                                    Verkäufers kann der Kunde mögliche Eingabefehler durch aufmerksames Lesen der auf
                                    dem Bildschirm dargestellten Informationen erkennen. Ein wirksames technisches
                                    Mittel zur besseren Erkennung von Eingabefehlern kann dabei die
                                    Vergrößerungsfunktion des Browsers sein, mit deren Hilfe die Darstellung auf dem
                                    Bildschirm vergrößert wird. Seine Eingaben kann der Kunde im Rahmen des
                                    elektronischen Bestellprozesses so lange über die üblichen Tastatur- und
                                    Mausfunktionen korrigieren, bis er den Button anklickt, welcher den Bestellvorgang
                                    abschließt.
                                </li>
                                <li>
                                    2.8 Für den Vertragsschluss steht ausschließlich die deutsche Sprache zur Verfügung.
                                </li>
                                <li>
                                    2.9 Die Bestellabwicklung und Kontaktaufnahme finden in der Regel per E-Mail und
                                    automatisierter Bestellabwicklung statt. Der Kunde hat sicherzustellen, dass die von
                                    ihm zur Bestellabwicklung angegebene E-Mail-Adresse zutreffend ist, so dass unter
                                    dieser Adresse die vom Verkäufer versandten E-Mails empfangen werden können.
                                    Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern sicherzustellen, dass
                                    alle vom Verkäufer oder von diesem mit der Bestellabwicklung beauftragten Dritten
                                    versandten E-Mails zugestellt werden können.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 3 Widerrufsrecht</h3>
                            <ol>
                                <li>3.1 Verbrauchern steht grundsätzlich ein Widerrufsrecht zu.
                                </li>
                                <li>3.2 Nähere Informationen zum Widerrufsrecht ergeben sich aus der Widerrufsbelehrung
                                    des Verkäufers.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 4 Preise und Zahlungsbedingungen</h3>
                            <ol>
                                <li>4.1 Sofern sich aus der Produktbeschreibung des Verkäufers nichts anderes ergibt,
                                    handelt es sich bei den angegebenen Preisen um Gesamtpreise, die die gesetzliche
                                    Umsatzsteuer enthalten. Gegebenenfalls zusätzlich anfallende Liefer- und
                                    Versandkosten werden in der jeweiligen Produktbeschreibung gesondert angegeben.
                                </li>
                                <li>4.2 Die Zahlungsmöglichkeit/en wird/werden dem Kunden im Online-Shop des Verkäufers
                                    mitgeteilt.
                                </li>
                                <li>4.3 Bei Auswahl einer über den Zahlungsdienst „PayPal“ angebotenen Zahlungsart
                                    erfolgt die Zahlungsabwicklung über PayPal, wobei sich PayPal hierzu auch der
                                    Dienste dritter Zahlungsdienstleister bedienen kann. Sofern der Verkäufer über
                                    PayPal auch Zahlungsarten anbietet, bei denen er gegenüber dem Kunden in Vorleistung
                                    geht (z. B. Rechnungskauf oder Ratenzahlung), tritt er seine Zahlungsforderung
                                    insoweit an PayPal bzw. an den von PayPal beauftragten und dem Kunden konkret
                                    benannten Zahlungsdienstleister ab. Vor Annahme der Abtretungserklärung des
                                    Verkäufers führt PayPal bzw. der von PayPal beauftragte Zahlungsdienstleister unter
                                    Verwendung der übermittelten Kundendaten eine Bonitätsprüfung durch. Der Verkäufer
                                    behält sich vor, dem Kunden die ausgewählte Zahlungsart im Falle eines negativen
                                    Prüfungsergebnisses zu verweigern. Bei Zulassung der ausgewählten Zahlungsart hat
                                    der Kunde den Rechnungsbetrag innerhalb der vereinbarten Zahlungsfrist bzw. in den
                                    vereinbarten Zahlungsintervallen zu bezahlen. Er kann in diesem Fall nur an PayPal
                                    bzw. den von PayPal beauftragten Zahlungsdienstleister mit schuldbefreiender Wirkung
                                    leisten. Der Verkäufer bleibt jedoch auch im Falle der Forderungsabtretung zuständig
                                    für allgemeine Kundenanfragen z. B. zur Ware, Lieferzeit, Versendung, Retouren,
                                    Reklamationen, Widerrufserklärungen und -zusendungen oder Gutschriften.
                                </li>
                                <li>4.4 Bei Auswahl der Zahlungsart „SOFORT“ erfolgt die Zahlungsabwicklung über den
                                    Zahlungsdienstleister SOFORT GmbH, Theresienhöhe 12, 80339 München (im Folgenden
                                    „SOFORT“). Um den Rechnungsbetrag über „SOFORT“ bezahlen zu können, muss der Kunde
                                    über ein für die Teilnahme an „SOFORT“ frei geschaltetes Online-Banking-Konto
                                    verfügen, sich beim Zahlungsvorgang entsprechend legitimieren und die
                                    Zahlungsanweisung gegenüber „SOFORT“ bestätigen. Die Zahlungstransaktion wird
                                    unmittelbar danach von „SOFORT“ durchgeführt und das Bankkonto des Kunden belastet.
                                    Nähere Informationen zur Zahlungsart „SOFORT“ kann der Kunde im Internet unter
                                    https://www.klarna.com/sofort/ abrufen.
                                </li>
                                <li>4.5 Bei Auswahl einer über den Zahlungsdienst "mollie" angebotenen Zahlungsart
                                    erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister Mollie B.V.,
                                    Keizersgracht 313, 1016 EE Amsterdam, Niederlande (im Folgenden: „mollie“). Die
                                    einzelnen über mollie angebotenen Zahlungsarten werden dem Kunden im Online-Shop des
                                    Verkäufers mitgeteilt. Zur Abwicklung von Zahlungen kann sich mollie weiterer
                                    Zahlungsdienste bedienen, für die ggf. besondere Zahlungsbedingungen gelten, auf die
                                    der Kunde ggf. gesondert hingewiesen wird. Weitere Informationen zu "mollie" sind im
                                    Internet unter https://www.mollie.com/de/ abrufbar.
                                </li>
                                <li>4.6 Bei Auswahl einer über den Zahlungsdienst "VR Payment" angebotenen Zahlungsart
                                    erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister VR Payment GmbH,
                                    Saonestraße 3a, 60528 Frankfurt am Main, Deutschland (im Folgenden: „VR Payment“).
                                    Die einzelnen über VR Payment angebotenen Zahlungsarten werden dem Kunden im
                                    Online-Shop des Verkäufers mitgeteilt. Zur Abwicklung von Zahlungen kann sich VR
                                    Payment der Dienste dritter Zahlungsdienstleister bedienen, für die ggf. besondere
                                    Zahlungsbedingungen gelten, auf die der Kunde ggf. gesondert hingewiesen wird.
                                    Weitere Informationen zu "VR Payment" sind im Internet unter
                                    https://www.vr-payment.de/onlinehandel#article-878 abrufbar.
                                </li>
                                <li>4.7 Bei Auswahl der Zahlungsart Kreditkarte ist der Rechnungsbetrag mit
                                    Vertragsschluss sofort fällig. Die Abwicklung der Zahlungsart Kreditkartenzahlung
                                    erfolgt in Zusammenarbeit mit der PAYONE GmbH, Lyoner Str. 9, 60528 Frankfurt/Main,
                                    welche der Verkäufer zur Forderungseinziehung in seinem Namen ermächtigt. Die PAYONE
                                    GmbH zieht den Rechnungsbetrag vom angegebenen Kreditkartenkonto des Kunden ein. Die
                                    Belastung der Kreditkarte erfolgt umgehend nach Absendung der Kundenbestellung im
                                    Online-Shop. Der Verkäufer bleibt auch bei Auswahl der Zahlungsart
                                    Kreditkartenzahlung über die PAYONE GmbH zuständig für allgemeine Kundenanfragen
                                    z.B. zur Ware, Lieferzeit, Versendung, Retouren, Reklamationen, Widerrufserklärungen
                                    und -zusendungen oder Gutschriften.
                                </li>
                                <li>4.8 Bei Auswahl einer über den Zahlungsdienst "Klarna" angebotenen Zahlungsart
                                    erfolgt die Zahlungsabwicklung über die Klarna Bank AB (publ), Sveavägen 46, 111 34
                                    Stockholm, Schweden (nachfolgend „Klarna“). Nähere Informationen sowie die
                                    Bedingungen von Klarna hierzu finden sich in den Zahlungsinformationen des
                                    Verkäufers, welche unter der folgenden Internetadresse einsehbar sind:
                                    https://www.klarna.com/de/verkaeufer/produkte/sofort-bezahlen/
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 5 Liefer- und Versandbedingungen</h3>
                            <ol>
                                <li>5.1 Bietet der Verkäufer den Versand der Ware an, so erfolgt die Lieferung innerhalb
                                    des vom Verkäufer angegebenen Liefergebietes an die vom Kunden angegebene
                                    Lieferanschrift, sofern nichts anderes vereinbart ist. Bei der Abwicklung der
                                    Transaktion ist die in der Bestellabwicklung des Verkäufers angegebene
                                    Lieferanschrift maßgeblich. Abweichend hiervon ist bei Auswahl der Zahlungsart
                                    PayPal die vom Kunden zum Zeitpunkt der Bezahlung bei PayPal hinterlegte
                                    Lieferanschrift maßgeblich.
                                </li>
                                <li>5.2 Scheitert die Zustellung der Ware aus Gründen, die der Kunde zu vertreten hat,
                                    trägt der Kunde die dem Verkäufer hierdurch entstehenden angemessenen Kosten. Dies
                                    gilt im Hinblick auf die Kosten für die Hinsendung nicht, wenn der Kunde sein
                                    Widerrufsrecht wirksam ausübt. Für die Rücksendekosten gilt bei wirksamer Ausübung
                                    des Widerrufsrechts durch den Kunden die in der Widerrufsbelehrung des Verkäufers
                                    hierzu getroffene Regelung.
                                </li>
                                <li>5.3 Handelt der Kunde als Unternehmer, geht die Gefahr des zufälligen Untergangs und
                                    der zufälligen Verschlechterung der verkauften Ware auf den Kunden über, sobald der
                                    Verkäufer die Sache dem Spediteur, dem Frachtführer oder der sonst zur Ausführung
                                    der Versendung bestimmten Person oder Anstalt ausgeliefert hat. Handelt der Kunde
                                    als Verbraucher, geht die Gefahr des zufälligen Untergangs und der zufälligen
                                    Verschlechterung der verkauften Ware grundsätzlich erst mit Übergabe der Ware an den
                                    Kunden oder eine empfangsberechtigte Person über. Abweichend hiervon geht die Gefahr
                                    des zufälligen Untergangs und der zufälligen Verschlechterung der verkauften Ware
                                    auch bei Verbrauchern bereits auf den Kunden über, sobald der Verkäufer die Sache
                                    dem Spediteur, dem Frachtführer oder der sonst zur Ausführung der Versendung
                                    bestimmten Person oder Anstalt ausgeliefert hat, wenn der Kunde den Spediteur, den
                                    Frachtführer oder die sonst zur Ausführung der Versendung bestimmte Person oder
                                    Anstalt mit der Ausführung beauftragt und der Verkäufer dem Kunden diese Person oder
                                    Anstalt zuvor nicht benannt hat.
                                </li>
                                <li>5.4 Der Verkäufer behält sich das Recht vor, im Falle nicht richtiger oder nicht
                                    ordnungsgemäßer Selbstbelieferung vom Vertrag zurückzutreten. Dies gilt nur für den
                                    Fall, dass die Nichtlieferung nicht vom Verkäufer zu vertreten ist und dieser mit
                                    der gebotenen Sorgfalt ein konkretes Deckungsgeschäft mit dem Zulieferer
                                    abgeschlossen hat. Der Verkäufer wird alle zumutbaren Anstrengungen unternehmen, um
                                    die Ware zu beschaffen. Im Falle der Nichtverfügbarkeit oder der nur teilweisen
                                    Verfügbarkeit der Ware wird der Kunde unverzüglich informiert und die Gegenleistung
                                    unverzüglich erstattet.
                                </li>
                                <li>5.5 Bietet der Verkäufer die Ware zur Abholung an, so kann der Kunde die bestellte
                                    Ware innerhalb der vom Verkäufer angegebenen Geschäftszeiten unter der vom Verkäufer
                                    angegebenen Adresse abholen. In diesem Fall werden keine Versandkosten berechnet.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 6 Eigentumsvorbehalt</h3>
                            <ol>
                                <li>Tritt der Verkäufer in Vorleistung, behält er sich bis zur vollständigen Bezahlung
                                    des geschuldeten Kaufpreises das Eigentum an der gelieferten Ware vor.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 7 Mängelhaftung (Gewährleistung)</h3>
                            <ol>
                                <li>7.1 Soweit sich aus den nachfolgenden Regelungen nichts anderes ergibt, gelten die
                                    Vorschriften der gesetzlichen Mängelhaftung. Hiervon abweichend gilt bei Verträgen
                                    zur Lieferung von Waren:
                                </li>
                                <li>7.2 Handelt der Kunde als Unternehmer,<br>
                                    - hat der Verkäufer die Wahl der Art der Nacherfüllung;<br>
                                    - beträgt bei neuen Waren die Verjährungsfrist für Mängel ein Jahr ab Ablieferung
                                    der Ware;<br>
                                    - sind bei gebrauchten Waren die Rechte und Ansprüche wegen Mängeln
                                    ausgeschlossen;<br>
                                    - beginnt die Verjährung nicht erneut, wenn im Rahmen der Mängelhaftung eine
                                    Ersatzlieferung erfolgt.
                                </li>
                                <li>7.3 Die vorstehend geregelten Haftungsbeschränkungen und Fristverkürzungen gelten
                                    nicht<br>
                                    - für Schadensersatz- und Aufwendungsersatzansprüche des Kunden,<br>
                                    - für den Fall, dass der Verkäufer den Mangel arglistig verschwiegen hat,<br>
                                    - für Waren, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk
                                    verwendet worden sind und dessen Mangelhaftigkeit verursacht haben,<br>
                                    - für eine ggf. bestehende Verpflichtung des Verkäufers zur Bereitstellung von
                                    Aktualisierungen für digitale Produkte, bei Verträgen zur Lieferung von Waren mit
                                    digitalen Elementen.
                                </li>
                                <li>7.4 Darüber hinaus gilt für Unternehmer, dass die gesetzlichen Verjährungsfristen
                                    für einen ggf. bestehenden gesetzlichen Rückgriffsanspruch unberührt bleiben.
                                </li>
                                <li>7.5 Handelt der Kunde als Kaufmann i.S.d. § 1 HGB, trifft ihn die kaufmännische
                                    Untersuchungs- und Rügepflicht gemäß § 377 HGB. Unterlässt der Kunde die dort
                                    geregelten Anzeigepflichten, gilt die Ware als genehmigt.
                                </li>
                                <li>7.6 Handelt der Kunde als Verbraucher, so wird er gebeten, angelieferte Waren mit
                                    offensichtlichen Transportschäden bei dem Zusteller zu reklamieren und den Verkäufer
                                    hiervon in Kenntnis zu setzen. Kommt der Kunde dem nicht nach, hat dies keinerlei
                                    Auswirkungen auf seine gesetzlichen oder vertraglichen Mängelansprüche.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 8 Einlösung von Aktionsgutscheinen</h3>
                            <ol>
                                <li>8.1 Gutscheine, die vom Verkäufer im Rahmen von Werbeaktionen mit einer bestimmten
                                    Gültigkeitsdauer unentgeltlich ausgegeben werden und die vom Kunden nicht käuflich
                                    erworben werden können (nachfolgend "Aktionsgutscheine"), können nur im Online-Shop
                                    des Verkäufers und nur im angegebenen Zeitraum eingelöst werden.
                                </li>
                                <li>8.2 Einzelne Produkte können von der Gutscheinaktion ausgeschlossen sein, sofern
                                    sich eine entsprechende Einschränkung aus dem Inhalt des Aktionsgutscheins ergibt.
                                </li>
                                <li>8.3 Aktionsgutscheine können nur vor Abschluss des Bestellvorgangs eingelöst werden.
                                    Eine nachträgliche Verrechnung ist nicht möglich.
                                </li>
                                <li>8.4 Bei einer Bestellung können auch mehrere Aktionsgutscheine eingelöst werden.
                                </li>
                                <li>8.5 Der Warenwert muss mindestens dem Betrag des Aktionsgutscheins entsprechen.
                                    Etwaiges Restguthaben wird vom Verkäufer nicht erstattet.
                                </li>
                                <li>8.6 Reicht der Wert des Aktionsgutscheins zur Deckung der Bestellung nicht aus, kann
                                    zur Begleichung des Differenzbetrages eine der übrigen vom Verkäufer angebotenen
                                    Zahlungsarten gewählt werden.
                                </li>
                                <li>8.7 Das Guthaben eines Aktionsgutscheins wird weder in Bargeld ausgezahlt noch
                                    verzinst.
                                </li>
                                <li>8.8 Der Aktionsgutschein wird nicht erstattet, wenn der Kunde die mit dem
                                    Aktionsgutschein ganz oder teilweise bezahlte Ware im Rahmen seines gesetzlichen
                                    Widerrufsrechts zurückgibt.
                                </li>
                                <li>
                                    8.9 Der Aktionsgutschein ist nur für die Verwendung durch die auf ihm benannte
                                    Person bestimmt. Eine Übertragung des Aktionsgutscheins auf Dritte ist
                                    ausgeschlossen. Der Verkäufer ist berechtigt, jedoch nicht verpflichtet, die
                                    materielle Anspruchsberechtigung des jeweiligen Gutscheininhabers zu prüfen.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 9 Einlösung von Geschenkgutscheinen</h3>
                            <ol>
                                <li>9.1 Gutscheine, die über den Online-Shop des Verkäufers käuflich erworben werden
                                    können (nachfolgend "Geschenkgutscheine"), können nur im Online-Shop des Verkäufers
                                    eingelöst werden, sofern sich aus dem Gutschein nichts anderes ergibt.
                                </li>
                                <li>9.2 Geschenkgutscheine und Restguthaben von Geschenkgutscheinen sind bis zum Ende
                                    des dritten Jahres nach dem Jahr des Gutscheinkaufs einlösbar. Restguthaben werden
                                    dem Kunden bis zum Ablaufdatum gutgeschrieben.
                                </li>
                                <li>9.3 Geschenkgutscheine können nur vor Abschluss des Bestellvorgangs eingelöst
                                    werden. Eine nachträgliche Verrechnung ist nicht möglich.
                                </li>
                                <li> 9.4 Bei einer Bestellung können auch mehrere Geschenkgutscheine eingelöst werden.
                                </li>
                                <li>
                                    9.5 Geschenkgutscheine können nur für den Kauf von Waren und nicht für den Kauf von
                                    weiteren Geschenkgutscheinen verwendet werden.
                                </li>
                                <li>
                                    9.6 Reicht der Wert des Geschenkgutscheins zur Deckung der Bestellung nicht aus,
                                    kann zur Begleichung des Differenzbetrages eine der übrigen vom Verkäufer
                                    angebotenen Zahlungsarten gewählt werden.
                                </li>
                                <li>
                                    9.7 Das Guthaben eines Geschenkgutscheins wird weder in Bargeld ausgezahlt noch
                                    verzinst.
                                </li>
                                <li>
                                    9.8 Der Geschenkgutschein ist nur für die Verwendung durch die auf ihm benannte
                                    Person bestimmt. Eine Übertragung des Geschenkgutscheins auf Dritte ist
                                    ausgeschlossen. Der Verkäufer ist berechtigt, jedoch nicht verpflichtet, die
                                    materielle Anspruchsberechtigung des jeweiligen Gutscheininhabers zu prüfen.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 10 Anwendbares Recht</h3>
                            <ol>
                                <li>Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik
                                    Deutschland unter Ausschluss der Gesetze über den internationalen Kauf beweglicher
                                    Waren. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte
                                    Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher
                                    seinen gewöhnlichen Aufenthalt hat, entzogen wird.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h3>§ 11 Alternative Streitbeilegung</h3>
                            <ol>
                                <li>11.1 Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur
                                    Online-Streitbeilegung bereit: https://ec.europa.eu/consumers/odr
                                    Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von
                                    Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein
                                    Verbraucher beteiligt ist.
                                </li>
                                <li>11.2 Der Verkäufer ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                                    Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                                </li>
                            </ol>
                        </li>
                    </ul>
                  <!--  <p>Damme, Juli 2022</p>-->


                 <!--   <h2 class="text-center">Anhang:</h2>
                    <h3>Widerrufsbelehrung</h3>
                    <p>Widerrufsrecht</p>-->
                    <!--<p>Ist der Kunde ein Verbraucher gem. § 13 BGB oder eine natürliche Person, die ein Rechtsgeschäft
                        zu einem
                        Zweck abschließt, der überwiegend weder seiner gewerblichen noch seiner selbständigen
                        beruflichen Tätigkeit
                        zugerechnet werden kann, steht ihm ein Widerrufsrecht gemäß § 312g i. V. m. § 355 BGB zu, wenn
                        die
                        Auftragserteilung außerhalb der Geschäftsräume des Auftragnehmers oder ausschließlich über
                        Fernabsatz
                        erfolgt.</p>
                    <p>Das Widerrufsrecht besteht nicht bei Verträgen hinsichtlich der Lieferung von Waren, die nicht
                        vorgefertigt
                        sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher
                        maßgeblich
                        ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind. Des
                        Weiteren
                        gilt das Widerrufsrecht nicht bei der Lieferung von Waren, die schnell verderben können; die aus
                        Gründen des
                        Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung
                        nach Lieferung
                        entfernt wurde; wenn die Waren nach Lieferung auf Grund ihrer Beschaffenheit untrennbar mit
                        anderen Gütern
                        vermischt wurden. Ansonsten gilt:</p>
                    <p>Widerrufsbelehrung</p>
                    <p>Sofern Ihnen ein Widerrufsrecht zusteht, können Sie den Vertrag ohne Angabe von Gründen
                        widerrufen. Die
                        Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht
                        auszuüben, müssen Sie
                        uns (Kontaktdaten siehe unten) mittels einer eindeutigen Erklärung (z.B. per Postversandt oder
                        per E-Mail)
                        über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Hierfür können Sie das
                        beigefügte
                        Muster-Widerrufsformular verwenden.</p>
                    <p>Zur Wahrung der Frist reicht es aus, wenn Sie die Mitteilung der Ausübung Ihres Widerrufsrechts
                        vor Ablauf
                        der o.g. Frist absenden.</p>
                    <p>Der Widerruf richtet sich an: </p>
                    <div class="row">
                        <div class="info1 col-sm-4 ">
                            <p>Im Fall eines schriftlichen Widerrufs</p>
                        </div>
                        <div class="info1 col-sm-4 ">
                            <p>Pizza Daves <br>{{ storeData?.street }}<br> {{ storeData?.zip }} {{ storeData?.city }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="info1 col-sm-4 ">
                            <p>Im Fall eines Widerrufs per E-Mail: </p>
                        </div>
                        <div class="info1 col-sm-4 "><a
                                href="mailto:{{ storeData?.email }}">{{ storeData?.email }}</a></div>
                    </div>-->
                    <!--<div class="row">
                        <div class="info1 col-sm-4">
                            <p>Im Fall eines Widerrufs per E-Mail: </p>
                        </div>
                        <div class="info1 col-sm-4 "><a href="tel:{{ storeData.phone }}">{{ storeData.phone }}</a></div>
                    </div>-->

                    <!--<p>Folgen des Widerrufs:</p>
                    <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten
                        haben,
                        einschließlich der Lieferkosten, unverzüglich und spätestens binnen 14 Tagen ab dem Tag
                        zurückzuzahlen, an
                        dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
                        Rückzahlung
                        verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt
                        haben, es sei
                        denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden Ihnen wegen
                        dieser
                        Rückzahlung Entgelte berechnet.</p>
                    <p>Haben Sie verlangt, dass die Leistung während der Widerrufsfrist beginnen soll, so haben Sie uns
                        einen
                        angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der
                        Ausübung des
                        Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Leistungen im
                        Vergleich zum
                        Gesamtumfang der im Vertrag vorgesehenen Leistungen entspricht.
                    </p>-->


                    <!--<div class="col-md-12">
                        <h3 class="text-center">Widerrufsformular</h3>
                        <p class="font-weight-bold">Wenn der bereits geschlossene Vertrag widerrufen werden soll, füllen
                            Sie bitte
                            dieses Formular aus und senden Sie es an uns zurück.</p>

                        <p class="info1 col-sm-4">Pizza Daves <br>{{ storeData?.street }}<br> {{ storeData?.zip }} {{
                            storeData?.city }}</p>

                        <p>Hiermit widerrufe(n) ich/wir dem von mir/uns abgeschlossenen Vertrag über die folgende
                            Bestellung:</p>
                        <div class="row">
                            <div class="info1 col-sm-3 ">
                                <p>bestellt am <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Vor- und Zuname <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Anschrift <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Telefonnummer <br>_____________________</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="info1 col-sm-3 ">
                                <p>Ort <br>_____________________</p>
                            </div>
                            <div class="info1 col-sm-3 ">
                                <p>Datum <br>_____________________</p>
                            </div>
                        </div>

                    </div>-->

                </div>
            </div>
        </div>
    </ng-container>
</div>
