<div class="static-page-layout">

    <div class="row">
        <div class="col-md-12">

            <h1 class="static-page-title">Werden Sie erfolgreicher Systempartner</h1>

            <p>Der geschmackvolle Weg zum beruflichen Erfolg.</p>

            <h2>
                Werden Sie MEISTERSTORES-Besitzer in einem System, das für Sicherheit und beruflichen Erfolg steht.
            </h2>

        </div>

        <div class="col-md-12">

            <h3><i class="fas fa-angle-double-right"></i> Wir machen den Unterschied</h3>
            <p>
                Unsere Produktepalette, Store-Konzepte und Store-Kosten bilden den großen Vorteil
                gegenüber unseren Mitbewerbern. Das macht den Unterschied.
            </p>
            <p>
                Das einzigartige MEISTERSTORES-Konzept hebt sich nicht nur von Mitbewerbern ab, es bietet
                klare Vorteile für unsere Systempartner und Kunden.
                Damit die MEISTERSTORES gesund wachsen - genau wie die Zufriedenheit der Kunden.
            </p>
            <p>
                <b>Vorteile für den Systempartner:</b>
            </p>
            <ul>
                <li>die ersten 3 Monate keine System-Gebühr</li>
                <li>wir sind nicht an Ihrem Umsatz beteiligt</li>
                <li>hauseigene Grafik- und Marketingabteilung, die Sie mit strategischer Werbung unterstützt</li>
                <li>Lieferanten- und Qualitätsmanagement</li>
                <li>Planung und Ausführung der Stores durch unsere Innenarchitekten und MEISTERSTORE-Küchensysteme</li>
                <li>persönliche Betreuung</li>
            </ul>
            <p>
                <b>Vorteile für den Kunden:</b>
            </p>
            <ul>
                <li>hochwertige und frische Zutaten für den perfekten Genuss</li>
                <li>Produkte werden überdurchschnittlich reich belegt</li>
                <li>alle Produkte werden in einer hochwertigen und exklusiven Verpackung geliefert</li>
                <li>Produkte- und Aktions-Informationen werden über aufwendig gestaltete Werbematerialien kommuniziert
                </li>
                <li>ausgezeichnetes Preis-Leistungsverhältnis</li>
            </ul>
            <p>
                <b>Mit diesen "Zutaten" versprechen wir Ihnen das perfekte Geschmackserlebnis.</b>
            </p>

            <h3><i class="fas fa-angle-double-right"></i> Individuelle Store-Konzepte</h3>
            <p>
                Keine andere Systempartnerschaft bietet Ihnen solche umfassenden Möglichkeiten zur individuellen
                Store-Gestaltung wie wir es für Sie tun.
            </p>
            <p>
                Bei uns finden Sie für jede Lage oder Größe eines Geschäftes das passende MEISTERSTORE-Konzept.
            </p>
            <p>
                Vom kleinen Laden an der Ecke bid hin zum großen Restaurant mit Lieferdienst oder ohne - bei uns ist
                alles möglich.
                Je nach Invetitonssumme und Größe Ihres Geschäfts entwickeln wir mit Ihnen zusammen das passende
                Store-Konzept. Hierbei können Sie z.B. auch die Produktsäulen wählen, die später in Ihrem MEISTERSTORE
                für Sättigung und Umsatz sorgen sollen. Ihre Produktauswahl bestimmt den Namen Ihres Stores wie
                MeisterPizza, MeisterSub oder MeisterGrill.
            </p>
            <p>Sie werden überrascht sein, wie einfach das geht.</p>

            <h3><i class="fas fa-angle-double-right"></i> Vielfältige Produktpalette</h3>
            <p>
                Profitieren Sie von einer hochwertigen und bis ins Detail durchdachten Produktpalette, die Sie ganz
                nach Ihren wünschen zusammenstellen können.
            </p>
            <p>
                Meister<b>Pizza</b><br>
                Meister<b>Pasta</b><br>
                Meister<b>Sub</b><br>
                Meister<b>Grill</b><br>
                Meister<b>Salat</b>
            </p>
            <p>
                Die Produktpalette der MEISTERSTORES macht bereits den wichtigsten Unterschied aus. Ob es unsere
                ofenfrische Meisterpizza oder die täglich frisch gebackenen Meistersubs, bei uns hat Qualität ihren
                Geschmack. Keine andere Systempartnerschaft in dieser Branche offeriert Ihnen eine so breit aufgestellte
                Produktpalette von hoher Qualität, aus welcher Sie je nach Standort oder Storegröße Ihre eigene Auswahl
                zusammenstellen können!
            </p>
            <p>
                Selbstverständlich beraten wir Sie dabei gern.
            </p>

            <h3><i class="fas fa-angle-double-right"></i> Planbare Store-Kosten</h3>
            <p>
                Alle unsere monatlichen Gebühren sind in einem <b>Festpreis-Paket</b> für Sie enthalten. Wir sind
                also nicht an Ihrem Umsatz beteiligt.
            </p>
            <p>
                Die Kosten für das monatliche Fest-Preis-Paket richten sich nach der Anzahl der Produktsäulen, wobei
                die ersten 3 Monate für Sie kostenfrei sind.
            </p>
            <p>
                Die Produktpalette der MEISTERSTORES macht bereits den wichtigsten Unterschied aus. Ob es unsere
                ofenfrische Meisterpizza oder die täglich frisch gebackenen Meistersubs, bei uns hat Qualität ihren
                Geschmack. Keine andere Systempartnerschaft in dieser Branche offeriert Ihnen eine so breit aufgestellte
                Produktpalette von hoher Qualität, aus welcher Sie je nach Standort oder Storegröße Ihre eigene Auswahl
                zusammenstellen können!
            </p>
            <p>
                Selbstverständlich beraten wir Sie dabei gern.
            </p>
            <p>
                <b>einmalige Einstiegsgebühr:</b><br>
                5000€ (Netto)
            </p>
            <p>
                <b>monatliches Festpreis-Paket:</b>
            </p>
            <ul>
                <li>die ersten 3 Monate kostenfrei</li>
                <li>richtet sich nach den Produktsäulen</li>
            </ul>
            <p>
                <b>inklusive:</b>
            </p>
            <ul>
                <li>Lizensgebühren für MeisterSTORE</li>
                <li>Werbepauschale</li>
                <li>eigener Webshop</li>
                <li>sehr gute Einkaufskonditionen</li>
                <li>persönlicher Betreuer</li>
            </ul>
            <p>
                Um Ihnen den Einstieg bei MEISTERSTORE noch einfacher zu gestalten, ist das Festpreis-Paket die ersten
                3 Monate kostenlos für Sie. Im Vergleich zu unseren Mitbewerbern, arbeiten wir nicht mit
                Umsatzbeteiligung.
            </p>
            <p>
                <b>Jeder Mehr-Umsatz bleibt Ihr Mehr-Umsatz!</b>
            </p>

            <h3><i class="fas fa-angle-double-right"></i> Ihre Voraussetzungen</h3>
            <p>
                Damit Sie ein erfolgreicher Systempartner werden, sollten Sie idealerweise flgende Voraussetzungen
                mitbringen.
            </p>
            <p>
                Egal ob quereinsteiger, Berufsstarter oder bestehendes Geschäft - MEISTERSTORE plant und realisiert
                mit Ihnen gemeinsam Ihren individuellen Weg zum erfolgreichen MEISTERSTORE-Besitzer.
            </p>
            <p>
                <b>Sie sind:</b>
            </p>
            <ul>
                <li>Existenzgründer oder Neugründer</li>
                <li>Inhaber eines bestehenden Geschäftes</li>
                <li>Franchisenhmer anderer Unternehmen</li>
                <li>angestellter oder selbstständiger Einzelhändler aus Branchen im Umbruch</li>
            </ul>
            <p>
                <b>Sie haben:</b>
            </p>
            <ul>
                <li>eine abgeschlossene Berufsausbildung</li>
                <li>Erfahrungen / Fähigkeiten im Umgang und Motivation von Mitarbeitern</li>
                <li>kaufmännisches Denken und Grundlagen</li>
                <li>Möglichkeit zur Investition in die Systempartnerschaft</li>
                <li>Gastronomieerfahrung oder Kochausbildung</li>
                <li>Freude im Umgang mit Kunden</li>
                <li>Spaß an der Arbeit mit Lebensmitteln</li>
                <li>den Willen zum Erfolg</li>
            </ul>
            <p>
                <b>Wenn Sie sich hier wiedererkennen, ist der erste Schritt für Sie schon getan.</b>
            </p>

            <h3><i class="fas fa-angle-double-right"></i> Kontaktieren Sie uns!</h3>
            <p>
                Für eine schnelle und unkomplizierte Beratung nutzen Sie einfach unser Kontaktformular. Der
                Meisterstore-Systemvertrieb wird Sie schnellstens kontaktieren.<br>
                Um einen ersten persönlichen Kontakt herzustellen, füllen Sie bitte die Felder aus.
            </p>

            <div>
                <form>
                    <fieldset>
                        <div>
                            <label>Anrede:*</label><br>
                            <input type="radio" id="herr" required name="anrede" value="Herr">
                            <label for="herr">Herr</label><br>
                            <input type="radio" id="frau" required name="frau" value="Frau">
                            <label for="frau">Frau</label><br>
                        </div>
                        <div>
                            <label>Vorname:*</label><br><input type="input" required name="">
                        </div>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput>
                        </mat-form-field>
                        <div>
                            <label>Name:*</label><br><input type="input" required name="">
                        </div>
                        <div>
                            <label>Straße und Hausnr.:*</label><br><input type="input" required name="">
                        </div>
                        <div>
                            <label>PLZ:*</label><br><input type="input" required name=""><br>
                            <label>Ort:*</label><br><input type="input" required name="">
                        </div>
                        <div>
                            <label>Telefon:*</label><br><input type="input" required name="">
                        </div>
                        <div>
                            <label>E-Mail:*</label><br><input type="email" required name="">
                        </div>
                        <div>
                            <p>
                                Da Kommunikation in unserem Unternehmen einen hohen Stellenwert besitzt, würden wir gern
                                von Beginn an persönlich ins Gespräch kommen. Unsere freundlichen und hilfreichen
                                MEISTERSTORE-Kundenberater geben Ihnen alle Informationen und Tipps, die Sie benötigen.
                                Ganz einfach und schnell. Auf Wunsch senden wir Ihnen gern auch unsere System-Broschüre
                                zu.
                            </p>
                        </div>
                        <div>
                            <label>MEISTERSTORE-Kundenberater</label>&nbsp;<input type="checkbox" required name="">&nbsp;
                            <label>System-Broschüre</label>&nbsp;<input type="checkbox" required name="">
                        </div>
                        <div>
                            <label>Ihre Nachricht*</label><br><textarea name=""></textarea>
                        </div>
                        <div>
                            <label>
                                <input value="" name="" type="checkbox" required>
                                Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme zu, dass meine
                                Angaben und Daten aus dem Formular zur Beantwortung meiner Anfrage elektronisch erhoben
                                und gespeichert werden.
                                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in unserer
                                <a>Datenschutzerklärung</a>. Sie können Ihre Einwilligung
                                jederzeit per E-Mail an kontakt@meisterpizza.de widerrufen.
                            </label>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>

</div>
