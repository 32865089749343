import {Ingredient} from "./Ingredient";

export class IngredientList {
    ingredients: Ingredient;
    amount: number;
    free_amount: number;
    // was added for free ingredients; amount calculates and displayedAmount shows how much was actually added
    displayed_amount: number;
    ing_cat_index: number;
    ing_index: number;
    added_as_free_addable: boolean;
    max_free_addable: number;
    max_addable: number;
    now_edited: boolean = false;
    addDisabled: any = false;
    addDisabledTopList: any = false;
    // ingredient_group_master: number;
    // free_amount: number;

    constructor(ingredient: Ingredient, cat_index: number, ing_index: number, added_as_free_addable: boolean, max_free_addable: number, max_addable = 1, now_edited = false) {
        this.ingredients = ingredient;
        this.amount = 1;
        this.ing_cat_index = cat_index;
        this.ing_index = ing_index;
        this.added_as_free_addable = added_as_free_addable;
        this.max_free_addable = max_free_addable;
        this.max_addable = max_addable;

        if (added_as_free_addable) {
            this.free_amount = 1;
        } else {
            this.free_amount = 0;
        }
        this.now_edited = now_edited;
        // this.displayed_amount = 1;
        // if(free === true){
        //     this.free_amount = 1;

        // }else{
        //     this.free_amount =0;
        // }
    }

}

