import {Component, ElementRef, QueryList, OnInit, ViewChildren, HostBinding, AfterViewChecked, Input} from '@angular/core';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../../services/main.service';
declare var jQuery: any;

@Component({
  selector: 'app-element-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    storeData: any;
    data: any =[];
    image_url;
    ready: boolean = false;
    @Input('element') element: string;
  constructor(
      private http: HttpClient,
      private main_service: MainService,

  ) {



  }


  ngOnInit() {
      this.image_url = this.main_service.image_url;
      const url = this.main_service.getFrontendUrl() + '/elementByName/' + this.element;
      this.http.get(url).subscribe(data => {

          this.ready = true;
          this.data = data;

      });

      this.storeData = JSON.parse(localStorage.getItem('storedata'));
  }



}


