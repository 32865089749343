/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./welcome.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../services/main.service";
var styles_WelcomeElementComponent = [i0.styles];
var RenderType_WelcomeElementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomeElementComponent, data: {} });
export { RenderType_WelcomeElementComponent as RenderType_WelcomeElementComponent };
function View_WelcomeElementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row mb-7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "col-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "category-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "picture", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["media", "(min-width: 62em)"], ["srcset", "/assets/img/startseite/Daves_Produktrange/Daves_Produktrange_1950x400px.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "source", [["media", "(min-width: 36em)"], ["srcset", "/assets/img/startseite/Daves_Produktrange/Daves_Produktrange_1100x850px.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Daves"], ["class", "img-fluid col-12 px-0"], ["src", "/assets/img/startseite/Daves_Produktrange/Daves_Produktrange_330x300px.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "category-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "p", [["class", "category-title-inner"]], null, null, null, null, null))], null, null); }
export function View_WelcomeElementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeElementComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WelcomeElementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-element-welcome", [], null, null, null, View_WelcomeElementComponent_0, RenderType_WelcomeElementComponent)), i1.ɵdid(1, 114688, null, 0, i3.WelcomeElementComponent, [i4.HttpClient, i5.MainService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WelcomeElementComponentNgFactory = i1.ɵccf("app-element-welcome", i3.WelcomeElementComponent, View_WelcomeElementComponent_Host_0, { element: "element" }, {}, []);
export { WelcomeElementComponentNgFactory as WelcomeElementComponentNgFactory };
