import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { MainService } from 'src/app/services/main.service';
import { FrontendService } from 'src/app/services/frontend.service';
import { UserService } from '../../../services/user.service';
import { CartService } from '../../../services/cart.service';
import { User } from '../../../../_classes/User';
import { AddressItem } from '../adress-page/adress-page.component';

import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Anmeldung } from '../../welcome/step-5/welcomenDaten';
import { SessionService } from '../../../services/session.service';

/**
 * Decorators
 */
@Component({
    selector: 'app-address-input',
    templateUrl: './address-input.component.html',
    styleUrls: ['./address-input.component.scss']
})

/**
 * Class for min order value calculation
 */
export class AddressInputComponent implements OnInit, OnDestroy {

    /* inform parent omponent, if given addres is valid and saved to current session */
    @Output() validateAddress = new EventEmitter<boolean>();

    // Zip
    searchPlzFormControl = new FormControl();
    filteredPlz: any;
    isLoadingPlz = false;
    errorMessagePlz = {
        response: Object,
        message: '',
        noResultsMessage: ''
    };
    plz = '';
    mode = '';

    // Street
    searchStreetFormControl = new FormControl();
    filteredStreet: any;
    isLoadingStreet = false;
    errorMessageStreet = {
        response: Object,
        message: '',
        noResultsMessage: ''
    };
    street = '';

    // other address values
    streetNumberFormControl = new FormControl();
    streetNumber = '';
    errorMessageStreetNumber = {
        response: Object,
        message: ''
    };

    city = '';
    district = '';



    // Other
    private subscriptions: Subscription[] = [];

    // template vars
    onShowAddressFound = false;
    resettedStreet = false;
    resettedPlz = false;
    onPickup: boolean;
    minOrderValue: number;

    // User initialization
    user: User;
    tempUser: User;

    /* Set true if user was already in session storage before */
    userLoadedFromCache: boolean;
    streetCheck = false;
    plzCheck = false;

    anmeldung: Anmeldung;
    userAddressId: any = '';

    constructor(
        private http: HttpClient,
        private main_service: MainService,
        private frontend_service: FrontendService,
        private user_service: UserService,
        private cart_service: CartService,
        private location: Location,
        private router: Router,
        public sessionService: SessionService
    ) { }

    ngOnInit() {

        this.getAnmeldung();

        // get user data from service
        this.subscriptions.push(this.user_service.user_observable.subscribe(
            data => {
                this.user = data;
                this.tempUser = data;
                if (this.plz == '') {
                    this.user_service.setAddressValid(false);
                }
                // if (this.user.adress.plz != '' && this.plz == '') {
                //     this.plz = this.user.adress.plz;
                //     console.log('PLZ form ', this.searchPlzFormControl);
                //     this.searchPlzFormControl.setValue(this.plz);
                //     console.log('PLZ form ', this.searchPlzFormControl);
                //     this.checkPlz();
                //     if (this.user.adress.district != '' && this.district == '') {
                //         this.district = this.user.adress.district;
                //     }
                //     if (this.user.adress.street != '' && this.street == '') {
                //         this.street = this.user.adress.street;
                //         console.log('STREET form ', this.searchStreetFormControl);
                //         this.searchStreetFormControl.setValue(this.street);
                //         console.log('STREET form ', this.searchStreetFormControl);
                //         this.checkStreet();
                //     }
                //     if (this.user.adress.street_number != '' && this.streetNumber == '') {
                //         this.streetNumber = this.user.adress.street_number;
                //         this.streetNumberFormControl.setValue(this.streetNumber);
                //         this.checkStreetNumber(this.streetNumber);
                //     }
                //     // this.checkIfFilledOut();
                // }
                // console.log(this.plz, ' ', this.street , ' ', this.streetNumber);
            }));

        /* check if user was already in storage */
        this.subscriptions.push(this.user_service.userLoadedFromCacheObservable.subscribe(
            data => {
                this.userLoadedFromCache = data;
            }));

        // get min order value from cart service
        this.subscriptions.push(this.cart_service.min_val_observable.subscribe(
            data => {
                this.minOrderValue = data;
            }
        ));

        // get min order value from cart service
        this.subscriptions.push(this.cart_service.on_pickup_observable.subscribe(
            data => {
                this.onPickup = data;
            }
        ));

        this.autocompletePlz();
        this.autocompleteStreet();

        this.cart_service.loadMinOrderValueSessionStorage();

    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    changeStreet(){
       this.mode = 'street';

    }

    changePlz(){

        this.mode = 'plz';
        this.street = '';
        this.checkStreet();

    }

    /**
     * Zip calculation
     */
    autocompletePlz() {
        this.searchPlzFormControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.filteredPlz = [];
                    this.isLoadingPlz = true;
                }),
                switchMap(value => this.http.get(this.main_service.adress_url + '/get-address-data?field=plz&plz=' + value + '&street=' + '' + '&district=')
                    .pipe(
                        finalize(() => {
                            this.isLoadingPlz = false;
                        }),
                    )
                )
            )
            .subscribe(data => {
                if (data === undefined) {
                    this.filteredPlz = [];
                    this.errorMessagePlz.message = 'Data undefined';
                } else {
                    this.filteredPlz = data;
                    if (this.filteredPlz.length === 0 && this.searchPlzFormControl.value && !this.resettedPlz && !this.plzCheck) {
                        this.errorMessagePlz.noResultsMessage = 'Postleitzahl nicht gefunden';
                    } else {
                        this.errorMessagePlz.noResultsMessage = '';
                    }
                    this.resettedPlz = false;
                }

            });
    }

    displayFnPlz(address: AddressItem): string {
        return (address && address.plz && (address.plz !== '')) ? address.plz : '';
    }

    // on select autocomplete value
    plzClick(event: any) {
        this.plz = event.option.value.plz;
        this.city = event.option.value.city;
        this.district = event.option.value.district;

        this.errorMessagePlz.message = '';
        this.errorMessagePlz.noResultsMessage = '';
        this.checkPlz();
    }

    // validate on blur
    checkPlz() {
        if (!this.plz || this.plz !== this.searchPlzFormControl.value.plz) {
            this.searchPlzFormControl.setValue(null);
            this.plz = '';
            this.errorMessagePlz.message = 'Bitte gültige Postleitzahl eingeben';
            this.plzCheck = false;
        } else {
            this.plzCheck = true;
            this.errorMessagePlz.message = '';
        }
        this.checkIfFilledOut();
    }

    /**
     * Street calculation
     */
    autocompleteStreet() {


        this.searchStreetFormControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.filteredStreet = [];
                    this.isLoadingStreet = true;
                }),
                switchMap(value => this.http.get(this.main_service.adress_url + '/get-address-data?field=street&plz=' + ((this.mode === 'plz') ? this.plz : '') + '&street=' + value + '&district=' + '')
                    .pipe(
                        finalize(() => {
                            this.isLoadingStreet = false;
                        }),
                    )
                )
            )
            .subscribe(data => {
                if (data === undefined) {
                    this.filteredStreet = [];
                    this.errorMessageStreet.message = 'Data undefined for Street';
                } else {
                    this.filteredStreet = data;
                    if (this.filteredStreet.length === 0 && this.searchStreetFormControl.value && !this.resettedStreet && !this.streetCheck) {
                        this.errorMessageStreet.noResultsMessage = 'Straße nicht gefunden';
                    } else {
                        this.errorMessageStreet.noResultsMessage = '';
                    }
                    this.resettedStreet = false;
                }
            });
    }

    displayFnStreet(address: AddressItem): string {
        return (address && address.street && (address.street !== '')) ? address.street : '';
    }

    // on select autocomplete value
    streetClick(event: any) {
        this.street = event.option.value.street;
        this.userAddressId = event.option.value.id;
        this.plz = event.option.value.plz;
        this.city = event.option.value.city;
        this.district = event.option.value.district;
        // preselect value for zip code
        this.searchPlzFormControl.setValue(event.option.value);

        this.errorMessagePlz.message = '';
        this.errorMessagePlz.noResultsMessage = '';
        this.errorMessageStreet.message = '';
        this.errorMessageStreet.noResultsMessage = '';
        this.checkStreet();
    }

    // validate on blur
    checkStreet() {
        if (!this.street || this.street !== this.searchStreetFormControl.value.street) {
            this.searchStreetFormControl.setValue(null);
            this.street = '';
            this.errorMessageStreet.message = 'Bitte gültige Straße eingeben';
            this.streetCheck = false;
        } else {
            this.streetCheck = true;
        }
        this.checkIfFilledOut();
    }

    // validate on blur
    checkStreetNumber(event: any) {
        if (this.streetNumberFormControl.value === '') {
            this.streetNumber = '';
            this.errorMessageStreetNumber.message = 'Bitte Hausnummer eingeben';
        } else if(!this.streetNumberFormControl.value.match('^[a-zA-Z0-9 -]*$')) {
            this.streetNumber = '';
            this.errorMessageStreetNumber.message = 'Nur Ziffern oder Buchstaben sind erlaubt.';
        } else {
            this.streetNumber = this.streetNumberFormControl.value;
            this.errorMessageStreetNumber.message = '';
        }
        this.checkIfFilledOut();
    }

    checkIfFilledOut() {

        if ((this.street === '')  || (this.plz === '') || (this.streetNumber === '')) {
            this.user_service.setAddressValid(false);
            if (this.street === '') {
                this.user_service.setStreetValid(false);
            } else {
                this.user_service.setStreetValid(true);
            }
            if (this.plz === '') {
                this.user_service.setZipValid(false);
            } else {
                this.user_service.setZipValid(true);
            }
        } else {
            this.user_service.setAddressValid(true);
            this.user_service.setStreetValid(true);
            this.user_service.setZipValid(true);
            sessionStorage.setItem('user_address_id', this.userAddressId);
            this.onSubmit();
        }
    }

    /**
     * Other min order value functions
     */

    // go back to previous page
    onGoBack() {
        this.location.back();
    }

    // Adresse zur Validierug an BE senden, response: Adresse io / Alternativen
    onSubmit() {
        // save data to global user object
        this.saveUserData();

        this.cart_service.calcMinVal(this.plz, this.district);
        // this.onShowAddressFound = true;

        // save to temp data to show previous address while input new values
        this.tempUser = this.user;
        this.saveAddress(this.user.adress.plz, this.user.adress.city, this.user.adress.district, this.user.adress.street, this.user.adress.street_number);

        // save to storage
        sessionStorage.setItem('tempUser', JSON.stringify(this.user));

        // this.validateAddress.emit(true);
        // this.router.navigate(['dashboard']);
    }

    /**
     * Resets the whole form and all given data
     */
    onResetForm() {
        this.onShowAddressFound = false;
        this.user = new User();

        this.plz = '';
        this.street = '';
        this.city = '';
        this.district = '';
        this.streetNumber = '';

        this.searchPlzFormControl.setValue(null);
        this.searchStreetFormControl.setValue(null);
        this.streetNumberFormControl.setValue(null);

        this.resettedPlz = true;
        this.resettedStreet = true;

        // this.errorMessageStreetNumber.message = '';
    }

    // save form data to user object
    saveUserData() {
        this.user = new User();
        this.user.adress.plz = this.plz;
        this.user.adress.street = this.street;
        this.user.adress.city = this.city;
        this.user.adress.district = this.district;
        this.user.adress.street_number = this.streetNumber;
    }

    // set user data over service to set usser globally
    saveAddress(plz: string, city: string, district: string, street: string, number: string) {
        this.user_service.setUserAdressData(plz, city, district, street, number);

        // get price for min order value
        this.subscriptions.push(this.frontend_service.getMinVal(plz, district).subscribe(
            (data: string) => {
                this.cart_service.setMinVal(data);
                // verzögerung bei min value berechnung
                // thisNewMinValueSet = true;
            }
        ));
    }

    checkDeleteStreet(event) {
        if (event.inputType == 'deleteContentBackward' || event.inputType == 'deleteContentForward') {
            if (this.searchStreetFormControl.value == '') {
                this.onResetForm();
            }
        }
    }

    checkDeleteZip(event) {
        if (event.inputType == 'deleteContentBackward' || event.inputType == 'deleteContentForward') {
            if (this.searchPlzFormControl.value == '') {
                this.onResetForm();
            }
        }
    }

    // Misc
    onLog() {
        // console.log('LOG COMPONENT: %o', this);
    }


    getAnmeldung(): void {
        this.sessionService.getAnmeldung().subscribe(
            objTmp => this.updateAnmeldung(objTmp)
        )
    }

    updateAnmeldung(obj: Anmeldung) {
        this.anmeldung = obj;
        // console.log("Daten in der Anmeldung wurden geändert");
    }

}
