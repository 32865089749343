/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./liefergebiete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./liefergebiete.component";
import * as i4 from "../../../services/main.service";
import * as i5 from "../../../services/frontend.service";
import * as i6 from "@angular/common/http";
var styles_LiefergebieteComponent = [i0.styles];
var RenderType_LiefergebieteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LiefergebieteComponent, data: {} });
export { RenderType_LiefergebieteComponent as RenderType_LiefergebieteComponent };
function View_LiefergebieteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.district; _ck(_v, 1, 0, currVal_0); }); }
export function View_LiefergebieteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "static-page-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "header-background-img header-wrapper"], ["style", "background: url('assets/img/header/header-about.png'); background-size: cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Liefergebiete"])), (_l()(), i1.ɵeld(5, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "mp-font-large-yellow text-center my-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Liefergebiete"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "card card-body liefergebite"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "col-xxl-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [["class", "d-flex flex-wrap justify-content-center justify-content-xxl-start list-unstyled mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiefergebieteComponent_1)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.deliveryAreas; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_LiefergebieteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-liefergebiete", [], null, null, null, View_LiefergebieteComponent_0, RenderType_LiefergebieteComponent)), i1.ɵdid(1, 245760, null, 0, i3.LiefergebieteComponent, [i4.MainService, i5.FrontendService, i6.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiefergebieteComponentNgFactory = i1.ɵccf("app-liefergebiete", i3.LiefergebieteComponent, View_LiefergebieteComponent_Host_0, {}, {}, []);
export { LiefergebieteComponentNgFactory as LiefergebieteComponentNgFactory };
