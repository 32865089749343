export class Prices {
    regular_price: number;                          // Regulärer Preis
    happy_hour_price: number;                       // Preise im Happy Hour
    lunch_price: number;                            // Preis beim Mittagstisch
    pickup_price: number;                           // Preis beim Abholen
    price_for_added_ingredients: number;            // Zusatzkosten für hinugefügte Erweiterungsartikel
    // TODO: this needs individual number of sizes, maybe get them from backend??
    mega_deal_price: number;
    real_price_total: number;
    real_price: number;

    // needed for recalculating the final price when the user switches the delivery time
    // (was added due to issues with the 'only_on_lunch' feature, which adds the possibility that an article with free addables can only be active when its lunch time)
    price_for_added_free_addables: number;

    constructor(regular: number, happy_hour: number, lunch_price: number, pickup: number, added_ing: number, mega_deal_price: number, free_addables_price: number) {
        this.regular_price = regular;
        this.happy_hour_price = happy_hour;
        this.lunch_price = lunch_price;
        this.pickup_price = pickup;
        this.price_for_added_ingredients = added_ing;
        this.mega_deal_price = mega_deal_price;
        this.price_for_added_free_addables = free_addables_price;
    }

    setPriceAddedIng(price: number) {
        this.price_for_added_ingredients = price;
    }
}
