export const environment = {
    production: false
};

export const url = {
    frontend: 'https://dev-pizzaback.awag-it.de/api',
    // frontend: 'https://system-pizza.awag-it.de/api',
    image:  'https://dev-pizzaback.awag-it.de/images',
    // image:  'https://system-pizza.awag-it.de/images',
    submit: 'https://dev-pizzaback.awag-it.de/direct-us/server/order_server.php',
    // submit: 'https://system-pizza.awag-it.de/direct-us/server/order_server.php',
    adress: 'https://dev-pizzaback.awag-it.de/api',
    // adress: 'https://system-pizza.awag-it.de/api',
};
