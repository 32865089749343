import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-mittags-angebot',
  templateUrl: './mittags-angebot.component.html',
  styleUrls: ['./mittags-angebot.component.scss']
})
export class MittagsAngebotComponent implements OnInit {
  storeData: any;

  // headerBilder
    headerbild_Daves = "/assets/img/header/Daves_Headerbild_Obergruppen_304x170px_drei/Daves_Headerbild_Obergruppen_304x170px_Mittagsangebot.jpg";
    headerbild_Daves_Desktop = "/assets/img/header/Daves_Headerbild_Obergruppen_2184x656px_burg_stellen_mittags/Daves_Headerbild_Obergruppen_2184x656px_Mittagsangebot.jpg";


    sizeSmall = true;


  constructor() { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
    window.onresize = this.setHeaderbild;
    this.setHeaderbild();

  }

  // Headerbild setzen
    setHeaderbild() {
        let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall) return;
        this.sizeSmall = small;
        /*console.log("Kleines Bild = ",small);*/
        if (small === false) {
            this.headerbild_Daves = this.headerbild_Daves_Desktop;
        } else {
        }
    }

/*getHeaderBild() {
      var tmpUrl;
      if (this.storeData.id== 2){
          tmpUrl= this.headerbild_Mittags_MP;
      } else if(this.storeData.id== 7) {
          tmpUrl= this.headerbild_Mittags_MD;
      } else if(this.storeData.master== 3) {
          tmpUrl= this.headerbild_Mittags_PB;
      }

      return  tmpUrl;
}*/

}
