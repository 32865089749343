import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./main.service";
var SettingsService = /** @class */ (function () {
    function SettingsService(http, main_service) {
        this.http = http;
        this.main_service = main_service;
    }
    SettingsService.prototype.get = function (name) {
        var _this = this;
        if (this.loaded) {
            return new Promise(function (resolve) {
                resolve(_this.settings[name]);
            });
        }
        else {
            return new Promise(function (resolve) {
                _this.getSettings().then(function (promise) {
                    _this.loaded = true;
                    resolve(_this.settings[name]);
                });
            });
        }
    };
    SettingsService.prototype.getSettings = function () {
        var _this = this;
        return this.http.get(this.main_service.getFrontendUrl() + '/settings')
            .toPromise()
            .then(function (data) {
            _this.settings = data;
        });
    };
    SettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.inject(i1.HttpClient), i0.inject(i2.MainService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
