/**
 * @license
 * Copyright Meisterpizza Freiberg All Rights Reserved.
 */
import { OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { forkJoin, of } from 'rxjs';
import { FrontendService } from 'src/app/services/frontend.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { DateService } from '../../../services/date.service';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from '../../../services/session.service';
import { PageScrollService } from 'ngx-page-scroll-core';
/* Decorator */
var Step5Component = /** @class */ (function () {
    /**
     * Component constructor which provides service classes
     * @param frontend_service Frontend service provides mostly api calls used inside logic
     */
    function Step5Component(userService, frontendService, cartService, location, router, dateService, pageScrollService, sessionService, document) {
        var _this = this;
        this.userService = userService;
        this.frontendService = frontendService;
        this.cartService = cartService;
        this.location = location;
        this.router = router;
        this.dateService = dateService;
        this.pageScrollService = pageScrollService;
        this.sessionService = sessionService;
        this.document = document;
        /* emit true or false to show popup */
        // @Output() showPriceDifferencesPopupEmitter: EventEmitter<any> = new EventEmitter();
        /* 'addressValid' is set to true when given address inside address input component is correct and was saved to current session; then is emitted fomr child <app-address-input> */
        this.addressValid = false;
        this.streetValid = false;
        this.zipValid = false;
        /* Check if service has loaded api call from service and render page on success */
        this.ready = false;
        /* init the array which holds the possible time values for the delivery */
        this.deliveryLaterTimeValues = [];
        /* Set if shop is closed at the moment the users loads page  */
        this.shopClosed = false;
        /* get all cart items from service */
        this.cartItems = [];
        /* set true if no time sleection was made to disable 'next'-button */
        this.noTimeSelected = true;
        this.showPriceDifferencesPopupOpened = false;
        this.no_delivery_until_closed_popup = null;
        this.no_delivery_until_closed_next_time = '';
        this.next_delivery_later_time = false;
        this.editStep = false;
        this.showFieldsV = false;
        this.subscriptions = [];
        this.buttonValue = "null";
        this.showPopup_no_delivery_until_closed_popup = false;
        this.seenPopup_no_delivery_until_closed_popup = false;
        this.noMoreDeliveryLunch = false;
        this.noMoreDelivery = false;
        this.showPopupNow = false;
        this.timeValuesCreated = false;
        this.isDelivDateNow = false;
        this.initData();
        this.initDataInterval = setInterval(function (data) {
            _this.cartService.checkShopOpenOrClosed().then(function () {
                _this.updateTimes();
            });
        }, 120000);
    }
    Step5Component.prototype.initData = function () {
        var _this = this;
        this.no_delivery_anymore_popup = false;
        /* Make api and service calls and get data - fork join is used to check the completion of alle calls at once */
        forkJoin([
            /* make api calls */
            this.frontendService.getStoreData(),
            this.frontendService.getOpeningHoursNow(),
            this.frontendService.getOpeningHours(1),
            /* provide number of observables from the related service */
            of(this.cartService.deliveryLaterObservable),
            of(this.cartService.on_pickup_observable),
            of(this.cartService.priceCartObservable),
            of(this.cartService.shopClosedObservable),
            of(this.cartService.article_group_list_observable),
            of(this.cartService.deliveryLaterTimeValuesObservable),
            of(this.cartService.onLunchObservable),
            of(this.cartService.deliveryDateObservable),
            of(this.cartService.deliveryTimeObservable),
            of(this.cartService.showPriceDifferencesPopupObservable),
            of(this.cartService.priceCartTempObservable),
            of(this.cartService.shopClosedUntilTomorrowObservable),
            // von Step 4
            of(this.userService.addressValidObservable),
            of(this.userService.streetValidObservable),
            of(this.userService.zipValidObservable),
            of(this.cartService.noMoreDeliveryObservable),
            of(this.cartService.noMoreDeliveryLunchObservable)
        ])
            .subscribe(function (data) {
            _this.distributeServiceDataOnSuccess(data);
        }, function (err) { return console.error(err); });
    };
    /**
     * Call angular lifecycle hook on init
     */
    Step5Component.prototype.ngOnInit = function () {
        var _this = this;
        this.showFieldsV = false;
        this.subscriptions.push(this.cartService.deliveryDateObservable.subscribe(function (data) {
            _this.deliveryDate = data;
        }));
        /* get delivery time form service */
        this.subscriptions.push(this.cartService.deliveryTimeObservable.subscribe(function (data) {
            _this.deliveryTime = data;
        }));
        this.getAnmeldung();
        if (this.anmeldung.edit) {
            var visible = this.anmeldung.isHolen || this.anmeldung.isLiefern;
            if (visible) {
                this.sessionService.showBtnWarenkorb();
            }
        }
        else {
            this.initDeliveryDateAndTime();
            this.sessionService.showBtnBestellen();
        }
        if (this.router.url.includes('edit')) {
            this.editStep = true;
            // this.anmeldung.valueHolen = '';
            // this.anmeldung.valueLiefern = '';
            var scroller_1 = this.pageScrollService;
            var scrollerDoc_1 = this.document;
            setTimeout(function () {
                scroller_1.scroll({
                    document: scrollerDoc_1,
                    scrollTarget: '#datum',
                    scrollOffset: 40,
                    duration: 500
                });
            }, 1500);
        }
        else {
            this.editStep = false;
        }
        this.subscriptions.push(this.cartService.timeValuesCreatedObservable.subscribe(function (data) {
            _this.timeValuesCreated = data;
        }));
        this.subscriptions.push(this.cartService.isDelivDateNowObservable.subscribe(function (data) {
            _this.isDelivDateNow = data;
        }));
        this.cartService.checkShopOpenOrClosed().then(function () {
            _this.updateTimes();
        });
    };
    /**
     * Call angular lifecycle hook on destroy
     */
    Step5Component.prototype.ngOnDestroy = function () {
        clearInterval(this.initDataInterval);
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    // async getIngredients() {
    //     const ingredientsByProductSubCategory = await this.frontendService.getIngredientsByProductSubCategory(4);
    //     return ingredientsByProductSubCategory;
    // }
    /**
     * Distribute the service data to the corresponding attributes
     */
    Step5Component.prototype.distributeServiceDataOnSuccess = function (data) {
        var _this = this;
        /* distribute the api call response to the corresponding vars */
        this.storeData = data[0];
        this.openingHoursNow = data[1];
        this.openingHours = data[2];
        /* subscribe to observables */
        data[3].subscribe(function (event) { return _this.deliveryLater = event; });
        data[4].subscribe(function (event) { return _this.pickup = event; });
        data[5].subscribe(function (event) { return _this.priceCart = event; });
        data[6].subscribe(function (event) { return _this.shopClosed = event; });
        data[7].subscribe(function (event) { return _this.cartItems = event; });
        // data[8].subscribe((event: any) => this.deliveryLaterTimeValues = event);
        data[8].subscribe(function (data) {
            if (typeof data !== 'undefined') {
                var oldTimes = JSON.stringify(_this.deliveryLaterTimeValues);
                var newTimes = data;
                if (oldTimes != newTimes) {
                    _this.deliveryLaterTimeValues = data;
                    if (_this.noTimeSelected && _this.deliveryLaterTimeValues && _this.deliveryLaterTimeValues.length && _this.deliveryLaterTimeValues[0].time && !_this.editStep) {
                        _this.deliveryTime = _this.deliveryLaterTimeValues[0].time;
                    }
                    var today = _this.dateService.newDate();
                    today.setHours(0);
                    today.setMinutes(0);
                    today.setSeconds(0);
                    today.setMilliseconds(0);
                    var deliveryDate = new Date(_this.deliveryDate.getTime());
                    deliveryDate.setHours(0);
                    deliveryDate.setMinutes(0);
                    deliveryDate.setSeconds(0);
                    deliveryDate.setMilliseconds(0);
                    /**
                     * Keine Zeiten für die gewählte Lieferzeit
                     */
                    if (data.length > 0) {
                        /**
                         * Vorbestellzeiten sind heute noch verfügbar
                         */
                        if (today.getTime() === deliveryDate.getTime()) {
                            _this.no_delivery_anymore_popup = false;
                            if (_this.shopClosed && !_this.shopClosedUntilTomorrow) {
                                _this.next_delivery_later_time = data[0].time.substring(0, 5);
                                _this.cartService.next_delivery_later_time = _this.next_delivery_later_time;
                            }
                            for (var openingHour in _this.openingHoursNow) {
                                var open_from = _this.dateService.newDate();
                                // tslint:disable-next-line: radix
                                open_from.setHours(parseInt(_this.openingHoursNow[openingHour].time_from.substring(0, 2)));
                                // tslint:disable-next-line: radix
                                open_from.setMinutes(parseInt(_this.openingHoursNow[openingHour].time_from.substring(3, 5)));
                                var open_to = _this.dateService.newDate();
                                // tslint:disable-next-line: radix
                                open_to.setHours(parseInt(_this.openingHoursNow[openingHour].time_to.substring(0, 2)));
                                // tslint:disable-next-line: radix
                                open_to.setMinutes(parseInt(_this.openingHoursNow[openingHour].time_to.substring(3, 5)));
                                var now = _this.dateService.newDate();
                                var next_delivery_later_date = _this.dateService.newDate();
                                // tslint:disable-next-line: radix
                                next_delivery_later_date.setHours(parseInt(data[0].time.substring(0, 2)));
                                // tslint:disable-next-line: radix
                                next_delivery_later_date.setMinutes(parseInt(data[0].time.substring(3, 5)));
                                _this.no_delivery_until_closed_next_time = data[0].time;
                                if (now > open_from && now < open_to && (next_delivery_later_date > open_to) && _this.no_delivery_until_closed_popup === null) {
                                    _this.no_delivery_until_closed_popup = true;
                                    _this.no_delivery_until_closed_next_time = data[0].time;
                                }
                                if (now > open_from && now < open_to) {
                                    _this.next_closed_time = _this.openingHoursNow[openingHour].time_to.substring(0, 5);
                                }
                            }
                        }
                    }
                    else {
                        _this.onLunch = false;
                        /**
                         * Die Lieferzeit ist nicht für heute oder der Shop ist heute nicht mehr geöffnet
                         */
                        if (today.getTime() !== deliveryDate.getTime()) {
                            _this.no_delivery_anymore_popup = false;
                            /**
                             * Dem Kunden anbieten, dass er noch sofort bestellen kann oder ab morgen
                             */
                        }
                        else {
                            if (_this.shopClosedUntilTomorrow) {
                                _this.setDeliveryTomorrow();
                                _this.no_delivery_anymore_popup = false;
                            }
                            else {
                                if (today.getTime() === deliveryDate.getTime()) {
                                    for (var openingHour in _this.openingHoursNow) {
                                        // Kostyil (TODO)
                                        if (!_this.openingHoursNow[openingHour].time_from) {
                                            _this.openingHoursNow[openingHour].time_from = '00:01';
                                        }
                                        if (!_this.openingHoursNow[openingHour].time_to) {
                                            _this.openingHoursNow[openingHour].time_to = '00:02';
                                        }
                                        var open_from = _this.dateService.newDate();
                                        // tslint:disable-next-line: radix
                                        open_from.setHours(parseInt(_this.openingHoursNow[openingHour].time_from.substring(0, 2)));
                                        // tslint:disable-next-line: radix
                                        open_from.setMinutes(parseInt(_this.openingHoursNow[openingHour].time_from.substring(3, 5)));
                                        var open_to = _this.dateService.newDate();
                                        // tslint:disable-next-line: radix
                                        open_to.setHours(parseInt(_this.openingHoursNow[openingHour].time_to.substring(0, 2)));
                                        // tslint:disable-next-line: radix
                                        open_to.setMinutes(parseInt(_this.openingHoursNow[openingHour].time_to.substring(3, 5)));
                                        var now = _this.dateService.newDate();
                                        var next_delivery_later_date = _this.dateService.newDate();
                                        if (now > open_from && now < open_to) {
                                            _this.next_closed_time = _this.openingHoursNow[openingHour].time_to.substring(0, 5);
                                        }
                                    }
                                }
                                _this.no_delivery_anymore_popup = true;
                            }
                        }
                    }
                }
            }
        }, function (err) { return console.error(err); });
        data[9].subscribe(function (event) { return _this.onLunch = event; });
        data[10].subscribe(function (event) { return _this.deliveryDate = event; });
        data[11].subscribe(function (event) { return _this.deliveryTime = event; });
        data[12].subscribe(function (event) {
            _this.showPriceDifferencesPopup = event;
            if (event === true && _this.showPriceDifferencesPopupOpened === false) {
                _this.showPriceDifferencesPopupOpened = true;
            }
        });
        data[13].subscribe(function (event) { return _this.priceCartTemp = event; });
        data[14].subscribe(function (event) { return _this.shopClosedUntilTomorrow = event; });
        data[15].subscribe(function (event) { return _this.updateAddressValid(event); });
        data[16].subscribe(function (event) { return _this.updateStreetValid(event); });
        data[17].subscribe(function (event) { return _this.updateZipValid(event); });
        data[18].subscribe(function (event) {
            _this.noMoreDelivery = event;
            if (_this.noMoreDelivery && !_this.seenPopup_no_delivery_until_closed_popup) {
                _this.showPopup_no_delivery_until_closed_popup_f(true);
            }
        });
        data[19].subscribe(function (event) {
            _this.noMoreDeliveryLunch = event;
            if (_this.noMoreDeliveryLunch && !_this.seenPopup_no_delivery_until_closed_popup) {
                _this.showPopup_no_delivery_until_closed_popup_f(true);
            }
        });
        /** set page ready when finished */
        this.ready = true;
        this.closedInThisDate = this.openingHoursNow[0].closedInThisDate;
        this.updateTimes();
    };
    Step5Component.prototype.updateTimes = function () {
        if (this.shopClosed) {
            this.onSetDeliveryLater(true);
        }
        var now = this.dateService.newDate();
        var time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
        if (sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
            this.cartService.setDeliveryDateAndTime(now, time);
        }
        if (this.anmeldung.edit) {
            time = sessionStorage.getItem('deliveryTime');
        }
        var tomorrow = this.dateService.newDate();
        tomorrow.setDate(this.dateService.newDate().getDate() + 1);
        tomorrow.setHours(0, 0, 0, 1);
        if (this.shopClosedUntilTomorrow && this.deliveryDate.getTime() < tomorrow.getTime()) {
            // if (this.shopClosedUntilTomorrow) {
            this.minDeliveryDate = tomorrow;
            if (!sessionStorage.getItem('deliveryTime')) {
                this.cartService.setDeliveryDateAndTime(this.minDeliveryDate, time);
            }
        }
        else {
            this.minDeliveryDate = this.dateService.newDate();
            if (!sessionStorage.getItem('deliveryDate') || sessionStorage.getItem('deliveryDate') == 'Invalid Date') {
                this.cartService.setDeliveryDateAndTime(this.minDeliveryDate, time);
            }
            else {
                var selectedDate = new Date(sessionStorage.getItem('deliveryDate'));
                if (typeof this.deliveryTime == 'undefined' || this.deliveryTime == '') {
                    this.cartService.setDeliveryDateAndTime(selectedDate, time);
                }
                else {
                    this.cartService.setDeliveryDateAndTime(selectedDate, this.deliveryTime);
                }
            }
        }
    };
    Step5Component.prototype.getAnmeldung = function () {
        var _this = this;
        this.sessionService.getAnmeldung().subscribe(function (objTmp) { return _this.updateAnmeldung(objTmp); });
    };
    Step5Component.prototype.updateAnmeldung = function (obj) {
        this.anmeldung = obj;
    };
    Step5Component.prototype.updateStreetValid = function (value) {
        this.streetValid = value;
        this.checkDisableButtons();
    };
    Step5Component.prototype.updateZipValid = function (value) {
        this.zipValid = value;
        this.checkDisableButtons();
    };
    Step5Component.prototype.updateAddressValid = function (value) {
        this.addressValid = value;
        this.checkDisableButtons();
    };
    Step5Component.prototype.checkDisableButtons = function () {
        var wohinValid = this.addressValid && this.zipValid;
        wohinValid = wohinValid && this.streetValid;
        var enable = wohinValid || this.anmeldung.isHolen;
        this.sessionService.setDisableButtons(!enable);
    };
    Step5Component.prototype.initDeliveryDateAndTime = function () {
        var now = this.dateService.newDate();
        var time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
        this.cartService.setDeliveryDateAndTime(now, time);
        // this.cartService.setDeliveryDateAndTime(now, time).then(() => {
        // if (this.anmeldung.edit) {
        // if (!this.showPriceDifferencesPopup) {
        //     this.router.navigate(['/shop/bestellen/warenkorb']);
        // }
        // }
        // });
    };
    // onSetDeliveryLater(deliveryLater: boolean) {
    //     this.deliveryLater = deliveryLater;
    //     this.cartService.setDeliveryLater(deliveryLater);
    //     sessionStorage.setItem('deliveryLater', deliveryLater === true ? '1' : '0');
    // }
    /**
     * Set the delivery date for the current order
     * @param date The given date object
     */
    Step5Component.prototype.onChangeDeliveryDate = function (date) {
        var _this = this;
        if (!this.showFieldsV) {
            this.showFieldsV = true;
        }
        if (!this.deliveryLater) {
            this.onSetDeliveryLater(true);
        }
        this.timeValuesCreated = false;
        this.deliveryDate = date;
        if (!this.deliveryTime) {
            this.deliveryTime = '00:00';
        }
        this.cartService.setDeliveryDateAndTime(this.deliveryDate, this.deliveryTime).then(function () {
            _this.cartService.checkLunchTime();
        });
        sessionStorage.setItem('deliveryDate', this.deliveryDate.toString());
    };
    /**
     * Set the delivery time for the current order
     */
    Step5Component.prototype.onChangeDeliveryTime = function () {
        this.cartService.setDeliveryDateAndTime(this.deliveryDate, this.deliveryTime);
        this.noTimeSelected = false;
        // for (let i; i < this.deliveryLaterTimeValues.length; i++) {
        //     if (this.deliveryLaterTimeValues[i].time == this.deliveryTime) {
        //         if (this.deliveryLaterTimeValues[i].label.includes('chnellstmöglich')) {
        //             sessionStorage.setItem('Schnell', '1');
        //             sessionStorage.setItem('Schnell_text', this.deliveryLaterTimeValues[i].label);
        //         } else {
        //             sessionStorage.setItem('Schnell', '0');
        //             sessionStorage.setItem('Schnell_text', '');
        //         }
        //     }
        // }
    };
    /**
     * Go back in in page history
     */
    Step5Component.prototype.onGoBack = function () {
        this.location.back();
    };
    Step5Component.prototype.setDeliveryTomorrow = function (setSeen) {
        if (setSeen === void 0) { setSeen = false; }
        if (setSeen) {
            this.showPopup_no_delivery_until_closed_popup_f(true);
        }
        this.no_delivery_anymore_popup = false;
        var now = this.dateService.newDate();
        now.setSeconds(0);
        now.setMinutes(0);
        now.setMilliseconds(0);
        now.setHours(0);
        var today = this.dateService.newDate();
        var tomorrow = new Date(today);
        tomorrow.setTime(tomorrow.getTime() + 86400000);
        this.cartService.setDeliveryDateAndTime(tomorrow, '00:00');
        this.showFieldsV = true;
    };
    Step5Component.prototype.setDeliveryNow = function () {
        this.onSetDeliveryLater(true);
        this.no_delivery_anymore_popup = false;
        if (this.pickup) {
            this.router.navigate(['/shop']);
        }
        else {
            this.router.navigate(['/willkommen/start']);
        }
    };
    Step5Component.prototype.confirmPriceDifference = function () {
        this.router.navigate(['/shop/bestellen/warenkorb']);
        this.showPriceDifferencesPopup = false;
    };
    /**
     * Log component for debugging purposes
     */
    Step5Component.prototype.onLog = function () {
    };
    Step5Component.prototype.onChangePickup = function (pickup) {
        var _this = this;
        sessionStorage.setItem('pickupChanged', '0');
        var scroller = this.pageScrollService;
        var scrollerDoc = this.document;
        this.showFieldsV = false;
        var now = this.dateService.newDate();
        var time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
        this.deliveryTime = time;
        // this.pickup = pickup;
        sessionStorage.setItem('pickup', pickup === true ? '1' : '0');
        sessionStorage.setItem('deliveryTime', time);
        sessionStorage.setItem('deliveryTypeSelected', '1');
        this.noTimeSelected = true;
        this.cartService.setPickup(pickup);
        this.cartService.setDeliveryDateAndTime(now, time).then(function () {
            if (!_this.anmeldung.edit) {
                _this.sessionService.showBtnBestellen();
            }
            if (!pickup) {
                _this.sessionService.setLiefern();
                _this.onSetPickup(false);
                setTimeout(function () {
                    scroller.scroll({
                        document: scrollerDoc,
                        scrollTarget: '.step4-mp-container',
                        scrollOffset: 40,
                        duration: 500
                    });
                }, 500);
            }
            else {
                _this.sessionService.setHolen();
                _this.onSetPickup(true);
                setTimeout(function () {
                    scroller.scroll({
                        document: scrollerDoc,
                        scrollTarget: '.target-scroll-pikup',
                        scrollOffset: 40,
                        duration: 500
                    });
                }, 500);
            }
            _this.checkDisableButtons();
        });
    };
    Step5Component.prototype.onSetPickup = function (pickup) {
        // this.pickup = pickup;
        // this.cartService.setPickup(pickup);
        // sessionStorage.setItem('pickup', pickup === true ? '1' : '0');
        // 0-liefern; 1-selbst
        var _this = this;
        if (this.pickup) {
            sessionStorage.removeItem('tempUser');
        }
        this.cartService.checkShopOpenOrClosed().then(function () {
            _this.updateTimes();
        });
    };
    Object.defineProperty(Step5Component.prototype, "getEnviorment", {
        get: function () {
            if (window.location.href.indexOf('pizza.awag') > 0 || window.location.href.indexOf('pizza-dd.awag') > 0) {
                return 'dev';
            }
            if (window.location.href.indexOf('stage') > 0) {
                return 'stage';
            }
            if (window.location.href.indexOf('meisterpizza.de') > 0) {
                return 'prod';
            }
        },
        enumerable: true,
        configurable: true
    });
    Step5Component.prototype.weiter = function () {
        var scroller = this.pageScrollService;
        var scrollerDoc = this.document;
        setTimeout(function () {
            scroller.scroll({
                document: scrollerDoc,
                scrollTarget: '.step3-mp-container',
                scrollOffset: 40,
                duration: 500
            });
        }, 500);
    };
    Step5Component.prototype.onSetDeliveryLater = function (deliveryLater) {
        var _this = this;
        this.deliveryLater = deliveryLater;
        this.cartService.setDeliveryLater(deliveryLater);
        sessionStorage.setItem('deliveryLater', deliveryLater === true ? '1' : '0');
        if (!deliveryLater) {
            var now = this.dateService.newDate();
            var time = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
            this.deliveryDate = now;
            this.deliveryTime = time;
            this.cartService.setDeliveryDateAndTime(now, time).then(function () {
                if (_this.editStep) {
                    if (!_this.showPriceDifferencesPopup) {
                        _this.router.navigate(['/shop/bestellen/warenkorb']);
                    }
                }
            });
        }
    };
    Step5Component.prototype.showFields = function (popap, sofort) {
        if (popap === void 0) { popap = false; }
        if (sofort === void 0) { sofort = false; }
        if (popap) {
            this.showPopup_no_delivery_until_closed_popup_f(true, true);
        }
        if (sofort) {
            sessionStorage.setItem('sofortAfterPopapClick', '1');
            clearInterval(this.initDataInterval);
            this.onSetDeliveryLater(false);
            this.router.navigate(['/shop']);
            return;
        }
        /*this.onSetDeliveryLater(deliveryLater);*/
        this.showFieldsV = true;
        var scroller = this.pageScrollService;
        var scrollerDoc = this.document;
        setTimeout(function () {
            scroller.scroll({
                document: scrollerDoc,
                scrollTarget: '.stpZt',
                scrollOffset: 40,
                duration: 500
            });
        }, 500);
    };
    Step5Component.prototype.showPopup_no_delivery_until_closed_popup_f = function (state, show) {
        if (show === void 0) { show = false; }
        this.showPopup_no_delivery_until_closed_popup = state;
        if (show) {
            this.showPopupNow = show;
        }
        if (!state) {
            this.seenPopup_no_delivery_until_closed_popup = true;
        }
    };
    return Step5Component;
}());
export { Step5Component };
