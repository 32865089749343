<div class="static-page-layout">
    <ng-container>

        <div class="row">

            <!--Header-->
            <div class="col-md-12">
                <!--<div class="header-background-img header-wrapper"

                    style="background-image: url('/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Spar-Coupon.jpg')">
                    <h1 class="static-page-title">Spar-Coupon</h1>
                </div>-->
                <div class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + getHeaderBild() + ')'">
                    <h1 class="static-page-title">Mittags-Angebot</h1>
                </div>

            </div>

            <!--Content-->
            <div class="col-md-12">
                <div class="center-content-narrow">

                    <h2 class="text-center mp-font-medium-yellow">Spar-Coupon</h2>

                    <!--Content Meisterpizza-->
                    <div class="contentCoupon">
                        <h5 class="text-center">Willkommen in unserem Stammkundenbereich!</h5>
                        <p class="text-left spacing">
                            Bist du bei uns für Newsletter angemeldet oder bekommst du von uns die Werbung per Post,
                            erhältst du dabei unsere Sparcoupons.
                        </p>
                        <h5 class="text-center">Wie es funktioniert?</h5>
                        <p class="text-left spacing">
                            Lege deine Lieblingspizza, Burger, Salat oder Fingerfood zum Mindestbestellwert in den
                            Warenkorb
                            und gebe den Sparcoupon-code bei der Bestellung ein wie es hier angezeigt:
                        </p>

                        <div class="text-center containerBild">
                            <img class="img-fluid rounded d-block " alt="Bestelldetails"
                                src='assets/img/dummy/bestelldetailsMP.png'>
                        </div>

                        <p class="text-left spacing">
                            Der Artikel aus dem Sparcoupon erscheint automatisch im Warenkorb und
                            wird dir zusammen mit deiner Bestellung zugeliefert oder zur Abholung vorbereitet.
                        </p>

                      <!--  <p class="text-center spacing mp-font-medium-yellow">
                            Danke, dass du bei uns bleibst!
                        </p>-->
                    </div>

                </div>
            </div>
        </div>

    </ng-container>
</div>
