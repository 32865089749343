import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private auth_service: AuthService,
        private router: Router,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.auth_service.isAuthenticated()) {
            // weiterleitung, falls Nutzer nicht eingeloggt ist
            this.router.navigate(['login']);
        }
        return this.auth_service.isAuthenticated();
    }
}
