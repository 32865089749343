import {NgModule} from "@angular/core";
import {
    MatAutocompleteModule,
    MatCheckboxModule, MatDatepickerModule,
    MatInputModule, MatNativeDateModule, MatRadioModule,
    MatSelectModule, MatSnackBarModule,
    MatStepperModule, MatTabsModule, MatTooltipModule
} from '@angular/material';

@NgModule(
    {
        imports: [
            MatInputModule,
            MatAutocompleteModule,
            MatStepperModule,
            MatSelectModule,
            MatCheckboxModule,
            MatRadioModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatTabsModule,
            MatTooltipModule,
            MatSnackBarModule,
        ],
        exports: [
            MatInputModule,
            MatAutocompleteModule,
            MatStepperModule,
            MatSelectModule,
            MatCheckboxModule,
            MatRadioModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatTabsModule,
            MatTooltipModule,
            MatSnackBarModule,
        ]
    }
)

export class MaterialModule{}