import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zahlungsarten',
  templateUrl: './zahlungsarten.component.html',
  styleUrls: ['./zahlungsarten.component.scss']
})
export class ZahlungsartenComponent implements OnInit {
  storeData: any;
  // headerBilder
  headerbild_MP = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_MP.jpg";
  headerbild_PB = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_PB.jpg";
  headerbild_MP_Desktop = "/assets/img/header/Headerbild_Obergruppen_Newsletter_MP.jpg";
  headerbild_PB_Desktop = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Newsletter.jpg";
  sizeSmall = true;
constructor() { }

ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
    // console.log("STOREDATE", this.storeData);
    this.setHeaderbild();
}

 // Headerbild setzen
 setHeaderbild() {
  let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
  if (small == this.sizeSmall) return;
  this.sizeSmall = small;
  /*console.log("Kleines Bild = ",small);*/
  if (small === false) {
      this.headerbild_MP = this.headerbild_MP_Desktop;
      this.headerbild_PB = this.headerbild_PB_Desktop;
      // console.log(this.headerbild_MP, "MP-DESKTOP");
      // console.log(this.headerbild_PB, "PB-DESKTOP");
  }
  else {
      // console.log(this.headerbild_MP, "MP-MOBIL");
      // console.log(this.headerbild_PB, "PB-MOBIL");
  }
}

}
