import {Injectable} from '@angular/core';
import {FrontendService} from './frontend.service';
import {Router} from '@angular/router';
import {CartService} from './cart.service';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';




@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    loaded: boolean;
    settings: any;
    constructor(
        private http: HttpClient,
        private main_service: MainService
    ) {


    }


    get(name){

        if( this.loaded ){
            return new Promise((resolve) => {
                resolve( this.settings[name] );
            });
        } else {
            return new Promise((resolve) => {
                    this.getSettings().then(promise => {

                        this.loaded = true;
                        resolve( this.settings[name] );
                    });

                }
            );
        }

    }

    getSettings(){
        return this.http.get(this.main_service.getFrontendUrl() + '/settings')
            .toPromise()
            .then((data: any) => {
                this.settings = data;
            });
    }

}
