<div class="static-page-layout">
  <ng-container>
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="header-wrapper d-flex justify-content-center align-items-center"
             [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
          <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
            <h1 class="static-page-title">Über uns</h1>
          </div>
        </div>
      </div>
    </div>


    <div class="inhalt-container px-4">
      <div class="row justify-content-center align-items-center m-0 flex-column">
        <h2 class="text-center">Wir stehen für ehrliche und nachhaltige Produkte.</h2>
        <p class="text-center">Von uns dürfen Sie immer etwas mehr erwarten…</p>
      </div>

      <div class="row justify-content-center align-items-center m-0">

        <div class="row justify-content-center align-items-center m-0 flex-column">
          <h2 class="text-center">.... bei unserem Catering</h2>
          <p class="">
            Stellen wir Dir deine gewünschten Speisen und Getränke frisch zusammen und liefern diese
            pünktlich an. Vorab beraten wir Dich gerne und erstellen Dir auch ein unverbindliches Angebot.
          </p>
        </div>


        <div class="row justify-content-center align-items-center m-0 flex-column">
          <h2 class="text-center">.... bei Großbestellung</h2>
          <p class="">
            Laufen unsere Öfen so richtig heiß! Das gut eingespielte Dave ́s Team ist in der Lage, deine
            Familie, Freunde und Verwandte in kürzester Zeit und in bester Qualität zu versorgen. Überzeuge
            dich selbst!
          </p>
        </div>

        <div class="row justify-content-center align-items-center m-0 flex-column">
          <h2 class="text-center">.... in der Qualität</h2>
          <p class="">
            Sind wir absolut spitze. Wir stehen für höchste Frische und beste Qualität ohne Kompromisse.
            Dafür verwenden wir ausschließlich Premium Zutaten:
          </p>
          <ul>
            <li>100% Gouda-Käse (laktosefrei)</li>
            <li>reines Rindfleisch</li>
            <li>Hähnchenbrust-Innenfilet</li>
            <li>Hochwertiger Kochschinken</li>
            <li>frisches Gemüse regionaler Händler</li>
          </ul>
          <p class="">Beim Einkauf achten wir sehr genau auf die Herkunft, Verarbeitung und Nachhaltigkeit
            unserer
            Zutaten. Wir treten unseren Lebensmitteln mit großem Respekt gegenüber, denn Qualität hat Ihren
            Geschmack!</p>

        </div>

        <div class="row justify-content-center align-items-center m-0 flex-column">
          <h2 class="text-center">.... im Preis-Leistungs-Verhältnis</h2>
          <p class="">
            ist uns Geiz zu billig. Durch unser ausgewogenes Preis-Leistungsverhältnis sind wir in der guten
            Lage unsere Produkte reichlich zu belegen und diesen in anspruchsvollen Verpackungen an unsere
            Kunden weiterzugeben. So sorgen wir für eine niveauvolle Essenskultur mit Genuss.
          </p>
        </div>

        <div class="row justify-content-center align-items-center m-0 flex-column">
          <h2 class="text-center">.... In der Hygiene</h2>
          <p class="">
            Sehen wir in der Pflicht, unseren hohen Anforderungen dafür täglich gerecht zu werden. Um eine
            professionelle Grundlage dafür zu schaffen, verwenden wir gelistete Reinigungsmittel. Auch im
            Umgang mit den Zutaten und Produkten, kann Hygiene in unserem Store nicht groß genug geschrieben
            werden. Ob es das Arbeiten mit speziellen Hygiene-Handschuhen oder die
            optimale Kühlung der Zutaten ist, Sauberkeit speilt für uns eine gewichte Rolle im täglichen
        </div>
      </div>
    </div>
  </ng-container>
</div>
