import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit {
  storeData: any;

  constructor() { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
  }

}