<div class="mega-deal-page static-page-layout">
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="header-wrapper d-flex justify-content-center align-items-center"
                 [style.backgroundImage]="'url(' + headerbild_Daves + ')'">
                <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                    <h1 class="static-page-title">Mega Deal</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="inhalt-container px-4">

        <div class="col-12 my-6 my-md-8">
            <h2 class="text-center">Ab 3 Pizzen aus unserem Pizzasortiment:</h2>
            <!-- <div class="mega-deal-item-wrapper mt-4">
                <div class="mega-deal-item">
                    <div class="mega-deal-item-size">
                        Small
                    </div>
                    <div class="mega-deal-item-price">
                        11,50 €
                    </div>
                </div>
                <div class="mega-deal-item">
                    <div class="mega-deal-item-size">
                        Medium
                    </div>
                    <div class="mega-deal-item-price">
                        15,50 €
                    </div>
                </div>
            </div> -->
            <div class="mega-deal-item-wrapper mt-4" *ngIf="sizesArr.length">
                <div class="mega-deal-item" *ngFor="let size of sizesArr">
                    <div class="mega-deal-item-size">
                        {{ size.name }}
                    </div>
                    <div class="mega-deal-item-price">
                        {{ size.mega_deal_price }} €
                    </div>
                    <div class="mega-deal-item-size">
                        Ab {{ size.megadeal_qty }} Pizzen
                    </div>
                </div>
            </div>
            <div class="mt-6 mt-md-8 text-center">
                <a routerLink="/shop/bestellen/130/pizza" class="mp-btn mp-btn-yellow-on-black mb-5">jetzt bestellen</a>
                <p class="text-center">Ab einer Bestellung von 3 Pizzen wird der Mega-Deal-Preis automatisch berechnet. Der Abholrabatt ist
                    beim Mega-Deal ausgeschlossen.</p>
            </div>
        </div>

    </div>
</div>
