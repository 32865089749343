import {Article} from "./Article";
import {IngredientList} from "./IngredientList";
import {ArticleList} from "./ArticleList";
import {Prices} from "./Prices";

export class ArticleGroup {
    article: Article;
    added_ingredients: IngredientList[];
    prices: Prices;
    size_index: number;
    sub_cat: number;
    amount: number;
    for_min_val: number;
    product_coupon: string;
    onlyCoupon = 0;

    constructor(article: Article,
                price: Prices,
                size: number,
                sub_cat: number,
                added_ingredients: IngredientList[],
                product_coupon = '',
                onlyCoupon = 0) {
        this.article = article;
        this.added_ingredients = added_ingredients;
        this.prices = price;
        this.size_index = size;
        this.sub_cat = sub_cat;
        this.amount = 1;
        this.for_min_val = article.for_min_val;
        this.product_coupon = product_coupon;
        this.onlyCoupon = onlyCoupon;
    }

    increaseAmount(){
        this.amount++;
    }

    decreaseAmount(){
        this.amount--;
        return this.amount == 0;
    }

}

