<div class="static-page-layout">
    <ng-container>
        <div>
            <div class="col-12 p-0 m-0 header-background-img header-wrapper headerBild"
                [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                <h1 class="static-page-title">Zahlungsarten</h1>
            </div>

            <div id="payments" class="col-md-6 col-xxl-3 mb-7 mb-xxl-0 text-center mx-auto">
                <h3 class="mp-font-large-yellow text-center">Zahlungsarten</h3>
                <ul class="list-unstyled mb-0">
                    <li>
                        <img src="assets/img/payments/bar.png" alt="Barzahlung" width="65">
                        <img src="assets/img/payments/paypal.png" alt="Paypal" width="65">

                        <img src="assets/img/payments/Icon_Kreditkarte.png" alt="Kreditkarte" width="65">

                    </li>
                    <li>
                        <img src="assets/img/payments/visa.png" alt="Kreditkarte - VISA" width="65">
                        <img src="assets/img/payments/mastercard.png" alt="Kreditkarte - Maestro" width="65">
                        <img src="assets/img/payments/american-express.png" alt="Kreditkarte - American Express"
                            width="65">
                    </li>
                    <li>
                        <img src="assets/img/payments/discover.png" alt="Discovery Club" width="65">
                        <img src="assets/img/payments/jcb.png" alt="JCB" width="65">
                        <img src="assets/img/payments/diners-club.png" alt="Diners Club" width="65">
                    </li>

                </ul>
            </div>


        </div>
    </ng-container>
</div>