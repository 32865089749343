import { User } from "../../_classes/User";
import { ReplaySubject } from "rxjs/internal/ReplaySubject";
import { FrontendService } from "./frontend.service";
import { CartService } from "./cart.service";
import { DateService } from './date.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./frontend.service";
import * as i3 from "./cart.service";
import * as i4 from "./date.service";
var UserService = /** @class */ (function () {
    //constructor   - - - - - - - - - - - - - - - -
    function UserService(document, frontend_service, cart_service, dateService) {
        this.document = document;
        this.frontend_service = frontend_service;
        this.cart_service = cart_service;
        this.dateService = dateService;
        //variable stuff  - - - - - - - - - - - - - - - -
        // user observable stuff
        this.user = new User();
        this.user_subject = new ReplaySubject(1);
        this.user_observable = this.user_subject.asObservable();
        // user observable stuff
        this.userLoadedFromCache = false;
        this.userLoadedFromCacheSubject = new ReplaySubject(1);
        this.userLoadedFromCacheObservable = this.userLoadedFromCacheSubject.asObservable();
        // token observable stuff
        this.token = '';
        this.token_subject = new ReplaySubject(1);
        this.token_observable = this.token_subject.asObservable();
        this.addressValid = false;
        this.addressValidSubject = new ReplaySubject(1);
        this.addressValidObservable = this.addressValidSubject.asObservable();
        this.streetValidSubject = new ReplaySubject(1);
        this.streetValidObservable = this.streetValidSubject.asObservable();
        this.zipValidSubject = new ReplaySubject(1);
        this.zipValidObservable = this.zipValidSubject.asObservable();
        this.user_set_cookie = false;
        this.user_has_account = false;
        this.cookie_jwt_state = 0;
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        //init functions
        // this.initUserData();
        this.user_subject.next(this.user);
        this.userLoadedFromCacheSubject.next(this.userLoadedFromCache);
        this.token_subject.next(this.token);
        this.loadUserSessionStorage();
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    // load cart session storage
    UserService.prototype.loadUserSessionStorage = function () {
        if (sessionStorage.getItem('tempUser')) {
            var loadedtempUser = JSON.parse(sessionStorage.getItem('tempUser'));
            this.user = loadedtempUser;
            this.user_subject.next(this.user);
            this.userLoadedFromCache = true;
            this.userLoadedFromCacheSubject.next(this.userLoadedFromCache);
        }
    };
    UserService.prototype.setUserAdressData = function (plz, city, district, street, street_number) {
        // this.user.setAdress(plz, city, district, street, street_number);
        this.user.adress.plz = plz;
        this.user.adress.city = city;
        this.user.adress.district = district;
        this.user.adress.street = street;
        this.user.adress.street_number = street_number;
        this.user_subject.next(this.user);
    };
    UserService.prototype.setUserData = function (title, fam_name, fir_name, no_marketing, phone, mail) {
        this.user.title = title;
        this.user.fam_name = fam_name;
        this.user.fir_name = fir_name;
        this.user.no_marketing = no_marketing;
        this.user.phone = phone;
        this.user.mail = mail;
        this.user.has_acc = true;
        this.setMinVal();
    };
    //function to print adress in a pretty way
    UserService.prototype.userAdressPrettyPrint = function () {
        var adress = [];
        adress.push(this.user.title + ' ' + this.user.fir_name + ' ' + this.user.fam_name);
        if (this.user.adress.firma != '') {
            adress.push('Firma: ' + this.user.adress.firma + '/' + this.user.adress.department);
        }
        adress.push(this.user.adress.street.replace('%', ' ') + ' ' + this.user.adress.street_number);
        if (this.user.adress.district == '') {
            adress.push(this.user.adress.plz + ' ' + this.user.adress.city.replace('%', ' '));
        }
        else {
            adress.push(this.user.adress.plz + ' ' + this.user.adress.city.replace('%', ' ') + ' Ortsteil: ' + this.user.adress.district);
        }
        return adress;
    };
    UserService.prototype.clearCurrentUser = function () {
        this.user.fir_name = '';
        this.user.fam_name = '';
        this.user.adress.city = '';
        this.user.adress.district = '';
        this.user.adress.street = '';
        this.user.adress.street_number = '';
        this.user.adress.plz = '';
        this.user.adress.etage = '';
        this.user.title = '';
        this.user.mail = '';
        this.user.phone = '';
        this.cart_service.setMinVal(null);
    };
    UserService.prototype.onReInitUserData = function () {
        this.initUserData();
    };
    UserService.prototype.setMinVal = function () {
        var _this = this;
        this.frontend_service.getMinVal(this.user.adress.plz, this.user.adress.district).subscribe(function (data) {
            _this.cart_service.setMinVal(data);
        });
    };
    UserService.prototype.setAddressValid = function (addressValid) {
        this.addressValidSubject.next(addressValid);
    };
    UserService.prototype.getAddressValid = function () {
        return this.addressValidSubject;
    };
    UserService.prototype.setStreetValid = function (streetValid) {
        this.streetValidSubject.next(streetValid);
    };
    UserService.prototype.getStreetValid = function () {
        return this.streetValidSubject;
    };
    UserService.prototype.setZipValid = function (zipValid) {
        this.zipValidSubject.next(zipValid);
    };
    UserService.prototype.getZipValid = function () {
        return this.zipValidSubject;
    };
    UserService.prototype.getEMail = function () {
        return this.user.mail;
    };
    UserService.prototype.changeMail = function (new_mail) {
        this.user.mail = new_mail;
        this.user_subject.next(this.user);
    };
    //JWT funktions - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    UserService.prototype.deleteToken = function () {
        this.updateToken('');
        this.createCookieEntry('jwt', '');
    };
    UserService.prototype.updateToken = function (token) {
        this.token = token;
        this.createCookieEntry('jwt', token);
        this.token_subject.next(this.token);
    };
    //cookie funktions - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //saves all necessary infos in the coookie - should only called when user allowed
    UserService.prototype.saveUserToCookie = function () {
        // ACHTUNG: Nutzerdaten im Cookie speichern ist jetz nich wirklich clever. Das muss unbedingt noch umgebaut
        // werden! Cookies können gestohlen werden! Beispielumsetzung:
        // https://www.php-einfach.de/experte/php-codebeispiele/loginscript/angemeldet-bleiben/
        this.createCookieEntry('f_name', this.user.fir_name);
        this.createCookieEntry('l_name', this.user.fam_name);
        this.createCookieEntry('city', this.user.adress.city);
        this.createCookieEntry('distr', this.user.adress.district);
        this.createCookieEntry('street', this.user.adress.street);
        this.createCookieEntry('hnr', this.user.adress.street_number);
        this.createCookieEntry('plz', this.user.adress.plz);
        this.createCookieEntry('floor', this.user.adress.etage);
        this.createCookieEntry('title', this.user.title);
        this.createCookieEntry('mail', this.user.mail);
        this.createCookieEntry('phone', this.user.phone);
        this.createCookieEntry('ud_set', 'true');
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    UserService.prototype.initUserData = function () {
        var _this = this;
        // check if token is set
        var jwt = this.getCookieEntry('jwt');
        if (jwt != false) {
            // fetch user data from BE + init user object with request data
            this.frontend_service.fetchAdressData(jwt)
                .subscribe(function (data) {
                if (data != 0) {
                    _this.token = jwt;
                    _this.token_subject.next(_this.token);
                    _this.user_has_account = true;
                    _this.loadUserFromResponse(data);
                    _this.setMinVal();
                }
                else {
                    //token löschen
                    _this.deleteToken();
                }
            });
        }
        if (!jwt) {
            // if not: check if user data is set in the cookie
            if (this.getCookieEntry('ud_set') != false) {
                // init user object with cookie data
                this.loadUserFromCookie();
                this.user_set_cookie = true;
                this.setMinVal();
            }
        }
    };
    UserService.prototype.loadUserFromCookie = function () {
        this.user.fir_name = this.getCookieEntry('f_name');
        this.user.fam_name = this.getCookieEntry('l_name');
        this.user.adress.city = this.getCookieEntry('city');
        this.user.adress.district = this.getCookieEntry('distr');
        this.user.adress.street = this.getCookieEntry('street');
        this.user.adress.street_number = this.getCookieEntry('hnr');
        this.user.adress.plz = this.getCookieEntry('plz');
        this.user.adress.etage = this.getCookieEntry('floor');
        this.user.title = this.getCookieEntry('title');
        this.user.mail = this.getCookieEntry('mail');
        this.user.phone = this.getCookieEntry('phone');
    };
    UserService.prototype.loadUserFromResponse = function (response) {
        this.user.fir_name = response.first_name;
        this.user.fam_name = response.last_name;
        this.user.adress.city = response.city;
        this.user.adress.district = response.district;
        this.user.adress.street = response.street;
        this.user.adress.street_number = response.house_no;
        this.user.adress.plz = response.zip;
        this.user.adress.etage = response.add_adress;
        this.user.title = response.title;
        this.user.mail = response['e-mail'];
        this.user.phone = response.phone_number;
        this.setMinVal();
    };
    UserService.prototype.createCookieEntry = function (name, val) {
        var date = this.dateService.newDate();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        var delete_cookie = "expires=" + date.toUTCString();
        this.document.cookie = name + '=' + val + ';' + delete_cookie;
    };
    UserService.prototype.getCookieEntry = function (entry) {
        var cookie_array = this.document.cookie.split(';');
        var searched_entry = entry + '=';
        //iterate through the cookie-fields
        for (var i = 0; i < cookie_array.length; i++) {
            var cookie_elem = cookie_array[i];
            //delete unneccessary spaces
            while (cookie_elem.charAt(0) === ' ') {
                cookie_elem = cookie_elem.substring(1);
            }
            //get data substring
            if (cookie_elem.indexOf(searched_entry) === 0) {
                return cookie_elem.substring(searched_entry.length, cookie_elem.length);
            }
        }
        return false;
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.DOCUMENT), i0.inject(i2.FrontendService), i0.inject(i3.CartService), i0.inject(i4.DateService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
