import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from "rxjs/internal/Subscription";
import { FrontendService } from "../../../../services/frontend.service";
import { IngredientList } from "../../../../../_classes/IngredientList";
import { Ingredient } from "../../../../../_classes/Ingredient";
import { MainService } from 'src/app/services/main.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-add-ingredients',
    templateUrl: './add-ingredients.component.html',
    styleUrls: ['./add-ingredients.component.scss']
})
export class AddIngredientsComponent implements OnInit, OnDestroy {
    //public stuff  - - - - - - - - - - - - - - - -
    @Input() sub_cat_id: number;                                // ID der Unterkategorie
    @Input() ingredient_list: any[];                            // hinzufügbare Zutaten (sortiert nach Zutatenkategorie)
    @Input() current_size_index: number;
    @Input() added_ing_list: IngredientList[] = [];             // beinhaltet alle hinzugefügten Zutaten
    @Input() price_ing_sum: number[] = [];                     // Array mit summierten Preisen für die hinzugefügten Zutaten
    @Input() amount: any[];                                     //[ing_cat][ing_index] - datenstruktur der ingredient_list, beinhaltet Anzehl + Summe
    @Input() onLunch: boolean;
    @Input() potatoAdded: boolean;
    @Input() notFreeTabQ: number;
    @Input() ingredientListLoaded: boolean;
    @Input() showFreeAddNow: number;
    @Output() changed_ing_emitter = new EventEmitter();         // informiert über hinzugefügte Zutat
    @Output() sub_ing_emitter = new EventEmitter<number>();     // informiert über dezimierung Anzahl Zutat
    @Output() add_ing_emitter = new EventEmitter<any>();     // informiert über dezimierung Anzahl Zutat


    ready = false;                                              // true, wenn alle relevanten Daten geladen
    show_tab: number = 0;                                       // helper-variable für HTML -> welcher Tab/Zutatenkategorie ist aktuell aktiv
    isAdded: boolean = false;
    image_url: string;

    /* show the info box for the ingredient */
    showInfoBox = false;
    /* holds the current infobox ingredient information */
    infoBoxIngredient: any;

    //constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private main_service: MainService,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //init functions
    ngOnInit() {
        this.image_url = this.main_service.image_url;
        this.ready = true;

        const scroller = this.pageScrollService;
        const scrollerDoc = this.document;
        /*let target = ".article-price-wrap";*/
        let target = ".target-oben";
        let offset = this.positionAbfrage();
        setTimeout( function() {
            scroller.scroll({
                document: scrollerDoc,
                // scrollTarget: '.article-price-wrap',
                scrollTarget: target,
                scrollOffset: offset,
                duration: 600
            });
        }, 500 );
        for ( let i = 0; i < this.ingredient_list.length; i++ ) {
            if (this.ingredient_list[i].sizes_free_addables.length == 0
                        || (this.ingredient_list[i].sizes_free_addables[this.current_size_index].length != 0 && !this.onLunch && this.ingredient_list[i].sizes_free_addables[this.current_size_index].only_at_lunch)
                    ) {
                this.show_tab = i;
                break;
            } else if (typeof this.ingredient_list[i].sizes_free_addables[this.current_size_index] == 'undefined') {
                this.show_tab = i;
                break;
            }
        }
    }

    ngOnDestroy() {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    onLog() {

    }

    onAddIng(ing_cat_index: number, ing_index: number) {
        // check if item is already in array
        const index = this.includeIngredient(this.ingredient_list[ing_cat_index].ingredients[ing_index]);
        // show overlay for item when added
        if (document.getElementById('item-added-' + ing_index)) {
            document.getElementById('item-added-' + ing_index).classList.remove('item-added-hidden');
            setTimeout(() => {
                document.getElementById('item-added-' + ing_index).classList.add('item-added-hidden');
                this.goNextTab(this.ingredient_list[ing_cat_index].ingredient_group_master);
            }, 1000);
        } else {
            this.goNextTab(this.ingredient_list[ing_cat_index].ingredient_group_master);
        }
        this.add_ing_emitter.emit({index: index, ing_cat_index: ing_cat_index, ing_index: ing_index});
    }

    goNextTab(catId: any) {

        const categoryNoNeedJump = [3, 84];

        if (categoryNoNeedJump.includes(catId)) {
            // stay on current tab
            return;
        }
        if (this.notFreeTabQ > this.show_tab + 1) {
            // go to next tab
            this.show_tab++;
            return;
        }
    }

    // sub ing -> calls function in parent class
    onSubIng(ing_cat_index: number, ing_index: number) {
        const index = this.includeIngredient(this.ingredient_list[ing_cat_index].ingredients[ing_index]);
        // show overlay for item when removed
        document.getElementById('item-removed-' + ing_index).classList.remove('item-removed-hidden');

        setTimeout(() => {
            document.getElementById('item-removed-' + ing_index).classList.add('item-removed-hidden');
        }, 1000);
        // call method of parent to remove ingredient
        this.sub_ing_emitter.emit(index);
    }

    onOpenInfoBox(id: number) {
        /* request current article form api */
        this.frontend_service.getIngredientByID(id).then((data) => {
            /* fill in variables */
            this.infoBoxIngredient = data;
            // CODE HERE...

            /* open popup with info */
            this.showInfoBox = true;
        });
    }

    private subIngIsDisabled(i_cat, i_ing) {

        for ( let i = 0; i <= this.added_ing_list.length - 1; i++ ) {
            if ( this.added_ing_list[i].ing_cat_index === i_cat && this.added_ing_list[i].ing_index === i_ing ) {
                if ( this.added_ing_list[i].free_amount === this.added_ing_list[i].amount ) {
                    return true;
                }
            }
        }

        if ( this.amount[i_cat][i_ing] === 0  ) {
            return true;
        }

        return false;

    }

    private includeIngredient(ing: Ingredient) {
        if (this.added_ing_list.length == 0) return -2;
        for (let i = 0; i < this.added_ing_list.length; i++) {
            if (ing.id === this.added_ing_list[i].ingredients.id) {
                return i;
            }
        }

        return -1;
    }

    checkFreeSize(indx) {
        if (this.ingredient_list[indx].sizes_free_addables.length == 0) {
            return false;
        } else if (typeof this.ingredient_list[indx].sizes_free_addables[this.current_size_index] == 'undefined') {
            return false;
        } else if (this.ingredient_list[indx].sizes_free_addables[this.current_size_index].only_at_lunch && !this.onLunch) {
            return false;
        }
        return true;
    }

    positionAbfrage() {
        let header = this.document.getElementById('header');
        /*console.log("HEADER", header);*/
        let hoehe = header.clientHeight;
        return hoehe;
    }
}

