import { PaymentsService } from './payments.service';
import * as i0 from "@angular/core";
import * as i1 from "./payments.service";
var PaypalService = /** @class */ (function () {
    function PaypalService(paymentsService) {
        this.paymentsService = paymentsService;
        this.libIsLoaded = false;
    }
    PaypalService.prototype.loadLib = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.libIsLoaded === false) {
                _this.paymentsService.load().then(function (payments) {
                    _this.libIsLoaded = true;
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = 'https://www.paypal.com/sdk/js?client-id=' + payments.paypal.settings.clientId + '&commit=true&currency=EUR&locale=de_DE';
                    script.onload = function () {
                        console.warn('PayPal Lib Loaded');
                        resolve();
                    };
                    document.getElementsByTagName('head')[0].appendChild(script);
                });
            }
            else {
                resolve();
            }
        });
    };
    PaypalService.ngInjectableDef = i0.defineInjectable({ factory: function PaypalService_Factory() { return new PaypalService(i0.inject(i1.PaymentsService)); }, token: PaypalService, providedIn: "root" });
    return PaypalService;
}());
export { PaypalService };
