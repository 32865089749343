import { ReplaySubject } from "rxjs/internal/ReplaySubject";
import { FrontendService } from "./frontend.service";
import { UserService } from "./user.service";
import { Router } from "@angular/router";
import { CartService } from "./cart.service";
import * as i0 from "@angular/core";
import * as i1 from "./frontend.service";
import * as i2 from "./user.service";
import * as i3 from "./cart.service";
import * as i4 from "@angular/router";
var AuthService = /** @class */ (function () {
    //constructor   - - - - - - - - - - - - - - - -
    function AuthService(frontend_service, user_service, cart_service, router) {
        var _this = this;
        this.frontend_service = frontend_service;
        this.user_service = user_service;
        this.cart_service = cart_service;
        this.router = router;
        this.valid_login_subject = new ReplaySubject(1);
        this.valid_login_observable = this.valid_login_subject.asObservable();
        this.error_message_login_failed_subject = new ReplaySubject(1);
        this.error_message_login_failed_observable = this.error_message_login_failed_subject.asObservable();
        this.valid_sign_up_subject = new ReplaySubject(1);
        this.valid_sign_up_observable = this.valid_login_subject.asObservable();
        this.error_message_signup_failed_subject = new ReplaySubject(1);
        this.error_message_signup_failed_observable = this.error_message_signup_failed_subject.asObservable();
        this.user_service.token_observable.subscribe(function (token) {
            _this.token = token;
        });
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    //creates a new account with mail and pw on firebase
    AuthService.prototype.signupUser = function (email, password, titel, first_name, last_name, plz, city, district, street, hnr, floor, phone) {
    };
    AuthService.prototype.updateEMail = function (new_mail, password) {
    };
    AuthService.prototype.updatePassword = function (old_pw, new_pw) {
    };
    //logging the user in
    AuthService.prototype.loginUser = function (email, password) {
        this.user_service.clearCurrentUser();
    };
    AuthService.prototype.getToken = function () {
    };
    //returns true, if user is loged in
    AuthService.prototype.isAuthenticated = function () {
        return this.token.length > 5;
    };
    AuthService.prototype.logoutUser = function () {
        this.cart_service.setMinVal('0');
        this.user_service.deleteToken();
        this.user_service.clearCurrentUser();
    };
    AuthService.prototype.resetPassword = function (email) {
    };
    AuthService.prototype.fetchAdressData = function () {
        var _this = this;
        this.frontend_service.fetchAdressData(this.token).subscribe(function (data) {
            if (data['result'] == null) {
                console.error('invalid token');
                return;
            }
            _this.user_service.setUserAdressData(data['zip'], data['city'], data['district'], data['street'], data['house_no']);
            _this.user_service.setUserData(data['title'], data['last_name'], data['first_name'], data['no_marketing'] == 1, data['phone_number'], data['e-mail']);
        });
    };
    AuthService.prototype.deleteUser = function (password) {
        var _this = this;
        // delete user in direct us
        this.frontend_service.deleteUser(this.token).subscribe(function (result) {
            //if response is positive
            if (result == 1) {
                // delete firebase acc
                _this.user_service.deleteToken();
                _this.user_service.clearCurrentUser();
                delete _this.token;
            }
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.FrontendService), i0.inject(i2.UserService), i0.inject(i3.CartService), i0.inject(i4.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
