<div class="static-page-layout">
    <div class="row">
        <div class="col-md-12 p-0">
            <div class="header-wrapper-small d-flex justify-content-center align-items-center">
                <div class="categorie-title d-flex justify-content-center align-items-center justify-content-md-end">
                    <h1 class="static-page-title">Impressum</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="inhalt-container px-4">
        <ng-container>
            <div class="center-content">
                <h3>Angaben gemäß § 5 TMG</h3>

                <p>
                    <span>David Krieger </span> <br/>
                    {{ storeData?.name }}<br/>
                    {{ storeData?.street }}<br/>
                    {{ storeData?.zip }} {{ storeData?.city }}<br>
                    Umsatzsteuer-ID: DE292432685
                </p>


                <h3>Kontakt</h3>
                <p>Telefon: {{ storeData?.phone }}<br/>
                    E-Mail: {{ storeData?.email }}</p>

                <h3>Konzeption, Webdesign und Webentwicklung</h3>

                <p>Maho Werbeagentur für Webdesign | Grafik | Fotografie</p>

                <p>Otto-Mohr-Straße 5</p>
                <p>D-01237 Dresden</p>
                <p>+49 (0)351/20288057</p>
                <p>info(at)maho-werbeagentur.de</p>

                <p> <a href="https://maho-werbeagentur.de/">www.maho-agentur.de</a></p>

                <h3>Streitschlichtung</h3>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                        href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener">https://ec.europa.eu/consumers/odr</a>.<br/>
                    Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.</p>

                <p><strong>Haftung für Inhalte</strong></p>
                <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
                    den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                    jedoch
                    nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu
                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                    allgemeinen
                    Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis
                    einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden
                    wir diese Inhalte umgehend entfernen.</p>
                <p><strong>Haftung für Links</strong></p>
                <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
                    Einfluss
                    haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
                    der
                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die
                    verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
                <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                    Anhaltspunkte einer
                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige
                    Links
                    umgehend entfernen.</p>
                <p><strong>Urheberrecht</strong></p>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
                    gestattet.</p>
                <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                    Urheberrechte
                    Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                    trotzdem
                    auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden
                    von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

            </div>
        </ng-container>
    </div>
</div>
