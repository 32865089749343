import { Component, OnInit } from '@angular/core';
import { FrontendService } from 'src/app/services/frontend.service';

@Component({
  selector: 'app-pizza-party-mega-deal',
  templateUrl: './pizza-party-mega-deal.component.html',
  styleUrls: ['./pizza-party-mega-deal.component.scss']
})
export class PizzaPartyMegaDealComponent implements OnInit {

  storeData: any;
  sizesArr: any;

  sizeSmall = true;

  headerbild_Daves = "/assets/img/mittagsangebote/Daves_Headerbild_Obergruppen_304x170pxUeberUns+MegaDeal/Daves_Headerbild_Obergruppen_304x170px_MegaDeal.jpg";
  headerbild_Daves_Desktop = "/assets/img/mittagsangebote/Fwd_Daves_Headerbild_Obergruppen_2184x656pxUeberUns+MegaDeal/Daves_Headerbild_Obergruppen_2184x656px_MegaDeal.jpg";


  constructor(private frontend_service: FrontendService) { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
    window.onresize = this.setHeaderbild;
    this.setHeaderbild();
    this.getSizes();
  }

  // Headerbild setzen
  setHeaderbild() {
    let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
    if (small == this.sizeSmall) return;
    this.sizeSmall = small;
    /*console.log("Kleines Bild = ",small);*/
    if (small === false) {
      this.headerbild_Daves = this.headerbild_Daves_Desktop;
    }
    else {
      // console.log(this.headerbild_Mittags_MP, "MP-MOBIL");
      // console.log(this.headerbild_Mittags_PB, "PB-MOBIL");
    }
  }

  getHeaderBild() {
    var tmpUrl;
    tmpUrl= this.headerbild_Daves;
    return  tmpUrl;
  }

  private getSizes() {
    this.sizesArr = [];
    this.frontend_service.getMegadealSizes(347).subscribe(data => {
        if (!data) {
            console.error('invalid token');
            return;
        }
        this.sizesArr = data;
        for (let i=0; this.sizesArr.length > i; i++) {
          if (data[i].name == 'Ø 26er') {
            data[i].name = 'Small';
          }
          if (data[i].name == 'Ø 32er') {
            data[i].name = 'Medium';
          }
        }
        console.log(data);
    });
}

}
