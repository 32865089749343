/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../wrapper-components/3-other/login/login.component.ngfactory";
import * as i10 from "../wrapper-components/3-other/login/login.component";
import * as i11 from "../services/auth.service";
import * as i12 from "../services/user.service";
import * as i13 from "@angular/router";
import * as i14 from "./test.component";
import * as i15 from "../services/frontend.service";
import * as i16 from "../services/main.service";
import * as i17 from "@angular/material/snack-bar";
import * as i18 from "@angular/common";
var styles_TestComponent = [i0.styles];
var RenderType_TestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestComponent, data: {} });
export { RenderType_TestComponent as RenderType_TestComponent };
export function View_TestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 71, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 45, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hauptkategorien f\u00FCr Navigation "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogMastercats() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log master_cats"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Artikelliste, geordnet nach sub_cat f\u00FCr gegebene master_cat"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogArricleList(3, "pickup", "2022.07.01") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log article_list"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Artikeldetail, f\u00FCr gegebene ID, Gr\u00F6\u00DFe"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogArticleDetail(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log article detail"])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Zutaten f\u00FCr eine bestimmte aub_cat"])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogIngList(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log ing list"])), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Men\u00FCartikel liste anzeigen"])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogMenArtList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log article for menue"])), (_l()(), i1.ɵeld(27, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Kontrolliere ob/wie lange Shop offen ist + ob Mittagsangebot"])), (_l()(), i1.ɵeld(29, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogOH() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log current opening_hours"])), (_l()(), i1.ɵeld(32, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00D6ffnungszeit aktuell ausgeben"])), (_l()(), i1.ɵeld(34, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogOpeningHours() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log OH"])), (_l()(), i1.ɵeld(37, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Aktionsartikel anzeigen"])), (_l()(), i1.ɵeld(39, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogOfferArticle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log spec offer articles"])), (_l()(), i1.ɵeld(42, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Slider Informationen auslesen"])), (_l()(), i1.ɵeld(44, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogSliderStuff() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["log slider stuff"])), (_l()(), i1.ɵeld(47, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Bootstrap test button: "])), (_l()(), i1.ɵeld(49, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(50, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["data-html", "true"], ["data-toggle", "tooltip"], ["title", "<em>Tooltip</em> <u>with</u> <b>HTML</b>"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Tooltip with HTML "])), (_l()(), i1.ɵeld(52, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(54, 16777216, null, null, 2, "button", [["matTooltip", "hier ist der tooltip man!"], ["matTooltipPosition", "above"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 55).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 55)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 55)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 55).toggle() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(55, 147456, [["tooltip", 4]], 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵted(-1, null, [" Tooltip "])), (_l()(), i1.ɵeld(57, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" snackbar "])), (_l()(), i1.ɵeld(59, 0, null, null, 1, "button", [["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSnackBar("Coca Cola", "in den Warenkorb gelegt") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show snack-bar"])), (_l()(), i1.ɵeld(61, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(62, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(63, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(64, 0, null, null, 2, "div", [["style", "padding: 5%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(65, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Adresseingabe"])), (_l()(), i1.ɵeld(67, 0, null, null, 4, "div", [["style", "padding: 5%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(68, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵeld(70, 0, null, null, 1, "app-login", [], null, null, null, i9.View_LoginComponent_0, i9.RenderType_LoginComponent)), i1.ɵdid(71, 245760, null, 0, i10.LoginComponent, [i11.AuthService, i12.UserService, i13.Router], null, null)], function (_ck, _v) { var currVal_0 = "above"; var currVal_1 = "hier ist der tooltip man!"; _ck(_v, 55, 0, currVal_0, currVal_1); _ck(_v, 71, 0); }, null); }
export function View_TestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-test", [], null, null, null, View_TestComponent_0, RenderType_TestComponent)), i1.ɵdid(1, 245760, null, 0, i14.TestComponent, [i15.FrontendService, i16.MainService, i17.MatSnackBar, i18.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestComponentNgFactory = i1.ɵccf("app-test", i14.TestComponent, View_TestComponent_Host_0, {}, {}, []);
export { TestComponentNgFactory as TestComponentNgFactory };
